import React ,{useEffect, useState}from 'react';
import { Link,useNavigate } from 'react-router-dom';
import UsersService from '../../../services/Users';
import { Modal } from 'react-bootstrap';
import EmployerImage from '../../../services/image/EmployerImage';
import AlertMessage from '../../../components/AlertMessage';

const EmployerSideBar = (props) => {
  const [showLogout, setShowLogOut] = useState(false);
  const [image, setImage] = useState(null)
	const [employerImage, setEmployerImage] = useState([]);
	const [imageModal, setImageModal] = useState(false);
	const [imageData, setImageData] = useState({});
 const [alertState,setAlertState]=useState({})

  let userId = JSON.parse(localStorage.getItem('userId'));
  const userName = localStorage.getItem("userName");	
	const navigate = useNavigate();
    
  const toggleLogOutModal = async () => {
		setShowLogOut(!showLogout);
	};


  const onImageChange = (img) => {
		setEmployerImage(img.target.files);
		if (img.target.files && img.target.files[0].name) {
			setImageModal(true);
			setImage(URL.createObjectURL(img.target.files[0]));
			console.log(img.target.files, "image onchange")
			console.log(img.target.files[0].name, "image file name")
		}
	}

  const getAllImages = async () => {
		
		try {
			const res = await EmployerImage.getEmployerImageById(userId);
			if (res.status === 200) {
				setImageData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		
	}


  useEffect(()=>{
    getAllImages();
  },[`${userId}`])
	const handleLogout = async () => {
		try {
		  const userEmail = localStorage.getItem("userMailMob");
		  const userPassword = localStorage.getItem("password");
		  const userLogout = {};
		  userLogout.email = userEmail;
		  userLogout.password = userPassword;
		  const res = await UsersService.logoutUser(userLogout);
		  if (res.status === 200) {
      window.localStorage.clear();
      window.localStorage.setItem('userId', 0);
      window.localStorage.setItem('companyId',0);
			navigate("/");
		  }
		} catch (error) {
		  console.log(error);
		}
		//window.location.reload();
	};
  const activeMenu = props.activemenu;
  
  
  const sideMenu = [{ icon: 'fa fa-user-o', link: 'company-profile', menuName: 'Company Profile' },
    { icon: 'fa fa-file-text-o', link: 'job-post', menuName: 'Post A Job' },
    { icon: 'fa fa-briefcase', link: 'manage-job', menuName: 'Manage jobs' },
    { icon: 'fa fa-id-card-o', link: 'resume', menuName: 'Resume' },
    // { icon: 'fa-solid fa-user-group', link: 'employer-team', menuName: 'Employer Team' },
    

    ];

    const SidemenuList = sideMenu.map((item,ind) => {
      return (
          <li key={ind}><Link to={"/" + item.link} className={item.link === activeMenu && "active"}>
              <i className={item.icon} aria-hidden="true"></i>
              <span>{item.menuName}</span></Link>
          </li>
      );
  });


  const uploadHandler = async () => {
		try {
			const formdata = new FormData();
			formdata.append('userId', userId);
			formdata.append('file', employerImage[0]);

			const res = await EmployerImage.imageUpload(formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},
			});

			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: " Employer Profile picture Upload successfully" })
				window.location.reload();
			}

		} catch (error) {
			console.log(error);
		}
	}
	const imageUpdateHandler = async () => {
		try {
			const ID = await imageData.id;

			const formdata = new FormData();
			formdata.append('file', employerImage[0]);

			const res = await EmployerImage.updateUploadImage(ID, formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},
			});
			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: "Employer Profile picture Update successfully " })
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	}


  function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({ open: false });
	};

  return (
    <>
    <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
        <div className="sticky-top">
        <div className="candidate-info company-info">
            <div className="candidate-detail text-center">
                <div className="canditate-des">
                    <Link to={"#"}>
                    {
																		!imageData.image ?
																			<img alt="" src="images/logo/icon3.jpg" />
																			:
																			(image ?
																				<img src={image} style={{ width: '100%', height: "100%", borderRadius: "50%" }} />
																				:

																				<img src={imageData.image} style={{ width: '100%', height: "100%", borderRadius: "50%" }} />)

																	}
                    </Link>
                    <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
                        <input type="file" className="update-flie" onChange={(e) => onImageChange(e)} accept="image/x-png, image/gif, image/jpeg"/>
                        <i className="fa fa-pencil"></i>
                    </div>
                </div>
                <div className="candidate-title">
                    <h4 className="m-b5"><Link to={"#"}>@{userName}</Link></h4>
                </div>
            </div>
            <ul>
                {/* <li><Link to={"/company-profile"} className="active">
                    <i className="fa fa-user-o" aria-hidden="true"></i> 
                    <span>Company Profile</span></Link></li>
                <li><Link to={"/job-post"}>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i> 
                    <span>Post A Job</span></Link></li>
               
                <li><Link to={"/manage-job"}>
                    <i className="fa fa-briefcase" aria-hidden="true"></i> 
                    <span>Manage jobs</span></Link></li>
                <li><Link to={"/browse-candidates"}>
                    <i className="fa fa-id-card-o" aria-hidden="true"></i>
                    <span>Resume</span></Link></li> */}
                    {SidemenuList}
                
                <li onClick={toggleLogOutModal} ><Link to={"#"}>
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    <span>Log Out</span></Link></li>
            </ul>
        </div>

    </div> 
          <Modal
                show={showLogout}
                onHide={toggleLogOutModal}
                className="modal fade lead-form-modal"
                style={{marginTop:"80px"}}
                >
                <div className="modal-dialog" role="document" >
                  <div className="modal-content">
                    <button
                      type="button"
                      className="close"
                      style={{ marginRight:-7, marginTop: "2px" }}
                      onClick={toggleLogOutModal}
                    >
                      <span aria-hidden="true" >&times;</span>
                    </button>
                    <div className="modal-body row m-a0 clearfix">
                      <div
                        className="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0"
                        style={{
                          backgroundImage: "url(images/background/bg3.jpg)",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}>
                        <div className="form-info text-white align-self-center">
                          <p className="m-t15">
                            Get started with JobYard to receive latest job
                            alerts based on your preferences.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 p-a0">
                        <div className="lead-form browse-job text-left">
                          <h5
                            style={{ textAlign: "center" }}
                            className="m-t0 mb-5 font-16">
                            Do You Want To Logout ?
                          </h5>
                          <div
                            className="clearfix "
                            style={{
                            marginTop: 130,
                            textAlign: "center",
                            justifyItems: "center",
                            marginRight: "45px",
                            }}>
                            <button
                              style={{ borderRadius: 100, textAlign: "center" }}
                              type="button"
                              className=" btn btn-success mr-4 ml-5 "
                              onClick={toggleLogOutModal}
                            >
                              No{" "}
                            </button>
                            <button
                              style={{ borderRadius: 100 }}
                              type="button"
                              className=" btn btn-danger "
                              onClick={handleLogout}
                            >
                              Yes{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </Modal>
          <Modal show={imageModal} onHide={setImageModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog my-0 mx-0" role="document">
					<div className="modal-content">
						<div className="modal-header" >
							<h5 className="modal-title" id="RegionMaster">Image Upload</h5>
							<button type="button" className="close" onClick={() => { setImageModal(false) }} >
								<span aria-hidden="true" >&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{
								imageData.image ?
									<p className="text-center" style={{ color: 'red' }}> Are you sure to Update this Image ? </p> :
									<p className="text-center" style={{ color: 'red' }}> Are you sure to Upload this Image ? </p>

							}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" onClick={() => { setImageModal(false); setImage(null) }}>Cancel</button>
							{
								imageData.image ?
									<button type="button" className="btn btn-success" onClick={imageUpdateHandler}  >Update</button> :
									<button type="button" className="btn btn-success" onClick={uploadHandler}  >Upload</button>

							}
						</div>
					</div>
				</div>
			</Modal>
    </>
 )
};

export default EmployerSideBar;