import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import AdminBlogService from '../../services/admin/AdminBlogService';
import AdminblogImageService from '../../services/admin/AdminblogImageService';

const BlogDetails = () => {

    const [allBlogdetails, setAllBlogDetails] = useState([])
    const [keyword, setKeyword] = useState();
    const [blogId, setBlogId] = useState(0)
    const [blogImage, setBlogImage] = useState([]);
    const [blogimageData, setBlogImagedata] = useState([]);

        const BlogId=useParams()

    const getAllBlogDetails = async () => {
        const res = await AdminBlogService.getBlog();
        if (res.status === 200) {
            setAllBlogDetails(res.data)
        }
    }


    const getBlogImage = async () => {
        const res = await AdminblogImageService.getBlogImage()
        if (res.status === 200) {
            setBlogImage(res.data)

        }
    }

    useEffect(() => {
        getAllBlogDetails();
        getBlogImage();
    }, [])



    const getBlogImageById = async () => {
        const res = await AdminblogImageService.getBlogImageById(blogId)
        if (res.status === 200) {
            setBlogImagedata(res.data)
        }
    }

    useEffect(() => {
        getBlogImageById()
    }, [`${blogId}`])


    const BlogHandler = (id) => {
        setBlogId(id);
    }

    const imageList = []

    for (let i = 0; i < allBlogdetails.length; i++) {
        for (let j = 0; j < blogImage.length; j++) {
            if (allBlogdetails[i].id === blogImage[j].blogId) {
                imageList.push(blogImage[j])
            }
        }
    }

    return (
        <>
            <Header />
            <div className="page-content bg-white">

                <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                    <div className="container">
                        <div className="dez-bnr-inr-entry">
                            <h1 className="text-white">Blog Details</h1>

                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Blog Details</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="content-area">
                    <div className="container">
                        <div className="row">
                            {blogId === 0 ?
                                <div className="col-lg-8 col-md-7 m-b10">

                                    <div className="blog-post blog-single blog-style-1">


                                        {allBlogdetails.slice(0, 1)

                                            .map((val, ind) => {
                                                return (
                                                    <>
                                                        <div className="dez-post-meta" key={{ ind }}>
                                                            <ul className="d-flex align-items-center">



                                                                <li className="post-date"><i className="fa fa-calendar"></i>{val.publishdate}</li>
                                                                <li className="post-author"><i className="fa fa-user"></i>By <Link to="#">{val.authorName}</Link> </li>
                                                                {/* <li className="post-comment"><i className="fa fa-comments-o"></i><Link to="#">5k</Link> </li> */}
                                                            </ul>
                                                        </div>
                                                        <div className="dez-post-title">
                                                            <h4 className="post-title m-t0">{val.blogTitle}</h4>
                                                        </div>
                                                        {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "C") { return itm } }).map((value, index) => {
                                                            return (
                                                                <div className="dez-post-media dez-img-effect zoom-slow m-t20" key={index}><img src={value.image} style={{ width: "100%", height: "100%" }} alt="" /></div>
                                                            )

                                                        })}
                                                        <div className="dez-post-text">
                                                            <p>{val.content}</p>

                                                            <blockquote>{val.quotation}</blockquote>
                                                            {/* <p>{val.secondContent}</p> */}
                                                            {/* <h5>Completely Responsive</h5> */}
                                                            {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "L") { return itm } }).map((value, index) => {
                                                                return (
                                                                    <img className="alignleft blog-side-img" src={value.image} style={{ width: "40%", height: "25%" }} alt="" />
                                                                )

                                                            })}
                                                            <p>{val.secondContent}</p>
                                                            <div className="dez-divider bg-gray-dark"></div>
                                                            {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "R") { return itm } }).map((value, index) => {
                                                                return (
                                                                    <img className="alignright blog-side-img" src={value.image} style={{ width: "40%", height: "25%" }} alt="" />
                                                                )

                                                            })}
                                                            <p>{val.thirdContent}</p>
                                                        </div>


                                                        <div className="dez-post-tags clear">
                                                            <div className="post-tags">
                                                                <Link to="#">Child </Link>
                                                                <Link to="#">Eduction </Link>
                                                                <Link to="#">Money </Link>
                                                                <Link to="#">Resturent </Link>
                                                            </div>
                                                        </div>
                                                        <div className="dez-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>
                                                        <div className="share-details-btn">
                                                            <ul>
                                                                <li><h5 className="m-a0">Share Post</h5></li>
                                                                <li><Link to="#" className="site-button facebook button-sm"><i className="fa fa-facebook"></i> Facebook</Link></li>
                                                                <li><Link to="#" className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                                                <li><Link to="#" className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                                                <li><Link to="#" className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                                                <li><Link to="#" className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                                                <li><Link to="#" className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                                            </ul>
                                                        </div>
                                                    </>

                                                )
                                            })
                                        }
                                    </div>


                                </div>

                                :

                                <div className="col-lg-8 col-md-7 m-b10">

                                    <div className="blog-post blog-single blog-style-1">


                                        {

                                            allBlogdetails.filter((item) => {
                                                if (item.id === blogId || item.id===BlogId) { return (item) }

                                            }).map((val) => {
                                                return (
                                                    <>
                                                        <div className="dez-post-meta">
                                                            <ul className="d-flex align-items-center">



                                                                <li className="post-date"><i className="fa fa-calendar"></i>{val.publishdate}</li>
                                                                <li className="post-author"><i className="fa fa-user"></i>By <Link to="#">{val.authorName}</Link> </li>
                                                                {/* <li className="post-comment"><i className="fa fa-comments-o"></i><Link to="#">5k</Link> </li> */}
                                                            </ul>
                                                        </div>
                                                        <div className="dez-post-title">
                                                            <h4 className="post-title m-t0">{val.blogTitle}</h4>
                                                        </div>
                                                        {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "C") { return itm } }).map((value, index) => {
                                                            return (
                                                                <div className="dez-post-media dez-img-effect zoom-slow m-t20"><img src={value.image} alt="" /></div>
                                                            )
                                                        })}


                                                        <div className="dez-post-text">
                                                            <p>{val.content}</p>

                                                            <blockquote>{val.quotation}</blockquote>
                                                            {/* <p>{val.secondContent}</p> */}
                                                            {/* <h5>Completely Responsive</h5> */}
                                                            {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "L") { return itm } }).map((value, index) => {
                                                            return (
                                                            <img className="alignleft blog-side-img" src={value.image} alt="" />
                                                           
                                                            )
                                                        })}

                                                            <p>{val.secondContent}</p>
                                                            <div className="dez-divider bg-gray-dark"></div>
                                                            {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "R") { return itm } }).map((value, index) => {
                                                            return (
                                                            <img className="alignright blog-side-img" src={value.image} alt="" />
                                                            )
                                                        })}
                                                            <p>{val.thirdContent}</p>
                                                        </div>
                                                        <div className="dez-post-tags clear">
                                                            <div className="post-tags">
                                                                <Link to="#">Child </Link>
                                                                <Link to="#">Eduction </Link>
                                                                <Link to="#">Money </Link>
                                                                <Link to="#">Resturent </Link>
                                                            </div>
                                                        </div>
                                                        <div className="dez-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>
                                                        <div className="share-details-btn">
                                                            <ul>
                                                                <li><h5 className="m-a0">Share Post</h5></li>
                                                                <li><Link to="#" className="site-button facebook button-sm"><i className="fa fa-facebook"></i> Facebook</Link></li>
                                                                <li><Link to="#" className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                                                <li><Link to="#" className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                                                <li><Link to="#" className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                                                <li><Link to="#" className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                                                <li><Link to="#" className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                                            </ul>
                                                        </div>
                                                    </>

                                                )
                                            })
                                        }
                                    </div>


                                </div>}



                            <div className="col-lg-4 col-md-5 sticky-top">
                                <aside className="side-bar">
                                    <div className="widget">
                                        <h6 className="widget-title style-1">Search</h6>
                                        <div className="search-bx style-1">
                                            <form role="search" method="post">
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Search by BlogTitle"
                                                        value={keyword}
                                                        onChange={(e) => setKeyword(e.target.value)}
                                                        style={{ borderRadius: "5px" }}
                                                    />
                                                    <span className="input-group-btn">
                                                        <button type="submit" className="fa fa-search text-primary"></button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="widget recent-posts-entry">
                                        <h6 className="widget-title style-1">Recent Posts</h6>
                                        <div className="widget-post-bx">

                                            {allBlogdetails.filter((val) => { if ((!keyword || keyword && val.blogTitle.toLowerCase().includes(keyword.toLowerCase()))) { return val; } }).map((val, index) => {

                                                return (

                                                    <div className="widget-post clearfix">
                                                        {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "C") { return itm } }).map((value) => {
                                                            return (
                                                                <div className="dez-post-media"> <img src={value.image} width="200" height="143" alt="" /> </div>
                                                            )

                                                        })}

                                                        <div className="dez-post-info">
                                                            <div className="dez-post-header">
                                                                <h6 className="post-title"><Link to='#' onClick={() => BlogHandler(val.id)}>{val.blogTitle}</Link></h6>
                                                            </div>
                                                            <div className="dez-post-meta">
                                                                <ul className="d-flex align-items-center">
                                                                    <li className="post-date"><i className="fa fa-calendar"></i>{val.publishdate}</li>
                                                                    <li className="post-comment"><Link to="#"><i className="fa fa-comments-o"></i>5k</Link> </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>

                                    <div className="widget widget_gallery gallery-grid-3">
                                        <h6 className="widget-title style-1">Our services</h6>
                                        <ul className="lightgallery">
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic1.jpg" data-src="images/gallery/middle/pic1.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic1.jpg" alt="" />
                                                </span>
                                            </li>
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic2.jpg" data-src="images/gallery/middle/pic2.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic2.jpg" alt="" />
                                                </span>
                                            </li>
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic3.jpg" data-src="images/gallery/middle/pic3.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic3.jpg" alt="" />
                                                </span>
                                            </li>
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic4.jpg" data-src="images/gallery/middle/pic4.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic4.jpg" alt="" />
                                                </span>
                                            </li>
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic5.jpg" data-src="images/gallery/middle/pic5.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic5.jpg" alt="" />
                                                </span>
                                            </li>
                                            <li>
                                                <span data-exthumbimage="images/gallery/small/pic6.jpg" data-src="images/gallery/middle/pic6.jpg" className="lightimg dez-img-overlay1 dez-img-effect zoom-slow" title="Maintenance">
                                                    <img src="images/gallery/small/pic6.jpg" alt="" />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="widget widget_archive">
                                        <h6 className="widget-title style-1">Categories List</h6>
                                        <ul>
                                            <li><Link to="#">Social Media</Link></li>
                                            <li><Link to="#">Book and writing</Link></li>
                                            <li><Link to="#">Sports</Link></li>
                                            <li><Link to="#">News</Link></li>
                                            <li><Link to="#">Art and design</Link></li>
                                        </ul>
                                    </div>

                                    <div className="widget widget-newslatter">
                                        <h6 className="widget-title style-1">Newsletter</h6>
                                        <div className="news-box">
                                            <p>Enter your e-mail and subscribe to our newsletter.</p>
                                            <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="input-group">
                                                    <input name="dzEmail" required="required" type="email" className="form-control" placeholder="Your Email" />
                                                    <button name="submit" value="Submit" type="submit" className="site-button btn-block">Subscribe Now</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BlogDetails