import axios from 'axios';

const addCandidate = (payload) => axios.post('/candidate/profile/add', payload);


const getCandidateById=(candidate_id)=>axios.get(`/candidate/profile/`+candidate_id);

const updateCandidateProfile=(candidate_id,payload) => axios.put(`/candidate/profile/`+candidate_id,payload);

export default {
    
    addCandidate,
    getCandidateById,
    updateCandidateProfile


};