import axios from 'axios';

const addEmployment = (payload) => axios.post('/candidate-employment/add', payload);

const Candidate_Employment_By_Id=(candidate_id)=>axios.get(`/candidate-employment/`+candidate_id);

const updateEmployment=(candidate_id,payload)=>axios.put(`/candidate-employment/`+candidate_id,payload);

const getEmploymentByUserId =(userId)=> axios.get(`/candidate-employment/user/${userId}`);

const deleteEmployment =(userId)=>axios.delete(`/candidate-employment/`+userId);

const addEmploymentMap =(payload)=>axios.post(`/employment/mapping/add`,payload);

const getEmploymentMap =()=> axios.get(`/employment/mapping/`);


export default { addEmployment,
                 Candidate_Employment_By_Id,
                 updateEmployment,
                 getEmploymentByUserId,
                 deleteEmployment,
                 addEmploymentMap,
                 getEmploymentMap
                };