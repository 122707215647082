import axios from 'axios';

const Candidate_Presentation = (payload) => axios.post('/candidate-presentation/add', payload);

const Candidate_Presentation_By_Id=(candidate_id)=>axios.get(`/candidate-presentation/`+candidate_id);

const Candidate_Presentation_Update=(candidate_id,payload)=>axios.put(`/candidate-presentation/`+candidate_id,payload);


export default { Candidate_Presentation,
                 Candidate_Presentation_By_Id,
                 Candidate_Presentation_Update
                
                
                };