import React, { Component } from 'react';
import Slider from "react-slick";



const postBlog = [
    {
        image:"images/testimonials/candidate.png",
		name:"Durga Prasad Sahu",
		role:"Candidate",
		testimonial:"JobYard made my job search a breeze! Their user-friendly platform and personalized recommendations helped me land my dream job. Highly recommended!"
    },
    {
        image:"images/testimonials/candidate.png",
		name:"Supriya Kumar Das",
		role:"Candidate",
		testimonial:"JobYard offers a wide range of job listings and excellent support. Thanks to them, I'm now happily employed!"
    },
    {
        image:"images/testimonials/candidate.png",
		name:"Monalisha Swain",
		role:"Candidate",
		testimonial:"JobYard is a game-changer! They matched me with perfect opportunities, and I secured a fantastic job."
    },
    {
        image:"images/testimonials/candidate.png",
		name:"Subhrasmit Behera",
		role:"Candidate",
		testimonial:"JobYard is the best job site I've used. Their filters and interview resources were invaluable. Highly recommend!"
    },
	{
        image:"images/testimonials/candidate.png",
		name:"Pratap Kumar Sahu",
		role:"Candidate",
		testimonial:"JobYard provides a streamlined job search experience. Thanks to them, I found a job that aligns perfectly with my skills!"
    }
]


const TestimonialSlider = () => {
    var settings = {     
        infinite: true,
        speed: 5000,
		autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
		pauseOnHover: true,
		swipeToSlide: true,
        autoplay: true,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    autoplay: true
                    
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    fontSize:12
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    fontSize:10
                }
            }
        ]
      };
     

      
    return (
    <div>
       <Slider className="blog-carousel-center owl " {...settings}>
                {postBlog.map((item, index) => (                    
                    <div className="item p-3" key={index} style={{width:"300px"}} >
                    <div className="testimonial-5">
                        <div className="testimonial-text " >
                            <p>{item.testimonial}</p>
                        </div>
                        <div className="testimonial-detail clearfix">
                            <div className="testimonial-pic radius shadow">
                                <img src={item.image} width="100" height="100" alt=""/>
                            </div>
                            <strong className="testimonial-name">{item.name}</strong> 
                            <span className="testimonial-position">{item.role}</span> 
                        </div>
                    </div>
                </div>
                    
                ))}

            </Slider>

    </div>
  )
}

export default TestimonialSlider;