import React from 'react'
import { Link } from 'react-router-dom'

export const Portfollio2 = () => {
  return (
   <>

      <div className="content-block" >
			<div className="section-full content-inner-2 portfolio-box">
				<div className="container">
					<div className="section-head text-black text-center m-b20">
						<h2 className="m-b10">Our Portfolio</h2>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
					</div>
					<div className="site-filters style1 clearfix center">
						<ul className="filters" data-toggle="buttons">
							<li data-filter="" className="btn active"><input type="radio"/><Link to={"#"}><span>All</span></Link></li>
							<li data-filter="web" className="btn"><input type="radio"/><Link to={"#"}><span>Designing</span></Link></li>
							<li data-filter="advertising" className="btn"><input type="radio"/><Link to={"#"}><span>Construct</span></Link></li>
							<li data-filter="branding" className="btn"><input type="radio"/><Link to={"#"}><span>Finance</span></Link></li>
							<li data-filter="design" className="btn"><input type="radio"/><Link to={"#"}><span>Development</span></Link></li>
							<li data-filter="photography" className="btn"><input type="radio"/><Link to={"#"}><span>Archeology</span></Link></li>
						</ul>
					</div>
					<div className="clearfix">
						<ul id="masonry" className="dez-gallery-listing gallery-grid-4 gallery mfp-gallery sp10" >
							<li className="web design card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect">
										<Link to={"#"}> <img src="images/gallery/middle/pic1.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Designing" to="images/gallery/middle/pic1.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="advertising branding photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect dez-img-effect "> 
									<Link to={"#"}> <img src="images/gallery/middle/pic2.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Construct" to="images/gallery/middle/pic2.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="branding design photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect"> 
									<Link to={"#"}> <img src="images/gallery/middle/pic3.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Finance" to="images/gallery/middle/pic3.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="web design card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect"> 
									<Link to={"#"}> <img src="images/gallery/middle/pic4.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Development" to="images/gallery/middle/pic4.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="web branding card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect">
									<Link to={"#"}> <img src="images/gallery/middle/pic5.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Archeology" to="images/gallery/middle/pic5.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="advertising design photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect ">
									<Link to={"#"}> <img src="images/gallery/middle/pic6.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Designing" to="images/gallery/middle/pic6.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="web branding card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect">
										<Link to={"#"}> <img src="images/gallery/middle/pic7.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Construct" to="images/gallery/middle/pic7.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="advertising design photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect dez-img-effect "> 
									<Link to={"#"}> <img src="images/gallery/middle/pic8.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Development" to="images/gallery/middle/pic8.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="web photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect"> 
									<Link to={"#"}> <img src="images/gallery/middle/pic9.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Archeology" to="images/gallery/middle/pic9.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="advertising branding card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect"> 
									<Link to={"#"}> <img src="images/gallery/middle/pic1.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Construct" to="images/gallery/middle/pic1.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							 <li className="web design card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect">
									<Link to={"#"}> <img src="images/gallery/middle/pic2.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Finance" to="images/gallery/middle/pic2.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="advertising branding photography card-container col-lg-3 col-md-4 col-sm-6 col-6">
								<div className="dez-box dez-gallery-box">
									<div className="dez-media dez-img-overlay1 dez-img-effect ">
									<Link to={"#"}> <img src="images/gallery/middle/pic3.jpg"  alt=""/> </Link>
										<div className="overlay-bx">
											<div className="overlay-icon"> 
												<Link className="mfp-link" title="Finance" to="images/gallery/middle/pic3.jpg"> <i className="ti-fullscreen"></i> </Link>	
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
	   </div>
		{/* <!-- contact area END --> */}
    

    
   
   </>
  )
}

 