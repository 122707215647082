import axios from "axios";

const PostAdminBlog=(payload)=>axios.post("/admin-blog/add",payload)


const getBlog=()=>axios.get("/admin-blog/")

const getBlogById=(id)=>axios.get(`/admin-blog/`+id)

const deleteById=(id)=>axios.delete(`/admin-blog/`+id)

export default{PostAdminBlog,getBlog,getBlogById,deleteById}