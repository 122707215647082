import React,{useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import JobPostService from '../../services/JobPost';
import AlertMessage from '../../components/AlertMessage';
import { Row,Col } from 'react-bootstrap';


const CategoryJobs = () => {
  
	const [joblists , setJoblists]=useState([]);
	const [radioData , setRadioData]=useState('');
	const [alertState, setAlertState] = useState({});

	const navigate=useNavigate();

	


	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({open:false});
	};

	const getAllJobs=async()=>{
		try{
           const res = await JobPostService.getJobPost();
		  
		   if(res.status===200){
			   setJoblists(res.data);
		   }
		}catch(err){
			return err;
		}

	}

	useEffect(()=>{
		getAllJobs();
	},[]);

	var company = joblists
    .filter((val) => {
      if (val.status === "Active") {
        return val;
        
      }
    })
    
    .map((val) => val.job_category);


var arr=[...new Set(company)];


	const searchHandler=async(e)=>{
		try{
	
			if(radioData){
				window.localStorage.setItem('categories',radioData);
				window.localStorage.setItem('city','');
				window.localStorage.setItem('all','');
				window.localStorage.setItem('companyData','');
				window.localStorage.setItem('tags','');
				window.localStorage.setItem('designation','');
	
	
	
	
				navigate('/browse-job-grid');
			}else{
				setAlertState({open:true, type:'warning', message:'Please select company.'});
	
			}
	
		}catch(err){
	
			setAlertState({open:true, type:'error', message:'ERROR',err});
	
		}
	
	}
	
	return (
    <>
        <Header activelist="category-Jobs"/>
		<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>

     <div className="page-content">
       
        <div className="dez-bnr-inr jobs-category overlay-black-middle" >
            <div className="container">
                <div className="dez-bnr-inr-entry">
					
					<div className="category-jobs-info">
						<div className="nav">
							<ul>
								<li><Link to="/category-all-jobs">All Jobs</Link></li>
								<li><Link to="/category-company-jobs">Jobs by Company</Link></li>
								<li className="active"><Link to="/category-Jobs">Jobs by Category</Link></li>
								<li><Link to="/category-location-jobs">Jobs by Location</Link></li>
								<li><Link to="/category-designation-jobs">Jobs by Designation</Link></li>
								<li><Link to="/category-skill-jobs">Jobs by Skill</Link></li>
							</ul>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div className="content-block">
			{/* <!-- Browse Jobs --> */}
		{
			arr.map(val=>val).length === 0 ?
			<center><h6 style={{color:'#2e55fa'}}>No Job Found</h6></center>:
		
			<div className="section-full content-inner jobs-category-bx bg-white">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 m-b30">
							<div className="job-bx">
								<div className="job-bx-title clearfix">
									<h6 className="text-uppercase">Browse Jobs by Functional Area / Department
									<Link to={"#"} className="float-right font-12 text-primary"></Link></h6>
								</div>
								<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none "}}>
								          {arr.map((item,index)=>(	
								        	     <li className="col-lg-4 col-md-4" key={index} >
												 <div className="job-post-inf ">
												   <div className="d-flex m-b10">
													 <div className="job-post-info">
													 <ul>
													   <div  style={{display:"flex",alignItems:"center"}}>
											             <li style={{listStyle:"none"}}>																  
															<label htmlFor={'radio4'+item} >
																<div style={ radioData === item ? {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"} :  {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}} onClick={()=>{setRadioData(item)}}>
																	 <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span style={{ color:"#2e55fa"}}>{item}</span></div>																	  
															</label>
											             </li>
													    </div>
													   </ul>													  
													 </div>
												   </div>
												 </div>
											   </li>
								))}	
							</ul>
							</div>    
						</div>
					</div>
				</div>
				<center ><button type="button"  className="site-button btn-block col-lg-2 col-md-2" onClick={searchHandler} style={{borderRadius:"50px"}}>Find Job</button></center>	
			</div>}
            {/* <!-- Browse Jobs END --> */}
		</div>
            
     </div>

        <Footer/>    
    </>
  )
}

export default CategoryJobs;