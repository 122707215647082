import axios from "axios";

const getEmployerTeam = () => axios.get('/employer-team/');

const addEmployer =(payload) => axios.post('/employer-team/add',payload);

const editEmployer =(id,payload) => axios.put('/employer-team/'+id,payload);

const getEmployerByUserId =(userId) => axios.get(`/employer-team/byUserId/`+userId);

const getEmployerById =(id) => axios.get(`/employer-team/`+id);

const deleteEmployer =(id) => axios.delete(`/employer-team/`+id);



export default { getEmployerTeam , addEmployer ,editEmployer , getEmployerByUserId , getEmployerById , deleteEmployer};