import axios from 'axios';

const Candidate_White_Paper = (payload) => axios.post('/candidate-white-paper/add', payload);

const Candidate_White_Paper_By_Id=(candidate_id)=>axios.get(`/candidate-white-paper/`+candidate_id);

const Candidate_White_Paper_Update=(candidate_id,payload)=>axios.put(`/candidate-white-paper/`+candidate_id,payload);


export default { Candidate_White_Paper,
                 Candidate_White_Paper_By_Id,
                 Candidate_White_Paper_Update
                
                
                }