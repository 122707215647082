import axios from "axios";


const PostBlogImage=(payload)=>axios.post("/blog-image/upload",payload)

const getBlogImage=()=>axios.get('/blog-image/')

const getBlogImageById=(id)=>axios.get(`/blog-image/`+id)

const deleteBlogImage=(id)=>axios.delete(`/blog-image/`+id)

export default {PostBlogImage,getBlogImage,getBlogImageById,deleteBlogImage}