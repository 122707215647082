import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import JobPostService from "../../src/services/JobPost";
import SavedJobServices from "../services/SavedJob";
import AlertMessage from "./AlertMessage";
import Users from "../services/Users";
import {  Oval } from 'react-loader-spinner'

function Jobsection() {
  const [userData, setUserData] = useState({});
  const [dataServices, setDataServices] = useState([]);
  const [data, setData] = useState([]);
  const [alertState, setAlertState] = useState({ });
  const [keySkills, setKeySkillls] = useState([]);
  const [loader, setLoader] = useState(false);
  const [jobRequirement, setJobRequirement] = useState([]);
  const [loaderId , setLoaderId] = useState(0);
  const [loaderSavedJob , setLoaderSavedJob] = useState(false)


  const userId = JSON.parse(localStorage.getItem("userId"));
  const navigate=useNavigate();

  const getUserData = async () => {
    var userId = JSON.parse(localStorage.getItem("userId"));
    try {
      const res = await Users.getUserById(userId);
      if (res.status === 200) {
        setUserData(res.data);
        
      }
    } catch (error) {
      return error;
    }
  };

  const getAllJobRequirement = async () => {
    try {
      const res = await JobPostService.getAllJobRequirement();
      setJobRequirement(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
    getAllJobRequirement();
  }, []);

  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }

  const getAllJob = async () => {
    const allJobTypes = await JobPostService.getJobPost();
    if (allJobTypes.status === 200) {
    }
    setDataServices(allJobTypes.data);
  };

  const getKeySkill = async () => {
    try {
      const res = await JobPostService.getAllKeySkills();
      setKeySkillls(res.data);
     
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllJob();
    getKeySkill();
  }, []);


  function formatNumber(number) {
  if (number >= 10000000) {
    return (number / 10000000).toFixed(1) + 'Cr';
  } else if (number >= 100000) {
    return (number / 100000).toFixed(1) + 'L';
  } 
  else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  }else {
    return number;
  }
}


  const getSavedJob = async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    try {
      const res = await SavedJobServices.getSavedJob(userId);

      setData(res.data);
     
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSavedJob();
  }, [userId]);

  const saveHandler = async (jobs) => {
    setLoaderId(jobs.id);
    setLoaderSavedJob(true);
        try {
      let job = data.filter((val) => {
        if (val.job_id === jobs.id) {
          return (val.id);
        }
      });
      if (job.length > 0) {
        var id = job[0].id;

        const res = await SavedJobServices.deleteSavedJob(id);
       
          if (res.status === 200) {
            getAllJob();
            getKeySkill();
            getUserData();
            getAllJobRequirement();
            getSavedJob();
          }
        
      } else {
        const payload = {};

        payload.user_id = userId;
        payload.job_id = parseInt(jobs.id);
        payload.job_title = jobs.job_title;
        payload.companay = jobs.job_company;
        payload.created_on = jobs.created_on;

        const res = await SavedJobServices.postSavedJob(payload);

      

          if (res.status === 200) {
            getAllJob();
            getKeySkill();
            getUserData();
            getAllJobRequirement();
            getSavedJob();
          }
        
      }
    } catch (err) {
      return err;
    }
    getAllJob();
    setTimeout(() => {
      setLoaderSavedJob(false);
        }, 1000);
   getAllJob();
   getSavedJob();

  };



  return (
    <div className="section-full bg-white content-inner-2">
      <AlertMessage
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        onclose={alertClose}
      />

      <div className="container">
        <div className="d-flex job-title-bx section-head">
          <div className="mr-auto">
            <h2 className="m-b5">Recent Jobs</h2>
            {dataServices
              .filter((val) => {
                if (val.status === "Active") {
                  return val;
                }
              })
              .map((val) => val).length === 0 ? null : (
              <h6 className="fw4 m-b0">
                {dataServices
                  .filter((val) => {
                    if (val.status === "Active") {
                      return val;
                    }
                  })
                  .map((val) => val).length < 21
                  ? dataServices
                      .filter((val) => {
                        if (val.status === "Active") {
                          return val;
                        }
                      })
                      .map((val) => val).length
                  : "20+"}{" "}
                Recently Added Jobs
              </h6>
            )}
          </div>
          <div className="align-self-end">
            <Link
              to={"/browse-job-filter-list"}
              className="site-button button-sm"
            >
              Browse All Jobs <i className="fa fa-long-arrow-right"></i>
            </Link>
           
          </div>
        </div>
        <div className="row">          
            <div className="col-lg-9">          
               <ul className="post-job-bx browse-job">
                {dataServices
                  .filter((val) => {
                    if (val.status === "Active") {
                      return val;
                    }
                  })
                  .map((val) => val).length === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: 500,
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                  <a href="{#}"><img src="images/gallery/noJobFound.png" width={150} height={150} alt=""/></a>
                  </div>
                ) : (
                  dataServices
                    .filter((val) => {
                      if (val.status === "Active") {
                        return val;
                      }
                    })
                    .reverse()
                    .slice(0, 6)
                    .map((item, index) => (
                      
                      <li key={index}>
                        
                        <div className="post-bx">
                          <div className="d-flex m-b30">
                            <div className="job-post-company">
                              <span>
                                <img alt="" src="images/logo/svg/logo1.svg" />
                              </span>
                            </div>
                            <div className="job-post-info">
                              {/* <h4   onClick={() => {JobDetailsHandler(item.id)}}> */}
                            <h4>
                                <Link to={`/job-details/${item.id}`}>
                               
                                  {item.job_title}
                                </Link>
                              </h4>
                              <p className="m-b5 font-16">
														{item.job_tags}  at {""}
														<Link to="#" className="text-primary">{item.job_company}</Link></p>
                              <ul>
                                <li>
                                  <i className="fa fa-map-marker"></i>{" "}
                                  {item.workplace_type},{item.location},
                                  {item.region}
                                </li>
                                <li>
                                  <i className="fa fa-bookmark-o"></i>{" "}
                                  {item.job_type}{" "}
                                </li>
                                <li>
                                  <i className="fa fa-clock-o"></i>Published on{" "}
                                  {item.created_on}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="job-time mr-auto">
                              <Link to="#">
                                <span> {item.job_type} </span>
                              </Link>
                            </div>
                            <div className="salary-bx">
                              <span>
                                {" "}
                                {formatNumber(item.minimum_salary)} - {formatNumber(item.maximum_salary)}
                              </span>
                            </div>
                          </div>
                      {
                        userData.roleId === 1 ?                         
                          loaderSavedJob && loaderId === item.id ?
                        (  <label className="like-btn">
                             <Oval
                             height={20}
                             width={20}
                             color="#4fa94d"
                             wrapperStyle={{}}
                             wrapperClass=""
                             visible={true}
                             ariaLabel='oval-loading'
                             secondaryColor="#4fa94d"
                             strokeWidth={2}
                             strokeWidthSecondary={2}                      
                            /> 
                            </label>):                       
                         ( <label className="like-btn">
                            <input type="checkbox" />
                           {/* <span className="checkmark"></span>   */}                           
                             {  data.find((val)=>{if(val.job_id === item.id){return val;} }) ? (
                                <Link>
                                  <i
                                    style={{ color: "red" }}
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                    onClick={() => saveHandler(item)}
                                  ></i>
                                </Link>
                              ) : (
                                <Link>
                                  <i
                                    style={{ color: "blue" }}
                                    className="fa fa-heart-o"
                                    aria-hidden="true"
                                    onClick={() => saveHandler(item)}
                                  ></i>
                                </Link>
                              )
                }
                          </label>):null}
                        </div>
                      </li>
                    ))
                )}
              </ul> 
            </div>
          

          <div class="col-lg-3">
						<div class="sticky-top">
           
              
            <div className="quote-bx m-b30">
						   <div className="quote-info">	
								  <img src="images/jobYard.png" style={{width:'100%',height:'50%'}} alt=""/>							
									<p style={{textAlign:'justify'}}>JobYard ହେଉଛି ଓଡିଶାର ଅଗ୍ରଣୀ ହାସଲେମୁକ୍ତ ଚାକିରି ପୋର୍ଟାଲ୍ |ବ୍ଲୁ, ଗ୍ରେ କଲର ଏବଂ ଏଣ୍ଟ୍ରି ଲେଭଲ୍ ପ୍ରାର୍ଥୀ ନିଯୁକ୍ତି ପାଇଁ ନିଯୁକ୍ତିଦାତା ଏବଂ ନିଯୁକ୍ତିକାରୀଙ୍କ ପାଇଁ |ଆଇଟି, ସଫ୍ଟୱେର୍, ବିକ୍ରୟ, ମାର୍କେଟିଂ, ଅପରେସନ୍, ଫାଇନାନ୍ସ ଏବଂ ଅଧିକରେ ଉଚ୍ଚ ବେତନ ପ୍ରାପ୍ତ ଚାକିରି ପାଇବାକୁ ପ୍ରାର୍ଥୀମାନଙ୍କ ପାଇଁ |</p>									
								</div>
							</div>
              <div className="quote-bx  m-b30">
						   <div className="quote-info">	
									<p style={{textAlign:'justify'}}>ଏହି ଦୁନିଆର ସବୁଠାରୁ ବଡ ଜିନିଷ ଏତେ ଅଧିକ ନୁହେଁ ଯେଉଁଠାରେ ଆମେ ଛିଡା ହୋଇଥାଉ, ଯେପରି ଆମେ କେଉଁ ଦିଗକୁ ଗତି କରୁଛୁ |</p>									
								</div>
							</div>
						</div>
					</div>
        </div>
      </div>
    </div>
  );
}
export default Jobsection;
