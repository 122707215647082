import React, { useEffect, useState } from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import PageTitle from '../components/Layout/PageTitle';
import { Link, useParams } from 'react-router-dom';
import ReportJobService from '../services/admin/ReportJobService';


const ReportJobsBytheCandidates = () => {
	const [dataServices, setDataServices] = useState([]);

	 const {id} = useParams();

	 const Id = parseInt(id);


	const getAllCandiates=async()=>{
		try{
           const res = await ReportJobService.getReportJob();
		   if(res.status === 200){
			   setDataServices(res.data);
		   }
		}catch(err){
			return err;
		}
	}

	useEffect(()=>{
		getAllCandiates();
		},[])
	
  return (
    <>
      <Header/>
      <div className="page-content bg-white">
			<div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                    <PageTitle activeName="Candidate Lists" motherName="Home" />
                </div>
                 <div className="content-block">
					<div className="section-full bg-white p-t50 p-b20">
						<div className="container">
							<div className="row">
								<div className="col-xl-12 col-lg-12 m-b30">
									<div className="job-bx browse-job clearfix">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Candidate List</h5>
											
											</div>
											<ul className="post-job-bx browse-job-grid post-resume row">
											{
												dataServices.filter((item) => {
													if (item.job_id === Id) {
														return item;
													}
												}).map((item,index) => {
													return (
														<>
														
															<li className="col-lg-4 col-md-4" key={index}>
													<div className="post-bx">
														<div className="d-flex m-b20">
														<div className="job-post-company">
														
														</div>
															<div className="job-post-info">
																<h5 className="m-b0"><Link to={`#`}>{item.name}</Link></h5>
																<p className="m-b5 font-14 text-primary"><i className="fa fa-phone mr-1 fa-1x"></i>
																	{item.phone ? "+91"+item.phone:"+91xxxxxxxxxx"} 
																	<i className="fa  fa-envelope ml-1 fa-1x"></i> {item.email}
																	 </p>
																<ul className='fz'>
																	<li><i className="fa fa-map-marker"></i>{item.city}, {item.country}</li>
																	<li><i className="fa fa-money"></i> ₹ {item.current_salary}</li>
																	<li className='mt-2'>{ item.job_title ? "Reported job :"+ ' ' + item.job_title :null}</li>

																	<li className='mt-2'>{ item.reason ? "Reason of report :"+ ' ' + item.reason :null}</li>
																</ul>
															</div>
														</div>
														<div className="job-time m-t15 m-b10">
													
																	{/* <Link  to={"/files/pdf-sample.pdf"} target="blank" className="job-links">
	                                                    	         <i className="fa fa-download"></i>
	                                                               </Link> */}
														</div>
													 
													</div>
												</li>
														</>
													)


												})
											}
										</ul>
										 
                                     </div>
                                 </div>
                            </div>
                       </div>
                  </div>
             </div>
         </div>
      <Footer/>
      
    </>
  )
}

export default ReportJobsBytheCandidates;
