import React, { useState, useEffect } from 'react';
import EmployerSideBar from './component/EmployerSideNav';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import JobPostServices from '../../services/JobPost';
import CategoryMaster from '../../services/Master/CategoryMaster';
import RegionMaster from '../../services/Master/RegionMaster';
import SkillMaster from '../../services/Master/SkillMaster';
import Users from '../../services/Users';
import LocationMaster from '../../services/Master/LocationMaster';
import EmployerTeam from '../../services/EmployerTeam';
import ExperienceMaster from '../../services/Master/ExperienceMaster';
import JobType from '../../services/JobType';
import BrowseCandidate from '../../services/BrowseCandidate';
import BrowseCandiate from '../../services/BrowseCandiate';
import SavedJob from '../../services/SavedJob';
import AppliedJob from '../../services/AppliedJob';
import Header from '../../components/Layout/Header';
import JobPost from '../../services/JobPost';
import AlertMessage from '../../components/AlertMessage';
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';


const NewManagejob = () => {

	const [jobList, setJobList] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [RegionData, setRegionData] = useState([]);
	const [LocationData, setLocationData] = useState([]);
	const [Experiencedata, setExperienceData] = useState([]);
	const [SkillData, setSkillData] = useState([]);
	const [jobTypeData, setJobTypeData] = useState([]);
	const [userData, setUserData] = useState({});
	const [team, setTeam] = useState([]);
	const [allUser, setAllUser] = useState([]);
	const [loader, setLoader] = useState(false);
	const [alertState, setAlertState] = useState({});
	const [browseData, setBrowseData] = useState([]);
	const [candidateList, setCandidateList] = useState([])
	const [appliedJobList, setAppliedJobList] = useState([]);
	const [savedJobList, setSavedJobList] = useState([]);
	const [browseCandidateList, setBrowseCandidateList] = useState([]);
	const [browseCandidateData, setBrowseCandidateData] = useState([]);
	const [savedJobData, setSavedJobData] = useState([]);
	const [appliedJobData, setAppliedJobData] = useState([]);
	const [descriptionData, setDescriptionData] = useState([]);
	const [requirementData, setRequirementData] = useState([]);
	const [keySkillData, setKeySkillData] = useState([])
	const [deleteJobId, setDeleteJobId] = useState(0);
	const [deleteJobTitle, setDeleteJobTitle] = useState('');
	const [viewModal, setViewModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false)

	const [JobTitle, setJobTitle] = useState("");
	const [JobExperience, setJobExperience] = useState(0);
	const [JobDescription, setJobDescription] = useState("");
	const [JobEmail, setJobEmail] = useState("");
	const [JobTags, setJobTags] = useState("");
	const [JobQualification, setJobQualification] = useState("");
	const [JobCategory, setJobCategory] = useState("");
	const [JobMinSalary, setJobMinSalary] = useState(0);
	const [JobMaxSalary, setJobMaxSalary] = useState(0);
	const [JobType, setJobType] = useState("");
	const [JobCompany, setJobCompany] = useState('')
	const [JobRegion, setJobRegion] = useState("");
	const [JobLocation, setJobLocation] = useState("");
	const [Status, setStatus] = useState('');
	const [Bike, setBike] = useState("");
	const [Dl, setDl] = useState("");
	const [Rc, setRc] = useState('')
	const [Mobile, setMobile] = useState("");
	const [Laptop, setLaptop] = useState("");
	const [Bank, setBank] = useState('');
	const [Pan, setPan] = useState('');
	const [Aadhar, setAadhar] = useState('');
	const [JobVacancy, setJobVacancy] = useState(0);
	const [Id, setId] = useState(0);
	const [LastDate, setLastDate] = useState('');
	const [UserId, setUserId] = useState(0);
	const [Created, setCreated] = useState('');
	const [JobArea, setJobArea] = useState('');
	const [JobTimeStart, setJobTimeStart] = useState('');
	const [Sun, setSun] = useState('');
	const [Mon, setMon] = useState('');
	const [Tue, setTue] = useState('');
	const [Wed, setWed] = useState('');
	const [Thu, setThu] = useState('');
	const [Fri, setFri] = useState('');
	const [Sat, setSat] = useState('');
	const [Jobflat, setJobflat] = useState('');
	const [workTill, setworkTill] = useState('');
	const [workplace, setworkpalce] = useState('');
	const [Hrname, setHrname] = useState('');
	const [Hrwhatsapp, setHrwhatsapp] = useState('');
	const [fee, setfee] = useState('');
	const [Gender, setGender] = useState('');
	const [JobRequirements, setJobRequirements] = useState('');
	const [JobKeySkills, setJobKeySkills] = useState('');
	const [Hrcompany, setHrcompany] = useState('');
	const [filterData, setFilterData] = useState({
		statusData: ''
	});
	const [sortedData, setSortedData] = useState([]);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedDetails, setSortedDetails] = useState([]);
	const [sortOrder1, setSortOrder1] = useState('asc')
	const [sortBy, setSortBy] = useState('name');
	const [pageNumber, setPageNumber] = useState(0);
	const [pageNumbers, setPageNumbers] = useState(0);
	const [pageNumberCount, setPagenumberCount] = useState(0);
	const [keyword, setKeyword] = useState();
	const [data, setData] = useState([])




	const itemsPerPage = 10


	const navigate = useNavigate()



	var userId = localStorage.getItem('userId');








	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({ open: false });
	};
	const getUserData = async () => {
		try {
			const res = await Users.getUserById(userId);
			setUserData(res.data)
			if (res.data.status === 'In Review' && res.data.roleId === 2) {
				navigate('/company-profile');
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getUserData();
	}, [userId])


	const getCandidateLists = async () => {
		try {
			const res = await BrowseCandiate.getBrowseCandidate();
			if (res.status === 200) {
				setCandidateList(res.data);
			}
		} catch (err) {
			return err;
		}
	}

	const getJobPost = async () => {
		setLoader(true)
		try {
			const res = await JobPostServices.getJobPost();
			console.log(res);
			if (res.status === 200) {
				setJobList(res.data);
			}
			else {
				setAlertState({ open: true, type: 'error', message: 'Could not fetch jobs.' });
			}
		} catch (err) {
			//console.log(err);
			//setLoading(false);
			setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
		}
		setLoader(false)
	};



	const getCategoryData = async () => {
		setLoader(true)
		try {
			const res = await CategoryMaster.getCategory();
			if (res.status === 200) {
				setCategoryData(res.data);
			}
		} catch (err) {
			return err;
		}
		setLoader(false)
	}

	const getRegionData = async () => {
		setLoader(true)
		try {
			const res = await RegionMaster.GetRegion();
			if (res.status === 200) {
				setRegionData(res.data);
			}

		} catch (error) {
			return error;
		}
		setLoader(false)
	}

	const getLocationData = async () => {
		setLoader(true)
		try {
			const res = await LocationMaster.GetLocation();
			if (res.status === 200) {
				setLocationData(res.data);
			}
		} catch (error) {
			return error;
		}
		setLoader(false)
	}

	const getExperienceData = async () => {
		setLoader(true)
		try {
			const res = await ExperienceMaster.GetExperience();
			if (res.status === 200) {
				setExperienceData(res.data);
			}
		} catch (error) {
			return error;
		}
		setLoader(false)
	}

	const getSkillData = async () => {
		setLoader(true)
		try {
			const res = await SkillMaster.getSkills();
			if (res.status === 200) {
				setSkillData(res.data);
			}
		} catch (err) {
			return err;
		}
		setLoader(false)
	}

	const getAllJobTypes = async () => {
		setLoader(true)
		try {
			const res = await JobType.getJobTypes();
			if (res.status === 200) {
				setJobTypeData(res.data);
			}
		} catch (err) {
			return err;
		}
		setLoader(false)
	}


	const getAllEmployerTeam = async () => {
		try {
			const res = await EmployerTeam.getEmployerTeam();
			setTeam(res.data);
		} catch (error) {
			console.log(error)
		}
	}


	const getAllUser = async () => {
		try {
			const res = await Users.getUser();
			setAllUser(res.data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getJobPost();
		getCandidateLists();
		getCategoryData();
		getRegionData();
		getLocationData();
		getExperienceData();
		getSkillData();
		getAllJobTypes();
		getAllUser();
		getAllEmployerTeam();
	}, []);


	const getBrowseCandidate = async () => {
		try {
			const res = await BrowseCandiate.getBrowseCandidate();
			setBrowseData(res.data);
		} catch (error) {
			console.log(error)
		}
	}

	const getAplliedJob = async () => {
		try {
			const res = await AppliedJob.getAppliedJob();
			setAppliedJobList(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getSavedJobList = async () => {
		try {
			const res = await SavedJob.getSavedJoblist();
			setSavedJobList(res.data);
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getBrowseCandidate();
		getAplliedJob();
		getSavedJobList();
	}, []);

	let employerJoblist = [];
	let jobUser = [];
	let employerTeam = team.filter((val) => { if (val.employerId === userId) { return val } })

	for (let i = 0; i < employerTeam.length; i++) {
		for (let j = 0; j < allUser.length; j++) {

			if (allUser[j].email === employerTeam[i].email) {
				jobUser.push(allUser[j]);
			}

		}

	}
	jobUser.push(userData);

	for (let l = 0; l < jobUser.length; l++) {
		for (let k = 0; k < jobList.length; k++) {
			if (jobList[k].user_id === jobUser[l].id) {
				employerJoblist.push(jobList[k])
			}
		}
	}


	const ViewHandler = (Id) => {
		jobList.find((item) => {
			if (item.id === Id) {
				setJobKeySkills(item.skill)
				setRc(item.rc_option)
				setStatus(item.status);
				setfee(item.interview_fee);
				setJobRegion(item.region);
				setJobLocation(item.location);
				setUserId(item.user_id)
				setJobMinSalary(item.minimum_salary)
				setMobile(item.smartPhone);
				setPan(item.pan_card);
				setLaptop(item.laptop_option);
				setJobRequirements(item.requirements)
				setCreated(item.created_on)
				setLastDate(item.last_date);
				setSun(item.sun);
				setMon(item.mon);
				setTue(item.tue);
				setWed(item.wed);
				setThu(item.thu);
				setFri(item.fri);
				setSat(item.sat);
				setworkTill(item.worktime_end);
				setworkpalce(item.workplace_type);
				setLastDate(item.last_date);
				setHrcompany(item.hr_company);
				setJobTimeStart(item.worktime_start)
				setJobVacancy(item.vacancy)
				setJobTitle(item.job_title);
				setJobExperience(item.experience);
				setJobTags(item.job_tags);
				setJobEmail(item.job_email);
				setJobQualification(item.qualification);
				setJobType(item.job_type);
				setJobCompany(item.job_company);
				setHrname(item.hr_name);
				setJobArea(item.job_area);
				setAadhar(item.adhar_card);
				setBank(item.back_passbook);
				setBike(item.bike_option);
				setDl(item.dl_option);

				setGender(item.gender);
				setHrwhatsapp(item.whatsapp);
				setJobCategory(item.job_category);
				setJobDescription(item.description);
				setJobMaxSalary(item.maximum_salary);
				setJobflat(item.job_flat_no);
				setViewModal(true);

			}
		})
	}
	const deleteHandler = async (Id) => {
		var BrowseCandiateData = browseData.filter((item) => { if (item.job_id === Id) { return item; } })
		var SavedJobData = savedJobList.filter((item) => { if (item.job_id === Id) { return item; } })
		var AppliedJobData = appliedJobList.filter((item) => { if (item.job_id === Id) { return item; } })
		var DescriptionData = await JobPost.getJobDescrioption(Id);
		var KeyskillsData = await JobPost.getKeySkill(Id);
		var RequirementData = await JobPost.getJobRequirement(Id);


		jobList.forEach((item) => {
			if (item.id === Id) {
				setBrowseCandidateData(BrowseCandiateData);
				setSavedJobData(SavedJobData);
				setAppliedJobData(AppliedJobData);
				setDescriptionData(DescriptionData.data)
				setKeySkillData(KeyskillsData.data);
				setRequirementData(RequirementData.data);
				setDeleteJobTitle(item.job_title);
				setDeleteModal(true);
				setDeleteJobId(item.id)
				setLoader(false);

			}
		})
	}
	const deleteJobHandler = async () => {
		var length = browseCandidateData.length
		var saved_length = savedJobData.lenght
		var applied_length = appliedJobData.length
		var description_length = descriptionData.length
		var keySkill_length = keySkillData.length
		var requirement_length = requirementData.length


		try {
			const res = await JobPost.deleteJobPost(deleteJobId)
			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: "Job Deleted Successfully" })
				getJobPost();
				if (length > 0) {
					for (let i = 0; i < length; i++) {
						await BrowseCandiate.DeleteBrowseCandidate(browseCandidateData[i].id);
					}
				}
				if (applied_length > 0) {
					for (let j = 0; j < applied_length; j++) {
						await AppliedJob.DeleteAppliedJob(appliedJobData[j].id);
					}
				}
				if (saved_length > 0) {
					for (let l = 0; l < saved_length; l++) {
						await SavedJob.deleteSavedJob(savedJobData[l].id);
					}
				}
				if (keySkill_length > 0) {
					for (let x = 0; x < keySkill_length; x++) {
						await JobPost.deleteJobSkill(keySkillData[x].id);
					}
				}
				if (description_length > 0) {
					for (let y = 0; y < description_length; y++) {
						await JobPost.deleteJobDecription(descriptionData[y].id);
					}
				}
				if (requirement_length > 0) {
					for (let z = 0; z < requirement_length; z++) {
						await JobPost.deleteJobRequirement(requirementData[z].id);
					}
				}
				setDeleteModal(false);
			}


		} catch (error) {
			console.log(error)

		}
	}

	var showStatus;

	team.find((val) => {
		if (val.email === userData.email) {
			allUser.find((itm) => {
				if (itm.id === val.employerId) {
					showStatus = itm.status;
					if (itm.status === 'In Review' || itm.status === 'Cancel') {
						navigate('/company-profile');
					} else if (userData.status === 'In Review') {
						navigate('/company-profile');
					}
				}
			})
		}
	})

	const JobListEmployerTeam=jobList.filter((val) => { if (val.user_id === parseInt(userId)) { return val; } })

	const handlePageChange = ({ selected }) => {
		setPageNumber(selected);
	};

	const startIndex = pageNumber * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const displayedItems = employerJoblist.slice(startIndex, endIndex);

	const handlePageChangeTeam = ({ selected }) => {
		setPageNumbers(selected);
	};

	const startIndex1 = pageNumbers * itemsPerPage;
	const endIndex1 = startIndex1 + itemsPerPage;
	const displayedTeam = JobListEmployerTeam.slice(startIndex1, endIndex1);

	const sortingHandler = (col) => {


		if (sortOrder === "asc") {
			const sorted = displayedItems.sort((a, b) =>
				a[col] > b[col] ? 1 : -1


			);
			setSortedData(sorted);
			setSortOrder('dsc')
		} else {
			const sorteds = displayedItems.sort((a, b) =>
				a[col] < b[col] ? 1 : -1

			);
			setSortedData(sorteds);
			setSortOrder('asc')

		}
	};
	const sortingHandlers = (col) => {


		if (sortOrder1 === "asc") {
			const sort = displayedTeam.sort((a, b) =>
				a[col] > b[col] ? 1 : -1


			);
			setSortedDetails(sort);
			setSortOrder1('dsc')

		} else {
			const sortes = displayedTeam.sort((a, b) =>
				a[col] < b[col] ? 1 : -1

			);
			setSortedDetails(sortes);
			setSortOrder1('asc')

		}
	};
	
	function formatNumber(number) {
		if (number >= 10000000) {
		  return (number / 10000000).toFixed(1) + 'Cr';
		} else if (number >= 100000) {
		  return (number / 100000).toFixed(1) + 'L';
		} 
		else if (number >= 1000) {
		  return (number / 1000).toFixed(1) + 'K';
		}else {
		  return number;
		}
	  }


	return (
		<>
			<Header activelist="manage-job" />

			<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
			{loader ?
				<div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
					<ThreeCircles
						height="80"
						width="80"
						color="#2e55fa"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
						ariaLabel="three-circles-rotating"
						outerCircleColor=""
						innerCircleColor=""
						middleCircleColor=""
					/>
				</div> :
				<div style={{ borderTop: '3px solid #2e55fa' }}>
					<div className="page-content bg-white">

						<div className="content-block">

							<div className="section-full bg-white p-t50 p-b20">
								<div className="container" PaginatedItems itemsPerPage={5} >

									<div className="row">
										<div className="col-xl-3 col-lg-4 m-b30">
											<EmployerSideBar activemenu="manage-job" />
										</div>
										{
											userData.roleId === 2 ?
												<div className="col-xl-9 col-lg-8 m-b30">
													<div className="job-bx clearfix">


														<div className="job-bx-title clearfix">
															<h5 className="font-weight-700 pull-left text-uppercase">Manage jobs</h5>
															<div className="float-right">
																{/* <span className="select-title">Sort by freshness</span> */}

																<div className="">
																	<input type="text"
																		className="form-control"
																		placeholder="Search by Job Title"
																		value={keyword}
																		onChange={(e) => setKeyword(e.target.value)}
																		style={{ borderRadius: "5px" }}
																	/>
																</div>



															</div>
														</div>



														{
															employerJoblist.length === 0 ?

																<div style={{
																	width: '100%',
																	height: 150,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	flexDirection: 'column'
																}}>
																	<h5 style={{ color: '#2e55fa' }}>No Job Found!</h5>
																	<p>Post a job and hire employees for your organization.</p>
																</div> :
																<div>
																	{
																		sortOrder === 'asc' ?
																			<table className="table-job-bx cv-manager company-manage-job">
																				<thead >
																					<tr>

																						<th onClick={() => sortingHandler('job_title')} style={{ fontSize: 14, fontWeight: 800 }}>Job Title<span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Applications </th>
																						<th onClick={() => sortingHandler('last_date')} style={{ fontSize: 14, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800, paddingLeft: '5%' }} onClick={() => sortingHandler('status')}>Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Action</th>
																					</tr>
																				</thead>
																				<tbody>

																					{
																						displayedItems.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
																							.map((item, index) => {

																								return (

																									<tr key={index} >

																										<td className="job-name">

																											<Link to="#" style={{ fontSize: "14px", fontWeight: 800 }}>{item.job_title}.</Link>
																											<ul className="job-post-info ">
																												<li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
																												<ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
																													<li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

																											</ul>
																										</td>
																										<Link to={`/jobs-candidate-profile-list/${item.id}`}>
																											<td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
																												if (val.job_id === item.id) {
																													return val;
																												}
																											}).map(val => { return val }).length})</td></Link>
																										<td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
																										<td style={{ fontSize: "12px" }}>
																											{

																												item.status === "Review" ?
																													<td style={{ color: 'blue' }} className="expired primary">In Review</td> :
																													item.status === "Active" ?
																														<td className="expired success">Active</td> :
																														item.status === "Cancel" ?
																															<td style={{color:"red"}} className="expired danger">Cancelled</td> :
																															item.status === 'Expired' ?
																																<td style={{ color: "orange" }}>Expired</td> :
																																null
																											}
																										</td>
																										<td className="job-links mt-2 ml-2" >
																											<Link to="#" data-toggle="modal" data-target="#exampleModalLong">
																												<i className="fa fa-eye" onClick={() => ViewHandler(item.id)}></i></Link>
																											<Link to="#"><i className="ti-trash" onClick={() => { deleteHandler(item.id) }}></i></Link>
																											<Link to={`/update-jobs/${item.id}`}><i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i></Link>
																										</td>
																									</tr>

																								)
																							})

																					}



																				</tbody>


																				<div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
																					<ReactPaginate
																						previousLabel={'Previous'}
																						nextLabel={'Next'}
																						breakLabel={'...'}
																						pageCount={Math.ceil(employerJoblist.length / itemsPerPage)}
																						marginPagesDisplayed={7}
																						pageRangeDisplayed={employerJoblist.length}
																						onPageChange={handlePageChange}
																						containerClassName={'pagination'}
																						activeClassName={'active'}
																					/></div>
																			</table>
																			:


																			<table className="table-job-bx cv-manager company-manage-job">
																				<thead >
																					<tr >

																						<th onClick={() => sortingHandler('job_title')} style={{ fontSize: 14, fontWeight: 800 }}>Job Title <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Applications</th>
																						<th onClick={() => sortingHandler('last_date')} style={{ fontSize: 14, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ paddingLeft: "5%", fontSize: 14, fontWeight: 800 }} onClick={() => sortingHandler('status')} >Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span> </th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{

																						sortedData.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
																							.map((item, index) => {

																								return (

																									<tr key={index}>

																										<td className="job-name" >

																											<Link to="#" style={{ fontSize: 14, fontWeight: 800 }}>{item.job_title}.</Link>
																											<ul className="job-post-info ">
																												<li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
																												<ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
																													<li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

																											</ul>
																										</td>
																										<Link to={`/jobs-candidate-profile-list/${item.id}`}>
																											<td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
																												if (val.job_id === item.id) {
																													return (val)
																												}
																											}).map((val) => { return (val) }).length}) </td></Link>
																										<td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
																										<td style={{ fontSize: "12px" }}>
																											{

																												item.status === "Review" ?
																													<td style={{ color: 'blue' }} className="expired primary">In Review</td> :
																													item.status === "Active" ?
																														<td className="expired success">Active</td> :
																														item.status === "Cancel" ?
																															<td style={{color:"red"}}className="expired danger ">Cancelled</td> :
																															item.status === 'Expired' ?
																																<td style={{ color: "orange" }}>Expired</td> :
																																null
																											}
																										</td>
																										<td className="job-links mt-2 ml-2" >
																											<Link to="#" data-toggle="modal" data-target="#exampleModalLong">
																												<i className="fa fa-eye" onClick={() => ViewHandler(item.id)}></i></Link>
																											<Link to="#"><i className="ti-trash" onClick={() => { deleteHandler(item.id) }}></i></Link>
																											<Link to={`/update-jobs/${item.id}`}><i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i></Link>
																										</td>
																									</tr>

																								)
																							})
																					}


																				</tbody>


																				<div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
																					<ReactPaginate
																						previousLabel={'Previous'}
																						nextLabel={'Next'}
																						breakLabel={'...'}
																						pageCount={Math.ceil(employerJoblist.length / itemsPerPage)}
																						marginPagesDisplayed={7}
																						pageRangeDisplayed={employerJoblist.length}
																						onPageChange={handlePageChange}
																						containerClassName={'pagination'}
																						activeClassName={'active'}
																					/></div>
																			</table>

																	}
																</div>
														}

														{
															employerJoblist.length ?
																employerJoblist.map(val => val).length === 0 ?
																	<div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
																		<h6 style={{ color: '#2e55fa' }}>No Job Found!</h6>
																	</div>
																	: null : null
														}
													</div>


												</div>

												:

												<div className="col-xl-9 col-lg-8 m-b30">
													<div className="job-bx browse-job clearfix">



														<div className="job-bx-title  clearfix">
															<h5 className="font-weight-700 pull-left text-uppercase">Manage Job </h5>
															<div className="float-right">


																<div className="">
																	<input type="text"
																		className="form-control"
																		placeholder="Search by Job Title"
																		value={keyword}
																		onChange={(e) => setKeyword(e.target.value)}
																		style={{ borderRadius: "5px" }}
																	/>
																</div>



															</div>
														</div>




														{

															jobList.filter((val) => { if (val.user_id === parseInt(userId)) { return val; } }).length === 0 ?

																<div style={{
																	width: '100%',
																	height: 150,
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	flexDirection: 'column'
																}}>

																	<h5 style={{ color: '#2e55fa' }}>No Job Found!</h5>

																</div> :




																			(  sortOrder1==="asc"?  
																			 <table className="table-job-bx cv-manager company-manage-job">
																				<thead >
																					<tr >

																						<th onClick={() => sortingHandlers('job_title')} style={{ fontSize: 14, fontWeight: 800 }}>Job Title <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Applications</th>
																						<th onClick={() => sortingHandlers('last_date')} style={{ fontSize: 14, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																						<th style={{ fontSize: 14, fontWeight: 800 }} onClick={() => sortingHandlers('status')} >Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span> </th>
																						<th style={{ fontSize: 14, fontWeight: 800 }}>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					{

																						displayedTeam.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
																							.map((item, index) => {

																								return (

																									<tr key={index}>

																										<td className="job-name" >

																											<Link to="#" style={{ fontSize: 14, fontWeight: 800 }}>{item.job_title}.</Link>
																											<ul className="job-post-info ">
																												<li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
																												<ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
																													<li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

																											</ul>
																										</td>
																										<Link to={`/jobs-candidate-profile-list/${item.id}`}>
																											<td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
																												if (val.job_id === item.id) {
																													return (val)
																												}
																											}).map((val) => { return (val) }).length}) </td></Link>
																										<td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
																										
																											{

																												item.status === "Review" ?
																													<td style={{ color: 'blue',fontSize: "12px" }} className="expired primary">In Review</td> :
																													item.status === "Active" ?
																														<td className="expired success"style={{fontSize: "12px"}} >Active</td> :
																														item.status === "Cancel"?
																															<td   className="expired danger "style={{fontSize: "12px",color:"red"}}>Cancelled</td> :
																															item.status === 'Expired' ?
																																<td style={{ color: "orange" ,fontSize: "12px"}} >Expired</td> :
																																null
																											}
																										
																										<td className="job-links mt-2 ml-2" >
																											<Link to="#" data-toggle="modal" data-target="#exampleModalLong">
																												<i className="fa fa-eye" onClick={() => ViewHandler(item.id)}></i></Link>
																											<Link to="#"><i className="ti-trash" onClick={() => { deleteHandler(item.id) }}></i></Link>
																											<Link to={`/update-jobs/${item.id}`}><i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i></Link>
																										</td>
																									</tr>

																								)
																							})
																					}


																				</tbody>


																				<div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
																					<ReactPaginate
																						previousLabel={'Previous'}
																						nextLabel={'Next'}
																						breakLabel={'...'}
																						pageCount={Math.ceil(jobList.length / itemsPerPage)}
																						marginPagesDisplayed={7}
																						pageRangeDisplayed={jobList.length}
																						onPageChange={handlePageChangeTeam}
																						containerClassName={'pagination'}
																						activeClassName={'active'}
																					/></div>
																			</table>:
																			
																			<table className="table-job-bx cv-manager company-manage-job">
																			<thead >
																				<tr >

																					<th onClick={() => sortingHandlers('job_title')} style={{ fontSize: 14, fontWeight: 800 }}>Job Title <span>{sortOrder1 === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																					<th style={{ fontSize: 14, fontWeight: 800 }}>Applications</th>
																					<th onClick={() => sortingHandlers('last_date')} style={{ fontSize: 14, fontWeight: 800 }}>Last Date <span>{sortOrder1 === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																					<th style={{ fontSize: 14, fontWeight: 800 }} onClick={() => sortingHandlers('status')} >Status <span>{sortOrder1 === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span> </th>
																					<th style={{ fontSize: 14, fontWeight: 800 }}>Action</th>
																				</tr>
																			</thead>
																			<tbody>
																				{

																					sortedDetails.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
																						.map((item, index) => {

																							return (

																								<tr key={index}>

																									<td className="job-name" >

																										<Link to="#" style={{ fontSize: 14, fontWeight: 800 }}>{item.job_title}.</Link>
																										<ul className="job-post-info ">
																											<li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
																											<ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
																												<li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

																										</ul>
																									</td>
																									<Link to={`/jobs-candidate-profile-list/${item.id}`}>
																										<td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
																											if (val.job_id === item.id) {
																												return (val)
																											}
																										}).map((val) => { return (val) }).length}) </td></Link>
																									<td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
																									
																										{

																											item.status === "Review" ?
																												<td style={{ color: 'blue',fontSize: "12px" }} className="expired primary">In Review</td> :
																												item.status === "Active" ?
																													<td className="expired success" style={{fontSize: "12px"}}>Active</td> :
																													item.status === "Cancel" ?
																														<td className="expired danger " style={{fontSize: "12px",color:"red"}}>Cancelled</td> :
																														item.status === 'Expired' ?
																															<td style={{ color: "orange",fontSize: "12px" }}>Expired</td> :
																															null
																										}
																									
																									<td className="job-links mt-2 ml-2" >
																										<Link to="#" data-toggle="modal" data-target="#exampleModalLong">
																											<i className="fa fa-eye" onClick={() => ViewHandler(item.id)}></i></Link>
																										<Link to="#"><i className="ti-trash" onClick={() => { deleteHandler(item.id) }}></i></Link>
																										<Link to={`/update-jobs/${item.id}`}><i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i></Link>
																									</td>
																								</tr>

																							)
																						})
																				}


																			</tbody>


																			<div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
																				<ReactPaginate
																					previousLabel={'Previous'}
																					nextLabel={'Next'}
																					breakLabel={'...'}
																					pageCount={Math.ceil(jobList.length / itemsPerPage)}
																					marginPagesDisplayed={7}
																					pageRangeDisplayed={jobList.length}
																					onPageChange={handlePageChangeTeam}
																					containerClassName={'pagination'}
																					activeClassName={'active'}
																				/></div>
																		</table>
																			)

																// <table className="table-job-bx cv-manager company-manage-job ">
																// 	<thead>
																// 		<th style={{ fontSize: 14, fontWeight: 800 }}>Job Title <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																// 		<th style={{ fontSize: 14, fontWeight: 800 }}>Applications</th>
																// 		<th style={{ fontSize: 14, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
																// 		<th style={{ paddingLeft: "5%", fontSize: 14, fontWeight: 800 }}>Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span> </th>
																// 		<th style={{ fontSize: 14, fontWeight: 800 }}>Action </th>


																// 	</thead>
																// 	<tbody>

																// 		{
																// 			displayedTeam.filter((val) => {
																// 				if (val.user_id === parseInt(userId) && (!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; }
																// 				console.log(val.user_id, "userid")
																// 				console.log(userId, "local host")
																// 			}).map((item, index) => (
																// 				<tr key={index} >
																// 					<td className="job-name" >
																// 						<Link to="#" style={{ fontWeight: 800, fontSize: "14" }}>{item.job_title}.</Link>
																// 						<ul className="job-post-info ">
																// 							<li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
																// 							<ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
																// 								<li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

																// 						</ul>
																// 					</td>
																// 					<Link to={`/jobs-candidate-profile-list/${item.id}`}>
																// 						<td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
																// 							if (val.job_id === item.id) {
																// 								return (val)
																// 							}
																// 						}).map((val) => { return (val) }).length}) </td></Link>

																// 					<td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
																// 					<td >
																// 						{

																// 							item.status === "Review" ?
																// 								<td style={{ color: 'blue' }} className="expired primary">In Review</td> :
																// 								item.status === "Active" ?
																// 									<td className="expired success">Active</td> :
																// 									item.status === "Cancel" ?
																// 										<td className="expired danger ">Cancelled</td> :
																// 										item.status === 'Expired' ?
																// 											<td style={{ color: "red" }}>Expired</td> :
																// 											null
																// 						}
																// 					</td>
																// 					<td className="job-links mt-2 ml-2" >
																// 						<Link to="#" data-toggle="modal" data-target="#exampleModalLong">
																// 							<i className="fa fa-eye" onClick={() => ViewHandler(item.id)}></i></Link>
																// 						<Link to="#"><i className="ti-trash" onClick={() => { deleteHandler(item.id) }}></i></Link>
																// 						<Link to={`/update-jobs/${item.id}`}><i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i></Link>
																// 					</td>

																// 				</tr>
																// 			))
																// 		}

																// 	</tbody>

																// 	<div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
																// 		<ReactPaginate
																// 			previousLabel={'Previous'}
																// 			nextLabel={'Next'}
																// 			breakLabel={'...'}
																// 			pageCount={Math.ceil(jobList.length / itemsPerPage)}
																// 			marginPagesDisplayed={2}
																// 			pageRangeDisplayed={jobList.length}
																// 			onPageChange={handlePageChangeTeam}
																// 			containerClassName={'pagination'}
																// 			activeClassName={'active'}
																// 		/></div>
																// </table>

														}

													</div>
												</div>


										}
									</div>
								</div>
							</div>



						</div>
					</div>
				</div>
			}


			<Modal
				show={viewModal}
				onHide={() => setViewModal}
				backdrop=""
				size='md'
				className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

			>
				<div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
					<div className="modal-content "  >
						<div class="modal-header">
							<div class="logo-img">
								<img alt="" src="images/logo/icon2.png" />
							</div>
							<h5 class="modal-title">{JobCompany}</h5>
							<button type="button" className="close" onClick={() => setViewModal(false)} data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body" >
							<h5 className="modal-title" >Job details: </h5>

							<ul>
								<li><strong>Job Title :</strong><p className='pl-5'> {JobTitle}</p></li>
								<li><strong>Job Tags :</strong><p className='pl-5'> {JobTags}</p></li>
								<li><strong>Job Types :</strong><p className='pl-5'> {JobType}</p></li>
								<li><strong>Job Category:</strong><p className='pl-5'> {JobCategory}</p></li>
								<li><strong>Email :</strong><p className='pl-5'> {JobEmail}</p></li>
								<li><strong>Minimum Salary:</strong><p className='pl-5'> {formatNumber(JobMinSalary)}</p></li>
								<li><strong>Maximum Salary:</strong><p className='pl-5'> {formatNumber(JobMaxSalary)}</p></li>
								<li><strong>Experience:</strong><p className='pl-5'>{JobExperience}</p></li>
								<li><strong>Qualification:</strong><p className='pl-5'> {JobQualification}</p></li>
								<li><strong>Region :</strong><p className='pl-5'> {JobRegion}</p></li>
								<li><strong>Location :</strong><p className='pl-5'> {JobLocation}</p></li>
								<li><strong>City :</strong><p className='pl-5'> {JobArea}</p></li>
								<li><strong>Working From :</strong><p className='pl-5' > {JobTimeStart}</p></li>
								<li><strong>Working Till :</strong><p className='pl-5'> {workTill}</p></li>
								<li><strong>Flat No. :</strong><p className='pl-5'> {Jobflat}</p></li>
								<ul style={{ display: "flex", justifyContent: "space-between" }}>
									<li>
										<li>Working Days</li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong> Sunday:</strong><p className='pl-5 '> {Sun}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Monday:</strong><p className='pl-5'> {Mon}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Tuesday:</strong><p className='pl-5'> {Tue}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Wednesday:</strong><p className='pl-5'> {Wed}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Thursday:</strong><p className='pl-5'> {Thu}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Friday:</strong><p className='pl-5'> {Fri}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Saturday:</strong><p className='pl-5'> {Sat}</p></li>
									</li>
									<li>
										<li>Required Item:</li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Bike:</strong><p className='pl-5'> {Bike}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>RC:</strong><p className='pl-5'> {Rc}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>DL:</strong><p className='pl-5'> {Dl}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Mobile:</strong><p className='pl-5'> {Mobile}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Laptop:</strong><p className='pl-5'> {Laptop}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Aadhar:</strong><p className='pl-5'> {Aadhar}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Pan:</strong><p className='pl-5'> {Pan}</p></li>
										<li style={{ display: "flex", justifyContent: "space-between" }}><strong>Bank A/C:</strong><p className='pl-5'> {Bank}</p></li>
									</li>

								</ul>


								<li><strong>Company:</strong><p className='pl-5'> {JobCompany}</p></li>
								<li><strong>Lastdate:</strong><p className='pl-5'> {LastDate}</p></li>
								<li><strong>No.of Vacancy:</strong><p className='pl-5'> {JobVacancy}</p></li>
								<li><strong>Gender:</strong><p className='pl-5'> {Gender}</p></li>
								<li><strong>Work Place :</strong><p className='pl-5'> {workplace}</p></li>
								<li><strong>Description :</strong><p className='pl-5'> {JobDescription}</p></li>
								<li><strong>Requirements:</strong><p className='pl-5'> {JobRequirements}</p></li>
								<li><strong>Keyskills :</strong><p className='pl-5'> {JobKeySkills}</p></li>
								<li><strong>Hr Name:</strong><p className='pl-5'> {Hrname}</p></li>
								<li><strong>Hr Company:</strong><p className='pl-5'> {Hrcompany}</p></li>
								<li><strong>Hr Email:</strong><p className='pl-5'> {JobEmail}</p></li>
								<li><strong>Hr Whatsapp:</strong><p className='pl-5'> {Hrwhatsapp}</p></li>
								<li><strong>Interview Fee:</strong><p className='pl-5'> {fee}</p></li>

							</ul>


						</div>
						<div className="modal-footer">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

							</div>
							<button type='button' className='btn btn-info' style={{ borderRadius: 20 }} onClick={() => setViewModal(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
							{/* <button type="button" className="btn btn-primary" style={{ borderRadius: 20 }} onClick={UpdateHandler}>Update and Next</button> */}

						</div>
					</div>
				</div>

			</Modal >
			<Modal
				show={deleteModal}
				onHide={() => setDeleteModal}
				backdrop=""
				size='md'
				className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

			>
				<div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
					<div className="modal-content "  >
						<div class="modal-header">
							<div class="logo-img">
								<img alt="" src="images/logo/icon2.png" />
							</div>
							<h5 class="modal-title">Delete Job</h5>
							<button type="button" className="close" onClick={() => setDeleteModal(false)} data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body" >
							{/* <h5 className="modal-title" >Job details: </h5> */}
							<p style={{ color: "red" }}> Do you want to delete this Job </p>



						</div>
						<div className="modal-footer">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

							</div>
							<button type='button' className='btn btn-info' onClick={() => setDeleteModal(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
							<button type="button" className="btn btn-danger" onClick={deleteJobHandler}>Delete</button>

						</div>
					</div>
				</div>

			</Modal >



		</>
	)
}

export default NewManagejob
