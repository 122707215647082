import axios from 'axios';

const Candidate_Career = (payload) => axios.post('/candidate-career-profile/add', payload);

const Candidate_Career_By_Id=(candidate_id)=>axios.get(`/candidate-career-profile/`+candidate_id);

const Candidate_Career_Update=(candidate_id,payload)=>axios.put(`/candidate-career-profile/`+candidate_id,payload);


export default { Candidate_Career,
                Candidate_Career_By_Id,
                Candidate_Career_Update
             };