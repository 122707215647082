import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import { Link } from 'react-router-dom';
import Locations from '../../services/Location';
import JobPostSevice from '../../services/JobPost';
import JobType from '../../services/JobType';
import BrowseCandiateService from '../../services/BrowseCandidate';
import Users from '../../services/Users';
import EmployerTeam from '../../services/EmployerTeam';
import { ThreeCircles } from 'react-loader-spinner';

const BrowseCandidates = () => {
	const [loader, setLoader] = useState(false);

	const [dataServices, setDataServices] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [jobTypeData, setJobTypeData] = useState([]);
	const [locationData, setLocationData] = useState([]);
	const [jobTypeRadio, setJobTypeRadio] = useState();
	const [locationRadio, setLocationRadio] = useState();
	const [jobdata, setJobData] = useState([]);
	const [userData, setUserData] = useState({});
	const [keyword, setKeyword] = useState('');
	const [allUser,setAllUser]=useState([])
 	const [team,setTeam]=useState([])



	const userId = JSON.parse(localStorage.getItem('userId'));

	const getUserData = async()=>{
		try {
			const res = await Users.getUserById(userId);
			if(res.status === 200){
			  	setUserData(res.data);
			}

		} catch (error) {
			console.log(error)
		}
	}

	useEffect(()=>{
		getUserData()
	},[userId])

	const getAllUser=async()=>{
		try {
			const res =await Users.getUser();
			setAllUser(res.data);
		} catch (error) {
			console.log(error);
		}
	}
	const getJobData = async () => {
		try {
			const res = await JobPostSevice.getJobPost();

			setJobData(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getAllLocationData = async () => {
		try {
			setLoader(true)
			const resp = await Locations.getLocations()
			if (resp.status === 200) {
				setLocationData(resp.data);
			}
			setLoader(false)
		} catch (error) {
			return error;
		}
	}

	const getAllJobTypeData = async () => {
		try {
			setLoader(true)
			const resp = await JobType.getJobTypes();
			if (resp.status === 200) {
				setJobTypeData(resp.data);
			}
			setLoader(false)
		} catch (error) {
			return error;
		}
	}

	const getAllJob = async () => {
		setLoader(true)
		const allJobTypes = await BrowseCandiateService.getBrowseCandidate();

		if (allJobTypes.status === 200) {
		}
		setDataServices(allJobTypes.data);
		setFilteredData(allJobTypes.data);
		setLoader(false)
	};

	const getAllEmployerTeam = async()=>{
		try {
			const res =await EmployerTeam.getEmployerTeam();
			setTeam(res.data);
			
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		getAllUser();
		getAllJob();
		getAllLocationData();
		getAllJobTypeData();
		getJobData();
		getAllEmployerTeam();
	}, []);

	function keyWordSearch(e) {
		let filteredResult = dataServices.filter(function (item) {
			return item.job_title.toLowerCase().includes(e.target.value.toLowerCase());
		});
		setFilteredData(filteredResult);

	}

	// function locationOnChange(e) {
	// 	let filteredResult = dataServices.filter(function (item) {
	// 		return item.location.toLowerCase().includes(e.target.value.toLowerCase());
	// 	});
	// 	setFilteredData(filteredResult);
	// }

	const jobTypeOnChange = (e) => {
		setJobTypeRadio(e.target.value);
	}

	const locationOnChange = (e) => {
		setLocationRadio(e.target.value);
	}

	// let Loc = jobdata.filter((val) => { if (val.status === 'Active') { return val } }).map((val) => val.location);
	let Loc=dataServices.map((val)=>val.location).sort()
	let LocationData = [...new Set(Loc)]
	var showStatus;
	team.find((val)=>{
		if(val.email === userData.email){
			allUser.find((item)=>{
				if(item.id === val.employerId){
					showStatus=item.status;
				}
			})
		}
	})
	var browseCadidateList = [];
	var employerTeam = [];
	var showUser= [];
 

	employerTeam = team.filter((val)=>{
		if(val.employerId === userId) { return val;}
	});

	for(let i=0; i<employerTeam.length ; i++){
		for (let j =0 ;j<allUser.length ;j++){
			if(allUser[j].email === employerTeam[i].email){
				showUser.push(allUser[j]);
			}
		}
	}
showUser.push(userData)

for(let k =0;k<showUser.length ; k++){
	for (let l =0; l<dataServices.length ;l++){
		if (dataServices [l].user_id === showUser[k].id){
			browseCadidateList.push(dataServices[l]);

		}
	}
}

function formatNumber(number) {
	if (number >= 10000000) {
	  return (number / 10000000).toFixed(1) + 'Cr';
	} else if (number >= 100000) {
	  return (number / 100000).toFixed(1) + 'L';
	} 
	else if (number >= 1000) {
	  return (number / 1000).toFixed(1) + 'K';
	}else {
	  return number;
	}
  }

	return (
		<>
			<Header activelist="browse-candidates" />
			{loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>:


			<div className="page-content bg-white">

				<div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>

					<div className="container">
						<div className="dez-bnr-inr-entry">

							<h1 className="text-white">Browse Candidates</h1>

							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={"/"}>Home</Link></li>
									<li>Browse Candidates</li>
								</ul>
							</div>

						</div>
					</div>
				</div>
{/* 
				<div className="section-full browse-job-find">
					<div className="container">
						
					</div>
				</div> */}
							{/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Browse Candidates</li>
								</ul>
							</div> */}
				<div className="content-block"  style={{ marginTop: "5%" }}>
					
					<div className="section-full bg-white browse-job p-b50">
						<div className="container">
						
							{
								userData.roleId === 2 ?
									<div className="row">
										
										{
											browseCadidateList.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase())) && (!jobTypeRadio || (jobTypeRadio && jobTypeRadio === val.job_type)) && (!locationRadio || (locationRadio && locationRadio === val.location))) { return val; } }).map(val => val).length === 0 ?
												<div className="col-xl-9 col-lg-8" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
													<h6 style={{ color: '#2e55fa' }}>No Candidate Found</h6>
												</div>
												:

												<div className="col-xl-9 col-lg-8">

													<ul className="post-job-bx browse-job-grid post-resume row">
														{browseCadidateList.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase())) && (!jobTypeRadio || (jobTypeRadio && jobTypeRadio === val.job_type)) && (!locationRadio || (locationRadio && locationRadio === val.location))) { return val; } }).map((item, index) => (
															
															<li className="col-lg-6 col-md-6" key={index}>
																<div className="post-bx">
																	<div className="d-flex m-b20">
																		<div className="job-post-info">
																			<h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
																			<p className="m-b5 font-13">
																				<Link to="#" className="text-primary">{item.designation} </Link>
																				{item.organization}</p>
																			<ul>
																				<li><i className="fa fa-map-marker"></i>{item.region},{item.location}</li>
																				<li><i className="fa fa-money"></i> {formatNumber(item.maximum_salary)}</li>
																			</ul>
																		</div>
																	</div>
																	<div className="job-time m-t15 m-b10">
																		<p className="mb-x font-15">
																			{item.job_title ? "Applied for :" + ' ' + item.job_title : null}
																		</p>
																	</div>
																	{/* <a href="files/pdf-sample.pdf" target="blank" className="job-links">
																		<i className="fa fa-download"></i>
																	</a> */}
																</div>
															</li>
															
															
															// <li className="col-lg-6 col-md-6" key={index}>
															// 	<div className="post-bx">
															// 		<div className="d-flex m-b20">
															// 			<div className="job-post-company">
															// 				<Link to={'#'}><span>
															// 					<img alt="" src="images/testimonials/pic2.jpg" />
															// 				</span></Link>
															// 			</div>
															// 			<div className="job-post-info">
															// 				<h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
															// 				<p className="m-b5 font-14 text-primary"><i className="fa fa-home mr-1 fa-1x"></i>
															// 					{item.designation} {item.organization ? + "at" + item.organization : null}
															// 				</p>
															// 				<ul className='fz'>
															// 					<li><i className="fa fa-map-marker"></i>{item.location}, {item.region}</li>
															// 					<li><i className="fa fa-money"></i> ₹ {item.maximum_salary}</li>
															// 				</ul>
															// 			</div>
															// 		</div>
															// 		<div className="job-time m-t15 m-b10">
															// 			<p className="mb-x font-15">
															// 				{item.job_title ? "Applied for :" + ' ' + item.job_title : null}
															// 			</p>
															// 		</div>

															// 	</div>
															// </li>
														))}

													</ul>

												</div>
										}
										<div className="col-xl-3 col-lg-4">
											<div className="sticky-top browse-candidates">
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 text-uppercase">Keywords</h5>
													<div className="">
														<input type="text"
															className="form-control"
															placeholder="Search by Job Title"
															value={keyword}
															onChange={(e) => setKeyword(e.target.value)}
														/>
													</div>
												</div>
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 m-t0 text-uppercase">Location</h5>

													<div className="row">
														<div className="col-lg-8 col-md-8 col-sm-8 col-8">
															<div className="product-brand">
																<input type="radio"
																	id="check19"
																	name="example1"
																	value={''}
																	onChange={locationOnChange}
																	defaultChecked
																/>
																<label className='ml-1' htmlFor='check19' >All</label>
																{
																	LocationData.map((val, ind) => {
																		return (
																			<>
																				<div >
																					<input type="radio"
																						id={`"check1"+ ${ind}`}
																						name="example1"
																						value={val}
																						onChange={locationOnChange}
																					/>
																					<label className='ml-1' htmlFor={`"check1"+ ${ind}`} >{val}</label>
																				</div>
																			</>
																		)
																	})
																}


															</div>
														</div>

													</div>
												</div>
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 text-uppercase">Job type</h5>

													<div className="row">
														<div className="col-lg-8 col-md-8 col-sm-8 col-8">
															<div className="product-brand">
																<div >
																	<input type="radio"
																		id="chec"
																		name="exampl"
																		value={''}
																		onChange={jobTypeOnChange}
																		defaultChecked
																	/>
																	<label className='ml-1' htmlFor='chec'>All</label>
																</div>
																{
																	jobTypeData.map((val) => {
																		return (
																			<div key={val.id} >
																				<input type="radio"
																					id={`1"function-services-1"+${val.id}`}
																					name="exampl"
																					value={val.jobType}
																					onChange={jobTypeOnChange}
																				/>
																				<label className='ml-1' htmlFor={`1"function-services-1"+${val.id}`}>{val.jobType}</label>
																			</div>
																		)
																	})
																}


															</div>
														</div>

													</div>
												</div>

											</div>
										</div>
									</div> :

									<div className="row">
										{
											filteredData.filter((val) => { if (val.user_id === userId && (!jobTypeRadio || (jobTypeRadio && jobTypeRadio === val.job_type)) && (!locationRadio || (locationRadio && locationRadio === val.location))) { return val; } }).map(val => val).length === 0 ?
												<div className="col-xl-9 col-lg-8" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
													<h6 style={{ color: '#2e55fa' }}>No Candidate Found</h6>
												</div>
												:
												<div className="col-xl-9 col-lg-8">

													<ul className="post-job-bx browse-job-grid post-resume row">
														{filteredData.filter((val) => { if (val.user_id === userId && (!jobTypeRadio || (jobTypeRadio && jobTypeRadio === val.job_type)) && (!locationRadio || (locationRadio && locationRadio === val.location))) { return val; } }).map((item, index) => (
															<li className="col-lg-5 col-md-5" key={index}>
															<div className="post-bx">
																<div className="d-flex m-b20">
																	<div className="job-post-info">
																		<h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
																		<p className="m-b5 font-13">
																			<Link to="#" className="text-primary">{item.designation} </Link>
																			{item.organization}</p>
																		<ul>
																			<li><i className="fa fa-map-marker"></i>{item.region},{item.location}</li>
																			<li><i className="fa fa-money"></i> {item.maximum_salary}</li>
																		</ul>
																	</div>
																</div>
																<div className="job-time m-t15 m-b10">
																	<p className="mb-x font-15">
																		{item.job_title ? "Applied for :" + ' ' + item.job_title : null}
																	</p>
																</div>
																<a href="files/pdf-sample.pdf" target="blank" className="job-links">
																	<i className="fa fa-download"></i>
																</a>
															</div>
														</li>
														))}

													</ul>

												</div>}


										<div className="col-xl-3 col-lg-4">
											<div className="sticky-top browse-candidates">
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 text-uppercase">Keywords</h5>
													<div className="">
														<input type="text"
															className="form-control"
															placeholder="Search by Job Title"
															onChange={keyWordSearch}
														/>
													</div>
												</div>
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 m-t0 text-uppercase">Location</h5>

													<div className="row">
														<div className="col-lg-8 col-md-8 col-sm-8 col-8">
															<div className="product-brand">
																<input type="radio"
																	id="check19"
																	name="example1"
																	value={''}
																	onChange={locationOnChange}
																	defaultChecked
																/>
																<label className='ml-1' >All</label>
																{
																	LocationData.map((val) => {
																		return (
																			<>
																				<div >
																					<input type="radio"
																						id="check1"
																						name="example1"
																						value={val}
																						onChange={locationOnChange}
																					/>
																					<label className='ml-1' >{val}</label>
																				</div>
																			</>
																		)
																	})
																}


															</div>
														</div>

													</div>
												</div>
												<div className="clearfix m-b30">
													<h5 className="widget-title font-weight-700 text-uppercase">Job type</h5>

													<div className="row">
														<div className="col-lg-8 col-md-8 col-sm-8 col-8">
															<div className="product-brand">
																<div >
																	<input type="radio"
																		id="chec"
																		name="exampl"
																		value={''}
																		onChange={jobTypeOnChange}
																		defaultChecked
																	/>
																	<label className='ml-1' >All</label>
																</div>
																{
																	jobTypeData.map((val) => {
																		return (
																			<>
																				<div >
																					<input type="radio"
																						id="chec"
																						name="exampl"
																						value={val.jobType}
																						onChange={jobTypeOnChange}
																					/>
																					<label className='ml-1' >{val.jobType}</label>
																				</div>
																			</>
																		)
																	})
																}


															</div>
														</div>

													</div>

												</div>

											</div>
										</div>
									</div>}
						</div>
					</div>
				</div>
			</div>}
			<Footer />
		</>
	)
};

export default BrowseCandidates;