import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';

const BrowseJobFilterGrid = () => {
  return (
    <>

    <Header/>
      <div className="page-content bg-white">
        {/* <!-- inner page banner --> */}
         <div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(images/banner/bnr1.jpg)"}}>
            <div className="container">
                <div className="dez-bnr-inr-entry">
                    <h1 className="text-white">Browse Job Filter Grid</h1>
					{/* <!-- Breadcrumb row --> */}
					<div className="breadcrumb-row">
						<ul className="list-inline">
							<li><Link to="index.html">Home</Link></li>
							<li>Browse Job Filter Grid</li>
						</ul>
					</div>
					{/* <!-- Breadcrumb row END --> */}
                </div>
            </div>
        </div>
        <div className="content-block" ><br/>
			{/* <!-- Browse Jobs --> */}
			<div className="section-full browse-job p-b50">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-4 col-md-5 m-b30">
							<aside id="accordion1" className="sticky-top sidebar-filter">
								<h6 className="title"><i className="fa fa-sliders m-r5"></i> Refined By <Link to={"#"} className="font-12 float-right">Reset All</Link></h6>
								<div className="panel">
									<div className="acod-head">
										<h6 className="acod-title"> 
											<Link data-toggle="collapse" to={"#companies"}>
												Companies 
											</Link>
										</h6>
									</div>
									<div id="companies" className="acod-body collapse show">
										<div className="acod-content">
											<div className="custom-control custom-checkbox">
												<input className="custom-control-input" id="companies1" type="checkbox" name="checkbox-companies"/>
												<label className="custom-control-label" for="companies1">Job Mirror Consultancy <span>(50)</span> </label>
											</div>
											<div className="custom-control custom-checkbox">
												<input className="custom-control-input" id="companies2" type="checkbox" name="checkbox-companies"/>
												<label className="custom-control-label" for="companies2">Engineering Group <span>(80)</span> </label>
											</div>
											<div className="custom-control custom-checkbox">
												<input className="custom-control-input" id="companies3" type="checkbox" name="checkbox-companies"/>
												<label className="custom-control-label" for="companies3">Electric Co. <span>(235)</span> </label>
											</div>
											<div className="custom-control custom-checkbox">
												<input className="custom-control-input" id="companies4" type="checkbox" name="checkbox-companies"/>
												<label className="custom-control-label" for="companies4">Telecom industry <span>(568)</span></label>
											</div>
											<div className="custom-control custom-checkbox">
												<input className="custom-control-input" id="companies5" type="checkbox" name="checkbox-companies"/>
												<label className="custom-control-label" for="companies5">Safety/ Health <span>(798)</span></label>
											</div>
										</div>
									</div>
								</div>
								<div className="panel">
									<div className="acod-head">
										<h6 className="acod-title"> 
											<Link adata-toggle="collapse" to={"#experience"} className="collapsed">
												Experience 
											</Link>
										</h6>
									</div>
									<div id="experience" className="acod-body collapse">
										<div className="acod-content">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="one-years" type="radio" name="radio-years"/>
												<label className="custom-control-label" for="one-years">0-1 Years <span>(120)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="two-years" type="radio" name="radio-years"/>
												<label className="custom-control-label" for="two-years">1-2 Years <span>(300)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="three-years" type="radio" name="radio-years"/>
												<label className="custom-control-label" for="three-years">2-3 Years <span>(235)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="four-years" type="radio" name="radio-years"/>
												<label className="custom-control-label" for="four-years">3-4 Years <span>(568)</span></label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="five-years" type="radio" name="radio-years"/>
												<label className="custom-control-label" for="five-years">4-5 Years <span>(798)</span></label>
											</div>
										</div>
									</div>
								</div>
								<div className="panel">
									<div className="acod-head">
										<h6 className="acod-title"> 
											<Link data-toggle="collapse"  to="#salary" className="collapsed" >
												Salary 
											</Link>
										</h6>
									</div>
									<div id="salary" className="acod-body collapse">
										<div className="acod-content">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="salary-op1" type="radio" name="radio-currency"/>
												<label className="custom-control-label" for="salary-op1">0-1 lacs <span>(120)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="salary-op2" type="radio" name="radio-currency"/>
												<label className="custom-control-label" for="salary-op2">1-2 lacs <span>(300)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="salary-op3" type="radio" name="radio-currency"/>
												<label className="custom-control-label" for="salary-op3">2-3 lacs <span>(235)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="salary-op4" type="radio" name="radio-currency"/>
												<label className="custom-control-label" for="salary-op4">3-4 lacs <span>(568)</span></label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="salary-op5" type="radio" name="radio-currency"/>
												<label className="custom-control-label" for="salary-op5">4-5 lacs <span>(798)</span></label>
											</div>
										</div>
									</div>
								</div>
								<div className="panel">
									<div className="acod-head">
										<h6 className="acod-title"> 
											<Link data-toggle="collapse"  to={"#job-function"} className="collapsed" >
												Job Function 
											</Link>
										</h6>
									</div>
									<div id="job-function" className="acod-body collapse">
										<div className="acod-content">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="function-services-1" type="radio" name="radio-function"/>
												<label className="custom-control-label" for="function-services-1">Production Management <span>(120)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="function-services-2" type="radio" name="radio-function"/>
												<label className="custom-control-label" for="function-services-2">Design Engineering <span>(300)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="function-services-3" type="radio" name="radio-function"/>
												<label className="custom-control-label" for="function-services-3">Safety/ Health <span>(235)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="function-services-4" type="radio" name="radio-function"/>
												<label className="custom-control-label" for="function-services-4">Engineering <span>(568)</span></label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="function-services-5" type="radio" name="radio-function"/>
												<label className="custom-control-label" for="function-services-5">Product Development <span>(798)</span></label>
											</div>
										</div>
									</div>
								</div>
								<div className="panel">
									<div className="acod-head">
										<h6 className="acod-title"> 
											<Link data-toggle="collapse"  to="#industry" className="collapsed" >
												Industry 
											</Link>
										</h6>
									</div>
									<div id="industry" className="acod-body collapse">
										<div className="acod-content">
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="industry1" type="radio" name="radio-industry"/>
												<label className="custom-control-label" for="industry1">Telecom <span>(5)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="industry2" type="radio" name="radio-industry"/>
												<label className="custom-control-label" for="industry2">Consulting Services <span>(10)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="industry3" type="radio" name="radio-industry"/>
												<label className="custom-control-label" for="industry3">Engineering/Projects <span>(15)</span> </label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="industry4" type="radio" name="radio-industry"/>
												<label className="custom-control-label" for="industry4">Manufacturing/Industrial <span>(12)</span></label>
											</div>
											<div className="custom-control custom-radio">
												<input className="custom-control-input" id="industry5" type="radio" name="radio-industry"/>
												<label className="custom-control-label" for="industry5">Architecture/Interior Design <span>(8)</span></label>
											</div>
										</div>
									</div>
								</div>
							</aside>
						</div>
						<div className="col-xl-9 col-lg-8 col-md-7">
							<div className="job-bx-title clearfix">
								<h5 className="font-weight-700 pull-left text-uppercase">2269 Jobs Found</h5>
								<div className="float-right">
									<span className="select-title">Sort by freshness</span>
									<select style={{fontSize:14,fontweight:20}}>
										<option>Last 2 Months</option>
										<option>Last Months</option>
										<option>Last Weeks</option>
										<option>Last 3 Days</option>
									</select>
									<div className="float-right p-tb5 p-r10">
										<Link to="/browse-job-filter-list" className="p-lr5"><i className="fa fa-th-list"></i></Link>
										<Link to="/browse-job-filter-grid" className="p-lr5"><i className="fa fa-th"></i></Link>
									</div>
								</div>
							</div>
							<ul className="post-job-bx browse-job-grid row">
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
								<li className="col-lg-6 col-md-12">
									<div className="post-bx">
										<div className="d-flex m-b30">
											<div className="job-post-info">
												<h5><Link to="job-detail.html">Digital Marketing Executive</Link></h5>
												<ul>
													<li><i className="fa fa-map-marker"></i> Sacramento, California</li>
													<li><i className="fa fa-bookmark-o"></i> Full Time</li>
													<li><i className="fa fa-clock-o"></i> Published 11 months ago</li>
												</ul>
											</div>
										</div>
										<div className="d-flex">
											<div className="job-time mr-auto">
												<Link to={"#"}><span>Full Time</span></Link>
											</div>
											<div className="salary-bx">
												<span>$1200 - $ 2500</span>
											</div>
										</div>
										<label className="like-btn">
											<input type="checkbox"/>
											<span className="checkmark"></span>
										</label>
									</div>
								</li>
							</ul>
							<div className="pagination-bx float-right m-t30">
								<ul className="pagination">
									<li className="previous"><Link to={"#"}><i className="ti-arrow-left"></i> Prev</Link></li>
									<li className="active"><Link to={"#"}>1</Link></li>
									<li><Link to={"#"}>2</Link></li>
									<li><Link to={"#"}>3</Link></li>
									<li className="next"><Link to={"#"}>Next <i className="ti-arrow-right"></i></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
            {/* <!-- Browse Jobs END --> */}
		</div>
     
     </div>
     <Footer/>
    
    
    </>
  )
}

export default BrowseJobFilterGrid