import React, { useState, useEffect } from "react";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { useNavigate, useParams } from "react-router-dom";
import JobPost from "../../services/JobPost";
import { Row, Modal } from "react-bootstrap";
import PageTitle from "./PageTitle";
import AppliedJobService from "../../services/AppliedJob";
import ReportJobService from "../../services/admin/ReportJobService";
import BrowseCandiate from "../../services/BrowseCandiate";
import BrowseCandidate from "../../services/BrowseCandidate";
import AlertMessage from "../AlertMessage";
import CandidateProfile from "../../services/CandidateProfile";

const AppliedJobDetails = () => {
  const [jobData, setJobData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [reason, setReason] = useState("");
  const [alertState, setAlertState] = useState({ });
  const [reportJobLists, setReportJoblists] = useState([]);
  const [candidateData, setCandidateData] = useState({});
  const [appledJobs, setAppliedJobs] = useState([]);
  const [BrowseCandidateData, setBrowseCandidateData] = useState([]);


   const {id}=useParams();
 
  const userId = JSON.parse(localStorage.getItem("userId"));

  const navigate = useNavigate();

  const getAppliedJobData = async () => {
    try {
      const res = await JobPost.getJobPostById(id);
      if (res.status === 200) {
        setJobData(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const appliedJoblist = async () => {
    try {
      const res = await AppliedJobService.getAppliedJobById(userId);
      setAppliedJobs(res.data);
    } catch (error) {
      console.log(error);
    }
  };




  

  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }
  useEffect(() => {
    getAppliedJobData();
  }, [id]);

  useEffect(() => {
    appliedJoblist();
  }, [userId]);

  const getAllReportJobs = async () => {
    try {
      const res = await ReportJobService.getReportJob();
      if (res.status === 200) {
        setReportJoblists(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const getBrowseCandidate = async () => {
    try {
      const res = await BrowseCandiate.getBrowseCandidatebyJobAndCandidate(userId,parseInt(id));
      setBrowseCandidateData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReportJobs();
    getBrowseCandidate();
  }, []);

  const getCandidateData = async () => {
    try {
      const res = await CandidateProfile.Get_Candidate_By_Id(`${userId}`);

      if (res.status === 200) {
        setCandidateData(res.data);
      }
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    getCandidateData();
  }, [`${userId}`]);

  if (candidateData.length === 0) {
    getCandidateData();
  }

  var ID = appledJobs.filter((val) => {
    if (val.job_id === jobData.id) {
      return val;
    }
  });

  const DeleteHandler = async () => {
    var jobId = BrowseCandidateData.map((val) => val.id);
    try {
      const res = await AppliedJobService.DeleteAppliedJob(ID[0].id);

      if (res.status === 200) {
        const result = await BrowseCandiate.DeleteBrowseCandidate(jobId[0]);
        if (result.status === 200) {
          navigate("/jobs-applied-jobs");
        }
      }
    } catch (err) {
      return err;
    }
  };

  const Reporthandler = () => {
    setReportModal(true);
  };

  const reportJobHandler = async () => {
    try {
      const payload = {};

      payload.user_id = jobData.user_id;
      payload.job_id = jobData.id;
      payload.candidate_id = userId;
      payload.job_title = jobData.job_title;
      payload.age = candidateData.age;
      payload.email = candidateData.email;
      payload.name = candidateData.name;
      payload.professional_title = candidateData.professional_title;
      payload.current_salary = candidateData.current_salary;
      payload.expected_salary = candidateData.expected_salary;
      payload.languages = candidateData.languages;
      payload.description = candidateData.description;
      payload.phone = candidateData.phone;
      payload.country = candidateData.country;
      payload.city = candidateData.city;
      payload.full_address = candidateData.full_address;
      payload.postcode = candidateData.postcode;
      payload.reason = reason;

     

      if (payload.reason) {
        const res = await ReportJobService.postReportJob(payload);

        if (res.status === 200) {
          setReportModal(false);
          setAlertState({
            open: true,
            type: "success",
            message: "Job reported successfully.",
          });
          window.location.reload();
        }
      } else {
        setAlertState({
          open: true,
          type: "warning",
          message: "Please select reason",
        });
      }
    } catch (err) {
      return err;
    }
  };

  console.log(
    reportJobLists
      .filter((val) => {
        if (val.candidate_id === userId && val.job_id === jobData.id) {
          return val;
        }
      })
      .map((val) => val).length, "@length"  );
   
      function formatNumber(number) {
        if (number >= 100000) {
          return (number / 100000).toFixed(1) + 'L';
        } else if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'K';
        } else {
          return number;
        }
      }
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div className="dez-bnr-inr overlay-black-middle">
          <PageTitle activeClassName="Applied Job Detail" motherName="Home" />
        </div>

        <div className="content-block">
          <div className="section-full content-inner-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                    <h4 className="text-black font-weight-700 p-t10 m-b15 text-center">
                      Job Details
                    </h4>
                    <hr />
                    <Row>
                      <div className="col-lg-4 ml-5">
                        <ul>
                          <li>
                            <i className="ti-briefcase"></i>
                            <strong className="font-weight-700 text-black">
                              Job Title
                            </strong>
                            <span className="text-black-light">
                              {" "}
                              {jobData.job_title}{" "}
                            </span>
                          </li>
                          <li>
                            <i className="ti-bookmark-alt"></i>
                            <strong className="font-weight-700 text-black">
                              Job Tags
                            </strong>
                            {jobData.job_tags}
                          </li>
                          <li>
                            <i className="ti-layers-alt"></i>
                            <strong className="font-weight-700 text-black">
                              {" "}
                              Job Type
                            </strong>
                            {jobData.job_type}
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-4 ml-4">
                        <ul>
                          <li>
                            <i className="ti-dashboard"></i>
                            <strong className="font-weight-700 text-black">
                              Experience
                            </strong>
                            {jobData.experience}
                          </li>
                          <li>
                            <i className="ti-money"></i>
                            <strong className="font-weight-700 text-black">
                              Salary
                            </strong>{" "}
                            ₹{formatNumber(jobData.minimum_salary)}- ₹{formatNumber(jobData.maximum_salary)}{" "}
                            Monthy
                          </li>
                          <li>
                            <i className="ti-location-pin"></i>
                            <strong className="font-weight-700 text-black">
                              Address
                            </strong>
                            <span className="text-black-light">
                              {" "}
                              Bhubaneswar{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 ml-2">
                        <ul>
                          <li>
                            <i className="ti-email"></i>
                            <strong className="font-weight-700 text-black">
                              Email
                            </strong>
                            {jobData.job_email}
                          </li>
                          <li>
                            <i className="ti-calendar"></i>
                            <strong className="font-weight-700 text-black">
                              Deadline
                            </strong>
                            {jobData.last_date}{" "}
                          </li>
                        </ul>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-2">
            <button
              className="site-button mt-2"
              onClick={DeleteHandler}
              style={{ borderRadius: "20px" }}
            >
              Remove
            </button>
            {reportJobLists
              .filter((val) => {
                if (val.candidate_id === userId && val.job_id === jobData.id) {
                  return val;
                }
              })
              .map((val) => val).length > 0 ? (
              <p style={{ color: "red", marginTop: "3px" }}>
                You reported this job.
              </p>
            ) : (
              <button
                className="site-button ml-2 mt-2"
                onClick={() => Reporthandler(jobData.id)}
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#efbb20",
                  color: "#000",
                }}
              >
                Report
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={reportModal}
        onHide={setReportModal}
        className="modal fade modal-bx-info"
      >
        <div
          className="modal-dialog my-0"
          role="document"
          style={{ width: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Report Job</h5>

              <button
                type="button"
                className="close"
                onClick={() => setReportModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AlertMessage
              open={alertState.open}
              type={alertState.type}
              message={alertState.message}
              onclose={alertClose}
            />

            <div className="modal-body">
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Enter Reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={reportJobHandler}
              >
                Report
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setReportModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Footer />
    </>
  );
};
export default AppliedJobDetails;
