import React,{useState,useEffect} from 'react'
import EmployerSideBar from './component/EmployerSideNav'
import { Link } from 'react-router-dom'
import Users from '../../services/Users'
import BrowseCandiateService from '../../services/BrowseCandiate';
import EmployerTeam from '../../services/EmployerTeam';
import Header from '../../components/Layout/Header';
import { ThreeCircles } from 'react-loader-spinner'
const Resume = () => {



    
	const [loader, setLoader] = useState(false);

	const [dataServices, setDataServices] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [userData, setUserData] = useState({});

	const [allUser,setAllUser]=useState([])
 	const [team,setTeam]=useState([])



	const userId = JSON.parse(localStorage.getItem('userId'));

	const getUserData = async()=>{
		try {
			const res = await Users.getUserById(userId);
			if(res.status === 200){
			  	setUserData(res.data);
			}

		} catch (error) {
			console.log(error)
		}
	}

	useEffect(()=>{
		getUserData()
	},[userId])

	const getAllUser=async()=>{
		try {
			const res =await Users.getUser();
			setAllUser(res.data);
		} catch (error) {
			console.log(error);
		}
	}






	const getAllJob = async () => {
		setLoader(true)
		const allJobTypes = await BrowseCandiateService.getBrowseCandidate();

		if (allJobTypes.status === 200) {
		}
		setDataServices(allJobTypes.data);
		setFilteredData(allJobTypes.data);
		setLoader(false)
	};

	const getAllEmployerTeam = async()=>{
		try {
			const res =await EmployerTeam.getEmployerTeam();
			setTeam(res.data);
			
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		getAllUser();
		getAllJob();
	;
		
		getAllEmployerTeam();
	}, []);

	



	var candidateResume = [];
	var employerTeam = [];
	var showUser= [];
 

	employerTeam = team.filter((val)=>{
		if(val.employerId === userId) { return val;}
	});

	for(let i=0; i<employerTeam.length ; i++){
		for (let j =0 ;j<allUser.length ;j++){
			if(allUser[j].email === employerTeam[i].email){
				showUser.push(allUser[j]);
			}
		}
	}
showUser.push(userData)

for(let k =0;k<showUser.length ; k++){
	for (let l =0; l<dataServices.length ;l++){
		if (dataServices [l].user_id === showUser[k].id){
			candidateResume.push(dataServices[l]);

		}
	}
}

function formatNumber(number) {
	if (number >= 10000000) {
	  return (number / 10000000).toFixed(1) + 'Cr';
	} else if (number >= 100000) {
	  return (number / 100000).toFixed(1) + 'L';
	} 
	else if (number >= 1000) {
	  return (number / 1000).toFixed(1) + 'K';
	}else {
	  return number.toString();
	}
  }

    return (  
   <>
      
        <Header/>
        {loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>:
     <div style={{borderTop:'3px solid #2e55fa'}}> 
      <div className="page-content bg-white">
        
        <div className="content-block">
			
			<div className="section-full bg-white p-t50 p-b20">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-4 m-b30">
							<EmployerSideBar activemenu='resume'/>
						</div>
                        
                        
						<div className="col-xl-9 col-lg-8 m-b30">
                        {
                            userData.roleId===2? 


							<div className="job-bx clearfix">
                                
                                
								<div className="job-bx-title clearfix">
									<h5 className="font-weight-700 pull-left text-uppercase">Resume</h5>
									<Link to ='/manage-job' className="site-button right-arrow button-sm float-right">Back</Link>
								</div>
                                {
                                    candidateResume.length===0 ?<div className="col-xl-12 col-lg-12 " style={{
                                        width:'100%',
                                        height:150,
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                     <h6 style={{color: '#2e55fa'}}>No Candidate Found!</h6>
                                     <p>Post a job to get the applied candidate's resume.</p>
                                </div>
                                :
                                
                                <ul className="post-job-bx browse-job-grid post-resume row">
                                {candidateResume.map((item, index) => (
                                    
                                    <li className="col-lg-6 col-md-6" key={index}>
                                        <div className="post-bx">
											<div className="d-flex m-b20">
												<div className="job-post-info">
													<h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
													<p className="m-b5 font-13">
														<Link to="#" className="text-primary">{item.designation} </Link> at {item.organization}
														</p>
													<ul>
														<li><i className="fa fa-map-marker"></i>{item.region},{item.location}</li>
														<li><i className="fa fa-money"></i> {formatNumber(item.maximum_salary)}</li>
													</ul>
												</div>
											</div> 
											<div className="job-time m-t15 m-b10">
                                                <Link to='#' ><span>{item.keySkills}</span></Link>
											</div>
										</div>
                                        
                                        
                                        
                                      
                                    </li>
                                    
                                    
                                  

                                ))}

                            </ul>
                                }
							
							</div>
                            
                            :
                            
                            <div className="job-bx clearfix">
                                 <div className="job-bx-title clearfix">
									<h5 className="font-weight-700 pull-left text-uppercase">Resume</h5>
									<Link to ='/manage-job' className="site-button right-arrow button-sm float-right">Back</Link>
								</div>
                                {
                                    filteredData.filter((itm)=>{if(itm.user_id === userId){return itm}}).map(val=>val).length===0 ?<div className="col-xl-12 col-lg-12 " style={{
                                        width:'100%',
                                        height:150,
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                     <h6 style={{color: '#2e55fa'}}>No Candidate Found!</h6>
                                     <p>Post a job to get the applied candidate's resume.</p>
                                </div>
                                :
                                
                                <ul className="post-job-bx browse-job-grid post-resume row">
                                {filteredData.filter((itm)=>{if(itm.user_id === userId){return itm}}).map((item, index) => (
                                    
                                    <li className="col-lg-6 col-md-6" key={index}>
                                        <div className="post-bx">
											<div className="d-flex m-b20">
												<div className="job-post-info">
													<h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
													<p className="m-b5 font-13">
														<Link to="#" className="text-primary">{item.designation} </Link> at
														{item.organization}</p>
													<ul>
														<li><i className="fa fa-map-marker"></i>{item.region},{item.location}</li>
														<li><i className="fa fa-money"></i> {item.maximum_salary}</li>
													</ul>
												</div>
											</div> 
											<div className="job-time m-t15 m-b10">
                                                <Link to='#' ><span>{item.keySkills}</span></Link>
											</div>
											<a href="files/pdf-sample.pdf" target="blank" className="job-links">
												<i className="fa fa-download"></i>
											</a>
										</div>
                                        
                                        
                                        
                                      
                                    </li>
                                    
                                    
                                  

                                ))}

                            </ul>
                                }
							
							</div>
                            }
                         </div>
					</div>
				</div>
			</div>
          
		</div>
    </div>
    </div>
}
    </>
  )
}

export default Resume
