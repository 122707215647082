import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export default function AlertMessage(props) {

    return(
        <Snackbar open={props.open} anchorOrigin={ { vertical: 'top', horizontal: 'right' } } autoHideDuration={3000} onClose={props.onclose} style={{zIndex:999999, opacity: 0.85}}>
            <Alert onClose={props.onclose} severity={props.type} elevation={6} variant="filled" sx={{ width: '100%' }} style={{borderRadius: '50px'}}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}
