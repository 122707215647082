import React, {useState,useEffect}from "react";
import AlertMessage from "../AlertMessage";
import { Form, useNavigate,Link } from "react-router-dom";
import { searchBucket } from './IndexBanner';
import CategoryMaster from "../../services/Master/CategoryMaster";
import JobPostService from '../../services/JobPost';

var searchBox={};

const Jobfindbox=({Data})=>{
    const [alertState, setAlertState] = useState({open:'', type:'', message:''});
	const [titleData , setTitleData] = useState([]);
	const [titleInput , setTitleInput] = useState(Data.title);
	const [locationInput , setLocationInput] = useState(Data.location);
	const [sectorInput , setSectorInput] = useState(Data.sector);		
	const [show , setShow] = useState(false);
	const [showLocation , setShowLocation] = useState(false);																														
	const [sector , setSector] = useState([]);

   const navigate=useNavigate()
  
    const onChangeTitle=(title)=>{
        setShow(true);
        setShowLocation(false);
        setTitleInput(title);
    }
   
   
    const onChangeLocation=(location)=>{
        setShowLocation(true);
        setShow(false);
        setLocationInput(location)
    }
   
    const onChangeSector=(sector)=>{
        setShowLocation(false);
        setShow(false);
        setSectorInput(sector);
    }
   
    function alertClose(event, reason) {
        if (reason === 'clickaway') {
        	return;
        }
        setAlertState({open:false});
    };

    const submitHandler=()=>{
		searchBucket.title="";
		searchBucket.location="";
		searchBucket.sector="";
		if(titleInput || locationInput || sectorInput)
		{
			
       const payload={};
		   payload.title=titleInput;
		   payload.location=locationInput;
		   payload.sector=sectorInput;
		   searchBox=payload;

		   
		   navigate('/browse-job-filter-list');
		}else{
			setAlertState({open:true, type:'warning', message:'Please fill atleast one field'});
		}
		
	}



    
    const fillLocationHandler = async(loc)=>{
        try {
            setShowLocation(false);
            setLocationInput(loc);
        } catch (error) {
            console.log(error)
        }
    }
  
  
    const getAllSector=async()=>{
        try {
          const res = await CategoryMaster.getCategory();
  
          if(res.status === 200){
              setSector(res.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const getAllJobData = async()=>{
          try {
              const res = await JobPostService.getJobPost();
              if(res.status === 200){
                  setTitleData(res.data);
              }
          } catch ({error}) {
              console.log(error)
          }
      }
  
      useEffect(()=>{
          getAllSector();
          getAllJobData();
      },[])
  
      let Array;

      Array = titleData.filter((val)=>{if(val.status === 'Active' ){return val}})
   let  City = [...new Set(Array.map(val=>val.location))]
   let Title = [...new Set(Array.map(val=>val.job_title))]
  
   const fillHandler = async(title)=>{
	try {
		setShow(false);
		 setTitleInput(title)
	
	
	} catch (error) {
		console.log(error)
	}
}


return(
<>
<div className="section-full browse-job-find">
			<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>
				<div className="container">
					<div className="find-job-bx">
						<form className="dezPlaceAni">
							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<div className="input-group">
											<input 
											   type="text"
											   className="form-control"
										     placeholder="Job Title"
											   value={titleInput}
										   	 onChange={(e)=>onChangeTitle(e.target.value)} 											 
											  />
											<div className="input-group-append">
											  <span className="input-group-text"><i className="fa fa-search"></i></span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6">
									<div className="form-group">
										<div className="input-group">
											<input
											  type="text"
											  className="form-control" 
											  placeholder="Location" 
											  value={locationInput}
											  onChange={(e)=>onChangeLocation(e.target.value)} 
											  />
											<div className="input-group-append">
											  <span className="input-group-text"><i className="fa fa-map-marker"></i></span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6">
									<div className="form-group">
										<Form.Control 
										as="select"
										custom className="select-btn"
										name='sector'
										value={sectorInput}
										onChange={(e)=>onChangeSector(e.target.value)} 
										>
											<option >Select Sector</option>
											{
												sector.map((val)=>{
													return(
                                      <option key={val.id}>{val.category}</option>
													)
												})
											}																					
										</Form.Control>
									</div>
								</div>
								<div className="col-lg-2 col-md-6">
									<button type="button" className="site-button btn-block" onClick={submitHandler} >Find Job</button>
								</div>
							</div>
						</form>
					</div>
					<div>
						{
					
							titleInput ?
							<div className='drop-down-box find-job-bx'>
                 
								 {
									  Title.filter((val)=>{
											if(val.toLowerCase().includes(titleInput.toLowerCase())){
												return val;
											}
										}).map((val)=>{
											 return(
												<>
										     {
													show ?
													  <Link className='drop-down ' onClick={()=>fillHandler(val)}>{val}</Link>
												 :null
												 }												 
												</>
											 )
										})
								 }
							 
							</div>:null
							
							}
					
             {locationInput ?
								<div  className='drop-down-box-div find-job-bx'>

								 {
											 City.filter((val)=>{
												 if(val.toLowerCase().includes(locationInput.toLowerCase())){
													 return val;
												 }
											 }).map((val)=>{
													return(
													 <>
														{
														 showLocation ?
														  <Link className='drop-down 'onClick={()=>fillLocationHandler(val)}>{val}</Link>
														:null
														}
														
													 </>
													)
											 })
										}
								 </div>:null}
              
						
                         </div>
				</div>
			</div>



</>
)




}
export default Jobfindbox;
export {searchBox};