import * as React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import CatagoryAllJobs from "./views/Jobs/CatagoryAllJobs";
import CategoryJobs from "./views/Jobs/CategoryJobs";
import CategoryCompanyJobs from "./views/Jobs/CategoryCompanyJobs";
import CategoryLocationJobs from "./views/Jobs/CategoryLocationJobs"
import CategoryDesignationJobs from "./views/Jobs/CategoryDesignationJobs";
import CategorySkillJobs from "./views/Jobs/CategorySkillJobs";

import JobsAppliedjobs from "./views/Candidate/JobsAppliedjobs";
import JobsProfile from "./views/Candidate/JobsProfile";
import JobsMyresumejob from "./views/Candidate/JobsMyresumejob";
import JobsChangePassword from "./views/Candidate/JobsChangePassword";
import JobsAlert from "./views/Candidate/JobsAlert";

import AboutUs from "./views/landing/AboutUs";
import BrowseJobGrid from "./views/landing/BrowseJobGrid";
import BrowseJobList from "./views/landing/BrowseJobList";
import BrowseJobFilterList from "./views/landing/BrowseJobFilterList";
import BrowseJobFilterGrid from "./views/landing/BrowseJobFilterGrid";
import Login from "./views/landing/Login";
import Portfollio from "./views/landing/Portfollio";
import { Portfollio2 } from "./views/landing/Portfollio2";
import JobDetails from "./views/landing/JobDetails";
import AppliedJobDetails from "./components/Layout/AppliedJobDetails";

import ContactUs from "./views/landing/ContactUs";
import Register from "./views/landing/Register";
import Home from "./views/landing/Home";
import CompanyProfile from "./views/employer/companyProfile";
import JobPost from "./views/employer/jobPost";
import UpdatePostJob from "./views/employer/UpdatePostJob";
import ManageJob from "./views/employer/manageJob";
import BrowseCandidates from "./views/employer/browseCandidates";

import AdminManageJob from "./views/Admin/AdminManageJob";
import AdminPostJob from "./views/Admin/AdminPostJob";
import AdminAllMasters from "./views/Admin/AdminAllMasters";
import AdminAlertJob from "./views/Admin/AdminAlertJob";
import AdminManageEmployer from "./views/Admin/AdminManageEmployer";
import AdminManageCandidate from "./views/Admin/AdminManageCandidate";

import AdminYearMaster from "./views/Admin/Component/AdminYearMaster";
import AdminMonthMaster from "./views/Admin/Component/AdminMonthMaster";
import AdminDateMaster from "./views/Admin/Component/AdminDateMaster";
import AdminLocationMaster from "./views/Admin/Component/AdminLocationMaster";
import AdminCategoryMaster from "./views/Admin/Component/AdminCategoryMaster";
import AdminRegionMaster from "./views/Admin/Component/AdminRegionMaster";
import AdminSkillMaster from "./views/Admin/Component/AdminSkillMaster";
import AdminJobTypeMaster from "./views/Admin/Component/AdminJobTypeMaster";
import AdminExperience from "./views/Admin/Component/AdminExperience";
import AdminQualificationMaster from "./views/Admin/Component/AdminQualificationMaster";
import EmployerTeam from "./views/employer/EmployerTeam";

import ReportJobsBytheCandidates from "./components/ReportJobsBytheCandidates";
import RepotedCanidatebyEmployer from "./components/RepotedCanidatebyEmployer";

import JobCandidateProfileList from "./views/Candidate/JobCandidateProfileList";
import CandidateDetails from "./views/landing/CandidateDetails";
import HomePage from "./views/landing/HomePage";
import SavedJobs from "./views/Candidate/SavedJobs";
import MyResume from "./views/Candidate/MyResume";
// import Managejob from "./views/employer/NewManagejob";
import NewManagejob from "./views/employer/NewManagejob";
import Resume from "./views/employer/Resume";
import NewCompanyProfile from "./views/employer/NewCompanyProfile";
import Signin from "./views/landing/Signin";
import AdminUpdateJob from "./views/Admin/AdminUpdateJob";
import EmployerDetails from "./views/landing/EmployerDetails";
import CommunityGuidelines from "./views/landing/CommunityAndGuidelines";
import PrivecyPolicy from "./views/landing/PrivecyPolicy";
import UserTermandCondition from "./views/landing/UserTermandCondition";
import BlogDetails from "./views/landing/BlogDetails";
import DashBoard from "./views/landing/DashBoard";
import AdminAddBlog from "./views/Admin/AdminAddBlog";
import ResumePdfView from "./views/Candidate/ResumePdfView";
import PdfPreview from "./views/Candidate/PdfPreview";
const JobyardRoutes = () => {
  return (
    <>
      <Routes>

        <Route path="/" element={<HomePage />} ></Route>
        <Route path="/register" element={<Register />} ></Route>
        <Route path="/login" element={<Login />} ></Route>
        <Route path='/signin' element={<Signin/>} />
        <Route path="/portfollio" element={<Portfollio />} ></Route>
        <Route path="/portfollio2" element={<Portfollio2 />} ></Route>
        <Route path="/community-guidelines" element={<CommunityGuidelines />} ></Route>
        <Route path="/privacy-policies" element={<PrivecyPolicy />} ></Route>
        <Route path="/term-and-condition" element={<UserTermandCondition />} ></Route>
        <Route path="/dashboard" element={< DashBoard/>} ></Route>

        <Route path="/category-all-jobs" element={<CatagoryAllJobs />} ></Route>
        <Route path="/category-Jobs" element={<CategoryJobs />} ></Route>
        <Route path="/category-company-jobs" element={<CategoryCompanyJobs />} ></Route>
        <Route path="/category-location-jobs" element={<CategoryLocationJobs />} ></Route>
        <Route path="/category-designation-jobs" element={<CategoryDesignationJobs />} ></Route>
        <Route path="/category-skill-jobs" element={<CategorySkillJobs />} ></Route>

        <Route path="/jobs-applied-jobs" element={<JobsAppliedjobs />} ></Route>
        <Route path="/jobs-profile" element={<JobsProfile />} ></Route>
        <Route path="/jobs-saved-jobs" element={<SavedJobs />} ></Route>
        <Route path="/jobs-my-resume" element={<MyResume />} ></Route>
        <Route path="/jobs-change-password" element={<JobsChangePassword />} ></Route>
        <Route path="/jobs-alerts" element={<JobsAlert />} ></Route>
        <Route path="/pdf-Preview" element={<p />} ></Route>

        <Route path="/about-us" element={<AboutUs />} ></Route>
        <Route path="/browse-job-grid" element={<BrowseJobGrid />} ></Route>
        <Route path="/browse-job-list" element={<BrowseJobList />} ></Route>
        <Route path="/browse-job-filter-list" element={<BrowseJobFilterList />} ></Route>
        <Route path="/browse-job-filter-grid" element={<BrowseJobFilterGrid />} ></Route>
        <Route path="/job-details/:id" element={<JobDetails />} ></Route>
        <Route path="/applied-job-details/:id" element={<AppliedJobDetails />} ></Route>
        <Route path="/contact-us" element={<ContactUs />} ></Route>
        
      
       
        <Route path="/company-profile" element={< NewCompanyProfile />} />
        <Route path="/job-post" element={< JobPost />} />
        <Route path="/update-jobs/:id" element={<UpdatePostJob />} />
        <Route path="/manage-job" element={<  NewManagejob />} />
        {/* <Route path="/newmanage-job" element={< NewManagejob />} /> */}

        <Route path="/browse-candidates" element={<BrowseCandidates />} />
        <Route path="/employer-team" element={<EmployerTeam />} />
        <Route path="/resume" element={<Resume/>} />


        <Route path="/admin-manage-job" element={<AdminManageJob />} />
        <Route path="/admin-post-job" element={<AdminPostJob />} />
        <Route path="/admin-all-masters" element={<AdminAllMasters />} />
        <Route path="/admin-alert-job" element={<AdminAlertJob />} />
        <Route path="/admin-manage-employer" element={<AdminManageEmployer />} />
        <Route path="/admin-manage-candidate" element={<AdminManageCandidate />} />
        <Route path="/admin-update-job/:id" element={<AdminUpdateJob />} />
        <Route path="/admin-add-blog" element={<AdminAddBlog />} />

        <Route path="/admin-year-master" element={<AdminYearMaster />} />
        <Route path="/admin-month-master" element={<AdminMonthMaster />} />
        <Route path="/admin-date-master" element={<AdminDateMaster />} />
        <Route path="/admin-location-master" element={<AdminLocationMaster />} />
        <Route path="/admin-category-master" element={<AdminCategoryMaster />} />
        <Route path="/admin-region-master" element={<AdminRegionMaster />} />
        <Route path="/admin-skill-master" element={<AdminSkillMaster />} />
        <Route path="/admin-jobtype-master" element={<AdminJobTypeMaster />} />
        <Route path="/admin-experience-master" element={<AdminExperience />} />
        <Route path="/admin-qualification-master" element={<AdminQualificationMaster />} />
        <Route path="/pdf-viewer" element={<PdfPreview />} />
       
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/report/jobs-by/candidate/:id" element={<ReportJobsBytheCandidates />} />
        <Route path="/report/candidate-by/employer/:id" element={<RepotedCanidatebyEmployer />} />

        <Route path="/jobs-candidate-profile-list/:id" element={<JobCandidateProfileList />} />
        <Route path="/candidate-applied-job-detail/:id" element={<CandidateDetails />} />
        <Route  path='/employer-details/:id'  element={<EmployerDetails/>}/>
      </Routes>
    </>
  )
};
export default JobyardRoutes;