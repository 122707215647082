import axios from 'axios';

const companyProfile = (payload) => axios.post('/company-profiles', payload);

const getEmployerById = (employer_id) => axios.get(`/company-profiles/`+employer_id);

const updateCompanyProfile =(id,payload) =>axios.put(`/company-profiles/`+id,payload);

const getAllCompanyProfile =()=> axios.get('/company-profiles/');

export default {
    companyProfile,
    getEmployerById,
    updateCompanyProfile,
    getAllCompanyProfile
};