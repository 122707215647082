import axios from "axios";

const imageUpload =(payload)=> axios.post('/employer-image/upload',payload);

const getImageUpload =(payload)=> axios.get('/employer-image/',+payload);

const getEmployerImageById=(id)=>axios.get(`/employer-image/${id}`);

const updateUploadImage =(id , payload)=> axios.put(`/employer-image/update/${id}`,payload);


export default{
    imageUpload,
    getImageUpload,
    updateUploadImage,
    getEmployerImageById
}