import axios from 'axios';

const Headline = (payload) => axios.post('/candidate-resume/add', payload);
const getHeadline = () => axios.get('/candidate-resume');
const getHeadlineById=(candidate_id)=>axios.get(`/candidate-resume/`+candidate_id);
const Headline_Update=(candidate_id,payload)=>axios.put(`/candidate-resume/`+candidate_id,payload);

export default { Headline,
                 getHeadline,
                 getHeadlineById,
                 Headline_Update
                
                
                };