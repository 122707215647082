import axios from 'axios';

const Candidate_Patent = (payload) => axios.post('/candidate-patent/add', payload);


const Candidate_Patent_By_Id=(candidate_id)=>axios.get(`/candidate-patent/`+candidate_id);

const Candidate_Patent_Update=(candidate_id,payload)=>axios.put(`/candidate-patent/`+candidate_id,payload);



export default { Candidate_Patent,
                 Candidate_Patent_By_Id,
                 Candidate_Patent_Update
                
                };