import axios from 'axios';

const Candidate_Profile_Summary = (payload) => axios.post('/candidate-profile-summary/add', payload);

const Candidate_Profile_Summary_By_Id=(candidate_id)=>axios.get(`/candidate-profile-summary/`+candidate_id);

const Candidate_Profile_Summary_Update=(candidate_id,payload)=>axios.put(`/candidate-profile-summary/edit/`+candidate_id,payload);

export default { Candidate_Profile_Summary,
                 Candidate_Profile_Summary_By_Id,
                 Candidate_Profile_Summary_Update
                };