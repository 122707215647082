import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import UsersService from '../../../services/Users';
import React, { useState, useEffect } from 'react';
import CandidateImage from '../../../services/image/CandidateImage';
import AlertMessage from '../../../components/AlertMessage';

const CandidateSideNav = (props) => {
	
	const [showLogout, setShowLogOut] = useState(false);
	const [image,setImage]=useState(null);
  const [imageData,setImageData]=useState({});
  const [imageModal,setImageModal]=useState(false);
  const [candidateImage,setCandidateImage]=useState([]);
	const [alertState,setAlertState]=useState({})
	
  
  const navigate = useNavigate();
  let userId = JSON.parse(localStorage.getItem('userId'));




  const getImageByUserId=async()=>{
    try {
        const res = await CandidateImage.getImageUpload(userId);
        setImageData(res.data);
       
    } catch (error) {
        console.log(error);
    }
}

 useEffect(()=>{
  getImageByUserId()
 },[`${userId}`])

 function alertClose(event, reason) {
  if (reason === 'clickaway') {
    return;
  }
  setAlertState({ open: false });
};

const onImageChange=(img)=>{
  setCandidateImage(img.target.files);
  if (img.target.files && img.target.files[0].name) {
    setImageModal(true);
    setImage(URL.createObjectURL(img.target.files[0]));

  }
}


   const uploadHandler =async()=>{
        try {
            const formdata = new FormData();
            formdata.append('candidate_id',userId);
            formdata.append('file',candidateImage[0]);


         const res =  await  CandidateImage.imageUpload(formdata,  {
            headers: {
                "Content-type": "multipart/form-data",
            }, 
                          
        });

        if(res.status === 200){
          setAlertState({open:true ,type :"success" ,message:"Candidate Profile picture Upload successfully "})
            window.location.reload();
        }
        } catch (error) {
            console.log(error);
        }
    }

    
    const imageUpdateHandler=async()=>{
        const ID =await imageData.id;

        try {
            const formdata = new FormData();
            formdata.append('file',candidateImage[0]);
            
         const res =  await  CandidateImage.updateUploadImage(ID,formdata,  {
            headers: {
                "Content-type": "multipart/form-data",
            },                    
        });
        if(res.status === 200){
          setAlertState({open:true ,type :"success" ,message:"Candidate Profile picture Update successfully "})
            window.location.reload();
        }
        } catch (error) {
            console.log(error);
        }
    }



	const activeMenu = props.activemenu;



  	
	const toggleLogOutModal = async () => {
		setShowLogOut(!showLogout);
	};

	
    const menus = [{ icon: 'fa fa-user-o', link: 'jobs-profile', menuName: 'My Profile' },
    { icon: 'fa fa-file-text-o', link: 'jobs-my-resume', menuName: 'My Resume' },
    { icon: 'fa fa-heart-o', link: 'jobs-saved-jobs', menuName: 'Saved Jobs' },
    { icon: 'fa fa-briefcase', link: 'jobs-applied-jobs', menuName: 'Applied Jobs' },
    { icon: 'fa fa-bell-o', link: 'jobs-alerts', menuName: 'Job Alerts' },
    { icon: 'fa fa-key', link: 'jobs-change-password', menuName: 'Change Password' },

    ];

    const menuitm = [{ icon: 'fa fa-user-o', link: 'jobs-profile', menuName: 'My Profile' },
    { icon: 'fa fa-file-text-o', link: '#', menuName: 'My Resume' },
    { icon: 'fa fa-heart-o', link: '#', menuName: 'Saved Jobs' },
    { icon: 'fa fa-briefcase', link: '#', menuName: 'Applied Jobs' },
    { icon: 'fa fa-bell-o', link: '#', menuName: 'Job Alerts' },
    { icon: 'fa fa-key', link: '#', menuName: 'Change Password' },

    ];

    const menuList = menus.map((item) => {
        return (
            <li><a href={"/" + item.link} className={item.link === activeMenu && "active"}>
                <i className={item.icon} aria-hidden="true"></i>
                <span>{item.menuName}</span></a>
            </li>
        );
    });

    const menuListitm = menuitm.map((item) => {
        return (
            <li><a href={"" + item.link} className={item.link === activeMenu && "active"}>
                <i className={item.icon} aria-hidden="true"></i>
                <span>{item.menuName}</span></a>
            </li>
        );
    });

	const handleLogout = async () => {
		try {
		  const userEmail = localStorage.getItem("userMailMob");
		  const userPassword = localStorage.getItem("password");
		  const userLogout = {};
		  userLogout.email = userEmail;
		  userLogout.password = userPassword;
		  const res = await UsersService.logoutUser(userLogout);
		  if (res.status === 200) {
			window.localStorage.clear();
			window.localStorage.setItem('userId', 0);
						navigate("/");
		  }
		} catch (error) {
		  console.log(error);
		}
	  };

    
	
	return (
    <>
    <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
        <div className="col-xl-3 col-lg-4 m-b30">
							<div className="sticky-top">
								<div className="candidate-info">
									<div className="candidate-detail text-center">
                  <div className="canditate-des">
																<Link to={"#"}>
                                {
																		!imageData.image  ?
																			<img alt="" src="images/logo/icon3.jpg" />
																			:
                                      (image ?
                                      <img src={image} style={{ width:'100%', height:"100%" ,borderRadius:"50%"}} />
                                      :
                                       
																			<img src={imageData.image} style={{ width:"100%", height:"100%" ,borderRadius:"50%"}} /> )

																	}
																	
																			
                              {/* <img src='images/blog/grid/pic1.jpg'/> */}
																	
																</Link>
																<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
																	<input
																		className="update-flie"
																		type="file"
																		onChange={(e) => onImageChange(e)}
                                    accept="image/x-png, image/gif, image/jpeg"
																	/>
																	<i className="fa fa-pencil"></i>
																</div>
															</div>
										<div className="candidate-title">
											<div className="">
												<h4 className="m-b5"><Link to={"#"}>{props.candidateName}</Link></h4>
												<p className="m-b0"><Link to={"#"}>{props.prof}</Link></p>
											</div>
										</div>
									</div>
									<ul>
                                        
								    {menuList}

									<li onClick={toggleLogOutModal} ><Link to="#" >
									   <i className="fa fa-sign-out" aria-hidden="true"></i> 
									   <span>Log Out</span></Link>
									</li>

									
									</ul>
								</div>
							</div>
						</div>
					      
			<Modal
                show={showLogout}
                onHide={toggleLogOutModal}
                className="modal fade lead-form-modal"
                style={{marginTop:"80px"}}
                >
                <div className="modal-dialog" role="document" >
                  <div className="modal-content">
                    <button
                      type="button"
                      className="close"
                      style={{ marginRight:-7, marginTop: "2px" }}
                      onClick={toggleLogOutModal}
                    >
                      <span aria-hidden="true" >&times;</span>
                    </button>
                    <div className="modal-body row m-a0 clearfix">
                      <div
                        className="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0"
                        style={{
                          backgroundImage: "url(images/background/bg3.jpg)",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}>
                        <div className="form-info text-white align-self-center">
                          <p className="m-t15">
                            Get started with JobYard to receive latest job
                            alerts based on your preferences.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 p-a0">
                        <div className="lead-form browse-job text-left">
                          <h5
                            style={{ textAlign: "center" }}
                            className="m-t0 mb-5 font-16">
                            Do You Want To Logout ?
                          </h5>
                          <div
                            className="clearfix "
                            style={{
                            marginTop: 130,
                            textAlign: "center",
                            justifyItems: "center",
                            marginRight: "45px",
                            }}>
                            <button
                              style={{ borderRadius: 100, textAlign: "center" }}
                              type="button"
                              className=" btn btn-success mr-4 ml-5 "
                              onClick={toggleLogOutModal}
                            >
                              No{" "}
                            </button>
                            <button
                              style={{ borderRadius: 100 }}
                              type="button"
                              className=" btn btn-danger "
                              onClick={handleLogout}
                            >
                              Yes{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </Modal> 
          <Modal show={imageModal} onHide={setImageModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0 mx-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Image Upload</h5>
                            <button type="button" className="close" onClick={() => {setImageModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                imageData.image ?
                                <p className="text-center" style={{color:'red'}}> Are you sure to Update this Image ? </p>:
                                <p className="text-center" style={{color:'red'}}> Are you sure to Upload this Image ? </p>

                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() =>{ setImageModal(false);setImage(null)}}>Cancel</button>
                            {
                                imageData.image?
                                <button type="button" className="btn btn-success" onClick={imageUpdateHandler}  >Update</button>:
                                <button type="button" className="btn btn-success" onClick={uploadHandler}  >Upload</button>

                            }
                        </div>
                    </div>
                </div>
            </Modal>
    </>
  )
}

export default CandidateSideNav;