import axios from 'axios';

const getSkills = () => axios.get('/masters/skill');

const addSkills=(payload)=>axios.post('/masters/skill',payload);


const UpdateSkills=(id,payload)=>axios.put(`/masters/skill/`+id,payload);

const DeleteSkills=(id)=>axios.delete(`/masters/skill/`+id);

export default { getSkills,addSkills,UpdateSkills,DeleteSkills };