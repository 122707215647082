import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UsersService from '../../../services/Users';
import { Modal } from 'react-bootstrap';


const AdminSideBar = (props) => {
  const [showLogout, setShowLogOut] = useState(false);

  const userName = localStorage.getItem("userName");

  const navigate = useNavigate();

  const toggleLogOutModal = async () => {
    setShowLogOut(!showLogout);
  };
  const activeMenu = props.activemenu;
 
 
 
  const handleLogout = async () => {
		try {
		  const userEmail = localStorage.getItem("userMailMob");
		  const userPassword = localStorage.getItem("password");
		  const userLogout = {};
		  userLogout.email = userEmail;
		  userLogout.password = userPassword;
		  const res = await UsersService.logoutUser(userLogout);
		  if (res.status === 200) {
			window.localStorage.clear();
			window.localStorage.setItem('userId', 0);
						navigate("/");
		  }
		} catch (error) {
		  console.log(error);
		}
	  };
  const sideMenu = [{ icon: 'fa fa-user-o', link: 'admin-all-masters', menuName: 'All Masters' },
  { icon: 'fa fa-file-text-o', link: 'admin-post-job', menuName: 'Post A Job' },
  { icon: "fa-sharp fa-solid fa-bell", link: 'admin-alert-job', menuName: 'Alert A Job' },
  { icon: 'fa fa-id-card-o', link: 'admin-manage-job', menuName: 'Manage jobs' },
  { icon: 'fa-solid fa-user-group', link: 'admin-manage-employer', menuName: 'Manage Employers' },
  { icon: "fa-solid fa-people-group", link: 'admin-manage-candidate', menuName: 'Manage Candidates' },
  { icon: "fa-solid fa-blog", link: 'admin-add-blog', menuName: 'Post A Blog' },
  ];



  const SidemenuList = sideMenu.map((item, ind) => {
    return (
      <li key={ind}><Link to={"/" + item.link} className={item.link === activeMenu && "active"}>
        <i className={item.icon} aria-hidden="true"></i>
        <span>{item.menuName}</span></Link>
      </li>
    );
  });
  return (
    <>
      <div className="sticky-top">
        <div className="candidate-info company-info">
          <div className="candidate-detail text-center">
            <div className="canditate-des">
              <Link to={"#"}>
                <img alt="" src="/images/logo/icon3.jpg" />
              </Link>
              <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
                <input type="file" className="update-flie" />
                <i className="fa fa-pencil"></i>
              </div>
            </div>
            <div className="candidate-title">
              <h4 className="m-b5"><Link to={"#"}>@{userName}</Link></h4>
            </div>
          </div>
          <ul>

            {SidemenuList}

            <  li onClick={toggleLogOutModal} ><Link to={"#"}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>
              <span>Log Out</span></Link></li>
           
          </ul>
        </div>

      </div>



      <Modal
        show={showLogout}
        onHide={toggleLogOutModal}
        className="modal fade lead-form-modal"
        style={{ marginTop: "80px" }}
      >
        <div className="modal-dialog" role="document" >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              style={{ marginRight: -7, marginTop: "2px" }}
              onClick={toggleLogOutModal}
            >
              <span aria-hidden="true" >&times;</span>
            </button>
            <div className="modal-body row m-a0 clearfix">
              <div
                className="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0"
                style={{
                  backgroundImage: "url(images/background/bg3.jpg)",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}>
                <div className="form-info text-white align-self-center">
                  <p className="m-t15">
                    Get started with JobYard to receive latest job
                    alerts based on your preferences.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 p-a0">
                <div className="lead-form browse-job text-left">
                  <h5
                    style={{ textAlign: "center" }}
                    className="m-t0 mb-5 font-16">
                    Do You Want To Logout ?
                  </h5>
                  <div
                    className="clearfix "
                    style={{
                      marginTop: 130,
                      textAlign: "center",
                      justifyItems: "center",
                      marginRight: "45px",
                    }}>
                    <button
                      style={{ borderRadius: 100, textAlign: "center" }}
                      type="button"
                      className=" btn btn-success mr-4 ml-5 "
                      onClick={toggleLogOutModal}
                    >
                      No{" "}
                    </button>
                    <button
                      style={{ borderRadius: 100 }}
                      type="button"
                      className=" btn btn-danger "
                      onClick={handleLogout}
                    >
                      Yes{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
};

export default AdminSideBar;