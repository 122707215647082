import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/templete.css";
import "./assets/css/animate.css";
import "./assets/css/skin/skin-1.css";
import "./assets/css/dark-layout.css";

import { GlobalProvider } from './context/GlobalState';

function App() {
  return (
    <div className="App">
      <GlobalProvider> 
        <Routes/>
      </GlobalProvider>
    </div>
  );
}

export default App;