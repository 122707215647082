import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import CandidateSideNav from "./component/CandidateSideNav";
import { Modal } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import AlertMessage from "../../components/AlertMessage";
import ProfileVisiterService from "../../services/ProfileVisiterService";
import { Form } from "react-bootstrap";
import ExperienceMaster from "../../services/Master/ExperienceMaster";
import Users from "../../services/Users";
import { ThreeCircles } from 'react-loader-spinner'
import CandidateService from "../../services/CandidateProfile";
import pincodeDirectory from "india-pincode-lookup"
import Error from "../../constants/ErrorMessage";

const JobsProfile = () => {
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [alertState, setAlertState] = useState({});
  const [visterData, setVisiterData] = useState([]);
  const [experienceData, setExperiencedata] = useState([]);
  const [user, setUser] = useState({});
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);

  const [profile, setProfile] = useState({
    candidate_id: 0,
    name: "",
    professional_title: "",
    age: 0,
    languages: "",
    current_salary: 0,
    expected_salary: 0,
    description: "",
    phone: 0,
    email: "",
    country: "",
    city: "",
    state: "",
    full_address: "",
    postcode: 0,
    experience: "",
    company: "",
    availability_to_join: 0,
  });

  const UserID = JSON.parse(localStorage.getItem("userId"));

  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }

  const getAllData = async () => {
    try {
      const res = await ExperienceMaster.GetExperience();
      setExperiencedata(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllVisiters = async () => {
    try {
      const res = await ProfileVisiterService.GetProfileVisiters();
      if (res.status === 200) {
        setVisiterData(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    setLoader(true);
    getAllVisiters();
    getAllData();
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  var company = visterData
    .filter((val) => {
      if (val.candidate_id === UserID) {
        return val;
      }
    })
    .map((val) => val);

  company.sort();
  var current = null;
  var arr = [];
  var cnt = 0;
  for (let i = 0; i < company.length; i++) {
    if (company[i].employer_id !== current) {
      if (cnt > 0) {
        arr.push(current);
      }
      current = company[i].employer_id;
      cnt = 1;
    } else {
      cnt++;
    }
  }
  if (cnt > 0) {
    arr.push(current);
  }

  const onChangeHandler = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };



  const saveHandler = async (e) => {
    setShowError(true);

    e.preventDefault();
    try {
      const payload = {};
      payload.id = 0;
      payload.name = user.name;
      payload.professional_title = profile.professional_title;
      payload.age = profile.age;
      payload.languages = profile.languages;
      payload.current_salary = profile.current_salary;
      payload.expected_salary = profile.expected_salary;
      payload.description = profile.description;
      payload.phone = user.mobile;
      payload.email = user.email;
      payload.country = "India";
      payload.city = profile.city;
      payload.state = "Odisha";
      payload.full_address = profile.full_address;
      payload.postcode = profile.postcode;
      payload.candidate_id = UserID;
      payload.experience = profile.experience;
      payload.company = profile.company;
      payload.availability_to_join = profile.availability_to_join;


      let d1 = new Date();
      let d2 = new Date(profile.availability_to_join);
      // if (payload.professional_title.length > 100) {

      //   setAlertState({ open: true, type: 'error', message: Error.titleLimit });
      // }
      // else if (payload.languages.length > 100) {
      //   setAlertState({ open: true, type: 'error', message: Error.languageLimit });
      // }
      // else if (payload.age.length > 2) {
      //   setAlertState({ open: true, type: 'error', message: Error.validAge });
      // }
      if (!payload.experience) {
        setAlertState({ open: true, type: 'error', message: Error.experience });
      }
      // else if (payload.experience !== "Fresher") {
      //   if(!payload.company){
      //     setAlertState({ open: true, type: 'error', message: Error.companyName });
      //   }
        
      // }
      // else if (payload.company.length > 250) {
      //   setAlertState({ open: true, type: 'error', message: Error.companyNameLimit });
      // }
      // else if (+d2 < +d1) {
      //   setAlertState({ open: true, type: 'error', message: Error.invalidDate });
      // }
      // else if (payload.current_salary.length > 8) {
      //   setAlertState({ open: true, type: 'error', message: Error.salLimit });
      // }
      // else if (payload.expected_salary.length > 8) {
      //   setAlertState({ open: true, type: 'error', message: Error.salLimit });
      // }
      // else if (payload.description.length > 250) {
      //   setAlertState({ open: true, type: 'error', message: Error.description });
      // }
      // else if (payload.full_address.length > 250) {
      //   setAlertState({ open: true, type: 'error', message: Error.addressLimit });
      // }
      else if ((pincodeDirectory.lookup(profile.postcode).length === 0) || pincodeDirectory.lookup(profile.postcode)[0].stateName !== 'ODISHA') {
        if (pincodeDirectory.lookup(profile.postcode).length === 0) {
          setAlertState({ open: true, type: 'warning', message: `${profile.postcode} doesn't belong to Odisha` });
        }
        else if (pincodeDirectory.lookup(profile.postcode)[0].stateName !== 'ODISHA') {
          setAlertState({ open: true, type: 'warning', message: `${profile.postcode} doesn't belong to Odisha` });

        }
      }
      // else if (payload.city.length > 100) {
      //   setAlertState({ open: true, type: 'error', message: Error.cityLimit });
      // }
      else {
        setLoader(true);

        const res = await CandidateService.addCandidate(payload);
        if (res.status === 200) {
          setAlertState({ open: true, type: 'success', message: 'Candidate Information saved successfully' });
         await getAllVisiters();
         await getAllData();
         await getCandidateData();
         await getUserData();
          setCandidateProfile(false);

          setTimeout(() => {
            setLoader(false);
          }, 1500);
          return res;
        } else {
          setLoader(false);

          setAlertState({ open: true, type: 'error', message: 'Something went wrong' });
        }

      }
    } catch (err) {
      setLoader(false);

      if (
        err.response &&
        err.response.data &&
        err.response.data.status === 400
      ) {
        setAlertState({
          open: true,
          type: "error",
          message: err.response.data.message,
        });
      } else {
        setAlertState({
          open: true,
          type: "error",
          message: "Something went wrong.",
        });
      }
    }
  };



  const getCandidateData = async () => {
    setLoader(true);
    const res = await CandidateService.getCandidateById(UserID);
    if (res.status === 200) {
      setProfile(res.data);
    }
    setLoader(false);
  };

  const getUserData = async () => {
    setLoader(true);
    try {
      const res = await Users.getUserById(UserID);
      setUser(res.data);


    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCandidateData();
    getUserData();
  }, [`${UserID}`]);


  const updateHandler = async (e) => {
    e.preventDefault();
    setShowError(true);


    try {
      const payload = {};

      payload.name = user.name;
      payload.professional_title = profile.professional_title;
      payload.age = profile.age;
      payload.languages = profile.languages;
      payload.current_salary = profile.current_salary;
      payload.expected_salary = profile.expected_salary;
      payload.description = profile.description;
      payload.phone = user.mobile;
      payload.email = user.email;
      payload.country = "India";
      payload.city = profile.city;
      payload.state = "Odisha";
      payload.full_address = profile.full_address;
      payload.postcode = profile.postcode;
      payload.candidate_id = UserID;
      payload.experience = profile.experience;
      payload.company = profile.company;
      payload.availability_to_join = profile.availability_to_join;
      let d1 = new Date();
      let d2 = new Date(profile.availability_to_join);
      // if (!payload.professional_title || payload.professional_title.length > 100) {
      //   if (payload.professional_title.length > 100) {
      //     setAlertState({ open: true, type: 'error', message: Error.titleLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.title });
      //   }
      // } else if (!payload.languages || payload.languages.length > 100) {
      //   if (payload.languages.length > 100) {
      //     setAlertState({ open: true, type: 'error', message: Error.languageLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.language });
      //   }
      // } 
      // if (!payload.age || payload.age.length > 2) {
      //   if (payload.age.length > 2) {
      //     setAlertState({ open: true, type: 'error', message: Error.validAge });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.age });
      //   }
      // }
     if (!payload.experience) {
        setAlertState({ open: true, type: 'error', message: Error.experience });
      }
      // if (payload.experience !== "Fresher") {
      //   if (!payload.company || payload.company.length > 250) {
      //     if (payload.company.length > 250) {
      //       setAlertState({ open: true, type: 'error', message: Error.companyNameLimit });
      //     } else {
      //       setAlertState({ open: true, type: 'error', message: Error.companyName });
      //     }
      //   }
      // }

      // else if (!payload.availability_to_join || +d2 < +d1) {
      //   if (+d2 < +d1) {
      //     setAlertState({ open: true, type: 'error', message: Error.invalidDate });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.date });
      //   }
      // } else if (!payload.current_salary || payload.current_salary.length > 8) {
      //   if (payload.current_salary.length > 8) {
      //     setAlertState({ open: true, type: 'error', message: Error.salLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.salary });
      //   }
      // } else if (!payload.expected_salary || payload.expected_salary.length > 8) {
      //   if (payload.expected_salary.length > 8) {
      //     setAlertState({ open: true, type: 'error', message: Error.salLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.Salary });
      //   }
      // } else if (!payload.description || payload.description.length > 250) {
      //   if (payload.description.length > 250) {
      //     setAlertState({ open: true, type: 'error', message: Error.description });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.Description });
      //   }
      // } else if (!payload.full_address || payload.full_address.length > 250) {
      //   if (payload.full_address.length > 250) {
      //     setAlertState({ open: true, type: 'error', message: Error.addressLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.address });
      //   }
      // }
       else if ((pincodeDirectory.lookup(profile.postcode).length === 0) || pincodeDirectory.lookup(profile.postcode)[0].stateName !== 'ODISHA') {

        if (pincodeDirectory.lookup(profile.postcode).length === 0) {
          setAlertState({ open: true, type: 'warning', message: `${profile.postcode} doesn't belong to Odisha` });
        }
        else if (pincodeDirectory.lookup(profile.postcode)[0].stateName !== 'ODISHA') {
          setAlertState({ open: true, type: 'warning', message: `${profile.postcode} doesn't belong to Odisha` });

        }
      } 
      // else if (!payload.city || payload.city.length > 250) {
      //   if (payload.city.length > 250) {
      //     setAlertState({ open: true, type: 'error', message: Error.cityLimit });
      //   } else {
      //     setAlertState({ open: true, type: 'error', message: Error.city });
      //   }
      // }
      else {
        const res = await CandidateService.updateCandidateProfile(UserID, payload);
        if (res.status === 200) {
          setAlertState({ open: true, type: 'success', message: 'Candidate Profile Updated successfully' });
         await getAllVisiters();
         await getAllData();
         await getCandidateData();
         await getUserData();
          setCandidateProfile(false);
          setTimeout(() => {
            setLoader(false);

          }, 1500);
          return res;
        } else {
          setLoader(false);
          setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
        }
      }
    } catch (err) {
      setLoader(false);
      if (
        err.response &&
        err.response.data &&
        err.response.data.status === 400
      ) {
        setAlertState({
          open: true,
          type: "error",
          message: err.response.data.message,
        });
      } else {
        setAlertState({
          open: true,
          type: "error",
          message: "Something went wrong.",
        });
      }
    }
  };
  let cityList = [];
  cityList = [...new Set(pincodeDirectory.lookup(profile.postcode).map(val => val.taluk))]

  let d1 = new Date();
  let d2 = new Date(profile.availability_to_join);

  function formatNumber(number) {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(1) + 'Cr';
    } else if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'L';
    }
    else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    } else {
      return number;
    }
  }


  return (
    <>
      <Header activelist="jobs-profile" />
      <AlertMessage
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        onclose={alertClose}
      />
      {loader ?
        <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
          <ThreeCircles
            height="80"
            width="80"
            color="#2e55fa"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>

        :
        <div style={{ borderTop: '3px solid #2e55fa' }}>

          {profile.candidate_id ? null : loader ? null : (
            <div
              className="mt-1"
              style={{
                height: 80,
                width: "100%",
                backgroundColor: "#f8f9fa",
                boxShadow: "5px solid black",
              }}
            >
              <div
                style={{
                  backgroundColor: "#2e55fa",
                  width: "100%",
                  height: "100%",
                  padding: 20,
                  paddingLeft: 25,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h5
                  className="font-weight-700 pull-left "
                  style={{ color: "#fff", textTransform: "capitalize" }}
                >
                  Profile Status Bar
                </h5>
                <div className="progress-box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "-3%",
                    }}
                  >
                    <h6 style={{ color: "#fff" }}>25%</h6>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      style={{ width: "25%" }}
                      role="progressbar"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {profile.candidate_id ? null : loader ? null : (
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6 className="fw3">
                Kindly , add your profile details else you are unable to apply for
                jobs
              </h6>
            </div>
          )}

          <div className="page-content bg-white">

            <div className="content-block">

              <div className="section-full bg-white browse-job p-t50 p-b20">
                <div className="container">
                  <div className="row">
                    <CandidateSideNav activemenu="jobs-profile" candidateName={user.name} prof={profile.professional_title} />

                    <div className="col-xl-9 col-lg-8 m-b30">
                      <div className="job-bx job-profile">
                        {arr.map((val) => val).length === 0 ? null : (
                          <div className="job-bx-title clearfix">
                            <Link to={`/employer-details/${UserID}`}>
                              {" "}
                              <h5 className="font-weight-700 pull-left text-uppercase">
                                Your Profile visiters (
                                {arr.map((val) => val).length > 9
                                  ? arr.map((val) => val).length
                                  : "0" + arr.map((val) => val).length}
                                )
                              </h5>
                            </Link>
                          </div>
                        )}
                        <div className="job-bx-title clearfix">
                          <h5 className="font-weight-700 pull-left text-uppercase">
                            Basic Information
                          </h5>
                          <Link
                            to="#"
                            className="site-button right-arrow button-sm float-right"
                            onClick={() => setCandidateProfile(true)}
                          >
                            {profile.name ? "Edit" : "Add"}
                          </Link>
                        </div>

                        <Modal
                          show={candidateProfile}
                          onHide={setCandidateProfile}
                          className="modal fade modal-bx-info editor" size="xl" >


                          <div role="document" >
                            <div className="modal-content " >

                              <div className="modal-header" >
                                <h5 className="modal-title">Candidate Profile</h5>
                                <button
                                  type="button"
                                  className="close"
                                  onClick={() => {
                                    setCandidateProfile(false);
                                    getAllVisiters();
                                    getAllData();
                                    getCandidateData();
                                    getUserData();
                                  }}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <div className="modal-body">
                                <form>
                                  <div className="row m-b30">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Your Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          value={user.name}
                                          onChange={onChangeHandler}
                                          placeholder="Alexander Weir"
                                          disabled
                                          style={{color:"#000000"}}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Professional title</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="professional_title"
                                          value={profile.professional_title}
                                          onChange={onChangeHandler}
                                          placeholder="Web Designer"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.professional_title ? null : showError ? (!profile.professional_title ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null) : null}
                                        {profile.professional_title ? (profile.professional_title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null} */}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Age</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="age"
                                          value={profile.age}
                                          onChange={onChangeHandler}
                                          placeholder="32 Year"
                                          min={0}
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.age ? null : showError ? (!profile.age ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.age}</span> : null) : null}
                                        {profile.age ? (profile.age > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.validAge}</span> : profile.age < 16 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.ageLimit}</span> : null) : null} */}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Experience <span style={{color:'red'}}>*</span> </label>

                                        <Form.Control
                                          as="select"
                                          name="experience"
                                          value={profile.experience}
                                          onChange={onChangeHandler}
                                          style={{ height: '45px',color:'#000000' }}
                                        >
                                          <option value={''}>---SELECT---</option>

                                          {experienceData.map((val, index) => {
                                            return (
                                              <option key={index} style={{color:"#000000"}}>
                                                {val.exp_year}
                                              </option>
                                            );
                                          })}
                                        </Form.Control>
                                        {profile.experience ? null : showError ? (!profile.experience ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.experience}</span> : null) : null}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Languages</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="languages"
                                          value={profile.languages}
                                          onChange={onChangeHandler}
                                          placeholder="English"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.languages ? null : showError ? (!profile.languages ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.language}</span> : null) : null}
                                        {profile.languages ? (profile.languages.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.languageLimit}</span> : null) : null} */}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Company Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company"
                                          value={profile.company}
                                          onChange={onChangeHandler}
                                          placeholder="Infosys"
                                          style={{color:"#000000"}}
                                        />

                                       {profile.experience === 'Fresher' ? null : showError ? (!profile.company ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyName}</span> : null) : null}
                                         {/* {profile.company ? (profile.company.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyNameLimit}</span> : null) : null} */}
                                      </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 mb-4">
                                      <label>Availability</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Enter Company Name"
                                        name='availability_to_join'
                                        value={profile.availability_to_join}
                                        onChange={onChangeHandler}
                                        style={{color:"#000000"}}
                                      />
                                      {/* {profile.availability_to_join ? null : showError ? (!profile.availability_to_join ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null) : null}
                                      {profile.availability_to_join ? +d1 < +d2 ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null} */}
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Current Salary (<span style={{fontSize:15}}>&#8377;</span>)</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="current_salary"
                                          value={profile.current_salary}
                                          onChange={onChangeHandler}
                                          placeholder="200"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.current_salary ? null : showError ? (!profile.current_salary ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Salary}</span> : null) : null}
                                        {profile.current_salary ? (profile.current_salary.length > 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.salLimit}</span> : null) : null} */}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Expected Salary (<span style={{fontSize:15}}>&#8377;</span>)</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="expected_salary"
                                          value={profile.expected_salary}
                                          onChange={onChangeHandler}
                                          placeholder="2500"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.expected_salary ? null : showError ? (!profile.expected_salary ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Salary}</span> : null) : null}
                                        {profile.expected_salary ? (profile.expected_salary.length > 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.salLimit}</span> : null) : null} */}
                                      </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                          className="form-control"
                                          name="description"
                                          value={profile.description}
                                          onChange={onChangeHandler}
                                          style={{color:"#000000"}}
                                          
                                        ></textarea>
                                        {/* {profile.description ? null : showError ? (<span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span>) : null}
                                        {profile.description ? (profile.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="job-bx-title clearfix">
                                    <h5 className="font-weight-700 pull-left text-uppercase">
                                      Contact Information
                                    </h5>
                                  </div>
                                  <div className="row m-b30">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Phone</label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={user.mobile}
                                          placeholder="+1 123 456 7890"
                                          style={{color:"#000000"}}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Email Address</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email_address"
                                          value={user.email}
                                          onChange={onChangeHandler}
                                          placeholder="info@example.com"
                                          style={{color:"#000000"}}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Country</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          onChange={onChangeHandler}
                                          placeholder="Country Name"
                                          name='country'
                                          value='India'
                                          style={{color:"#000000"}}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>State</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="state"
                                          value="Odisha"
                                          placeholder="Odisha"
                                          style={{color:"#000000"}}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Postcode <span style={{color:'red'}}>*</span></label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="postcode"
                                          value={profile.postcode}
                                          onChange={onChangeHandler}
                                          placeholder="751024"
                                          style={{color:"#000000"}}
                                        />
                                        {profile.postcode ? pincodeDirectory.lookup(profile.postcode).length > 0 ? (pincodeDirectory.lookup(profile.postcode)[0].stateName === 'ODISHA' ? null : <span style={{ color: 'red', fontSize: 11, fontWeight: 'bold', marginLeft: 5 }}>{profile.postcode} doesn't belong to Odisha</span>) : <span style={{ color: 'red', fontSize: 12 }}>{profile.postcode} doesn't belong to Odisha</span> : null}
                                        {profile.postcode ? null : (showError ? (!profile.postcode ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.pincode}</span> : null) : null)}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>City</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="city"
                                          value={profile.city}
                                          onChange={onChangeHandler}
                                          placeholder="City"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.city ? null : showError ? (!profile.city ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.city}</span> : null) : null}
                                        {profile.city ? (profile.city.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cityLimit}</span> : null) : null} */}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group">
                                        <label>Full Address</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="full_address"
                                          value={profile.full_address}
                                          onChange={onChangeHandler}
                                          placeholder="Patia"
                                          style={{color:"#000000"}}
                                        />
                                        {/* {profile.full_address ? null : showError ? (!profile.full_address ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.address}</span> : null) : null}
                                        {profile.full_address ? (profile.full_address.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.addressLimit}</span> : null) : null} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-12 col-md-12"
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {profile.candidate_id > 0 ? (
                                      <button
                                        type="button"
                                        className="site-button m-b30 "
                                        onClick={updateHandler}
                                        style={{ borderRadius: "50px" }}
                                      >
                                        Update
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="site-button m-b30 "
                                        onClick={saveHandler}
                                      >
                                        Save
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </Modal>


                        <Row style={{ width: '100%' }}>
                          <Col style={{ width: '50%', overflowWrap: 'break-word' }}>
                            <h6 className="font-14 m-b0">Name:</h6>
                            <p className="m-b0">{user.name}</p>
                            <h6 className="font-14 m-b0">Age:</h6>
                            <p className="m-b0">{profile.age ? profile.age : 'NA'}</p>
                            <h6 className="font-14 m-b0">Languages :</h6>
                            <p className="m-b0">{profile.languages ? profile.languages : 'NA'}</p>
                            <h6 className="font-14 m-b0">Current Salary(₹):</h6>
                            <p className="m-b0">{formatNumber(profile.current_salary) ? formatNumber(profile.current_salary) : 'NA'}</p>
                            <h6 className="font-14 m-b0">Description:</h6>
                            <p className="m-b0">{profile.description ? profile.description : 'NA'}</p>
                          </Col>

                          <Col style={{ width: '50%', overflowWrap: 'break-word' }}>
                            <h6 className="font-14 m-b0">Professional title:</h6>
                            <p className="m-b0">{profile.professional_title ? profile.professional_title : 'NA'}</p>
                            <h6 className="font-14 m-b0">Experience:</h6>
                            <p className="m-b0">{profile.experience ? profile.experience : 'NA'}</p>
                            <h6 className="font-14 m-b0">Company Name:</h6>
                            <p className="m-b0">{profile.company ? profile.company : 'NA'}</p>
                            <h6 className="font-14 m-b0">Expected Salary(₹)</h6>
                            <p className="m-b0">{formatNumber(profile.expected_salary) ? formatNumber(profile.expected_salary) : 'NA'}</p>
                            <h6 className="font-14 m-b0">Availability:</h6>
                            <p className="m-b0">{profile.availability_to_join ? profile.availability_to_join : 'NA'} </p>

                          </Col>
                        </Row>

                        <h5 className="font-weight-700 pull-left text-uppercase mt-5 mb-3">CONTACT Information</h5>

                        <hr />

                        <Row>
                          <Col>
                            <h6 className="font-14 m-b0">Phone:</h6>

                            <p className="m-b0">{user.mobile}</p>

                            <h6 className="font-14 m-b0">Country:</h6>

                            <p className="m-b0">{profile.country ? profile.country : 'NA'}</p>

                            <h6 className="font-14 m-b0">City:</h6>

                            <p className="m-b0">{profile.city ? profile.city : 'NA'}</p>
                            <h6 className="font-14 m-b0">State:</h6>

                            <p className="m-b0">{profile.full_address ? profile.state : 'NA'}</p>



                          </Col>

                          <Col>

                            <h6 className="font-14 m-b0">Email Address:</h6>

                            <p className="m-b0">{user.email}</p>

                            <h6 className="font-14 m-b0">Postcode:</h6>

                            <p className="m-b0">{profile.postcode ? profile.postcode : "NA"}</p>

                            <h6 className="font-14 m-b0">Full Address:</h6>

                            <p className="m-b0">{profile.full_address ? profile.full_address : 'NA'}</p>

                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>}
    </>
  );
};

export default JobsProfile;
