import React from 'react'
import Header from '../../components/Layout/Header'
import Footer from '../../components/Layout/Footer'

const PrivecyPolicy = () => {
  return (
    <>
    <Header/>

    <div style={{ borderTop: '3px solid #2e55fa' }}>
      <div style={{
        width:'100%',
        paddingTop:30,
        paddingLeft:'10%',
        paddingRight:'10%',
        marginBottom:"10%",
        display:'flex',
        flexDirection:'column',
        backgroundColor:'#fff'
      }}>
         {/* <div className="logo-header mostion">
								<Link to={"./"}><img src={require('../../assets/images/jobyard-logo.png')} className="logo" alt="" /></Link>
				 </div> */}

         <div className='pl-1' style={{width:'100%'}}>
            <h1>Privacy Policy</h1>
            <p>JobYard app is a commercial app by Yoosys Solution Private Limited ('Yoosys'). This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information for anyone using the app and website of JobYard . By using JobYard Platform, you consent to the terms of our privacy policy ("Privacy Policy") in addition to our Terms of Service. We encourage you to read this Privacy Policy regarding the collection, use, and disclosure of your information from time to time to keep yourself updated with the changes & updated that we make to this Privacy Policy.</p>
            <h3>Personal Identification Information</h3>
            <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Identification Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
            <p>The personal information you provide on JobYard Platform when you fill out your profile is public, such as your name, location, gender, profile picture, education and professional info including where you are working. This is hereinafter called Public Profile. Your Public Profile will be published on jobyard.in. Your Public Profile can:</p>
             <ul className='ml-5'>
              <li>
               <p >Be associated with you on the internet</p>
              </li>
              <li>
               <p >Show up when someone does a search on search engine We also use your Public Profile, to help connect you with friends and family which increases your and your friends' chances to find suitable job opportunities.</p>
              </li>
             </ul>
             <p>We may collect Personal Identification Information of users including the information that is available on the internet, such as from Facebook, LinkedIn, Twitter and Google, or publicly available information that we acquire from service providers. We collect this information to identify users for better communication, processing and personalization of the Services provided by Applozic.</p>
             <h4>Non-personal Identification Information</h4>
             <p>We collect the information about how you interact with our Service. This information may include your IP address, geographical location, browser type, referral source, length of visit, pages viewed and items clicked.</p>
             <h4>Information Collection</h4>
             <p>For a better experience, while using our Service, you are required to provide us with certain personally identifiable information for your Public Profile, including but not limited to:</p>
             <ul className='ml-5'>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Identity information,</span> such as your first name, last name, gender, username and/or similar as may be verified by voter ID card, PAN or driving license;</p>
                 </li>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Contact information,</span>  such as your mobile number, postal address, email address and telephone number;</p>
                 </li>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Professional information,</span>  such as your education, work experience, skills, photo, city or area. Professional information helps you to get more from our Services, including helping employers find you. Please do not post or add personal data to your resume that you would not want to be publicly available.</p>
                 </li>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Feedback and correspondence,</span> such as information you provide when you respond to surveys, participate in market research activities, report a problem with Service, receive customer support or otherwise correspond with us;</p>
                 </li>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Usage information,</span> such as information about how you use the Service and interact with us; and</p>
                 </li>
                 <li>
                    <p><span style={{fontWeight:'bolder',color:'black'}}>Marketing information,</span> such as your preferences for receiving marketing communications and details about how you engage with them.</p>
                 </li>
                 
             </ul>
             <p>JobYard Platform’s mission is to connect talent to job opportunities and employers to quality talent. We are committed to be transparent about the data we collect about you, how it is used and with whom it is shared.</p>
             <p>When you use the services of our customers and partners, such as employers or prospective employers and applicant tracking systems, we share your Public Profile (e.g., your job title and name of the company where you work) with prospective employers to enable you to get job interviews.</p>
             <h4>Information we collect</h4>
              <ul className='ml-5'>
                <li>
                   <p><h6>Contacts List.</h6>When you sync your contacts with our Services, we import and store the contacts list to our servers. You have the option to deny us the access to your contacts list.</p>
                   <p>We also receive personal data (including contact information) about you when others import or sync their contacts with our Services.</p>
                </li>
                <li>
                   <p><h6>Location Information.</h6>When you give location permission, we access information that is derived from your GPS. We may use third-party cookies and similar technologies to collect some of this information.</p>
                </li>
                <li>
                   <p><h6>Storage.</h6>When you opt-in for storage permission, we access your device storage like gallery photos.</p>
                </li>
                <li>
                   <p><h6>Camera.</h6>Granting camera permission allows us to access the photo that you click to be displayed on your resume.</p>
                </li>
                <li>
                   <p><h6>Microphone.</h6>Voice and audio information when you use audio features. How we use it</p>
                </li>
             </ul>
             <h4>How we use it</h4>
              <ul className='ml-5'>
                <li>
                   <p><h6>Contacts List.</h6>We collect your contacts to help you keep growing your network by suggesting connections for you and your friends.</p>
                   
                </li>
                <li>
                   <p><h6>Location Information.</h6>To provide you with location-based services like finding jobs near you.</p>
                </li>
                <li>
                   <p><h6>Storage.</h6>To allow you to select your profile picture from your existing photos in the gallery. A good resume photo helps you stand out among other candidates.</p>
                </li>
                <li>
                   <p><h6>Camera.</h6>To allow you to click your profile picture. A good resume photo helps you stand out among other candidates.</p>
                </li>
                <li>
                   <p><h6>Microphone.</h6>To allow you to send audio messages within the app.</p>
                </li>
                <li>
                   <p><h6>Call.</h6>For enabling the Call HR feature of the app.</p>
                   <p>To effectively provide and introduce any new Services to you, we collect and use certain information, including, but not limited to, such as:</p>
                   <ol className='ml-3'>
                      <li>
                        <p>We log your visits and use of our Services.</p>
                      </li>
                      <li>
                        <p>We receive data from your devices and networks, including location data.</p>
                        </li>
                        <li>
                        <p>We may further request and store additional information.</p>
                        </li>
                        <li>
                        <p>To monitor usage or traffic patterns (including to track users' movements around the Services) and gather demographic information.</p>
                        </li>
                        <li>
                        <p>To communicate directly with you, including by sending you information about new products and services.</p>
                        </li>
                        <li>
                        <p>To deliver you a personalized experience. May come in the form of messages, delivering tailor-made ads based on your interest and browsing history.</p>
                      </li>
                   </ol>
                </li>
             </ul>
             <p>To the extent permitted by the applicable law, we may record and monitor your communications with us to ensure compliance with our legal and regulatory obligations and our internal policies. This may include the recording of telephone conversations.</p>
             <h4>How we protect your information?</h4>
             <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our app. The data is stored securely on our servers - Server 1 & Server 2 .</p>
             <h4>Log Data</h4>
             <p>Whenever you use our Service, including our sites, app and platform technology, such as when you view or click on content (e.g., learning video) or perform a search, install or update one of our mobile apps, post messages or apply for jobs and in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as log-ins, cookies, your device Internet Protocol ("IP") address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics to identify you and log your use.</p>
             <h4>Cookies</h4>
             <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
             <p>This Service does not use these "cookies" explicitly. However, the app may use third party code and libraries that use "cookies" to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
             <h4>Service Providers</h4>
             <p>We may employ third-party companies and individuals due to the following reasons:</p>
             <p>To facilitate our Service; To provide the Service on our behalf; To perform Service-related services; or To assist us in analyzing how our Service is used. We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
             <h4>One-to-one messages</h4>
             <p>Yoosys has access to the one-to-one messages of users of the app and we review these messages periodically for moderation of trust and safety related concerns. However, Yoosys never shares this data with any third-party.</p>
             <h4>Security</h4>
             <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
             <h4>Links to Other Sites</h4>
             <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
             <h4>Children's Privacy</h4>
             <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
             <h4>No Impersonation or False Information to be Provided</h4>
             <p>You have to use your actual name on the Platform, you are required to input your correct phone number to use our Services. You will be getting a job based on your Jobyard account. You will not falsely represent yourself as another person or representative of another person to use our Services. You will not lie about your details, for any reason.</p>
             <h4>Trust and Safety</h4>
             <p>Yoosys takes the trust and safety all its users seriously. We do not allow content that promotes abuse, fraud, MLM/network marketing, job openings that charge fees, suicide, self-harm, or is intended to shock or disgust users. Strict action is taken against such content and with people posting such messages/content in the group.</p>
             <p>To ensure the best possible experience for all the users of the app, we have established some basic guidelines called the Community Guidelines. The Community Guidelines gets updated periodically. You will be notified when this happens.</p>
             <h4>Disclaimer</h4>
             <p>Jobyard does not hold any responsibility for any incident, fraud, cheat or crime that may happen to any users. We advise you to check and verify information of other users before proceeding with any transactions or interaction among users. Also, do not share your private information on group, to prevent the use of such information for any un-safe purposes.</p>
             <p>When you see something inappropriate or come across an offending content, click on the 3 vertical dots on the top right corner of the post and choose to 'report' post. This alerts the admins about the post and allows for necessary action to be taken.</p>
             <p>What to do if you find suicide & self-injury related content</p>
             <p>If you come across content in which someone expresses suicidal thoughts or is engaging in self-harm, click on the 3 vertical dots on the top right corner of the post and choose to 'report' post. This alerts the admins about the post and allows for necessary action to be taken.</p>
             <h4>Changes to This Privacy Policy</h4>
             <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
             <h4>Contact Us</h4>
             <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at:</p>
             <p>14/1534, Kalyani Nagar, Raghunathpurjali, Patia, Bhubaneswar, Odisha 751024 <br/>
             Email: info@yoosys.in</p>
             

         </div>
      </div>


     <Footer />
    </div>


    </>
  )
}

export default PrivecyPolicy