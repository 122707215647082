import React, { useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import UsersService from '../../services/Users';
import AlertMessage from '../../components/AlertMessage';
import { ThreeCircles } from 'react-loader-spinner'
import * as EmailValidator from 'email-validator';
import Error from '../../constants/ErrorMessage';


const Signin = () => { 
	const [userMailMob, setUserMailMob]= useState("");
	const [password, setPassword] = useState("");
	const [alertState, setAlertState] = useState({});
	const [errorMessage, setErrorMessage] = useState("");
	const [userState, setUserState] = useState({});
	const [loader , setLoader] = useState(false);
	const [showError, setShowError] = useState(false);
	const [passError, setPassError] = useState(false);
	const [passMsg, setPassMsgError] = useState("");


		
	const navigate = useNavigate();
	const applyJobId = window.localStorage.getItem('applyJobId');
	
	useEffect(() => {	
		setLoader(true);
			setLoader(false);
	},[]);
	
   
	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({ open: false });
	};
			
	const resetInputState = () => {
				setUserMailMob("");
				setPassword("");
			};
		
	const loginUserHandler = async (e) =>{
     e.preventDefault();	
	try {
		setShowError(true);

		var passedValidation = true;
		if (userMailMob === "") passedValidation = false;
		if (password === "") passedValidation = false;

		if (passedValidation) {
			setShowError(false);
			const userLogin = {};
			userLogin.email = userMailMob;
			userLogin.password = password;
			setLoader(true);

			const res = await UsersService.loginUser(userLogin);
			if (res.status === 200) {
				resetInputState();
				setAlertState({ open: true, type: 'success', message: 'Logged in successfully.' });
				setUserState(
					{
						loginStatus: true,
						userId: res.data.id,
						name: res.data.name,
						email: res.data.email,
						mobile: res.data.mobile,
						roleId: res.data.roleId,
						vToken: res.data.verificationToken,
						status: res.data.status,
						
					}
				);

					setLoader(false);
				
				window.localStorage.setItem('userState', JSON.stringify(userState));				
				window.localStorage.setItem('userId', res.data.id);
				window.localStorage.setItem('roleId',res.data.roleId);
				window.localStorage.setItem('loginStatus', true);
				window.localStorage.setItem('userName', res.data.name);
				window.localStorage.setItem('userMailMob',userMailMob);
			    window.localStorage.setItem('password',password);
				

				if(res.data.roleId === 1){
					setTimeout(()=>{
						navigate(`/job-details/${parseInt(applyJobId)}`);
					},[3000])
					
				}else if(res.data.roleId === 2 || res.data.roleId === 4 ){
					setTimeout(()=>{
						navigate('/company-profile');
					},[3000])
					
				}else if(res.data.roleId === 3){
					setTimeout(()=>{
						navigate('/admin-manage-job');
					},[3000])
				}else{
					setTimeout(()=>{
						navigate("/");
					},[2000])	
				}
							
			}else{
					setLoader(false);
				setErrorMessage(res.message);
			}
		} else {
				setLoader(false);
			setAlertState({ open: true, type: 'warning', message: 'Please provide valid credential.' });
		}
	} catch (err) {
			setLoader(false);
		if (err.response && err.response.data && (err.response.data.status === 404 || err.response.data.status === 400)) {
			setAlertState({ open: true, type: 'error', message: err.response.data.message });
			setPassError(true);
			setPassMsgError(err.response.data.message);
		} else {
			setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
		}
	}
};

return (
   <>
   <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
  {
	loader ?
	<div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
     <ThreeCircles
      height="90"
      width="90"
      color="#2e55fa"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
	</div>
	:
	<div>

	
   <Header />
   <div className="page-content">
       
        <div className="dez-bnr-inr overlay-black-middle bg-pt" >
            <div className="container">
                <div className="dez-bnr-inr-entry">
                    <h1 className="text-white">Login</h1>
					
					<div className="breadcrumb-row">
						<ul className="list-inline">
							<li><Link to="/">Home</Link></li>
							<li>Login</li>
						</ul>
					</div>
			
                </div>
            </div>
        </div>
       
        <div className="section-full content-inner-2 shop-account bg-white">
          
            <div className="container">
               	<div className="max-w500 m-auto bg-white m-b30">
					<div className="p-a30 card browse-job radius-sm">
						<div className="tab-content nav">
							<form id="login" className="tab-pane active col-12 p-a0 ">
								<h4 className="font-weight-700">LOGIN</h4>
								<p className="font-weight-600">If you have an account with us, please log in.</p>
								<div className="form-group">
											<label className="font-weight-700">E-MAIL *</label>
											<input 
											  name="dzName" 
												required="" 
												className="form-control" 
												placeholder="Your Email Address" 
												type="email" 
												value={userMailMob}
												onChange={(e)=>{
													setUserMailMob(e.target.value);
												    setPassError(false);
												  }}
												/>
                                           {userMailMob ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
										   {userMailMob.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
										   {userMailMob ? (EmailValidator.validate(userMailMob) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
										</div>
										<div className="form-group">
											<label className="font-weight-700">PASSWORD *</label>
											<input 
											 name="dzName" 
											 required="" 
											 className="form-control " 
											 placeholder="Type Password" 
											 type="password" 
											 value={password}
												onChange={(e)=>{
													setPassword(e.target.value);
													setPassError(false);
												}}
											 />
                                           {password ? null : (showError ? (!password ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.password}</span> : null) : null)}
										   {password ? (password.length < 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minPass}</span> : null) : null}
										   {password ? (password.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maxPass}</span> : null) : null}
										   {passError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{passMsg}</span> : null }
										</div>
								<div className="text-left">
									<button className="site-button m-r5 button-lg" onClick={loginUserHandler}>login</button>
									<Link data-toggle="tab" to="#forgot-password" className="m-l5 m-t15 forget-pass float-right"><i className="fa fa-unlock-alt"></i> Forgot Password</Link> 
								</div>
							</form>
							<form id="forgot-password" className="tab-pane fade  col-12 p-a0">
										<h4 className="font-weight-700">FORGET PASSWORD ?</h4>
										<p className="font-weight-600">We will send you an email to reset your password. </p>
										<div className="form-group">
											<label className="font-weight-700">E-MAIL *</label>
											<input name="dzName" required="" className="form-control" placeholder="Your Email Address" type="email" />
										</div>
										<div className="text-left"> 
											<Link className="site-button outline gray button-lg" data-toggle="tab" to="#login">Back</Link>
											<button type="button" className="btn-primary site-button btn-block" >Sign in </button>
										</div>
									</form>
						</div>
					</div>
				</div>
			</div>
           
		</div>
		
    </div>
   <Footer/>   
   </div>
  }
   </>
)};

export default Signin;