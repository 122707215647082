
import axios from "axios";



const addEducation=(payload)=>axios.post('/masters/education',payload)



const getEducation=()=>axios.get ('/masters/education');

const getQualificationById = (id) => axios.get('/masters/education/'+id);

const UpdateQualification=(id, payload)=>axios.put('/masters/education/'+id, payload);


const DeleteQualification = (id) => axios.delete('/masters/education/'+id);

export default {getEducation,addEducation,getQualificationById,UpdateQualification,DeleteQualification};