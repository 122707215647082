import axios from 'axios';

const Skill = (payload) => axios.post('/candidate-key-skills/add', payload);

const deleteKeySkill=(candidate_id)=>axios.delete(`/candidate-key-skills/`+candidate_id);

const Skill_Update=(candidate_id,payload)=>axios.put(`/candidate-key-skills/`+candidate_id,payload);

const getKeySkillByUserId =(userId) => axios.get(`/candidate-key-skills/user/${userId}`)

const getCandidateSkills=()=> axios.get('/candidate-key-skills');

export default { Skill,
                 deleteKeySkill,
                 Skill_Update,
                 getCandidateSkills,
                 getKeySkillByUserId
                
                };