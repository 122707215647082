import axios from 'axios';

const getJobTypes = () => axios.get('/masters/jobtype');

const addJobType =(payload) => axios.post('/masters/jobtype',payload);

const editJobType =(id,payload) => axios.put('/masters/jobtype/'+id,payload);


const deleteJobType =(id) => axios.delete(`/masters/jobtype/`+id);


export default { getJobTypes , addJobType ,editJobType , deleteJobType};