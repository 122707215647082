import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import AdminSideBar from './Component/AdminSideBar';
import { Modal } from 'react-bootstrap';
import JobType from '../../services/Master/JobTypeMaster';
import RegionMaster from '../../services/Master/RegionMaster';
import LocationMaster from '../../services/Master/LocationMaster';
import ExperienceMaster from '../../services/Master/ExperienceMaster';
import { useNavigate } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { Country, State, City } from 'country-state-city';
import 'react-dropdown/style.css';
import Select from 'react-dropdown-select';
import AlertMessage from '../../components/AlertMessage';
import Header from '../../components/Layout/Header';
import JobPostServices from '../../services/JobPost';
import CategoryMaster from '../../services/Master/CategoryMaster';
import Users from '../../services/Users';
import EducationMaster from '../../services/Education';
import Error from '../../constants/ErrorMessage';

const AdminPostJob = () => {
    const [jobData, setJobData] = useState({
        id: 0,
        job_title: '',
        job_email: '',
        job_tags: '',
        job_company: '',
        minimum_salary: '',
        maximum_salary: '',
        region: '',
        location: '',
        last_date: '',
        vacancy: '',
        workplace_type: '',
        worktime_start: '',
        worktime_end: '',
        interview_fee: '',
        gender: '',
        job_area: '',
        job_flat_no: '',
        whatsapp: '',
        hr_name: '',
        hr_company: '',
    })



    const [jobTypeOpt, setJobTypeOpt] = useState('');
    const [categoryOpt, setCategoryOpt] = useState('');
    const [experienceOpt, setExperienceOpt] = useState('');
    const [qualificationOpt, setQualificationOpt] = useState('');
    const [experienceTypes, setExperienceTypes] = useState([]);
    const [educationTypes, setEducationTypes] = useState([]);
    const [locationTypes, setLocationTypes] = useState([]);
    const [regionTypes, setRegionTypes] = useState([])
    const [categoryTypes, setCategoryTypes] = useState([]);

    const [jobTypeOptions, setJobTypeOptions] = useState([]);

    const [alertState, setAlertState] = useState({});
    const [description, setDescription] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [keySkill, setKeySkill] = useState([])
    const [descriptionData, setDescriptionData] = useState('');
    const [requirementsData, setRequirementsData] = useState('');
    const [skillData, setSkillData] = useState('')
    const [descriptionModal, setDescriptionModal] = useState(false);
    const [requirementModal, setRequirementModal] = useState(false);
    const [keySkillModal, setKeySkillModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [jobCountry, setJobCountry] = useState('');
    const [countryNames, setCountryNames] = useState([]);
    const [stateNames, setStateNames] = useState([]);
    const [jobState, setJobState] = useState();
    const [cityNames, setCityNames] = useState([]);
    const [jobCity, setJobCity] = useState('');
    const [descriptiondata, setDescriptionsdata] = useState([]);
    const [requirement, setRequirement] = useState([]);
    const [keySkills, setKeySkills] = useState([]);
    const [showError, setShowError] = useState(false);
    const [check, setcheck] = useState(false);
    const [allUser, setAllUser] = useState([]);

    const [sun, setSun] = useState("No");
    const [mon, setMon] = useState("Yes");
    const [tue, setTue] = useState("Yes");
    const [wed, setWed] = useState("Yes");
    const [thu, setThu] = useState("Yes");
    const [fri, setFri] = useState("Yes");
    const [sat, setSat] = useState("No");
    const [bike_option, setBike] = useState('No');
    const [rc_option, setRC] = useState('No');
    const [dl_option, setDL] = useState('No');
    const [laptop_option, setLaptop] = useState('No');
    const [smartPhone, setSmartPhone] = useState('No');
    const [pan_card, setPan] = useState('Yes');
    const [adhar_card, setAdhar] = useState('Yes');
    const [back_passbook, setPassbook] = useState('Yes');

    const userId = window.localStorage.getItem("userId");


    // const { id } = useParams();


    const navigate = useNavigate();

    const inputChange = (e) => {
        setJobData({ ...jobData, [e.target.name]: e.target.value });
    };

    const jobtypeonchange = (e) => {
        setJobTypeOpt(e[0].jobType)

    }

    const categoryOnchange = (e) => {
        setCategoryOpt(e[0].category)

    }
    const experienceOnchange = (e) => {
        setExperienceOpt(e[0].exp_year)

    }

    const qualificationOnchange = (e) => {
        setQualificationOpt(e[0].education)
        
    }
    const checkHandler = (check) => {
        try {
            setcheck(check)
        } catch (error) {
            console.log(error)
        }

    }

    const getJobtype = async () => {
        try {
            const jobtypes = await JobType.getAllJobTypes();
            if (jobtypes.status === 200) {
                setJobTypeOptions(jobtypes.data);
            }
        } catch (error) {
            console.log(error);

        }
    }



    const getExperienceMaster = async () => {

        try {
            const res = await ExperienceMaster.GetExperience();
            if (res.status === 200) {
                setExperienceTypes(res.data)
            }

        } catch (error) {
            console.log(error)

        }
    }

    const getEducationMaster = async () => {
        try {
            const res = await EducationMaster.getEducation();
            if (res.status === 200) {
                setEducationTypes(res.data)
            }

        } catch (error) {
            console.log(error)
        }
    }
    const getLocationMaster = async () => {
        try {
            const res = await LocationMaster.GetLocation()
            if (res.status === 200) {
                setLocationTypes(res.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const getRegionMaster = async () => {
        try {
            const res = await RegionMaster.GetRegion();
            if (res.status === 200) {
                setRegionTypes(res.data)

            }

        } catch (error) {
            console.log(error)
        }
    }

    const getCategoryTypes = async () => {
        try {
            const res = await CategoryMaster.getCategory();
            if (res.status === 200) {
                setCategoryTypes(res.data)
            }
        } catch (error) {
            console.log(error)

        }


    }
    
    const getAllCountryStateCityDatas = async () => {
        try {
            setCountryNames(Country.getAllCountries());
            setStateNames(State.getAllStates());
            setCityNames(City.getAllCities());
        } catch (error) {
            console.log(error);
        }
    }
    const getAllUser = async () => {
        try {
            const res = await Users.getUser();
            setAllUser(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getExperienceMaster();
        getEducationMaster();
        getLocationMaster();
        getRegionMaster();
        getCategoryTypes();
        getAllUser();
        getJobtype();
        getAllCountryStateCityDatas()
    }, []);


    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const descriptionHandler = async () => {
        try {
            if (!descriptionData || descriptionData.length >250) {
                if ( descriptionData.length>250){
                 setAlertState({ open: true, type: 'error', message: Error.jobDescriptionLimit });
                }else { 
                    setAlertState({ open: true, type: 'error', message: 'Please, add a description' })}      
            } else {
                let arr = [...description];

                arr = [...arr, { id: description.length + 1, data: descriptionData }];
                setDescription(arr)
                setDescriptionData('');
                setDescriptionModal(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const requirementHandler = async () => {
        try {
            if (!requirementsData || requirementsData.length >250) {
                if ( requirementsData.length>250){
                 setAlertState({ open: true, type: 'error', message: Error.jobRequirementLimit });
                }else { 
                    setAlertState({ open: true, type: 'error', message: 'Please, add a Requirements' })}      
            }else {
                let arr = [...requirements];

                arr = [...arr, { id: requirements.length + 1, data: requirementsData }];
                setRequirements(arr)
                setRequirementsData('');
                setRequirementModal(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const keySkillHandler = async () => {
        try {

            if (!skillData || skillData.length >250) {
                if ( skillData.length>250){
                 setAlertState({ open: true, type: 'error', message: Error.skillLimit });
                }else { 
                    setAlertState({ open: true, type: 'error', message: 'Please, add a skill' })}      
            } else {
                let arr = [...keySkill];

                arr = [...arr, { id: keySkill.length + 1, data: skillData }];
                setKeySkill(arr)
                setSkillData('');
                setKeySkillModal(false);
            }

        } catch (error) {
            console.log(error)
        }
    }

    const removeDescription = async (id) => {
        try {
            let arr = description.filter((val) => { if (val.id !== id) { return val; } }).map(val => val);
            setDescription(arr)

        } catch (error) {
            console.log(error)
        }
    }

    const removeRequirement = async (id) => {
        try {
            let arr = requirements.filter((val) => { if (val.id !== id) { return val; } }).map(val => val);
            setRequirements(arr)
        } catch (error) {
            console.log(error)
        }
    }

    const removeKeySkill = async (id) => {
        try {

            let arr = keySkill.filter((val) => { if (val.id !== id) { return val } });
            setKeySkill(arr);

        } catch (error) {
            console.log(error)
        }
    }
    const postJob = async () => {
        try {
            setShowError(true);

            if (!jobData.job_title || jobData.job_title.length > 100) {
                if (jobData.job_title.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: Error.jobTitleLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.jobTitle });
                }
            } else if (!jobData.job_company || jobData.job_company.length > 250) {
                if (jobData.job_company.length > 250) {
                    setAlertState({ open: true, type: 'warning', message: Error.companyNameLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.companyName });

                }
            } else if (!jobData.job_tags) {
                setAlertState({ open: true, type: 'warning', message: Error.jobTags });

            } else if (!jobTypeOpt) {
                setAlertState({ open: true, type: 'warning', message: Error.jobTypes });

            } else if (!categoryOpt) {
                setAlertState({ open: true, type: 'warning', message: Error.jobCategory });
            } else if (!experienceOpt) {
                setAlertState({ open: true, type: 'warning', message: Error.experience });
            } else if (!jobData.minimum_salary || jobData.minimum_salary.length > 8) {
                if (jobData.minimum_salary.length > 8) {
                    setAlertState({ open: true, type: 'warning', message: Error.salLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.minSal });
                }
            } else if (!jobData.maximum_salary || jobData.maximum_salary.length > 8 || (parseInt(jobData.minimum_salary) > parseInt(jobData.maximum_salary))) {
                if (jobData.maximum_salary.length > 8) {
                    setAlertState({ open: true, type: 'warning', message: Error.salLimit });
                } else if (parseInt(jobData.minimum_salary) > parseInt(jobData.maximum_salary)) {
                    setAlertState({ open: true, type: 'warning', message: Error.salary });
                }
                else {
                    setAlertState({ open: true, type: 'warning', message: Error.maxSal });
                }
            } else if (!jobCountry) {
                setAlertState({ open: true, type: 'warning', message: Error.region });
            } else if (!jobState) {

                setAlertState({ open: true, type: 'warning', message: Error.state });
            } else if (!jobCity) {
                setAlertState({ open: true, type: 'warning', message: Error.city });
            } else if (!jobData.job_flat_no || jobData.job_flat_no.length > 250) {
                if (jobData.job_flat_no.length > 250) {
                    setAlertState({ open: true, type: 'warning', message: Error.addressLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.address });

                }
            } else if (!jobData.last_date) {
                setAlertState({ open: true, type: 'warning', message: Error.lastdate });

            } else if (!qualificationOpt) {
                setAlertState({ open: true, type: 'warning', message: Error.qualification });
            } else if (!jobData.worktime_start) {
                setAlertState({ open: true, type: 'warning', message: Error.time });
            } else if (!jobData.worktime_end) {
                setAlertState({ open: true, type: 'warning', message: Error.time });
            } else if (!jobData.workplace_type) {
                setAlertState({ open: true, type: 'warning', message: Error.workPlaceType });
            } else if (!jobData.gender) {
                setAlertState({ open: true, type: 'warning', message: Error.gender });
            } else if (!jobData.vacancy || jobData.vacancy.length > 10) {
                if (jobData.vacancy.length > 10) {
                    setAlertState({ open: true, type: 'warning', message: Error.vacancyLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.vacancy });
                }
            } else if (!jobData.hr_company || jobData.hr_company.length > 100) {
                if (jobData.hr_company.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: Error.companyNameLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.companyName });
                }
            } else if (!jobData.hr_name || jobData.hr_name.length > 100) {
                if (jobData.hr_name.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: Error.nameLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.name });
                }
            } else if (jobData.job_email === "" || jobData.job_email.length > 100 || EmailValidator.validate(jobData.job_email) === false) {
                if (EmailValidator.validate(jobData.job_email) === false) {
                    setAlertState({ open: true, type: 'warning', message: Error.invalidEmail });
                }
                else if (jobData.job_email.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: Error.emailLimit });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.email });
                }
            }
            else if (!jobData.whatsapp || jobData.whatsapp.length !== 10) {
                if (jobData.whatsapp.length !== 10) {
                    setAlertState({ open: true, type: 'warning', message: Error.invalidMobile });
                } else {
                    setAlertState({ open: true, type: 'warning', message: Error.mobile });
                }
            } else if (!jobData.interview_fee) {
                setAlertState({ open: true, type: 'warning', message: Error.interViewFee });
            } else {
                const jobPost = {};
                jobPost.id = 0;
                jobPost.job_title = jobData.job_title;
                jobPost.job_email = jobData.job_email;
                jobPost.job_company = jobData.job_company;
                jobPost.job_tags = jobData.job_tags;
                jobPost.job_type = jobTypeOpt;
                jobPost.job_category = categoryOpt;
                jobPost.experience = experienceOpt;
                jobPost.qualification = qualificationOpt;
                jobPost.minimum_salary = parseInt(jobData.minimum_salary);
                jobPost.maximum_salary = parseInt(jobData.maximum_salary);
                jobPost.region = jobCountry;
                jobPost.location = jobState;
                jobPost.last_date = jobData.last_date;
                jobPost.user_id = userId;
                jobPost.bike_option = bike_option;
                jobPost.rc_option = rc_option;
                jobPost.dl_option = dl_option;
                jobPost.laptop_option = laptop_option;
                jobPost.smartPhone = smartPhone;
                jobPost.clr = "N";
                jobPost.status = 'Active';
                jobPost.adhar_card = adhar_card;
                jobPost.pan_card = pan_card;
                jobPost.back_passbook = back_passbook;
                jobPost.sun = sun;
                jobPost.mon = mon;
                jobPost.tue = tue;
                jobPost.wed = wed;
                jobPost.thu = thu;
                jobPost.fri = fri;
                jobPost.sat = sat;
                jobPost.job_area = jobCity;
                jobPost.job_flat_no = jobData.job_flat_no;
                jobPost.worktime_start = jobData.worktime_start;
                jobPost.worktime_end = jobData.worktime_end;
                jobPost.workplace_type = jobData.workplace_type;
                jobPost.gender = jobData.gender;
                jobPost.vacancy = jobData.vacancy;
                jobPost.hr_company = jobData.hr_company;
                jobPost.hr_name = jobData.hr_name;
                jobPost.whatsapp = jobData.whatsapp;
                jobPost.interview_fee = jobData.interview_fee;


                if (jobPost.job_title && jobPost.job_type && jobPost.minimum_salary && jobPost.maximum_salary && jobPost.region && jobPost.location && jobPost.last_date && jobPost.job_tags && jobPost.job_category && jobPost.job_company && jobPost.experience && jobPost.qualification && jobPost.job_email && jobPost.bike_option && jobPost.rc_option && jobPost.dl_option && jobPost.laptop_option && jobPost.smartPhone && jobPost.clr && jobPost.pan_card && jobPost.adhar_card && jobPost.status && jobPost.back_passbook && jobPost.workplace_type && jobPost.worktime_start && jobPost.worktime_end && jobPost.interview_fee && jobPost.gender && jobPost.sun && jobPost.mon && jobPost.tue && jobPost.wed && jobPost.thu && jobPost.fri && jobPost.sat && jobPost.job_area && jobPost.job_flat_no && jobPost.hr_name && jobPost.hr_company && jobPost.vacancy && jobPost.whatsapp && description.length !== 0 && requirements.length !== 0 && keySkill.length !== 0) {
                    setLoader(true)
                    const res = await JobPostServices.postJob(jobPost);
                    if (res.status === 200) {
                        setAlertState({ open: true, type: 'success', message: 'Job posted successfully.' });
                        setJobData({
                            id: 0,
                            job_title: '',
                            job_email: '',
                            job_tags: '',
                            // job_type: '',
                            // experience: '',
                            // qualification:"",
                            // job_category: '',
                            job_company: '',
                            minimum_salary: '',
                            maximum_salary: '',
                            region: '',
                            location: '',
                            // qualification: '',
                            last_date: '',
                            vacancy: '',
                            workplace_type: '',
                            worktime_start: '',
                            worktime_end: '',
                            interview_fee: '',
                            gender: '',
                            job_area: '',
                            job_flat_no: '',
                            whatsapp: '',
                            hr_name: '',
                            hr_company: '',
                        })

                        for (let i = 0; i < description.length; i++) {
                            const payloadDescription = {};
                            payloadDescription.jobId = res.data.identifiers[0].id
                            payloadDescription.description = description[i].data;
                            const result = await JobPostServices.jobDescrioptionPost(payloadDescription);
                        }


                        for (let j = 0; j < requirements.length; j++) {
                            const payloadRequirement = {};
                            payloadRequirement.jobId = res.data.identifiers[0].id
                            payloadRequirement.requirement = requirements[j].data;
                            const result = await JobPostServices.jobRequirementPost(payloadRequirement);
                        }

                        for (let k = 0; k < keySkill.length; k++) {
                            const payloadSkill = {};
                            payloadSkill.jobId = res.data.identifiers[0].id
                            payloadSkill.skill = keySkill[k].data;
                            const result = await JobPostServices.keySkillPost(payloadSkill);
                        }




                        navigate('/admin-manage-job')
                        setLoader(false)

                    }
                    else {
                        setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
                        setLoader(false)

                    }
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
                    setLoader(false)
                }
            }


        } catch (error) {
            console.log(error);
        }
    };



    let cityList = [];
    let stateList = [];
    let countryCode = countryNames.filter((val) => { if (val.name === jobCountry) { return val } })

    if (countryCode.length > 0) {

        stateList = stateNames.filter((val) => { if (val.countryCode === countryCode[0].isoCode) { return val } });
        if (stateList.length > 0) {
            let stateCode = stateList.filter((val) => { if (val.name === jobState) { return val; } })
            if (stateCode.length > 0) {
                cityList = cityNames.filter((val) => { if (val.stateCode === stateCode[0].isoCode && val.countryCode === countryCode[0].isoCode) { return val } });
            }
        }

    }


    let date = new Date();
    let date1 = new Date(jobData.last_date)

    const CountryOnchange = (e) => {
        setJobCountry(e[0].state)
    }
    const StateOnchange = (e) => {
        setJobState(e[0].city)
    }


    const CityOnchange = (e) => {
        setJobCity(e[0].name)
    }


    return (
        <>
            <Header activelist='admin-post-job' />
            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">

                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30">
                                        <AdminSideBar activemenu='admin-post-job' />
                                    </div>
                                    <div className="col-xl-9 col-lg-8 m-b30">
                                        <div className="job-bx submit-resume">
                                            <div className="job-bx-title clearfix">
                                                <h5 className="font-weight-700 pull-left text-uppercase">Post A Job</h5>
                                                <Link to={"/admin-manage-job"} className="site-button right-arrow button-sm float-right">Back</Link>
                                            </div>
                                            <form>
                                                <h5 style={{ fontSize: "15px", marginBottom: '40px' }}>JOB DETAILS: </h5>


                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Job Title <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" value={jobData.job_title}
                                                                name="job_title"
                                                                onChange={inputChange}
                                                                placeholder="Enter Job Title" />
                                                            {jobData.job_title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobTitle}</span> : null)}
                                                            {jobData.job_title ? (jobData.job_title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Job Tags <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control tags_input" value={jobData.job_tags}
                                                                name="job_tags"
                                                                onChange={inputChange}
                                                                placeholder="Enter Job Tag" />
                                                            {jobData.job_tags ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobTags}</span> : null)}
                                                            {jobData.job_tags ? (jobData.job_tags.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobTagLimit}</span> : null) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label> Job Types <span style={{ color: 'red' }}>*</span></label>
                                                            <Select options={jobTypeOptions} labelField="jobType" valueField="id" value={jobTypeOpt} onChange={jobtypeonchange} placeholder="--Select a JobType--" className='form-control' searchable={false} />
                                                            {jobTypeOpt ? null : (showError ? <span style={{ fontSize: 11, fontWeight: "bold", color: "red", marginLeft: 5, }}>{Error.jobTypes}</span> : null)}
                                                        </div>

                                                    </div>



                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Functional Area <span style={{ color: 'red' }}>*</span></label>

                                                            <Select options={categoryTypes} labelField="category" valueField="id" value={categoryOpt} onChange={categoryOnchange} placeholder="--Select a category--" className='form-control' searchable={false} />


                                                            {categoryOpt ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobCategory}</span> : null)}
                                                        </div>
                                                    </div>



                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Minimum Salary (&#8377;): <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="number" className="form-control" value={jobData.minimum_salary} min="0" name="minimum_salary" onChange={inputChange} placeholder="e.g. 10000" />
                                                            {jobData.minimum_salary ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minSal}</span> : null)}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Maximum Salary (&#8377;): <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="number" className="form-control" value={jobData.maximum_salary} min="0"
                                                                name="maximum_salary"
                                                                onChange={inputChange}

                                                                placeholder="e.g. 20000" />
                                                            {jobData.maximum_salary ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maxSal}</span> : null)}
                                                            {jobData.maximum_salary ? (parseInt(jobData.minimum_salary) > parseInt(jobData.maximum_salary) ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.salary}</span> : null) : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Experience <span style={{ color: 'red' }}>*</span></label>

                                                            <Select options={experienceTypes} labelField="exp_year" valueField="id" value={experienceOpt} onChange={experienceOnchange} placeholder='--Select a Experience--' className='form-control' searchable={false} />


                                                            {experienceOpt ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.experience}</span> : null)}


                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Minimum Qualification <span style={{ color: 'red' }}>*</span></label>

                                                            <Select options={educationTypes} labelField="education" valueField="id" value={qualificationOpt} onChange={qualificationOnchange} placeholder='--Select a Qualification--' className='form-control' searchable={false} />



                                                            {qualificationOpt ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.qualification}</span> : null)}


                                                        </div>
                                                    </div>


                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Country <span style={{ color: 'red' }}>*</span></label>

                                                            <Select options={regionTypes} labelField="state" valueField="id" value={jobCountry}
                                                                onChange={CountryOnchange}
                                                                placeholder='--Select a Country--' className='form-control' style={{ fontSize: 14 }} searchable={false} />

                                                            {jobCountry ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.country}</span> : null)}

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>State <span style={{ color: 'red' }}>*</span></label>

                                                            <Select options={locationTypes} labelField="city" valueField="id" value={jobState}
                                                                onChange={StateOnchange}
                                                                placeholder='--Select a state--' className='form-control' style={{ fontSize: 14 }} searchable={false} />

                                                            {jobState ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.state}</span> : null)}

                                                        </div>
                                                    </div>


                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>City <span style={{ color: "red" }}>*</span></label>

                                                            <Select options={cityList} labelField="name" valueField="stateCode" value={jobCity} onChange={CityOnchange} placeholder='--Select a City--' className='form-control' style={{ fontSize: 14 }} searchable={false} />




                                                            {jobCity ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.city}</span> : null)}


                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Area <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" name='job_area' value={jobData.job_area}
                                                                onChange={inputChange} placeholder='Enter your Locality' />
                                                            {jobData.job_area ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.area}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Flat no./Plot no./Building Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" name='job_flat_no' value={jobData.job_flat_no}
                                                                onChange={inputChange} placeholder='Enter your Plot no. ' />
                                                            {jobData.job_flat_no ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.flat}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Work From <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="time"
                                                                name='worktime_start'
                                                                className="form-control"
                                                                value={jobData.worktime_start}
                                                                onChange={inputChange} />
                                                            {jobData.worktime_start ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.workTimeStart}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor='time-input'>Work Till <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="time" className="form-control" id='time-input' name='worktime_end' value={jobData.worktime_end}
                                                                onChange={inputChange} />
                                                            {jobData.worktime_end ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.WorkTimeEnd}</span> : null)}
                                                            {/* {jobData.worktime_start? (jobData.worktime_start>jobData.worktime_end ?<span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.workingTym}</span>:null):null} */}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 ">
                                                        <h6 style={{ fontSize: "15px" }}>Working Days </h6>
                                                        <div className='d-flex justify-content-between mr-3' style={{ marginBottom: "20px", flexWrap: "wrap" }}>
                                                            <div className="col-lg-1 col-md-1 ">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id='b1' className="custom-control-input" value={sun} name="sun" onChange={(e) => {
                                                                        if (e.target.checked === true) {
                                                                            setSun('Yes');
                                                                        } else if (e.target.checked === false) {
                                                                            setSun('No');
                                                                        }
                                                                    }}
                                                                        checked={sun === 'Yes' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b1">SUN</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id='b2' defaultChecked={mon} className="custom-control-input" value={mon} name="mon" onChange={(e) => setMon(e.target.checked)}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b2">MON</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id='b3' defaultChecked={tue} className="custom-control-input" value={tue} name="tue" onChange={(e) => setTue(e.target.checked)}

                                                                    />
                                                                    <label className="custom-control-label fw" htmlFor="b3">TUE</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id='b4' defaultChecked={wed} className="custom-control-input" value={wed} name="wed" onChange={(e) => setWed(e.target.checked)}


                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b4">WED</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id='b5' defaultChecked={thu} className="custom-control-input" value={thu} name="thu" onChange={(e) => setThu(e.target.checked)}


                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b5">THU</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={fri}
                                                                        id='b6'
                                                                        className="custom-control-input"
                                                                        value={fri}
                                                                        name="fri"
                                                                        onChange={(e) => setFri(e.target.checked)}


                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b6">FRI</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1 col-md-1 mr-3">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='b7'
                                                                        className="custom-control-input"
                                                                        value={sat}
                                                                        name="sat"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setSat('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setSat('No');
                                                                            }
                                                                        }}
                                                                        checked={sat === 'Yes' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="b7">SAT</label>

                                                                </div>
                                                            </div>
                                                            {/* {jobData. ? null: (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.workTimeStart}</span>:null)}                  */}
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-12 col-md-12  ">
                                                        <h6 style={{ fontSize: "15px" }}>Required Items </h6>
                                                        <div className='d-flex ' style={{ marginBottom: "20px", flexWrap: "wrap" }}>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='c1'
                                                                        className="custom-control-input"
                                                                        value={bike_option}
                                                                        name="bike"

                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setBike('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setBike('No');
                                                                            }
                                                                        }}
                                                                        checked={bike_option === 'Yes' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c1">Bike</label>

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='c2'
                                                                        className="custom-control-input"
                                                                        value={rc_option}
                                                                        name="rc"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setRC('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setRC('No');
                                                                            }
                                                                        }}
                                                                        checked={rc_option === 'Yes' ? true : false}

                                                                    />


                                                                    <label className="custom-control-label fw" htmlFor="c2">RC</label>

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='c3'
                                                                        className="custom-control-input"
                                                                        value={dl_option}
                                                                        name="dl"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setDL('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setDL('No');
                                                                            }
                                                                        }}
                                                                        checked={dl_option === 'Yes' ? true : false}

                                                                    />


                                                                    <label className="custom-control-label fw" htmlFor="c3">DL</label>

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='c4'
                                                                        className="custom-control-input"
                                                                        value={smartPhone}
                                                                        name="smartphone"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setSmartPhone('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setSmartPhone('No');
                                                                            }
                                                                        }}
                                                                        checked={smartPhone === 'Yes' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c4">Smartphone</label>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id='c5'
                                                                        className="custom-control-input"
                                                                        value={laptop_option}
                                                                        name="laptop"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setLaptop('Yes');
                                                                            } else if (e.target.checked === false) {
                                                                                setLaptop('No');
                                                                            }
                                                                        }}
                                                                        checked={laptop_option === 'Yes' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c5">Laptop</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={adhar_card}
                                                                        id='c6'
                                                                        className="custom-control-input"
                                                                        value={adhar_card}
                                                                        name="adhar_card"
                                                                        onChange={(e) => setAdhar(e.target.checked)}



                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c6">Aadhar</label>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div className='d-flex ' style={{ marginBottom: "20px", flexWrap: "wrap" }}>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={pan_card}
                                                                        id='c7'
                                                                        className="custom-control-input"
                                                                        value={pan_card}
                                                                        name="pan_card"
                                                                        onChange={(e) => setPan(e.target.checked)}



                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c7">PAN</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={back_passbook}
                                                                        id='c8'
                                                                        className="custom-control-input"
                                                                        value={back_passbook}
                                                                        name="back_passbook"

                                                                        onChange={(e) => setPassbook(e.target.checked)}



                                                                    // Checked={back_passbook === 'Yes' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="c8">Bank A/C</label>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Company <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text"
                                                                className="form-control"
                                                                value={jobData.job_company}
                                                                name="job_company"
                                                                onChange={inputChange}
                                                                placeholder="Enter Company Name" />
                                                            {jobData.job_company ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyName}</span> : null)}
                                                            {jobData.job_company.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyNameLimit}</span> : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Last Date to Apply <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                value={jobData.last_date}
                                                                name="last_date"
                                                                onChange={inputChange}
                                                            />
                                                            {jobData.last_date ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.lastdate}</span> : null)}

                                                            {jobData.last_date ? +date1 > +date ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>No. Of Vacancy <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={jobData.vacancy}
                                                                name="vacancy"
                                                                onChange={inputChange}
                                                                placeholder='Enter the Numbers of Vacancy'
                                                            />
                                                            {jobData.vacancy ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.vacancy}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 ">
                                                        <label>Gender <span style={{ color: 'red' }}>*</span></label>
                                                        <div className='d-flex ' style={{ marginBottom: "20px", flexWrap: "wrap" }}>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='rr'
                                                                        className="custom-control-input"
                                                                        value="Male"
                                                                        name="gender"
                                                                        onChange={inputChange}
                                                                        checked={jobData.gender === 'Male' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="rr">Male</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='rr1'
                                                                        className="custom-control-input"
                                                                        value="Female"
                                                                        name="gender"
                                                                        onChange={inputChange}
                                                                        checked={jobData.gender === 'Female' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="rr1">Female</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"

                                                                        id='r12'
                                                                        className="custom-control-input"
                                                                        value="Both"
                                                                        name="gender"
                                                                        onChange={inputChange}
                                                                        checked={jobData.gender === 'Both' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="r12">Both</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 ">
                                                        <label>Work Place Type <span style={{ color: 'red' }}>*</span></label>
                                                        <div className='d-flex ' style={{ marginBottom: "20px", flexWrap: "wrap" }}>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='rrw'
                                                                        className="custom-control-input"
                                                                        value="Remote"
                                                                        name="workplace_type"
                                                                        onChange={inputChange}
                                                                        checked={jobData.workplace_type === 'Remote' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="rrw">Remote</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='rr1w'
                                                                        className="custom-control-input"
                                                                        value="On-Site"
                                                                        name="workplace_type"
                                                                        onChange={inputChange}
                                                                        checked={jobData.workplace_type === 'On-Site' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="rr1w">On-Site</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='r12w'
                                                                        className="custom-control-input"
                                                                        value="Hybrid"
                                                                        name="workplace_type"
                                                                        onChange={inputChange}
                                                                        checked={jobData.workplace_type === 'Hybrid' ? true : false}
                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="r12w">Hybrid</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 ">
                                                        <div className="form-group" >
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <label>Job Description <span style={{ color: 'red' }}>*</span></label>
                                                                <Link to="#" className="site-button right-arrow button-sm float-right mb-1" onClick={() => setDescriptionModal(true)} > + Add </Link>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12 mt-2 job-bx" style={{ overflowWrap: 'break-word' }}  >

                                                                <ul className="list-num-count no-round">
                                                                    {

                                                                        description.map((val) => {
                                                                            return (
                                                                                <>
                                                                                    <li >{val.data} <i className="fa fa-times-circle " aria-hidden="true" style={{ color: 'red' }} onClick={() => removeDescription(val.id)}></i></li>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 ">
                                                        <div className="form-group">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <label>Job Requirements <span style={{ color: 'red' }}>*</span></label>
                                                                <Link to="#" className="site-button right-arrow button-sm float-right mb-1" onClick={() => setRequirementModal(true)}> + Add </Link>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 mt-2 job-bx" style={{ overflowWrap: 'break-word' }}>
                                                                <ul className="list-num-count no-round">
                                                                    {

                                                                        requirements.map((val) => {
                                                                            return (
                                                                                <>
                                                                                    <li>{val.data}  <i className="fa fa-times-circle ml-1" aria-hidden="true" style={{ color: 'red' }} onClick={() => removeRequirement(val.id)}></i></li>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 ">
                                                        <div className="form-group">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <label>Key Skills <span style={{ color: 'red' }}>*</span></label>
                                                                <Link to="#" className="site-button right-arrow button-sm float-right mb-1" onClick={() => setKeySkillModal(true)} > + Add </Link>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 mt-2 job-bx job-bx-custom" style={{ overflowWrap: 'break-word' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '100%', overflowWrap: 'break-word' }}>
                                                                    {

                                                                        keySkill.map((val) => {
                                                                            return (
                                                                                <>
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='mr-2'>{val.data}  <i className="fa fa-times-circle" aria-hidden="true" style={{ color: 'red' }} onClick={() => removeKeySkill(val.id)}></i></div>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 mt-4 mb-4 " >
                                                        <h5 style={{ fontSize: "15px" }}>RECRUITER DETAILS: </h5>

                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label> Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text"
                                                                className="form-control"
                                                                value={jobData.hr_name}
                                                                name="hr_name"
                                                                onChange={inputChange}
                                                                placeholder="Enter Name" />
                                                            {jobData.hr_name ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.HrName}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label> Email <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="email"
                                                                className="form-control"
                                                                value={jobData.job_email}
                                                                name="job_email"
                                                                onChange={inputChange}
                                                                required
                                                                placeholder="info@gmail.com" />
                                                            {jobData.job_email ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Email}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label> Whatsapp No. <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="number"
                                                                className="form-control"
                                                                value={jobData.whatsapp}
                                                                name="whatsapp"
                                                                maxLength={10}
                                                                onChange={inputChange}
                                                                placeholder="Enter  whatsapp no." />
                                                            {jobData.whatsapp ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Whatsapp}</span> : null)}
                                                            {jobData.whatsapp ? (jobData.whatsapp.length > 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.whatsappLimit}</span> : null) : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label> Company <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text"
                                                                className="form-control"
                                                                value={jobData.hr_company}
                                                                name="hr_company"
                                                                onChange={inputChange}
                                                                placeholder="Enter Company Name"

                                                            />
                                                            {jobData.hr_company ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Recruitercompany}</span> : null)}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 ">
                                                        <label>Do you charge any fee for interview ?</label>
                                                        <div className='d-flex ' style={{ marginBottom: "20px" }}>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='r1'
                                                                        className="custom-control-input"
                                                                        value="Yes"
                                                                        name="interview_fee"
                                                                        onChange={inputChange}
                                                                        checked={jobData.interview_fee === 'Yes' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="r1">Yes</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="custom-control custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        id='r2'
                                                                        className="custom-control-input"
                                                                        value="No"
                                                                        name="interview_fee"
                                                                        onChange={inputChange}
                                                                        checked={jobData.interview_fee === 'No' ? true : false}

                                                                    />

                                                                    <label className="custom-control-label fw" htmlFor="r2">No</label>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <input className='mr-2'
                                                            type="checkbox"
                                                            id='checkbox'
                                                            onChange={(e) => checkHandler(e.target.checked)}
                                                        />

                                                        <label className='mt-2' htmlFor="checkbox">I agree with all <Link to="/term-and-condition">terms & conditions</Link> of Jobyard platform.</label><br />
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                                        <button disabled={check ? false : true} type="button" className={check ? "site-button " : "site-button bg-dark"} style={{ borderRadius: 30 }} onClick={postJob} >Post Job</button>
                                                       
                                                    </div>


                                                    {/* <button type="button" className={checkButton === true ? "site-button m-b30 mt-4" : " brr btn-secondary  m-b30 mt-4"} disabled={checkButton === true ? false : true} >Post Job</button> */}







                                                    {/* <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Location</label>
                                                        <input type="text" className="form-control" placeholder="London" />
                                                    </div>
                                                </div> */}
                                                    {/* <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Upload File</label>
                                                        <div className="custom-file">
                                                            <p className="m-a0">
                                                                <i className="fa fa-upload"></i>
                                                                Upload File
                                                            </p>
                                                            <input type="file" className="site-button form-control" id="customFile" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                                {/* <button type="button" className="site-button m-b30">Upload</button> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            show={requirementModal}
                            onHide={() => setRequirementModal(false)}
                            backdrop=""
                            size='md'
                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"


                        >
                            <div style={{ width: '100%' }}>
                                <div className="modal-content "  >

                                    <div className="modal-header" >
                                        <h5 className="modal-title" >Job Requirements</h5>
                                        <button type="button" className="close" onClick={() => setRequirementModal(false)}>
                                            <span aria-hidden="true" >&times;</span>
                                        </button>
                                    </div>


                                    <div className="modal-body">


                                        <label>Requirements</label>
                                        <textarea type="text"
                                            className="form-control"
                                            placeholder='Requirements'
                                            value={requirementsData}
                                            onChange={(e) => setRequirementsData(e.target.value)} style={{ color: 'black' }}/>

                                    </div>
                                    <div className="modal-footer">

                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <button type="button"
                                                className="btn btn-success "

                                                onClick={requirementHandler}>Add
                                            </button>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            show={descriptionModal}
                            onHide={() => setDescriptionModal(false)}
                            backdrop=""
                            size='md'
                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"


                        >
                            <div style={{ width: '100%' }}>
                                <div className="modal-content "  >
                                    <div className="modal-header" >
                                        <h5 className="modal-title" >Job Description</h5>
                                        <button type="button" className="close" onClick={() => setDescriptionModal(false)}>
                                            <span aria-hidden="true"  >&times;</span>
                                        </button>
                                    </div>


                                    <div className="modal-body">

                                        <label>Description</label>
                                        <textarea type="text"
                                            className="form-control"
                                            placeholder='Description'
                                            value={descriptionData}
                                            onChange={(e) => setDescriptionData(e.target.value)} />

                                    </div>
                                    <div className="modal-footer">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <button type="button"
                                                className="btn btn-success "

                                                onClick={descriptionHandler} >Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                        <Modal
                            show={keySkillModal}
                            onHide={() => setKeySkillModal(false)}
                            backdrop=""
                            size='md'
                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"


                        >

                            <div className="modal-header" >
                                <h5 className="modal-title" >Key Skill</h5>
                                <button type="button" className="close" onClick={() => setKeySkillModal(false)}>
                                    <span aria-hidden="true" >&times;</span>
                                </button>
                            </div>


                            <div className="modal-body">

                                <label>Skill</label>
                                <textarea type="text"
                                    className="form-control"
                                    placeholder='skill'
                                    value={skillData}
                                    onChange={(e) => setSkillData(e.target.value)} style={{color:""}}/>

                            </div>
                            <div className="modal-footer">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="button"
                                        className="btn btn-success "

                                        onClick={keySkillHandler} >Add
                                    </button>
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>

            </div>
            <Footer />
        </>
    )

};



export default AdminPostJob;