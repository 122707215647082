import axios from 'axios';

const addProject = (payload) => axios.post('/candidate-project/add', payload);


const Candidate_Projects_By_Id=(candidate_id)=>axios.get(`/candidate-project/`+candidate_id);

const updateProject=(candidate_id,payload)=>axios.put(`/candidate-project/`+candidate_id,payload);

const getProjectByUserId =(userId) =>axios.get(`/candidate-project/user/${userId}`);

const deleteProject =(id) => axios.delete(`/candidate-project/`+id);

export default { addProject,
                Candidate_Projects_By_Id,
                updateProject,
                getProjectByUserId,
                deleteProject,
            };