import React from 'react'
import Header from '../../components/Layout/Header';
import Home from './Home';

const HomePage = () => {
   
  return (
    <div>
       <Header/>
	   
       <Home/>
    </div>
  )
}

export default HomePage