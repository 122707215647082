import axios from 'axios';

const AddRegion=(payload)=> axios.post('/masters/region',payload);

const GetRegion=()=>axios.get('/masters/region');

const UpdateRegion=(id,payload)=>axios.put(`/masters/region/`+id,payload);

const DeleteRegion=(id)=>axios.delete(`/masters/region/`+id);

export default {AddRegion,GetRegion,UpdateRegion,DeleteRegion};