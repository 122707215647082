import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Layout/Footer'
import Header from '../../components/Layout/Header'

const CommunityGuidelines = () => {
   return (

      <>
         <Header />
         <div style={{ borderTop: '3px solid #2e55fa' }}>
            <div style={{
               width: '100%',
               paddingTop: 30,
               paddingLeft: '10%',
               paddingRight: '10%',
               marginBottom: "10%",
               display: 'flex',
               flexDirection: 'column',
               backgroundColor: '#fff'
            }}>
               <div className="logo-header mostion">
                  {/* <Link to={"./"}><img src={require('../../assets/images/jobyard-logo.png')} className="logo" alt="" /></Link> */}
               </div>

               <h1>Content and Community Guidelines</h1>
               <p>These Content and Community Guidelines (“Guidelines”) govern your use of JobYard Platform (including any mobile based applications, website and web applications) as made available by Yoosys Solution Private Limited (“Yoosys India”) a company incorporated under the Companies Act, 2017 having registered office 14/1534, Kalyani Nagar, Raghunathpurjali, Patia, Bhubaneswar, Odisha 751024</p>
               <p>These Guidelines are to be read with the Terms and Conditions and the Privacy Policy (collectively, “Terms of Use”). The capitalized words used in these guidelines shall have the meaning given to such words in the Terms.</p>
               <p>The JobYard Platform provides with a marketplace for recruiters and potential employees to connect with each other, a social community for Users to interact with each other in relation to their chosen industries, upskilling courses/content for its Users and other services related to enhancing access to employment in India. When you register and join the JobYard Platform, you become a member and a mandatory part of the groups on the JobYard Platform and additionally you may choose to become part of other communities based on your interest. The JobYard Platform is accessed by a diverse audience and in order to ensure that all our Users follow a standard practice and to foster a safe environment for you to express yourself, we have put in place strict guidelines and restrictions which govern the usage of the JobYard Platform. These guidelines and restrictions may not be exhaustive and we reserve the right to decide, in our sole discretion, what content violates the Terms of Use and how we want to deal with such violations.</p>
               <p>We reserve the right to change these Guidelines at any time.</p>
               <h3>APPLICABLE LAW</h3>
               <p>All content must adhere to applicable law. All Registered Users must ensure that the content they upload adheres to and conforms with applicable law.</p>
               <p>Content may not be uploaded, posted, commented on, or shared by you if it threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order. You may not post or engage with content that is insulting to any other nation, incites the commission of any offences or prevents the investigations of offences.</p>
               <h3>ZERO TOLERANCE POLICY</h3>
               <p>The JobYard Platform has a Zero Tolerance Policy for –</p>
               <ul className='ml-5'>
                  <li>
                     <p>any content or behavior that discriminates on the basis of age, sex, gender identity and expression, religion, caste, ethnicity, community, disability, nationality, race, immigration status, sexual orientation, victims of a major violent event and their kin; and</p>
                  </li>
                  <li>
                     <p>content that propagates hate or violence towards an individual or a group whether or not based on age, sex, gender identity and expression, religion, caste, ethnicity, community, disability, nationality, race, immigration status, sexual orientation, victims of a major violent event and their kin; and</p>
                  </li>
                  <li>
                     <p>content related to organized crime, criminal activities, promotion/sale/use of weapons, firearms and explosives, violence or terrorist activities; and</p>
                  </li>
                  <li>
                     <p>sale of illegal goods or services, regulated goods, drugs and controlled substances, and soliciting or selling of sexual services; and</p>
                  </li>
                  <li>
                     <p>content that propagates or showcases dangerous challenges or pranks; and</p>
                  </li>
                  <li>
                     <p>content that is harassing, harmful, or abusive to children; and</p>
                  </li> <li>
                     <p>content that is related to or encourages money laundering or gambling; and</p>
                  </li>
                  <li>
                     <p>content that is misleading, false or is designed specifically to spread misinformation; and</p>
                  </li>
                  <li>
                     <p>content that displays tutorials, instructions etc. relating to the commission of illegal activities, or activities that can harm or endanger an individual or group of persons; and</p>
                  </li>
                  <li>
                     <p>harassment or bullying through the use of abusive language or curse words; and</p>
                  </li>
                  <li>
                     <p>false information posted to harass someone for financial gain, or cause any injury to them; and</p>
                  </li>
                  <li>
                     <p>harassment or cyberbullying which includes name calling or malicious insults (such as racial slurs) based on someone's attributes (including physical attributes); and</p>
                  </li>
                  <li>
                     <p>content that encourages, glorifies or induces people to indulge in self harm, abuse or suicide; and</p>
                  </li>
                  <li>
                     <p>animal abuse; and</p>
                  </li>
                  <li>
                     <p>any content related to Multi-Level Marketing/ direct marketing/ network marketing/ digital marketing; and</p>
                  </li>
                  <li>
                     <p>links for earning money by referral/ online games; and</p>
                  </li>

                  <li>
                     <p>fake or fraudulent job openings and engagement; and</p>
                  </li>
                  <li>
                     <p>spamming</p>
                  </li>
               </ul>
               <h3>NUDITY, PORNOGRAPHY AND SEXUALLY EXPLICIT CONTENT</h3>
               <p>The JobYard Platform does not allow nudity, pornography or any form of sexually explicit content.</p>
               <p>Specifically, content which contains the following will be considered violation of these Guidelines and shall result in the suspension or absolute ban on your access to the JobYard Platform:</p>
               <ul className='ml-5'>
                  <li>
                     <p>Obscene, sexually explicit, pornographic (including without limitation, creation, promotion, glorification, transmission or browsing of pornography) or nude material or images/videos that expose private parts and/or portray sexual activities;</p>
                  </li>
                  <li>
                     <p>videos or images of people in compromising positions or content which portrays sexual actions or fetish or erotic intent or sexual arousal;</p>
                  </li>
                  <li>
                     <p>sextortion or revenge pornography;</p>
                  </li> <li>
                     <p>bestiality or zoophilia whether explicit or implied;</p>
                  </li>
                  <li>
                     <p>necrophilia whether explicit or implied;</p>
                  </li>
                  <li>
                     <p>content that exploits or endangers any person (for example, listing of phone numbers, or other personal information aimed at any exploitation or endangerment of a person including for the purposes of encouraging or soliciting prostitution or escort services);</p>
                  </li>
                  <li>
                     <p>content that is paedophilic or related to child pornography (including without limitation, creation, promotion, glorification, transmission or browsing of child pornography); or</p>
                  </li>
                  <li>
                     <p>content that is indecent, immoral or related to rape, sexual assault, sexual objectification, non-consensual activities and molestation.</p>
                  </li>
               </ul>
               <h3>INTELLECTUAL PROPERTY</h3>
               <p>Content should not violate the intellectual property rights of a third party. Content that is a third party’s intellectual property includes –</p>
               <ul className='ml-5'>
                  <li>
                     <p>literary, musical, dramatic, artistic, sound recordings, cinematographic works;</p>
                  </li>
                  <li>
                     <p>content that is not original and is copied from an individual/organisation who owns the intellectual property rights in such content/works ;</p>
                  </li>
                  <li>
                     <p>content that displays a logo, trademark, trade name or any other identifiers relating to a third party without the consent of such third party.</p>
                  </li>
               </ul>
               <p>Any content that is found to be in violation of a third party’s intellectual property rights will be taken down. Any Users found to be repeatedly posting content that infringes a third party’s intellectual property shall have their access to the JobYard Platform suspended or blocked.</p>
               <h3>NON-CONSENSUAL CONTENT</h3>
               <p>Content, data, or information of another person, including their pictures or videos without obtaining their prior consent to use such content, data or information on the JobYard Platform is strictly prohibited.</p>
               <p>You shall not post anyone’s personally identifiable information (including but not limited to their name, phone number(s), address, email id, bank account or other financial information) or their photographs or videos without their permission or consent.</p>
               <p>You shall not post content that is invasive of anyone’s privacy. We will remove such content.</p>
               <p>Any content that violates the above shall be immediately removed and Users who repeatedly post such content shall lose access to the JobYard Platform.</p>
               <h3>REPORTING</h3>
               <p>While JobYard makes every possible effort to ensure that all content is moderated to ensure adherence to and compliance with the Terms of Use and specifically these Guidelines we may not always be able to identify content that is violative. We, therefore, rely on our Users to report content that they feel might be in violation of the Terms of Use and specifically these Guidelines. We encourage you to report the post which might be in violation of the Terms of Use/these Guidelines. Additionally, you may also contact the Grievance Redressal Officer as mentioned in the <a href='/user-terms-and-conditions'>Terms and Conditions</a>.</p>
            </div>
            <Footer />
         </div>
      </>
   )
}

export default CommunityGuidelines
