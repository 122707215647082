import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import CandidateSideNav from './component/CandidateSideNav';
import AppliedJob from '../../services/AppliedJob';
import { ThreeCircles } from 'react-loader-spinner';
import AlertMessage from "../../components/AlertMessage";
import JobPost from '../../services/JobPost';
import jobDetailsService from '../../services/JobPost';
import ReactPaginate from 'react-paginate';
import { Modal } from "react-bootstrap";




const JobsAppliedjobs = () => {
	const [jobs, setjobs] = useState([]);
	const [loader, setLoader] = useState(false);
	const [dateData, setDateData] = useState("");
	const [keyskills, setKeySkills] = useState([]);
	const [jobRequirements, setJobRequirements] = useState([]);
	const [jobDescription, setJobDdscription] = useState([]);
	const [appliedJobs, setAppliedJobs] = useState([]);
	const [viewModal, setViewModal] = useState(false);
	const [deleteModal, setDeletModal] = useState(false);
	const [jobId, setJobId] = useState(0);
	const [jobData, setJobData] = useState({});
	const [alertState, setAlertState] = useState({});
	const [pageNumber, setPageNumber] = useState(0);
	const userId = JSON.parse(localStorage.getItem('userId'));



	const itemsPerPage = 10
	function alertClose(event, reason) {
		if (reason === "clickaway") {
			return;
		}
		setAlertState({ open: false });
	}

	const getKeySkills = async () => {
		try {
			const res = await jobDetailsService.getAllKeySkills();
			setKeySkills(res.data);
		} catch (error) {
			console.log(error)
		}
	}

	const getAppliedJobData = async () => {
		setLoader(true)
		try {
			const res = await AppliedJob.getAppliedJobById(userId);
			setAppliedJobs(res.data);
		} catch (err) {
			return err;
		}
		setLoader(false)
	}

	const getAllJobs = async () => {
		try {
			const res = await JobPost.getJobPost();
			setjobs(res.data);
		} catch (error) {
			console.log(error)
		}
	}

	const getAllJobRequirements = async () => {
		try {
			const res = await JobPost.getAllJobRequirement();
			setJobRequirements(res.data);
		} catch (error) {
			console.log(error);
		}
	}


	const getAllDescription = async () => {
		try {
			const res = await JobPost.getAllJobDescrioption();
			setJobDdscription(res.data);
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		setLoader(true);
		getKeySkills();
		getAllJobs();
		getAllJobRequirements();
		getAllDescription();
		setTimeout(() => {
			setLoader(false);
		}, 1500);
	}, []);


	useEffect(() => {
		getAppliedJobData();
	}, [userId]);


	let jobLists = [];
	if (appliedJobs.length > 0) {

		for (let i = 0; i < appliedJobs.length; i++) {
			for (let j = 0; j < jobs.length; j++) {
				if (appliedJobs[i].job_id === jobs[j].id) {
					jobLists.push(jobs[j]);
				}
			}
		}
	}


	if (dateData) {
		var today = new Date();
		var previousDate = new Date(new Date().setDate(today.getDate() - parseInt(dateData)));

	}


	const DeleteHandler = async () => {
		try {
			appliedJobs.filter(async (itm) => {
				if (itm.job_id === jobId) {
					setLoader(true);
					const res = await AppliedJob.DeleteAppliedJob(itm.id);


					if (res.status === 200) {
						setAlertState({ open: true, type: 'success', message: 'Job deleted successfully.' });
						setDeletModal(false);
						getKeySkills();
						getAllJobs();
						getAppliedJobData();
						setTimeout(() => {
							setLoader(false);

						}, 1500);
					}
					setLoader(false);
				}
			})



		} catch (error) {
			setLoader(false);

			console.log(error);
		}
	}

	const handlePageChange = ({ selected }) => {
		setPageNumber(selected);
	};

	const startIndex = pageNumber * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const displayedItems = jobLists.slice(startIndex, endIndex);

	return (
		<>

			<Header activelist="jobs-applied-jobs"/>
			<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

			{
				loader ?
					<div style={{ width: '100%', height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ThreeCircles
							height="80"
							width="80"
							color="#2e55fa"
							wrapperStyle={{}}
							wrapperClassName=""
							visible={true}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					</div> :
					<div className="page-content bg-white" style={{ borderTop: '3px solid #2e55fa ' }}>
						<div className="content-block">
							<div className="section-full bg-white p-t50 p-b20">
								<div className="container">
									<div className="row">
										<CandidateSideNav activemenu="jobs-applied-jobs" />
										<div className="col-xl-9 col-lg-8 m-b30">
											<div className="job-bx save-job table-job-bx clearfix">
												<div className="job-bx-title clearfix">
													<h5 className="font-weight-700 pull-left text-uppercase">{jobLists.filter((val) => { if ((!previousDate || (previousDate && Date.parse(previousDate) <= Date.parse(val.created_on)))) { return val } }).map(val => val).length} Applied Job(s)</h5>
													<div className="float-right">
														<span className="select-title">Sort by freshness</span>
														<select style={{
															fontSize: 12, boxShadow: "unset !important",
															border: "1px solid #e1e7ff ",
															width: "120px",
															height: "40px !important",
															float: "right",
															borderRadius: "5px",
															fontFamily: "roboto",
															padding: 12,
															textAlign: "Left",
															transition: "all 0.2s ease-in-out 0s",
															verticalAlign: 'middle',
															whiteSpace: "nowrap",
															lineHeight: 1.42857143,
														}}
															value={dateData}
															onChange={(e) => { setDateData(e.target.value) }}
														>
															<option value=""  style={{    fontWeight: 400,
																					
																					color: "#333",
																					whiteSpace: "nowrap"}}>All</option>
															<option value="60" >Last 2 Months</option>
															<option value="30" >Last Months</option>
															<option value="7" >Last Weeks</option>
															<option value="3" >Last 3 Days</option>
														</select>
													</div>
												</div>
												{
													jobLists.filter((val) => { if ((!previousDate || (previousDate && Date.parse(previousDate) <= Date.parse(val.created_on)))) { return val } }).map(val => val).length === 0 ?
														<div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
															<a href="{#}"><img src="images/gallery/noJobFound.png" width={150} height={150} alt="" /></a>
														</div> :

														<table>
															<thead>
																<tr>
																	<th></th>
																	<th>Applied jobs</th>
																	<th>Company</th>
																	<th>Posted On</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
																{

																	displayedItems.filter((val) => { if ((!previousDate || (previousDate && Date.parse(previousDate) <= Date.parse(val.created_on)))) { return val } }).map((itm, ind) => {
																		return (
																			<tr key={ind}>
																				<td className="job-post-company">
																					<a href="#"><span>
																						<img alt="" src="images/logo/icon2.png" />
																					</span></a>
																				</td>
																				<td className="job-name"><a href="#">{itm.job_title}</a></td>
																				<td className="criterias text-primary"><a href="#">@{itm.job_company}</a></td>
																				<td className="date">{itm.created_on}</td>
																				<td className="job-links">
																					<a href="#" onClick={() => { setViewModal(true); setJobData(itm) }}>
																						<i className="fa fa-eye"></i></a>
																					<a href="#" onClick={() => { setDeletModal(true); setJobId(itm.id); setJobData(itm) }}><i className="ti-trash"></i></a>
																				</td>
																			</tr>
																		)
																	})
																}

															</tbody>
														
															
														</table>
														
														}

														<ReactPaginate
																						previousLabel={'Previous'}
																						nextLabel={'Next'}
																						breakLabel={'...'}
																						pageCount={Math.ceil(displayedItems.length / itemsPerPage)}
																						marginPagesDisplayed={7}
																						pageRangeDisplayed={displayedItems.length}
																						onPageChange={handlePageChange}
																						containerClassName={'pagination'}
																						activeClassName={'active'}
																					/>
											</div>
											<Modal
												show={viewModal}
												onHide={setViewModal}
												className="modal fade modal-bx-info editor" size="xl"
											>
												<div role="document" >
													<div className="modal-content " >
														<div className="modal-header" >
															<h5 className="modal-title" >Job Details</h5>
															<button
																type="button"
																className="close"
																onClick={() => { setViewModal(false) }}
															>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<div style={{ width: '100%', display: 'flex', }}>
																<div style={{ width: '20%', display: 'flex', justifyContent: 'space-between' }}> <span>Job Title</span> <span>: </span></div>
																<div style={{ width: '80%', display: 'flex', flexWrap: 'wrap', paddingLeft: 5 }}> {jobData.job_title}</div>
															</div>
															<div style={{ width: '100%', display: 'flex', }}>
																<div style={{ width: '20%', display: 'flex', justifyContent: 'space-between' }}> <span>Experience</span> <span>: </span></div>
																<div style={{ width: '80%', display: 'flex', flexWrap: 'wrap', paddingLeft: 5 }}> {jobData.experience}</div>
															</div>
															<div style={{ width: '100%', display: 'flex', }}>
																<div style={{ width: '20%', display: 'flex', justifyContent: 'space-between' }}> <span>Description</span> <span>: </span></div>
																<div style={{ width: '80%', display: 'flex', flexWrap: 'wrap', paddingLeft: 5 }}>
																	{
																		jobDescription.filter((val) => {
																			if (val.jobId === jobData.id) { return val }
																		}).map((val) => {
																			return (
																				<p style={{ marginBottom: "0px" }} key={val.id}>{val.description}</p>
																			)
																		})
																	}
																</div>
															</div>
															<div style={{ width: '100%', display: 'flex', }}>
																<div style={{ width: '20%', display: 'flex', justifyContent: 'space-between' }}> <span>Requirements</span> <span>: </span></div>
																<div style={{ width: '80%', display: 'flex', flexWrap: 'wrap', paddingLeft: 5, flexDirection: 'column' }}>
																	{
																		jobRequirements.filter((itm) => {
																			if (itm.jobId === jobData.id) {
																				return itm
																			}
																		}).map((val) => {
																			return (
																				<p style={{ marginBottom: "0px" }} key={val.id}>{val.requirement}</p>
																			)
																		})

																	}
																</div>
															</div>
															<div style={{ width: '100%', display: 'flex' }}>
																<div style={{ width: '20%', display: 'flex', justifyContent: 'space-between' }}> <span>Skills</span> <span>:</span></div>
																<div style={{ width: '80%', display: 'flex', flexWrap: 'wrap', paddingLeft: 5, }}>
																	{
																		keyskills.filter((itm) => { if (itm.jobId === jobData.id) { return itm } }).map((val) => {
																			return (

																				<p style={{ paddingLeft: 3, paddingRight: 3, margin: '3px', border: "1px solid gray", borderRadius: '5px', color: 'black', backgroundColor: '#cfcce3' }} key={val.id}> {val.skill}</p>
																			)
																		})
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Modal>

											{/* delete modal */}

											<Modal
												show={deleteModal}
												onHide={setDeletModal}
												backdrop=""
												size='md'
												className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

											>
												<div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
													<div className="modal-content "  >
														<div class="modal-header">
															<div class="logo-img">
																<img alt="" src="images/logo/icon2.png" />
															</div>
															<h5 class="modal-title">Delete Job</h5>
															<button type="button" className="close" onClick={() => setDeletModal(false)} data-dismiss="modal" aria-label="Close">
																<span aria-hidden="true">&times;</span>
															</button>
														</div>

														<div className="modal-body" >
															<div>
																<p>Do you want to delete job <span style={{ color: 'red' }}> {jobData.job_title}</span> , which was applied by you ?</p>

															</div>

														</div>
														<div className="modal-footer">
															<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

															</div>
															<button type='button' className='btn btn-info' onClick={() => setDeletModal(false)} data-dismiss="modal" aria-label="Close" style={{ backgroundColor: "#2e55fa" }}>Cancel</button>
															<button type="button" className="btn btn-danger" onClick={DeleteHandler}>Delete</button>

														</div>
													</div>
												</div>

											</Modal >

											{/* <Modal
											   show={deleteModal}
											   onHide={setDeletModal}
											   className="modal fade modal-bx-info  " size="sm"
											  >																							
											   <div  role="document" >
												  <div className="modal-content " >
													 <div className="modal-header" >
													    <h5 className="modal-title" >Delete</h5>
														<button
															type="button"
															className="close"
															onClick={() => {setDeletModal(false)}}
														>
														<span aria-hidden="true">&times;</span>
													   </button>
													</div>
													<div className="modal-body">
														<div>
													<p>Do you want to delete job <span style={{color:'red'}}> {jobData.job_title}</span> , which was applied by you ?</p>
														</div>
														<div style={{display:'flex',justifyContent:'space-evenly'}}>
															<button type='button' className='btn  ' style={{backgroundColor:"red",color:"#fff"}} onClick={DeleteHandler}>Yes</button>
															<button type='button' className='btn  ' style={{backgroundColor:"green",color:"#fff"}} onClick={() => {setDeletModal(false)}}>No</button>

														</div>

													</div>
												</div>
												</div>
                                            </Modal>                     */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
			}
			<Footer />

		</>
	)
}

export default JobsAppliedjobs;
