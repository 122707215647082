import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import JobPost from '../services/JobPost';
const cityBox = [
    { image: 'images/city/angul.jpg', title: 'Angul', },
    { image: 'images/city/baleswar.jpg', title: 'Baleshwar', },
    { image: 'images/city/berhmpur.jpg', title: 'Brahmapur', },
    { image: 'images/city/bhubaneswar.jpg', title: 'Bhubaneshwar', },
    { image: 'images/city/baripada.jpg', title: 'Bhadrak', },
    { image: 'images/city/cuttack.jpg', title: 'Cuttack', },
    { image: 'images/city/jajpur.jpg', title: 'Jajpur', },
    { image: 'images/city/rourkela.jpg', title: 'Raurkela', },
]

function Featureblog() {
    const [jobs, setJobs] = useState([]);

    const navigate = useNavigate();

    const submitHandler = () => {
        navigate('/category-location-jobs');
    }

    const getAllJobs = async () => {
        try {
            const res = await JobPost.getJobPost();
            setJobs(res.data)

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAllJobs();
    }, [])

    var cityNames = [...new Set(jobs.filter((val)=>{if(val.status === "Active"){return val;}}).map(val=>val.job_area))]
    var cityData =[];
    
    for(let i=0; i<cityNames.length; i++){
        var match = false;
        for(let j=0; j< cityBox.length ; j++){
       
            if(cityNames[i] === cityBox[j].title){
                match = true;
                cityData.push(cityBox[j]);
                
            }
        }
        
    }


    let locations = jobs.map(val => val.job_area)
    const bbsr_job = jobs.filter((val) => { if (val.job_area === 'Bhubaneshwar' && val.status === 'Active') { return val; } }).map(val => val).length;
    const ctc_job = jobs.filter((val) => { if (val.job_area === "Cuttack" && val.status === 'Active') { return val; } }).map(val => val).length;
    const rourkela_job = jobs.filter((val) => { if (val.job_area === "Raurkela" && val.status === 'Active') { return val; } }).map(val => val).length;
    const berhampu_job = jobs.filter((val) => { if (val.job_area === "Brahmapur" && val.status === 'Active') { return val; } }).map(val => val).length;
    const angul_job = jobs.filter((val) => { if (val.job_area === "Angul" && val.status === 'Active') { return val; } }).map(val => val).length;
    const baleswar_job = jobs.filter((val) => { if (val.job_area === "Baleshwar" && val.status === 'Active') { return val; } }).map(val => val).length;
    const baripada_job = jobs.filter((val) => { if (val.job_area === "Bhadrak" && val.status === 'Active') { return val; } }).map(val => val).length;
    const jajpur_job = jobs.filter((val) => { if (val.job_area === "Jajpur" && val.status === 'Active') { return val; } }).map(val => val).length;
    var cities = [...new Set(locations)];

    const locationHandler = (city) => {
        try {
            window.localStorage.setItem('city', city);
            window.localStorage.setItem('categories', '');
            window.localStorage.setItem('all', '');
            window.localStorage.setItem('companyData', '');
            window.localStorage.setItem('tags', '');
            window.localStorage.setItem('designation', '');


    navigate("/browse-job-grid")


        } catch (error) {
            console.log(error)
        }
    }

   
    return (
        <>
        <div className="section-full content-inner bg-white">
            <div className="container">
                <div className="row">
                    {
                        cityData.map(val=>val).length === 0 ?null :
                   
                    <div className="col-lg-12 section-head text-center">
                        <h2 className="m-b5">Featured Cities</h2>
                        <h6 className="fw4 m-b0">{cities.map(val => val).length > 21 ? "20+" : cities.map(val => val).length} Featured Cities Added Jobs</h6>
                    </div> }
                </div>

                <div className="row">
                    {
                        cityData.map((val,index) => {
                            return (
                                <div className="col-lg-3 col-sm-6 col-md-6 m-b30"  key={index}  onClick={() => locationHandler(val.title)}>
                                    <Link  >
                                        <div className="city-bx align-items-end d-flex" style={{ backgroundImage: "url(" + val.image + ")" }}>
                                            <div className="city-info">
                                                <h5>{val.title}</h5>
                                                <span>{
                                                    val.title === 'Bhubaneshwar' ? (bbsr_job < 21 ? bbsr_job : '20+') :
                                                        val.title === 'Cuttack' ? (ctc_job < 21 ? ctc_job : '20+') :
                                                            val.title === 'Raurkela' ? (rourkela_job < 21 ? rourkela_job : '20+') :
                                                                val.title === 'Brahmapur' ? (berhampu_job < 21 ? berhampu_job : '20+') : 
                                                                val.title === 'Baleshwar' ? (baleswar_job < 21 ? baleswar_job : '20+') : 
                                                            val.title === 'Angul' ? (angul_job < 21 ? angul_job : '20+') : 
                                                        val.title === 'Bhadrak' ? (baripada_job < 21 ? baripada_job : '20+') : 
                                                    val.title === 'Jajpur' ? (jajpur_job < 21 ? jajpur_job : '20+') : '0'
                                                } Jobs</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
            {
                        cityData.map(val=>val).length === 0 ?null :
            <div className="col-lg-12 text-center m-t30">
                <button className="site-button radius-xl" onClick={submitHandler}>All Cities</button>
            </div>}
        </div>
     </>
    )
}
export default Featureblog;