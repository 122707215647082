import axios from 'axios';

const AddCategory = (payload) => axios.post('/masters/category', payload);

const getCategory = () => axios.get('/masters/category');

const getCategoryById = (id) => axios.get('/masters/category/'+id);

const UpdateCategory=(id, payload)=>axios.put('/masters/category/'+id, payload);

const DeleteCategory = (id) => axios.delete('/masters/category/'+id);

export default { AddCategory, getCategory, getCategoryById, UpdateCategory, DeleteCategory };
