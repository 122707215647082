import React, { useState, useEffect } from "react";
import CandidateSideNav from "./component/CandidateSideNav";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import JobAlertServices from "../../services/JobAlert";
import JobPostService from "../../services/JobPost";
import AlertMessage from "../../components/AlertMessage";
import HashLoader from "react-spinners/HashLoader";
import KeySkills from "../../services/candidate_resume/KeySkills";
import SkillMapping from "../../services/SkillMapping";
import FreeJobAlert from "../../services/FreeJobAlert";
import SkillMaster from "../../services/Master/SkillMaster";

const JobsAlert = () => {
  // const [jobAlertList, setJobAlertList] = useState([]);
  const [jobModal, setJobModal] = useState(false);
  const [modalJobTitle, setmodalJobTitle] = useState("");
  const [modalDateCreated, setModalDateCreated] = useState("");
  const [modalKeywords, setModalKeywords] = useState("");
  const [modaljobCategory, setModalJobCategory] = useState("");
  const [modalLastDate, setModalLastDate] = useState("");
  const [modalMinSal, setModalMinSal] = useState("");
  const [modalMaxSalary, setModalMaxSalary] = useState("");
  const [modalExperience, setModalExperience] = useState("");
  const [modalLocation, setModalLocation] = useState("");
  const [modalRegion, setModalRegion] = useState("");

  // const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const [getAlert, setGetAlert] = useState([]);
  const [skillData, setSkilldata] = useState([]);
  const [rejectData, setRejectData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [keySkillData, setKeySkillData] = useState([]);
  const [jobAlert, setJobAlert] = useState([])
  const [alertJobsData,setAlertJobsData]=useState([])
  const [skillDetails,setSkillDetails]=useState([])
  const [SkillsMapping, setSkillsMapping] = useState([])
  const [KeyskillsId,setKeySkillsId]=useState(0)
  const [alertState, setAlertState] = useState({ });
 const [SkillMappingId,setSkillMappingId]=useState(0)
const [alertJobId,setAlertJobId]=useState(0)

 


  const userId = JSON.parse(localStorage.getItem("userId"));
  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }

  const getSkillData = async () => {
    setLoader(true)
    try {

      const res = await KeySkills.getKeySkillByUserId(userId);
      if (res.status === 200) {
        setSkilldata(res.data);
          setKeySkillsId(res.data.id);

      }

    } catch (err) {
      if (err) {
        setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
      }
    }
    setLoader(false)
  }

  useEffect(() => {
    getSkillData();
  }, [`${userId}`]);


  const saveAlert = async () => {
    setLoader(true)
    try {
      const res = await JobPostService.getJobPost();
      if (res.status === 200) {
        setGetAlert(res.data);
      }
    } catch (err) {
      if (err) {
        setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
      }
    }
    setLoader(false)
  }

  
  const getAlertdata = async () => {
    setLoader(true)
    try {
      const res = await JobAlertServices.getJobAlerts();
      if (res.status === 200) {
        setRejectData(res.data);
      }
    } catch (err) {
      return err;
    }
    setLoader(false)
  }

  const getSkillsMapping = async () => {
    try {
      const res = await SkillMapping.getAllSkill();
      setSkillsMapping(res.data);
      setSkillMappingId(res.data.Alertjob_id);
    } catch (error) {
      console.log(error);
    }
  }
 
  const getSkillsMaster = async () => {
    try {
      const res = await SkillMaster.getSkills();
      setSkillDetails(res.data)
    } catch (error) {
      console.log(error);
    }
  }
 
  const getAlertJobs = async () => {
    try {
      const res = await FreeJobAlert.GetFreeJob();
      setAlertJobsData(res.data)
      setAlertJobId(res.data.id)
    } catch (error) {
      console.log(error);
    }
  }
  
  const getKeySkills = async () => {
    try {
      const res = await JobPostService.getAllKeySkills();
      setKeySkillData(res.data)
    } catch (error) {
      console.log(error);
    }
  }
 
  useEffect(() => {

    saveAlert();
    getAlertdata();
    getKeySkills();
    getSkillsMapping();
    getAlertJobs();
    getSkillsMaster();
  }, []);





  var activeJobs = getAlert.filter((val) => { if (val.status === 'Active') { return val; } })

  var newArray = [];
  var job = [];
var jobs=[]
 
 
  for(let i=0;i<skillData.length;i++){
    for(let j=0;j<skillDetails.length;j++){
      if((skillData[i].skills.toLowerCase()) === (skillDetails[j].skillName.toLowerCase())){
         job.push(skillDetails[j])
      }
    }
  }
  for(let k=0;k<job.length;k++){
    for(let l=0;l<SkillsMapping.length;l++){
      if(job[k].id === SkillsMapping[l].Skills_id){
        jobs.push(SkillsMapping[l]);
      }
    }
  }

  for (let m = 0; m < SkillsMapping.length; m++) {
    for (let n = 0; n < alertJobsData.length; n++) {
      if ((SkillsMapping[m].Alertjob_id) === (alertJobsData[n].id)) {
        newArray.push(alertJobsData[n])
      }
    }
  }

  var SkillData=[]

for (let a=0 ;a< jobs.length;a++){
  for (let b=0;b< job.length ;b++){
    if ((jobs[a].Skills_id )=== job[b].id){
          SkillData.push(job[b])
    }
  }
}






  
  let skills = [skillDetails.filter((val)=>{if((val.skills_id === KeyskillsId && SkillMappingId ===alertJobId ) ){return val; }})]




  const deleteJObAlertHandler = async (id) => {
    try {
      let arr = newArray.filter((val) => { if (val.id !== id) { return val; } }).map(val => val);

    } catch (error) {
      console.log(error)
    }
  }

  function formatNumber(number) {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(1) + 'Cr';
    } else if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'L';
    } 
    else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    }else {
      return number;
    }
  }
  return (
    <>
      <Header activelist="jobs-alerts"/>

      <AlertMessage
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        onclose={alertClose}
      />
      <div className="page-content bg-white" style={{ borderTop: '3px solid #2e55fa ' }}>
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <CandidateSideNav activemenu="jobs-alerts" />
                <div className="col-xl-9 col-lg-8 m-b30">



                  <div className="job-bx clearfix">


                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">Jobs Alert</h5>
                      <Link to='/jobs-applied-jobs' className="site-button right-arrow button-sm float-right">Back</Link>
                    </div>

                    {
                      newArray.map((val) => val).length === 0 ?
                        <div className="col-xl-12 col-lg-12 " style={{
                          width: '100%',
                          height: 150,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}>

                          <p style={{ color: "#2e55fa" }}> Jobs are not matched to your skills</p>
                        </div>
                        :

                        <ul className="post-job-bx browse-job-grid post-resume row">
                          {newArray.map((item, index) => (

                            <li className="col-lg-6 col-md-6" key={index}>

                              <div className="post-bx">
                                {/* <button
                                  style={{ marginRight: "-6%", marginTop:"-6%", width: "30px", height: "30px", color: "black", backgroundColor: "red" ,borderRadius:"5px"}}
                                  type="button"
                                  className="close"
                                  // onClick={deleteJObAlertHandler(item.id)}
                                >
                                  <span aria-hidden="true" style={{}}>&times;</span>
                                </button> */}
                                <div className="d-flex m-b20">

                                  <div className="job-post-info  ">

                                  
                                    <h5 className="m-b0"><Link to='#'>{item.job_tags}</Link></h5>
                                    <p className="m-b5 font-143">
                                      <Link to="#" className="text-primary"> {item.job_title} </Link> at {item.job_company}
                                    </p>
                                    <ul>

                                      <li style={{ fontSize: 14 }}><i className="fa fa-map-marker"></i>{item.job_area},   {item.location}       ,  <i className="fa fa-money"></i>{formatNumber(item.maximum_salary)} </li>
                                    </ul>
                                  </div>

                                </div>

                                <div className="job-time  m-t30 m-b10">
                                  
                                      <a href="#" className="mr-2"><span>{item.skillName}</span></a>

                                  

                                  {/* <a href="#"><span>Node</span></a> */}

                                  {/* <Link to={`/job-details/${item.id}`} className="btn btn-sm mr-3 " style={{ backgroundColor: "#2e55fa ", color: "white", height: "25px" }} >Apply Job</Link> */}

                                </div>

                              </div>




                            </li>




                          ))}

                        </ul>
                    }

                  </div>



                </div>
              </div>
            </div>
          </div>
        </div></div>
     

      <Footer />
    </>
  )
}

export default JobsAlert;
