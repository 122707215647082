import axios from 'axios';

const postJob = (payload) => axios.post('/job-posts', payload);

const getJobPost = () => axios.get('/job-posts');

const getJobPostById =(id) => axios.get(`/job-posts/`+id);

const deleteJobPost = (id) => axios.delete(`/job-posts/delete/`+id);

const updateJobPost = (id, payload) => axios.put(`/job-posts/`+id,payload);

const updatejobDescrioption = (id, payload) => axios.put(`/job-decription/`+id,payload);

const updatejobRequirement = (id, payload) => axios.put(`/job-requirement/`+id,payload);

const updatekeySkill = (id, payload) => axios.put(`/key-skill/`+id,payload);

const jobDescrioptionPost =(payload)=> axios.post(`/job-decription`,payload);

const jobRequirementPost =(payload)=> axios.post(`/job-requirement`,payload);

const getJobDescrioption =(payload)=> axios.get(`/job-decription/decription/`+payload);

const getJobRequirement =(payload)=> axios.get(`/job-requirement/requirement/`+payload);

const getAllJobRequirement =()=> axios.get(`/job-requirement/`);

const getAllJobDescrioption =()=> axios.get(`/job-decription/`);

const keySkillPost =(payload)=> axios.post(`/key-skill`,payload);

const getKeySkill = (payload) => axios.get(`/key-skill/`+payload);

const getAllKeySkills = () => axios.get('/key-skill/');

const deleteJobSkill = (id) => axios.delete(`/key-skill/delete/`+id);

const deleteJobDecription = (id) => axios.delete(`/job-decription/delete/`+id);

const deleteJobRequirement = (id) => axios.delete(`/job-requirement/delete/`+id);




export default {getAllJobDescrioption,updatekeySkill,updatejobRequirement,updatejobDescrioption,deleteJobRequirement,deleteJobDecription,deleteJobSkill,keySkillPost,getKeySkill, postJob, getJobPost, getJobPostById, deleteJobPost ,updateJobPost,jobDescrioptionPost,jobRequirementPost,getJobDescrioption,getJobRequirement,getAllJobRequirement,getAllKeySkills};
