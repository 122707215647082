import axios from 'axios';

const postSavedJob= (payload) => axios.post('/savedjobs', payload);

const getSavedJob = (userId) => axios.get(`/savedjobs/`+userId);

const getSavedJoblist = () => axios.get(`/savedjobs`);


const deleteSavedJob = (id) => axios.delete('/savedjobs/delete/'+id);


export default { postSavedJob,getSavedJob,deleteSavedJob,getSavedJoblist  };