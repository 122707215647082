import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';

const ContactUs = () => {
  return (
   
    <>
    <Header/>
    <div style={{ borderTop: '3px solid #2e55fa' }}>
            <div className="section-full content-inner bg-white contact-style-1">
			<div className="container">
                <div className="row">
					{/* <!-- right part start --> */}
					<div className="col-lg-4 col-md-6 d-lg-flex d-md-flex" >
                        <div className="p-a30 border-1  m-b30 contact-area border-1 align-self-stretch radius-sm" style={{borderRadius:"10px"}}>
							<h4 className="m-b10">Quick Contact</h4>
							<p>If you have any questions simply use the following contact details.</p>
                            <ul className="no-margin">
                                <li className="icon-bx-wraper left m-b30">
                                    <div className="icon-bx-xs border-1"> <Link to="#" className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                                    <div className="icon-content">
                                        <h6 className="text-uppercase m-tb0 dez-tilte">Address:</h6>
                                        <p>Plot-14/1534, Kalyani Nagar, Raghunathpurjali, Bhubaneswar, Odisha 751024</p>
                                    </div>
                                </li>
                                <li className="icon-bx-wraper left  m-b30">
                                    <div className="icon-bx-xs border-1"> <Link to="#" className="icon-cell"><i className="ti-email"></i></Link> </div>
                                    <div className="icon-content">
                                        <h6 className="text-uppercase m-tb0 dez-tilte">Email:</h6>
                                        <p>info@jobyard.in</p>
                                    </div>
                                </li>
                                <li className="icon-bx-wraper left">
                                    <div className="icon-bx-xs border-1"> <Link to="#" className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                                    <div className="icon-content">
                                        <h6 className="text-uppercase m-tb0 dez-tilte">PHONE</h6>
                                        <p>+91 98 6161 3261</p>
                                    </div>
                                </li>
                            </ul>
							<div className="m-t18">
								<ul className="dez-social-icon dez-social-icon-lg">
									<li style={{paddingRight:'3px'}}><Link target="_blank" to={"https://www.facebook.com/"} className="fa fa-facebook bg-primary"></Link></li>
									<li style={{paddingRight:'3px'}}><Link target="_blank" to={"https://twitter.com/"} className="fa fa-twitter bg-primary"></Link></li>
									<li style={{paddingRight:'3px'}}><Link target="_blank" to={"https://www.linkedin.com/"} className="fa fa-linkedin bg-primary"></Link></li>
									<li style={{paddingRight:'3px'}}><Link target="_blank" to={"https://www.instagram.com/"} className="fa fa-instagram bg-primary"></Link></li>
									<li style={{paddingRight:'3px'}}><Link target="_blank" to={"https://www.google.com/"} className="fa fa-google-plus bg-primary"></Link></li>
								</ul>
							</div>
                        </div>
                    </div>
                    {/* <!-- right part END --> */}
                    {/* <!-- Left part start --> */}
					<div className="col-lg-4 col-md-6">
                        <div className="p-a30 m-b30 radius-sm bg-gray clearfix" style={{ backgroundColor: '#eef1ff',height:"500px",borderRadius:'10px'}}>
							<h4>Send Message Us</h4>
							<div className="dzFormMsg"></div>
							<form method="post" className="dzForm" action="script/contact.php">
							<input type="hidden" value="Contact" name="dzToDo" />
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className="input-group" >
                                                <input name="dzName" type="text" required className="form-control" placeholder="Your Name"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className="input-group"> 
											    <input name="dzEmail" type="email" className="form-control" required  placeholder="Your Email Address" />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Your Message..."></textarea>
                                            </div>
                                        </div>
                                    </div>
									<div className="col-lg-12">
										<div className="recaptcha-bx">
											<div className="input-group">
                                                
												{/* <div className="g-recaptcha" data-sitekey="<!-- Put reCaptcha Site Key -->" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div> */}
												<input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha"/>
											</div>
										</div>
									</div>
                                    <div className="col-lg-12">
                                        <button name="submit" type="submit" value="Submit" className="site-button "> <span>Submit</span> </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <!-- Left part END --> */}
					<div className="col-lg-4 col-md-12 d-lg-flex m-b30" >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.4395450495126!2d85.822987214843!3d20.364759186361887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190a3ef2b0e9e1%3A0xd0b5d7bead6292fe!2sYoosys%20solution%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1676533665519!5m2!1sen!2sin" title='mymaps' width="600" height="450" style={{height:"500px",border:"0",borderRadius:'10px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
                </div>
            </div>
        </div>
        {/* <!-- contact area  END --> */}
        </div>
    <Footer/>
    </>
  )
}

export default ContactUs;