import axios from 'axios';

const Add_Month = (payload) => axios.post('/masters/month', payload);

const Get_Month=()=>axios.get(`/masters/month`);

const Update_Month=(id,payload)=>axios.put(`/masters/month/`+id,payload);

const Delete_Month=(id)=>axios.delete(`/masters/month/`+id);

const getMonthById=(id)=>axios.get(`/masters/month`+id);

export default {
    
    Add_Month,
    Get_Month,
    Update_Month,
    Delete_Month,
    getMonthById
};