import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import JobPost from '../../services/JobPost';
import { Modal } from 'react-bootstrap';
import Header from '../../components/Layout/Header';
import AdminSideBar from './Component/AdminSideBar';
import { Form } from 'react-bootstrap';
import JobPostService from '../../services/JobPost';
import BrowseCandiate from '../../services/BrowseCandiate';
import ReportJobService from '../../services/admin/ReportJobService';
import ReactPaginate from 'react-paginate';


const AdminManageJob = () => {
    const [loader, setLoader] = useState(false);
    const [joblists, setJoblists] = useState([]);
    const [modalJobTitle, setmodalJobTitle] = useState("");
    const [modalJobExperience, setModalJobExperience] = useState(0);
    const [modalJobDescription, setModalJobDescription] = useState("");
    const [modalJobEmail, setmodalJobEmail] = useState("");
    const [modalJobTags, setModalJobTags] = useState("");
    const [modalJobQualification, setModalJobQualification] = useState("");
    const [modalJobCategory, setModalJobCategory] = useState("");
    const [modalJobMinSalary, setmodalJobMinSalary] = useState(0);
    const [modalJobMaxSalary, setModalJobMaxSalary] = useState(0);
    const [modalJobType, setModalJobType] = useState("");
    const [modalJobCompany, setModalJobCompany] = useState('')
    const [modalJobRegion, setModalJobRegion] = useState("");
    const [modalJobLocation, setModalJobLocation] = useState("");
    const [modalBike, setModalBike] = useState("");
    const [modalDl, setModalDl] = useState("");
    const [modalRc, setModalRc] = useState('')
    const [modalMobile, setModalMobile] = useState("");
    const [modalLaptop, setModalLaptop] = useState("");
    const [modalBank, setmodalBank] = useState('');
    const [modalPan, setmodalPan] = useState('');
    const [modalAadhar, setmodalAadhar] = useState('');
    const [modalJobVacancy, setmodalJobVacancy] = useState(0);
    const [modaLastDate, setModalLastDate] = useState('');
    const [modalUserId, setModalUserId] = useState(0);
    const [modalCreated, setCreated] = useState('');
    const [modalJobArea, setmodalJobArea] = useState('');
    const [modalJobTimeStart, setmodalJobTimeStart] = useState('');
    const [modalSun, setmodalSun] = useState('');
    const [modalMon, setmodalMon] = useState('');
    const [modalTue, setmodalTue] = useState('');
    const [modalWed, setmodalWed] = useState('');
    const [modalThu, setmodalThu] = useState('');
    const [modalFri, setmodalFri] = useState('');
    const [modalSat, setmodalSat] = useState('');
    const [modalJobflat, setModalJobflat] = useState('');
    const [modalworkTill, setmodalworkTill] = useState('');
    const [modalworkplace, setmodalworkpalce] = useState('');
    const [modalHrname, setmodalHrname] = useState('');
    const [modalHrwhatsapp, setmodalHrwhatsapp] = useState('');
    const [modalfee, setmodalfee] = useState('');
    const [modalGender, setModalGender] = useState('');
    const [candidateList, setCandidateList] = useState([]);
    const [modalJobRequirements, setModalJobRequirements] = useState('');
    const [modalHrcompany, setmodalHrcompany] = useState('');
    const [filterData, setFilterData] = useState({
        dateData: '',
        statusData: ''
    });

    const [jobData, setJobData] = useState([]);
    const [description, setDescription] = useState([]);
    const [job_title, setJob_title] = useState();
    const [job_type, setJob_type] = useState();
    const [minimum_salary, setMinimum_salary] = useState();
    const [maximum_salary, setMaximum_salary] = useState();
    const [region, setRegion] = useState();
    const [location, setLocation] = useState();
    const [last_date, setLast_date] = useState();
    const [job_tags, setJob_tags] = useState();
    const [job_category, setJob_category] = useState();
    const [job_company, setJob_company] = useState();
    const [experience, setExperience] = useState();
    const [job_email, setJob_email] = useState();
    const [upload_file, setPpload_file] = useState();
    const [created_on, setCreated_on] = useState();
    const [status, setStatus] = useState();
    const [view, setView] = useState(false);
    const [id, setId] = useState();
    const [permission, setPermission] = useState(false);
    const [radioData, setRadioData] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [keyword, setKeyword] = useState();

    const itemsPerPage = 10
    const userId = JSON.parse(localStorage.getItem('userId'));

    const filterHanler = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value });
    }

    const radioHandler = (e) => {
        setRadioData(e.target.value)
    }

    let today = new Date().toISOString().slice(0, 10)

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const startIndex = pageNumber * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const jobdatas = jobData.slice(startIndex, endIndex);


    const getJobListData = async (idx) => {
        jobData.map((val) => {
            if (val.id === idx) {
                setId(val.id);
                setJob_title(val.job_title);
                setJob_type(val.job_type);
                setMinimum_salary(val.minimum_salary);
                setMaximum_salary(val.maximum_salary);
                setRegion(val.region);
                setLocation(val.location);
                setDescription(val.description);
                setLast_date(val.last_date);
                setJob_tags(val.job_tags);
                setJob_category(val.job_category);
                setJob_company(val.job_company);
                setExperience(val.experience);
                setJob_email(val.job_email);
                setPpload_file(val.upload_file);
                setCreated_on(val.created_on);
            }
        });

        const payload = {};

        payload.job_title = modalJobTitle;
        payload.job_type = modalJobType;
        payload.minimum_salary = modalJobMinSalary;
        payload.maximum_salary = modalJobMaxSalary;
        payload.region = modalJobRegion;
        payload.location = modalJobLocation;
        payload.description = modalJobDescription;
        payload.last_date = modaLastDate;
        payload.user_id = modalUserId;
        payload.job_tags = modalJobTags;
        payload.job_category = modalJobCategory;
        payload.job_company = modalJobCompany;
        payload.created_on = modalCreated;
        payload.experience = modalJobExperience;
        payload.job_email = modalJobEmail;
        payload.status = "Expired";
        payload.bike_option = modalBike;
        payload.rc_option = modalRc;
        payload.dl_option = modalDl;
        payload.laptop_option = modalLaptop;
        payload.smartPhone = modalMobile;
        payload.qualification = modalJobQualification;
        payload.clr = 'N';
        payload.pan_card = modalPan;
        payload.adhar_card = modalAadhar;
        payload.back_passbook = modalBank;
        payload.workplace_type = modalworkplace;
        payload.worktime_start = modalJobTimeStart;
        payload.worktime_end = modalworkTill;
        payload.interview_fee = modalfee;
        payload.gender = modalGender;
        payload.sun = modalSun;
        payload.mon = modalMon;
        payload.tue = modalTue;
        payload.wed = modalWed;
        payload.thu = modalThu;
        payload.fri = modalFri;
        payload.sat = modalSat;
        payload.job_area = modalJobArea;
        payload.job_flat_no = modalJobflat;
        payload.hr_name = modalHrname;
        payload.hr_company = modalHrcompany;
        payload.job_requirements = modalJobRequirements;
        payload.vacancy = modalJobVacancy;
        payload.whatsapp = modalHrwhatsapp;

        const res = await JobPost.updateJobPost(idx, payload);

        if (res.status === 200) {
            window.location.reload();
        }

        setLoader(false)
    }

    const getAllJobs = async () => {
        try {
            const res = await JobPostService.getJobPost();
            if (res.status === 200) {
                setJobData(res.data);

            }
        } catch (err) {
            return err;
        }
    }

    const getCandidateLists = async () => {
        try {
            const res = await BrowseCandiate.getBrowseCandidate();
            if (res.status === 200) {
                setCandidateList(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllJobsList = async () => {
        try {
            const res = await ReportJobService.getReportJob();
            if (res.status === 200) {
                setJoblists(res.data);
            }
        } catch (error) {
            return error;
        }
    }

    useEffect(() => {
        getAllJobs();
        getCandidateLists();
        getAllJobsList();
    }, []);

    const statusHandler = async () => {
        try {
            const payload = {};
            payload.id = id;
            payload.job_title = job_title;
            payload.job_type = job_type;
            payload.minimum_salary = minimum_salary;
            payload.maximum_salary = maximum_salary;
            payload.region = region;
            payload.location = location;
            payload.description = description;
            payload.last_date = last_date;
            payload.job_tags = job_tags;
            payload.job_category = job_category;
            payload.job_company = job_company;
            payload.experience = experience;
            payload.job_email = job_email;
            payload.upload_file = upload_file;
            payload.created_on = created_on;
            payload.status = radioData;

            const res = await JobPostService.updateJobPost(id, payload);
            if (res.status === 200) {
                window.location.reload();
            }


        } catch (err) {
            return err;
        }

    }

    const viewHandler = async (Id) => {
        try {
            jobData.map((val) => {
                if (val.id === Id) {
                    setJob_title(val.job_title);
                    setJob_type(val.job_type);
                    setMinimum_salary(val.minimum_salary);
                    setMaximum_salary(val.maximum_salary);
                    setRegion(val.region);
                    setLocation(val.location);
                    setDescription(val.description);
                    setLast_date(val.last_date);
                    setJob_tags(val.job_tags);
                    setJob_category(val.job_category);
                    setJob_company(val.job_company);
                    setExperience(val.experience);
                    setJob_email(val.job_email);
                    setPpload_file(val.upload_file);
                    setCreated_on(val.created_on);
                    setStatus(val.status)
                    setView(true);
                }
            });

        } catch (error) {
            return error;
        }
    }

    const permissionHandler = (Id) => {

        jobData.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setJob_title(val.job_title);
                setJob_type(val.job_type);
                setMinimum_salary(val.minimum_salary);
                setMaximum_salary(val.maximum_salary);
                setRegion(val.region);
                setLocation(val.location);
                setDescription(val.description);
                setLast_date(val.last_date);
                setJob_tags(val.job_tags);
                setJob_category(val.job_category);
                setJob_company(val.job_company);
                setExperience(val.experience);
                setJob_email(val.job_email);
                setPpload_file(val.upload_file);
                setCreated_on(val.created_on);
                setPermission(true);
            }
        })
    }

    const sortingHandler = (col) => {

        if (sortOrder === "asc") {
            const sorted = jobdatas.sort((a, b) =>
                a[col] > b[col] ? 1 : -1

            );
            setSortedData(sorted);
            setSortOrder('dsc')
        } else {
            const sorteds = jobdatas.sort((a, b) =>
                a[col] < b[col] ? 1 : -1

            );
            setSortedData(sorteds);
            setSortOrder('asc')

        }
    };
    function formatNumber(number) {
        if (number >= 10000000) {
            return (number / 10000000).toFixed(1) + 'Cr';
        } else if (number >= 100000) {
            return (number / 100000).toFixed(1) + 'L';
        }
        else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number;
        }
    }
    const filteredJobs = jobData.filter((itm) => {
        return (
            (!filterData.statusData || filterData.statusData === 'Status' || (filterData.statusData && filterData.statusData === itm.status)) &&
            (!filterData.dateData || (filterData.dateData && filterData.dateData === itm.last_date))
        );
    });

    const reportCount = joblists.filter((itm) => {
        return filteredJobs.some(job => job.id === itm.job_id);
    }).length;

    return (
        <>
            <Header activelist="admin-manage-job" />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">

                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container" PaginatedItems itemsPerPage={5}>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30">
                                        <AdminSideBar activemenu="admin-manage-job" />
                                    </div>
                                    <div className="col-xl-9 col-lg-8 m-b30">
                                        <div className="job-bx clearfix">
                                            <div className="job-bx-title clearfix">
                                                <h5 className="font-weight-700 pull-left text-uppercase">Manage jobs</h5>
                                                <div className="float-right">
                                                    {/* <span className="select-title">Sort by freshness </span> */}
                                                    <div className="">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="Search by Job Title"
                                                            value={keyword}
                                                            onChange={(e) => setKeyword(e.target.value)}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                sortOrder === 'asc' ?
                                                    <table className="table-job-bx cv-manager company-manage-job">
                                                        <thead >
                                                            <tr>

                                                                <th onClick={() => sortingHandler('job_title')} style={{ fontSize: 12, fontWeight: 800 }}>Job Title<span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800 }}>Applications </th>
                                                                <th onClick={() => sortingHandler('last_date')} style={{ fontSize: 12, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800, paddingLeft: '5%' }} onClick={() => sortingHandler('status')}>Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                jobdatas.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
                                                                    .map((item, index) => {

                                                                        return (

                                                                            <tr key={index} >

                                                                                <td className="job-name">

                                                                                    <Link to="#" style={{ fontSize: "14px", fontWeight: 800 }}>{item.job_title}.</Link>
                                                                                    <ul className="job-post-info ">
                                                                                        <li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
                                                                                        <ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
                                                                                            <li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

                                                                                    </ul>
                                                                                </td>


                                                                                <Link to={`/jobs-candidate-profile-list/${item.id}`}>
                                                                                    <td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
                                                                                        if (val.job_id === item.id) {
                                                                                            return val;
                                                                                        }
                                                                                    }).map(val => { return val }).length})</td></Link>
                                                                                <td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
                                                                                <td style={{ fontSize: 12 }}>
                                                                                    {

                                                                                        item.status === "Review" ?
                                                                                            <td style={{ color: 'blue', fontSize: 12 }} className="expired primary">In Review</td> :
                                                                                            item.status === "Active" ?
                                                                                                <td className="expired success" style={{ fontSize: 12 }}>Active</td> :
                                                                                                item.status === "Cancel" ?
                                                                                                    <td style={{ color: "red", fontSize: 12 }} className="expired danger ">Cancelled</td> :
                                                                                                    item.status === 'Expired' ?
                                                                                                        <td style={{ color: "orange", fontSize: 12 }}>Expired</td> :
                                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                <td className="job-links mt-2 ml-2">
                                                                                    <Link to="#" data-toggle="modal" data-target="#exampleModalLong">
                                                                                        <i className="fa fa-eye" onClick={() => viewHandler(item.id)}></i></Link>

                                                                                    <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permissionHandler(item.id)}></i></Link>
                                                                                    {
                                                                                        item.user_id === userId ?
                                                                                            <Link to={`/admin-update-job/${item.id}`} data-toggle="modal" data-target="#exampleModalLong">	<i className="fa fa-pencil bg-warning fa-sm" style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} ></i></Link>
                                                                                            : null

                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    })

                                                            }



                                                        </tbody>


                                                        <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                            <ReactPaginate
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(jobData.length / itemsPerPage)}
                                                                marginPagesDisplayed={7}
                                                                pageRangeDisplayed={jobData.length}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                activeClassName={'active'}
                                                            /></div>
                                                    </table>






                                                    :



                                                    <table className="table-job-bx cv-manager company-manage-job">
                                                        <thead >
                                                            <tr>

                                                                <th onClick={() => sortingHandler('job_title')} style={{ fontSize: 12, fontWeight: 800 }}>Job Title<span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800 }}>Applications </th>
                                                                <th onClick={() => sortingHandler('last_date')} style={{ fontSize: 12, fontWeight: 800 }}>Last Date <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800, paddingLeft: '5%' }} onClick={() => sortingHandler('status')}>Status <span>{sortOrder === 'asc' ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-up"></i>}</span></th>
                                                                <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                sortedData.filter((val) => { if ((!keyword || keyword && val.job_title.toLowerCase().includes(keyword.toLowerCase()))) { return val; } })
                                                                    .map((item, index) => {

                                                                        return (

                                                                            <tr key={index} >

                                                                                <td className="job-name">

                                                                                    <Link to="#" style={{ fontSize: "14px", fontWeight: 800 }}>{item.job_title}.</Link>
                                                                                    <ul className="job-post-info ">
                                                                                        <li style={{ fontSize: "12px" }}><i className="fa fa-map-marker"></i> {item.job_area},{item.location}</li>
                                                                                        <ul className='d-flex'> <li style={{ fontSize: "12px" }}><i className="fa fa-bookmark-o"></i>{item.job_type}</li>
                                                                                            <li style={{ fontSize: "12px" }}><i className="fa fa-filter"></i> {item.job_tags}</li></ul>

                                                                                    </ul>
                                                                                </td>


                                                                                <Link to={`/jobs-candidate-profile-list/${item.id}`}>
                                                                                    <td className="application text-primary" style={{ fontSize: "12px" }}> Application({candidateList.filter((val) => {
                                                                                        if (val.job_id === item.id) {
                                                                                            return val;
                                                                                        }
                                                                                    }).map(val => { return val }).length})</td></Link>
                                                                                <td className="expired pending" style={{ fontSize: "12px" }}>{item.last_date} </td>
                                                                                <td style={{ fontSize: 12 }}>
                                                                                    {

                                                                                        item.status === "Review" ?
                                                                                            <td style={{ color: 'blue', fontSize: 12 }} className="expired primary">In Review</td> :
                                                                                            item.status === "Active" ?
                                                                                                <td className="expired success" style={{ fontSize: 12 }}>Active</td> :
                                                                                                item.status === "Cancel" ?
                                                                                                    <td style={{ color: "red", fontSize: 12 }} className="expired danger ">Cancelled</td> :
                                                                                                    item.status === 'Expired' ?
                                                                                                        <td style={{ color: "orange", fontSize: 12 }}>Expired</td> :
                                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                <td className="job-links mt-2 ml-2">
                                                                                    <Link to="#" data-toggle="modal" data-target="#exampleModalLong">
                                                                                        <i className="fa fa-eye" onClick={() => viewHandler(item.id)}></i></Link>

                                                                                    <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permissionHandler(item.id)}></i></Link>
                                                                                    {
                                                                                        item.user_id === userId ?
                                                                                            <Link to={`/admin-update-job/${item.id}`} data-toggle="modal" data-target="#exampleModalLong">	<i className="fa fa-pencil bg-warning fa-sm" style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} ></i></Link>
                                                                                            : null

                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    })

                                                            }



                                                        </tbody>


                                                        <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                            <ReactPaginate
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                pageCount={Math.ceil(jobData.length / itemsPerPage)}
                                                                marginPagesDisplayed={7}
                                                                pageRangeDisplayed={jobData.length}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={'pagination'}
                                                                activeClassName={'active'}
                                                            /></div>
                                                    </table>



                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={permission} onHide={setPermission} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h5 className="modal-title" >Set Job Status</h5>
                                        <button type="button" className="close" onClick={() => setPermission(false)}>
                                            <span aria-hidden="true"  >&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Form.Control
                                            as="select"
                                            className="custom-select" style={{ height: "41px",color:'black' }}
                                            value={radioData}
                                            onChange={radioHandler}
                                        >
                                            <option>-----------------------------Select Status----------------------------</option>
                                            <option className='fc-grn' style={{color:'black'}}>Active</option>
                                            <option className=' fc-red' style={{color:'black'}}>Cancel</option>

                                        </Form.Control>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={statusHandler} >Submit</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => setPermission(false)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                        <Modal show={view} onHide={setView} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h5 className="modal-title" >View Job</h5>
                                        <button type="button" className="close" onClick={() => setView(false)}>
                                            <span aria-hidden="true" >&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul>
                                            <li>Title : {job_title}</li>
                                            <li>Company : {job_company} </li>
                                            <li>Salary : ₹{formatNumber(minimum_salary)} - ₹{formatNumber(maximum_salary)}</li>
                                            <li>Experience : {experience ? experience + " " + "Years" : null}</li>
                                            <li>Location : {location} , {region} </li>
                                            <li>Created on : {created_on}</li>
                                            <li>Last Date : {last_date}</li>
                                            <li>Status : {status}</li>
                                        </ul>
                                        {filteredJobs.length > 0 && (
                                            <Link to={`/report/jobs-by/candidate/${filteredJobs[0].id}`}>
                                                Reports <span style={{ color: 'red' }}>({reportCount})</span>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setView(false)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
};

export default AdminManageJob;
