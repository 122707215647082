import React, { useState, useEffect } from 'react';
import Header1 from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import AdminSideBar2 from './AdminSideBar2';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../../components/AlertMessage';



import MonthMasterService from '../../../services/Master/MonthMaster';


const AdminMonthMaster = () => {

    const [addMonth, setAddMonth] = useState(false);
    const [monthName, setMonthName] = useState([]);
    const [id, setId] = useState();
    const [month_name, setMonth_name] = useState();
    const [deleteMonth, setDeleteMonth] = useState(false);
    const [editMonth, setEditMonth] = useState(false);
    const [monthModal, setMonthModal] = useState();
    const [alertState, setAlertState] = useState({});




    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };


    const getAllMonths = async () => {
        try {
            const res = await MonthMasterService.Get_Month();
            if (res.status == 200) {
                setMonthName(res.data);
            }
        } catch (err) {
            return err;
        }

    }

    useEffect(() => {
        getAllMonths();
    }, [])

    const EditHandler = (Id) => {
        monthName.map((val) => {
            if (val.id === Id) {
                
                setId(val.id);
                setMonth_name(val.monthName);
                setEditMonth(true);
            }
        })
    }

    const MonthDeleteHandler = (Id) => {
        monthName.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteMonth(true);

            }
        })
    }

    const monthHandler = async () => {
        try {
            const payload = {};
            payload.monthName = monthModal;
            
            if (payload.monthName) {
                const res = await MonthMasterService.Add_Month(payload);
                if (res.status === 200) {
                    setMonthModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            }
        } catch (err) {
            return (err);
        }
    }

    const MonthEditMethod = async () => {
        try {
            const payload = {};
            payload.monthName = month_name;
            var Id = parseInt(id);
            if (payload.monthName) {
                const res = await MonthMasterService.Update_Month(Id, payload);
                if (res.status === 200) {
                    setMonth_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteMonthMethod = async () => {
        try {
            var Id = parseInt(id);
            const res = await MonthMasterService.Delete_Month(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    return (
        <>
            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Month Master</li>
								</ul>
							</div> */}
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                <AdminSideBar2 activelist="admin-month-master" />
                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12 ">
                                <div className=" job-bx bg-white m-b30">
                                    <div className='d-flex'	>
                                        <h5 className="m-b15">Month Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddMonth(true)}>
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>

                                    <table className=' table-job-bx cv-manager company-manage-job'>
                                        <thead>
                                            <tr className="table-primary">
                                                <th >Sl No.</th>
                                                <th >Months</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                monthName.map((val, ind) => {
                                                    return (
                                                        <tr className='tavle-hover' key={ind}  >
                                                            <td >{ind + 1} </td>
                                                            <td >{val.monthName}</td>
                                                            <td >
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => MonthDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }



                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addMonth} onHide={setAddMonth} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Add Month </h5>
                            <button type="button" className="close" onClick={() => { setAddMonth(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Month</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Month."
                                                value={monthModal}
                                                onChange={(e) => setMonthModal(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setAddMonth(false) }} >Cancel</button>
                            <button type="button" className=" btn btn-success"  onClick={monthHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/*================================================== Edit Modal ==================================================== */}
            <Modal show={editMonth} onHide={setEditMonth} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"  id="RegionMaster">Edit Month </h5>
                            <button type="button" className="close" onClick={() => { setEditMonth(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Month</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Month."
                                                value={month_name}
                                                onChange={(e) => setMonth_name(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setEditMonth(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning"  onClick={MonthEditMethod}  >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ============================================== Delete Modal ================================================= */}


         
            <Modal show={deleteMonth} onHide={setDeleteMonth} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">

                <div className="modal-dialog my-0 mx-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster"> Delete Month</h5>

                            <button type="button" className="close" onClick={() => { setDeleteMonth(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'> Do You want to delete this Month </span></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary sm" onClick={() => { setDeleteMonth(false) }} >Cancel</button>
                            <button type="button" className=" btn btn-danger sm" onClick={deleteMonthMethod} >Delete</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Footer />
        </>
    )
}

export default AdminMonthMaster