import React, { useEffect, useState } from 'react'
import Header from '../../components/Layout/Header'
import { Link, useNavigate } from 'react-router-dom'
import Users from '../../services/Users'
import { Modal, Form } from 'react-bootstrap'
import EmployerTeam from '../../services/EmployerTeam';
import Error from "../../constants/ErrorMessage";
import CategoryMaster from '../../services/Master/CategoryMaster'
import 'react-dropdown/style.css';
import * as EmailValidator from 'email-validator';
import pincodeDirectory from 'india-pincode-lookup';
import AlertMessage from '../../components/AlertMessage'
import EmployerProfileServices from '../../services/EmployerProfile';
import { ThreeCircles } from 'react-loader-spinner';
import EmployerImage from '../../services/image/EmployerImage'
import UserCompany from '../../services/UserCompany';



const NewCompanyProfile = () => {

	const [user, setUser] = useState({ company_name: '', company_email: '', company_website: '', company_gstin: '', company_cin: '', company_category: '', company_country: '', company_state: '', company_city: '', company_zip: '', facebook: '', twitter: '', google: '', linkedIn: '', company_streetAddress: '', company_phone: '', recruiter_name: '', recruiter_email: '', recruiter_whatsapp: '', recruiter_role: '', description: '' })
	const [companyData, setCompanyData] = useState({})
	const [allUser, setAllUser] = useState([])
	const [companyProfileModal, setCompanyProfileModal] = useState(false);
	const [loader, setLoader] = useState(false)
	const [userData, setUserData] = useState({})
	const [member, setMember] = useState({});
	const [image, setImage] = useState(null)
	const [employerImage, setEmployerImage] = useState([]);
	const [imageModal, setImageModal] = useState(false);
	const [imageData, setImageData] = useState({});
	const [showError, setShowError] = useState({})
	const [categoryTypes, setCategoryTypes] = useState([]);
	const [alertState, setAlertState] = useState({})
	const [employerTeam, setEmployerTeam] = useState([]);
	const [showLogout, setShowLogOut] = useState();
	const [company_Id, setCompany_Id] = useState('');
	

	let userId = JSON.parse(localStorage.getItem('userId'));
	let loginStatus = window.localStorage.getItem('loginStatus');
	let roleId = JSON.parse(localStorage.getItem('roleId'));
	let companyId = JSON.parse(localStorage.getItem('companyId'));

	const navigate = useNavigate();

	const onImageChange = (img) => {
		setEmployerImage(img.target.files);
		if (img.target.files && img.target.files[0].name) {
			setImageModal(true);
			setImage(URL.createObjectURL(img.target.files[0]));
		}
	}
	const handleInputs = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });

	}

	// 	const getUserCompanyData=async()=>{
	// 		try {
	// 			const res=await UserCompany.getAllUserCompany()
	// 				if(res.status=200){
	// 					setUserComapanyData(res.data)
	// 				}
	// 		} catch (error) {
	// 			console.log(error)
	// 		}

	// 	}

	const userCompanyDetails = async () => {
		setLoader(true);
		try {
			const res = await Users.getUserById(userId);
			if (res.status === 200) {
				setCompanyData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(()=>{
			setLoader(false);
		},[1000])
	}

	const getUserData = async () => {
		try {
			const res = await Users.getUserById(userId);
			setUserData(res.data)
			const resp = await EmployerTeam.getEmployerTeam();
			resp.data.find((val) => {
				if (val.email === res.data.email)
					setMember(val)
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getAllImages = async () => {
		setLoader(true);
		try {
			const res = await EmployerImage.getEmployerImageById(userId);
			if (res.status === 200) {
				setImageData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(()=>{
			setLoader(false);
		},[1000])
	}

	const getCompanyData = async () => {
		setLoader(true);
		try {
			const res = await EmployerProfileServices.getEmployerById(companyId)

			if (res.status === 200 || 304) {
				const resp = await EmployerProfileServices.getAllCompanyProfile();
				resp.data.find((val) => {
					if (val.id === res.data.id) {
						setUser(res.data)
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
		setTimeout(()=>{
			setLoader(false);
		},[1000])
	}
	useEffect(() => {
		getCompanyData();
	}, [companyId])

	useEffect(() => {
		getUserData();
		userCompanyDetails();
		// getUserCompanyData();
		getAllImages();
	}, [`${userId}`])


	const getAllUser = async () => {
		try {
			const res = await Users.getUser();
			setAllUser(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getCategoryTypes = async () => {
		try {
			const res = await CategoryMaster.getCategory();
			if (res.status === 200) {
				setCategoryTypes(res.data)
			}
		} catch (error) {
			console.log(error)
		}
	}
	const uploadHandler = async () => {
		try {
			const formdata = new FormData();
			formdata.append('userId', userId);
			formdata.append('file', employerImage[0]);

			const res = await EmployerImage.imageUpload(formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},
			});

			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: " Employer Profile picture Update successfully" })
				window.location.reload();
			}

		} catch (error) {
			console.log(error);
		}
	}

	const imageUpdateHandler = async () => {
		try {
			const ID = await imageData.id;

			const formdata = new FormData();
			formdata.append('file', employerImage[0]);

			const res = await EmployerImage.updateUploadImage(ID, formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},
			});
			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: "Employer Profile picture Update successfully " })
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getEmployerTeam = async () => {
		setLoader(true);
		try {
			
			const res = await EmployerTeam.getEmployerTeam()
			setEmployerTeam(res.data);
			const employerIds = res.data.map(item => item.employerId);
			if (res.status === 200) {
				if (roleId === 4) {
					const resp = await UserCompany.getAllUserCompany()
					if (resp.status === 200) {
						resp.data.find((val) => {
							if (employerIds.includes(val.user_id)) {
								setCompany_Id(val.company_id)
								window.localStorage.setItem('company_uuid', val.company_uuid);
							}
						})
					}

				}

			}

		} catch (error) {
			console.log(error);
		}
		setTimeout(()=>{
			setLoader(false);
		},[1000])
	}

	const getCompanyDetailsData = async () => {
		setLoader(true);
		try {
			const res = await EmployerProfileServices.getEmployerById(company_Id)

			if (res.status === 200 || 304) {
				const resp = await EmployerProfileServices.getAllCompanyProfile();
				resp.data.find((val) => {
					if (val.id === res.data.id) {
						setUser(res.data)
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
		setTimeout(()=>{
			setLoader(false);
		},[1000])
		
	}
	useEffect(()=>{
		getCompanyDetailsData()
	},[`${company_Id}`])

console.log(user,"users")
	useEffect(() => {
		getAllUser();
		getCategoryTypes();
		getEmployerTeam();
	}, [])



	const UpdateHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			if (user.company_name.length > 100) {
				if (user.company_name.length > 100) {
					setAlertState({ open: true, type: "warning", message: "Comapany name should be under 250 characters " })
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide company name.' });
				}
			} else if (user.company_website.length > 100) {
				setAlertState({ open: true, type: "warning", message: 'Please Provide company website' })
			} else if (!user.company_category) {
				setAlertState({ open: true, type: "warning", message: "Please Select a Category" })

			} else if (user.company_cin.length > 100) {
				if (user.company_cin.length > 100) {
					setAlertState({ open: true, type: 'warning', message: "CIN number should be under 100 Character" })
				} else {
					setAlertState({ open: true, type: "warning", message: "Please Provide Company CIN Number" })
				}
			} else if (user.company_gstin.length > 100) {
				if (user.company_gstin.length > 100) {
					setAlertState({ open: true, type: 'warning', message: "GSTIN number should be under 100 Character" })

				} else {
					setAlertState({ open: true, type: "warning", message: "Please Provide Company GSTIN number" })
				}
			} else if (user.description.length > 250) {
				setAlertState({ open: true, type: "warning", message: "Description Should be Under 250 Charecter" })
			} else if (user.company_country.length > 100 || !user.company_country) {
				if (user.company_country.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'country should be under 100 charcters' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a country.' });
				}
			}
			else if (user.company_phone.length !== 10 || !user.company_phone) {
				if (user.company_phone.length !== 10) {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid phone number' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a phone number.' });
				}
			}
			else if (user.company_email.length > 100 || !user.company_email || EmailValidator.validate(user.company_email) === false) {
				if (user.company_email.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 charcters' });
				} else if (EmailValidator.validate(user.company_email) === false) {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid Email.' });
				}
				else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a Email.' });
				}
			} else if (user.company_zip.length !== 6 || (pincodeDirectory.lookup(user.company_zip).length === 0) || pincodeDirectory.lookup(user.company_zip)[0].stateName !== 'ODISHA') {
				if (user.company_zip.length !== 6) {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid PIN code' });
				} else if (pincodeDirectory.lookup(user.company_zip).length === 0) {
					setAlertState({ open: true, type: 'warning', message: `${user.company_zip} doesn't belong to Odisha` });
				}
				else if (pincodeDirectory.lookup(user.company_zip)[0].stateName !== 'ODISHA') {
					setAlertState({ open: true, type: 'warning', message: `${user.company_zip} doesn't belong to Odisha` });

				}
				else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide PIN code.' });
				}
			} else if (!user.company_country) {
				if (user.company_country.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'country should be under 100 charcters' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a country.' });
				}
			} else if (!user.company_state) {

				setAlertState({ open: true, type: 'warning', message: 'Please provide a State.' });

			} else if (!user.company_city) {

				setAlertState({ open: true, type: 'warning', message: 'Please provide a city' });

			}
			else if (user.company_streetAddress.length > 100 || !user.company_streetAddress) {
				if (user.company_streetAddress.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'Address should be under 100 characters' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a StreetAddress' });
				}
			}
			else if (user.facebook.length > 250) {
				setAlertState({ open: true, type: 'warning', message: 'Facebook should be under 250 characters' });
			} else if (user.google.length > 250) {
				setAlertState({ open: true, type: 'warning', message: 'Google url should be under 250 characters' });
			} else if (user.twitter.length > 250) {
				setAlertState({ open: true, type: 'warning', message: 'Twitter url should be under 250 characters ' });
			} else if (user.linkedIn.length > 250) {
				setAlertState({ open: true, type: 'warning', message: 'Linkedin url should be under 250 characters' });
			} else if (!user.recruiter_email || user.recruiter_email.length > 100 || EmailValidator.validate(user.recruiter_email) === false) {
				if (user.recruiter_email.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters' });
				} else if (EmailValidator.validate(user.recruiter_email) === false) {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email for recruiter.' });
				}
				else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide recruiter email.' });
				};
			} else if (!user.recruiter_name || user.recruiter_name.length > 30) {
				if (user.recruiter_name.length > 30) {
					setAlertState({ open: true, type: 'warning', message: 'Recruiter Name should be under 30 characters' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide recruiter name.' });
				}
			} else if (!user.recruiter_role || user.recruiter_role.length > 100) {
				if (user.recruiter_role.length > 100) {
					setAlertState({ open: true, type: 'warning', message: 'Role should be under 100 characters' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide recruiter role.' });
				}
			} else if (!user.recruiter_whatsapp || user.recruiter_whatsapp.length !== 10) {
				if (user.recruiter_whatsapp.length !== 10) {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid whatsapp No..' });
				} else {
					setAlertState({ open: true, type: 'warning', message: 'Please provide a whatsapp.' });
				}

			} else {
				const payload = {};
				payload.id = companyId;
				payload.company_name = user.company_name;
				payload.company_email = user.company_email;
				payload.company_website = user.company_website;
				payload.company_gstin = user.company_gstin;
				payload.company_cin = user.company_cin;
				payload.description = user.description;
				payload.company_category = user.company_category;
				payload.company_country = user.company_country;
				payload.company_state = user.company_state;
				payload.company_city = user.company_city;
				payload.company_phone = user.company_phone;
				payload.company_zip = user.company_zip;
				payload.company_streetAddress = user.company_streetAddress;
				payload.facebook = user.facebook;
				payload.twitter = user.twitter;
				payload.google = user.google;
				payload.linkedIn = user.linkedIn;
				payload.recruiter_name = user.recruiter_name;
				payload.recruiter_email = user.recruiter_email;
				payload.recruiter_whatsapp = user.recruiter_whatsapp;
				payload.recruiter_role = user.recruiter_role;



				setLoader(true);
				const res = await EmployerProfileServices.updateCompanyProfile(companyId, payload)
				if (res.status === 200) {
					const payload = {};
					payload.name = companyData.name;
					payload.email = companyData.email;
					payload.mobile = companyData.mobile;
					payload.password = companyData.password;
					payload.roleId = companyData.roleId;
					payload.verificationToken = companyData.verificationToken;
					payload.emailVerified = companyData.emailVerified;
					payload.status = 'In Review';


					const result = await Users.updateUser(companyData.id, payload);
					if (result.status === 200) {

						setAlertState({ open: true, type: "success", message: "company Profile Update Successfully" })
						setCompanyProfileModal(false);
						getCompanyData();
						userCompanyDetails();
						// getAllImages();

						getCategoryTypes();
						getUserData();
						window.location.reload();
						setLoader(false);
						window.location.reload();
					}

				}

				setLoader(false);
			}
		} catch (error) {
			console.log(error)

		}



	}
	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({ open: false });
	};


	let cityList = [...new Set(pincodeDirectory.lookup(user.zip).map(val => val.taluk))]




	let showStatus;

	employerTeam.find((val) => {
		if (val.email === companyData.email) {
			allUser.find((itm) => {
				if (itm.id === val.employerId) {
					showStatus = itm.status

				}
			})
		}
	})




	const toggleLogOutModal = async () => {
		setShowLogOut(!showLogout);
	};

	const handleLogout = async () => {
		try {
			const userEmail = localStorage.getItem("userMailMob");
			const userPassword = localStorage.getItem("password");
			const userLogout = {};
			userLogout.email = userEmail;
			userLogout.password = userPassword;
			const res = await Users.logoutUser(userLogout);
			if (res.status === 200) {
				window.localStorage.clear();
				window.localStorage.setItem('userId', 0);
				navigate("/");
			}
		} catch (error) {
			console.log(error);
		}
		//window.location.reload();
	};
	return (
		<>
			<Header />


			<div style={{ borderTop: '3px solid #2e55fa' }}>
				{
					loader ? null :
						!user.id > 0 ? (user.id ? null : <div
							className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>{roleId === 4 ? null : <h6 className="fw3 mt-2 " style={{ fontSize: 14, color: '#2e55fa' }}>
							Note: Please ,Update your company profile details else you are unable to Post your
							jobs
						</h6>}

						</div>)



							:


							(
								(user.id && userData.status === 'In Review') ?
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
										<p style={{ fontSize: 14, color: '#2e55fa' }}>Note: Your Account is under review. You will able to post a job after your account is activated by jobYard.</p>
									</div> :


									(userData.roleId === 4 ? null :
										<div className='mt-1' style={{
											height: 80,
											width: '100%',
											backgroundColor: '#f8f9fa',
											boxShadow: '5px solid black'
										}}>
											<div style={{
												backgroundColor: '#2e55fa',
												width: '100%',
												height: '100%',
												padding: 20,
												paddingLeft: 25,
												display: 'flex',
												flexDirection: 'column'
											}}>
												<h5 className="font-weight-700 pull-left " style={{ color: '#fff', textTransform: 'capitalize' }}>Company Profile Status Bar</h5>
												<div className="progress-box">
													<div style={{
														display: 'flex',
														justifyContent: 'flex-end',
														marginTop: '-3%'
													}}><h6 style={{ color: '#fff' }}>25%</h6></div>
													<div className="progress">

														<div className="progress-bar bg-success" style={{ width: '25%' }} role="progressbar" ></div>
													</div>
												</div>

											</div>

										</div>
									)

							)
				}



				{
					userData.status === 'In Review' && (showStatus === 'Active' || showStatus === 'In Review' || showStatus === "On Verification") ?
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
							<p style={{ fontSize: 14, color: '#2e55fa' }}>Note: Your Account is under review. You will able to post a job after your account is activated by jobYard.</p>
						</div>
						:

						(showStatus === 'In Review' && (showStatus === 'Active' || showStatus === 'In Review' || showStatus === 'On Verification') ?
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
								<p style={{ fontSize: 14, color: '#2e55fa' }}>Note: Your Account is under review. You will able to post a job after your account is activated by jobYard.</p>
							</div>
							:
							(showStatus === "On Verification" && (showStatus === 'Active' || showStatus === 'In Review' || showStatus === 'On Verification') ?
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
									<p style={{ fontSize: 14, color: '#2e55fa' }}>Note: Your Account is under On Verifiaction. You will able to post a job after your account is Verified by jobYard.</p>
								</div>
								:
								(showStatus === 'cancel' ?
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
										<p style={{ fontSize: 14, color: '#2e55fa' }}><span style={{ color: 'black', fontWeight: 'bold' }}>Note : </span> Your Account is Blocked By Jobyard. </p>
									</div>
									: null
								)
							)

						)



				}

				{userData.status === 'On Verification' ?

					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
						<p style={{ fontSize: 14, color: '#2e55fa' }}>Note: Your Account is On Verification.</p>
					</div> : null
				}


				{
					loader ?

						<div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
							<ThreeCircles
								height="80"
								width="80"
								color="#2e55fa"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
								ariaLabel="three-circles-rotating"
								outerCircleColor=""
								innerCircleColor=""
								middleCircleColor=""
							/>
						</div> :


						<div className="page-content bg-white">

							<div className="content-block">

								<div className="section-full bg-white p-t50 p-b20">
									<div className="container">
										<div className="row">
											<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
											<div className="col-xl-3 col-lg-4 m-b30">
												<div className="sticky-top">
													<div className="candidate-info company-info">
														<div className="candidate-detail text-center">

															<div className="canditate-des">
																<Link to={"#"}>
																	{
																		!imageData.image ?
																			<img alt="" src="images/logo/icon3.jpg" />
																			:
																			(image ?
																				<img src={image} style={{ width: '100%', height: "100%", borderRadius: "50%" }} />
																				:

																				<img src={imageData.image} style={{ width: '100%', height: "100%", borderRadius: "50%" }} />)

																	}



																</Link>
																<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
																	<input
																		className="update-flie"
																		type="file"
																		onChange={(e) => onImageChange(e)}
																	/>
																	<i className="fa fa-pencil"></i>
																</div>
															</div>

															<div className="candidate-title">
																<h4 className="m-b5"><Link to="#">{companyData.name}</Link></h4>
															</div>
														</div>


														{
															(user.id && userData.status === 'Active' || userData.status === 'On Verification') || ((userData.status === 'Active' || userData.status === 'On Verification') && (showStatus === 'Active' || showStatus === 'On Verification') && (showStatus !== 'In Review' || showStatus !== 'Cancel')) ?
																<ul>
																	<li>
																		<Link to={"/company-profile"} className={"active"}>
																			<i className="fa fa-user-o" aria-hidden="true"></i>
																			<span>{'Company Profile'}</span></Link>
																	</li>
																	<li>
																		<Link to={'/job-post'} > <i className='fa fa-file-text-o' aria-hidden="true"></i><span>{'Post A Job'}</span></Link>
																	</li>
																	<li>
																		<Link to={'/manage-job'} > <i className='fa fa-briefcase' aria-hidden="true"></i><span>{'Manage Jobs'}</span></Link>
																	</li>
																	<li>
																		<Link to={'/resume'} > <i className='fa fa-id-card' aria-hidden="true"></i><span>{'Resume'}</span></Link>
																	</li>
																	{/* <li>
																	<Link to={'/employer-team'} > <i className='fa-solid fa-user-group' aria-hidden="true"></i><span>{'Employer Team'}</span></Link>
																</li> */}
																	{/* <li>
																	<Link to={'#'}><i className="fa fa-key" aria-hidden="true"></i><span>{'Change Password'}</span></Link>
																</li> */}
																	<li onClick={toggleLogOutModal} >
																		<Link to={"#"}> <i className="fa fa-sign-out" aria-hidden="true"></i><span>Log Out</span></Link>
																	</li>

																</ul> :
																(!user.id && userData.roleId === 2 ?
																	<ul>
																		<li>
																			<Link className={"active"} onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }}> <i className='fa fa-user-o' aria-hidden="true"></i><span>{'Company Profile'}</span></Link>
																		</li>
																		<li>
																			<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }} > <i className='fa fa-file-text-o' aria-hidden="true"></i><span>{'Post A Job'}</span></Link>
																		</li>
																		<li>
																			<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }} > <i className='fa fa-briefcase' aria-hidden="true"></i><span>{'Manage Jobs'}</span></Link>
																		</li>
																		<li>
																			<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }} > <i className='fa fa-id-card' aria-hidden="true"></i><span>{'Resume'}</span></Link>
																		</li>
																		{/* <li>
																		<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }} > <i className='fa-solid fa-user-group' aria-hidden="true"></i><span>{'Employer Team'}</span></Link>
																	</li> */}
																		{/* <li>
																		<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Please add company profile details' }); }}><i className="fa fa-key" aria-hidden="true"></i><span>{'Change Password'}</span></Link>
																	</li> */}
																		<li onClick={toggleLogOutModal} >
																			<Link to={"#"}> <i className="fa fa-sign-out" aria-hidden="true"></i><span>Log Out</span></Link>
																		</li>

																	</ul> : (

																		userData.roleId === 4 && showStatus === 'Cancel' ?


																			<ul>
																				<li>
																					<Link className={"active"} onClick={() => { setAlertState({ open: true, type: 'error', message: 'Your Account is Blocked.' }); }}> <i className='fa fa-user-o' aria-hidden="true"></i><span>{'Company Profile'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'error', message: 'Your Account is Blocked.' }); }} > <i className='fa fa-file-text-o' aria-hidden="true"></i><span>{'Post A Job'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'error', message: 'Your Account is Blocked.' }); }} > <i className='fa fa-briefcase' aria-hidden="true"></i><span>{'Manage Jobs'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'error', message: 'Your Account is Blocked.' }); }} > <i className='fa fa-id-card' aria-hidden="true"></i><span>{'Resume'}</span></Link>
																				</li>
																				{/* <li>
																				<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is Blocked.' }); }} > <i className='fa-solid fa-user-group' aria-hidden="true"></i><span>{'Employer Team'}</span></Link>
																			</li> */}
																				{/* <li>
																				<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is Blocked.' }); }}><i className="fa fa-key" aria-hidden="true"></i><span>{'Change Password'}</span></Link>
																			</li> */}
																				<li onClick={toggleLogOutModal} >
																					<Link to={"#"}> <i className="fa fa-sign-out" aria-hidden="true"></i><span>Log Out</span></Link>
																				</li>

																			</ul> :


																			<ul>
																				<li>
																					<Link className={"active"} onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }}> <i className='fa fa-user-o' aria-hidden="true"></i><span>{'Company Profile'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }} > <i className='fa fa-file-text-o' aria-hidden="true"></i><span>{'Post A Job'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }} > <i className='fa fa-briefcase' aria-hidden="true"></i><span>{'Manage Jobs'}</span></Link>
																				</li>
																				<li>
																					<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }} > <i className='fa fa-id-card' aria-hidden="true"></i><span>{'Resume'}</span></Link>
																				</li>
																				{/* <li>
																				<Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }} > <i className='fa-solid fa-user-group' aria-hidden="true"></i><span>{'Employer Team'}</span></Link>
																			</li> */}
																				{/* <li>
																            <Link onClick={() => { setAlertState({ open: true, type: 'info', message: 'Your Account is under review.' }); }}><i className="fa fa-key" aria-hidden="true"></i><span>{'Change Password'}</span></Link>
											                            </li> */}
																				<li onClick={toggleLogOutModal} >
																					<Link to={"#"}> <i className="fa fa-sign-out" aria-hidden="true"></i><span>Log Out</span></Link>
																				</li>

																			</ul>


																	)

																)


														}

													</div>
												</div>
											</div>
											<div className="col-xl-9 col-lg-8 m-b30">
												<div className="job-bx submit-resume">
													<div className="job-bx-title clearfix">
														<h5 className="font-weight-700 pull-left text-uppercase">Company Profile</h5>
														{/* <a href="company-profile.html" className="site-button right-arrow button-sm float-right">Back</a> */}
														{
															userData.roleId === 4 ? null :
																<Link className="site-button right-arrow button-sm float-right " onClick={() => setCompanyProfileModal(true)}>
																	<span>Edit</span>
																</Link>
														}

													</div>

													<div className="row m-b30">
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company Name</label>
																<p>{user.company_name}</p>
															</div>
														</div>

														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company Website</label>
																<p>{user.company_website}</p>
															</div>
														</div>


														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Comapany Category</label>
																<p>{user.company_category}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company CIN</label>
																<p>{user.company_cin}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company GstIn</label>
																<p>{user.company_gstin}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Description</label>
																<p>{user.description}</p>
															</div>
														</div>
													</div>

													<div className="job-bx-title clearfix">
														<h5 className="font-weight-700 pull-left text-uppercase">Contact Information</h5>
													</div>
													<div className="row m-b30">
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company Phone</label>
																<p>{user.company_phone}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Company Email</label>
																<p>{user.company_email}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>StreetAddress</label>
																<p>{user.company_streetAddress}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Pin Code</label>
																<p>{user.company_zip}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>City</label>
																<p>{user.company_city}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>State</label>
																<p>{user.company_state}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Country</label>
																<p>{user.company_country}</p>
															</div>
														</div>
													</div>

													<div className="job-bx-title clearfix">
														<h5 className="font-weight-700 pull-left text-uppercase">Social link</h5>
													</div>
													<div className="row">
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Facebook</label>
																<p>{user.facebook}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Twitter</label>
																<p>{user.twitter}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Google</label>
																<p>{user.google}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Linkedin</label>
																<p>{user.linkedIn}</p>
															</div>
														</div>
													</div>
													<div className="job-bx-title clearfix">
														<h6 className="font-weight-700 pull-left text-uppercase">RECRUITER DETAILS</h6>
													</div>

													<div className="row" >
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Recruiter Name</label>
																<p>{user.recruiter_name}</p>
																{/* <p>{userData.roleId === 4 ? companyData.name : (companyInfo.recruiter_name ? companyInfo.recruiter_name : companyData.recruiter_name)}</p> */}

															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Recruiter Email</label>
																{/* <p>{userData.roleId === 4 ? companyData.email : (companyInfo.recruiter_email ? companyInfo.recruiter_email : companyData.recruiter_email)}</p> */}
																<p>{user.recruiter_email}</p>
															</div>
														</div>
														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Recruiter Phone</label>
																{/* <p>{userData.roleId === 4 ? companyData.mobile : (companyInfo.recruiter_whatsapp ? companyInfo.recruiter_whatsapp : companyData.recruiter_whatsapp)}</p> */}
																<p>{user.recruiter_whatsapp}</p>
															</div>
														</div>

														<div className="col-lg-6 col-md-6">
															<div className="form-group">
																<label>Recruiter Role</label>
																{/* <p>{userData.roleId === 4 ? member.role : (companyInfo.recruiter_role ? companyInfo.recruiter_role : companyData.recruiter_role)}</p> */}
																<p>{user.recruiter_role}</p>
															</div>
														</div>
													</div>
													{/* <button type="submit" className="site-button m-b30">Update Setting</button> */}

												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

				}
			</div>


			<Modal show={companyProfileModal} onHide={setCompanyProfileModal} className="modal fade modal-bx-info editor">
				<div className="modal-dialog my-0 mx-0" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="EmploymentModalLongTitle">Company Profile</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setCompanyProfileModal(false); setShowError(false) }}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form>
								<div className="job-bx-title clearfix">
									<h6 className="font-weight-500 pull-left text-uppercase">Company Information</h6>
								</div>

								<div className="row m-b30">
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Company Name<span style={{ color: "red" }}> *</span></label>
											<input type='text' name='company_name' className='form-control' placeholder='Company name'
												value={user.company_name}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
												onChange={handleInputs} style={{ color: "#000000" }} />



											{user.company_name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyNameLimit}</span> : null}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Website <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name="company_website" className="form-control" placeholder="Website Link"
												value={user.company_website}
												onChange={handleInputs}
												// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
												style={{ color: "#000000" }}
											/>
											{user.company_website.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Company Category  <span style={{ color: 'red' }}>*</span></label>
											<Form.Control as="select" name="company_category" className="custom-select" style={{ height: "48px", color: "#000000" }} required
												value={user.company_category}
												onChange={handleInputs}

											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											>
												<option value='' disabled >--- Select Category/Industry ----</option>
												{
													categoryTypes.map((val) => {
														return (<React.Fragment key={val.id}>
															<option style={{ color: "#000000" }}>{val.category}</option>
														</React.Fragment>)
													})
												}
											</Form.Control>


											{user.company_category ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.category}</span> : null)}
										</div>

									</div>


									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Company CIN <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name='company_cin' className="form-control" placeholder="Company CIN"
												value={user.company_cin}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.company_cin ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cin}</span> : null)}
											{user.company_cin.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cinLimit}</span> : null}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Company GSTIN <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name='company_gstin' className="form-control" placeholder="Company GSTIN"
												value={user.company_gstin}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.company_gstin.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.gstLimit}</span> : null}
										</div>
									</div>

									<div className="col-lg-12 col-md-12 mb-2">
										<div className="form-group">
											<label>Description </label>
											<textarea name="description" className="form-control" required
												value={user.description}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											>
												{user.description.length > 250 ? (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null}
											</textarea>
										</div>
									</div>

								</div>
								<div className="job-bx-title clearfix">
									<h6 className="font-weight-700 pull-left text-uppercase">Contact Information</h6>
								</div>
								<div className="row m-b30">
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Company Phone <span style={{ color: 'red' }}>*</span></label>
											<input type="number" name="company_phone" className="form-control" placeholder="+91 9876543210"
												value={user.company_phone}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.company_phone ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.mobile}</span> : null)}
											{user.company_phone.length > 10 || user.company_phone.length < 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Comapany Email <span style={{ color: 'red' }}>*</span></label>
											<input type="email" name="company_email" className="form-control" placeholder="exemple@gmail.com" required
												value={user.company_email}
												onChange={handleInputs}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.company_email ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
											{user.company_email.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
											{user.company_email ? (EmailValidator.validate(user.company_email) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Street Address <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name="company_streetAddress" className="form-control" placeholder="Street Address" required
												value={user.company_streetAddress}

												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>

											{user.company_streetAddress.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyAddressLimit}</span> : null}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Pin Code <span style={{ color: 'red' }}>*</span></label>
											<input type="number" name="company_zip" className="form-control" placeholder="751024" required
												value={user.company_zip}
												onChange={handleInputs}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
												style={{ color: "#000000" }}
											/>

											{user.company_zip ? pincodeDirectory.lookup(user.company_zip).length > 0 ? (pincodeDirectory.lookup(user.company_zip)[0].stateName === 'ODISHA' ? null : <span style={{ color: 'red', fontSize: 11 }}>{user.company_zip} doesn't belong to Odisha</span>) : <span style={{ color: 'red', fontSize: 12 }}>{user.company_zip} doesn't belong to Odisha</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>City <span style={{ color: 'red' }}>*</span></label>
											<input list='ct' type="text" name="company_city" className="form-control" placeholder="City"
												value={user.company_city}
												onChange={handleInputs}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
												style={{ color: "#000000" }}
											/>
											<datalist id='ct' >
												{
													cityList.map((item, index) => {
														return (
															<option style={{ backgroundColor: 'blue', color: '#000000' }} key={index} >{item}</option>
														)
													})
												}
											</datalist>

											{user.company_city.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cityLimit}</span> : null}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>State <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name='company_state' className="form-control" placeholder='State'
												value={user.company_state}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>

										</div>

									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Country <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name="company_country" className="form-control" placeholder='Country'
												value={user.company_country}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
										</div>

									</div>
								</div>
								<div className="job-bx-title clearfix">
									<h6 className="font-weight-700 pull-left text-uppercase">Social link</h6>
								</div>
								<div className='row'>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Facebook</label>
											<input type="text" name="facebook" className="form-control" placeholder="https://www.facebook.com/"
												value={user.facebook}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.facebook.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Twitter</label>
											<input type="email" name="twitter" className="form-control" placeholder="https://www.twitter.com/"
												value={user.twitter}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.twitter.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Google</label>
											<input type="text" name="google" className="form-control" placeholder="https://www.google.com/"
												value={user.google}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.google.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Linkedin</label>
											<input type="email" name="linkedIn" className="form-control" placeholder="https://www.linkedin.com/"
												value={user.linkedIn}
												onChange={handleInputs}
												style={{ color: "#000000" }}
											// disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.linkedIn.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null}
										</div>
									</div>

								</div>
								<div className="job-bx-title clearfix">
									<h6 className="font-weight-700 pull-left text-uppercase">RECRUITER DETAILS</h6>
								</div>
								<div className='row'>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Recruiter Name <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name="recruiter_name" className="form-control" placeholder="Recruiter Name"
												value={user.recruiter_name}
												onChange={handleInputs}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.recruiter_name ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null)}
											{user.recruiter_name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label> Recruiter Email <span style={{ color: 'red' }}>*</span></label>
											<input type="email" name="recruiter_email" className="form-control" placeholder="Recruiter Email"
												value={user.recruiter_email}
												onChange={handleInputs}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.recruiter_email ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
											{user.recruiter_email.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
											{user.recruiter_email ? (EmailValidator.validate(user.recruiter_email) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Recruiter Phone <span style={{ color: 'red' }}>*</span></label>
											<input type="number" name="recruiter_whatsapp" className="form-control" placeholder="Recruiter Whatsapp"
												value={user.recruiter_whatsapp}
												onChange={handleInputs}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.recruiter_whatsapp.length !== 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>Role <span style={{ color: 'red' }}>*</span></label>
											<input type="text" name="recruiter_role" className="form-control" placeholder="Recruiter Role"
												value={user.recruiter_role}
												onChange={handleInputs}
												style={{ color: "#000000" }}
												disabled={(user.id && (companyData.status === 'In Review' || companyData.status === 'On Verification')) ? true : false}
											/>
											{user.recruiter_role ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.role}</span> : null)}
											{user.recruiter_role.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.roleLimit}</span> : null}
										</div>
									</div>
								</div>
							</form>


						</div>
						<div className="modal-footer">

							<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setCompanyProfileModal(false); setShowError(false) }}>Cancel</button>


							<button type="button" className="site-button Warning" onClick={UpdateHandler} >Update</button>
							{/* : (userData.status === 'In Review' ? <span style={{ color: 'red', fontSize: 12, fontWeight: 'bold', fontStyle: 'italic' }}>Your Account is under review</span> : <span style={{ color: 'red', fontSize: 12, fontWeight: 'bold', fontStyle: 'italic' }}>Your Account is under verification</span>)) */}

						</div>
					</div>
				</div>

			</Modal>

			<Modal
				show={showLogout}
				onHide={toggleLogOutModal}
				className="modal fade lead-form-modal"
				style={{ marginTop: "80px" }}
			>
				<div className="modal-dialog" role="document" >
					<div className="modal-content">
						<button
							type="button"
							className="close"
							style={{ marginRight: -7, marginTop: "2px" }}
							onClick={toggleLogOutModal}
						>
							<span aria-hidden="true" >&times;</span>
						</button>
						<div className="modal-body row m-a0 clearfix">
							<div
								className="col-lg-6 col-md-6 overlay-primary-dark d-flex p-a0"
								style={{
									backgroundImage: "url(images/background/bg3.jpg)",
									backgroundPosition: "center",
									backgroundSize: "cover",
								}}>
								<div className="form-info text-white align-self-center">
									<p className="m-t15">
										Get started with JobYard to receive latest job
										alerts based on your preferences.
									</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 p-a0">
								<div className="lead-form browse-job text-left">
									<h5
										style={{ textAlign: "center" }}
										className="m-t0 mb-5 font-16">
										Do You Want To Logout ?
									</h5>
									<div
										className="clearfix "
										style={{
											marginTop: 130,
											textAlign: "center",
											justifyItems: "center",
											marginRight: "45px",
										}}>
										<button
											style={{ borderRadius: 100, textAlign: "center" }}
											type="button"
											className=" btn btn-success mr-4 ml-5 "
											onClick={toggleLogOutModal}
										>
											No{" "}
										</button>
										<button
											style={{ borderRadius: 100 }}
											type="button"
											className=" btn btn-danger "
											onClick={handleLogout}
										>
											Yes{" "}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal show={imageModal} onHide={setImageModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog my-0 mx-0" role="document">
					<div className="modal-content">
						<div className="modal-header" >
							<h5 className="modal-title" id="RegionMaster">Image Upload</h5>
							<button type="button" className="close" onClick={() => { setImageModal(false) }} >
								<span aria-hidden="true" >&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{
								imageData.image ?
									<p className="text-center" style={{ color: 'red' }}> Are you sure to Update this Image ? </p> :
									<p className="text-center" style={{ color: 'red' }}> Are you sure to Upload this Image ? </p>

							}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" onClick={() => { setImageModal(false); setImage(null) }}>Cancel</button>
							{
								imageData.image ?
									<button type="button" className="btn btn-success" onClick={imageUpdateHandler}  >Update</button> :
									<button type="button" className="btn btn-success" onClick={uploadHandler}  >Upload</button>

							}
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default NewCompanyProfile
