import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
    loginStatus: false,
    role: 'none'
 }
 
 export const GlobalContext = createContext(initialState);
 
 export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
 
    // Actions for changing state
 
    function login(userRole) {
        dispatch({
            type: 'LOG_IN',
            payload: userRole
        });
    }
    function logout() {
        dispatch({
            type: 'LOG_OUT',
        });
    }
 
    return(
       <GlobalContext.Provider value = {{loginStatus : state.loginStatus, role: state.role, login, logout}}> 
         {children} 
       </GlobalContext.Provider>
    )
 }