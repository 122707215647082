import axios from 'axios';

const Candidate_Education = (payload) => axios.post('/candidate-education/add', payload);

const Candidate_Education_By_Id=(candidate_id)=>axios.get(`/candidate-education/`+candidate_id);

const Candidate_Education_Update=(candidate_id,payload)=>axios.put(`/candidate-education/`+candidate_id,payload);


export default { Candidate_Education,
                 Candidate_Education_By_Id,
                 Candidate_Education_Update
                
                };