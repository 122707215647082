import axios from "axios";

const imageUpload =(payload)=> axios.post('/upload-resume/add',payload);

const getImageUpload =(id)=> axios.get(`/upload-resume/${id}`);

const updateUploadImage =(id , payload)=>axios.put(`/upload-resume/edit/${id}`,payload)


export default{
  imageUpload,
  getImageUpload,
  updateUploadImage
}