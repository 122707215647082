import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Error from '../../constants/ErrorMessage';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import AlertMessage from "../../components/AlertMessage";
import { Oval, ThreeCircles } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import pincodeDirectory from 'india-pincode-lookup';
import { Country, State, City } from 'country-state-city';

// services
import CandidateBasicDetails from '../../services/CandidateBasicDetails';
import ResumeHeadline from '../../services/candidate_resume/ResumeHeadline';
import KeySkills from '../../services/candidate_resume/KeySkills';
import Employment from '../../services/candidate_resume/Employment';
import ItSkill from '../../services/candidate_resume/ItSkills';
import Project from '../../services/candidate_resume/Project';
import ProfileSummary from '../../services/candidate_resume/ProfileSummary';
import Education from '../../services/candidate_resume/Education';
import PersonalDetails from '../../services/candidate_resume/PersonalDetails';
import DesiredCareerProfile from '../../services/candidate_resume/DesiredCareerProfile';
import OnlineProfile from '../../services/candidate_resume/OnlineProfile';
import WorkSample from '../../services/candidate_resume/WorkSample';
import WhitePaper from '../../services/candidate_resume/WhitePaper';
import Presentation from '../../services/candidate_resume/Presentation';
import Patent from '../../services/candidate_resume/Patent';
import Certification from '../../services/candidate_resume/Certification';
import SkillMaster from '../../services/Master/SkillMaster';
import MonthMaster from '../../services/Master/MonthMaster';
import YearMaster from '../../services/Master/YearMaster';
import ExperienceMaster from '../../services/Master/ExperienceMaster';
import CandidateService from '../../services/CandidateProfile';
import Users from '../../services/Users';
import CandidateImage from '../../services/image/CandidateImage';
import ResumePdfView from './ResumePdfView';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Alert } from 'bootstrap';
import { colors } from '@mui/material';


var EmploymentIds;
var EmploymetsArray;
var DeleteEmp;
var minId
const MyResume = () => {
	const [resumeModal, setResumeModal] = useState(false);
	const [keySkillModal, setKeySkillModal] = useState(false);
	const [employmentModal, setEmploymentModal] = useState(false);
	const [employmentEditModal, setEmploymentEditModal] = useState(false);
	const [employmentDeleteModal, setEmploymentDeleteModal] = useState(false);
	const [employmentMapModal, setEmploymentMapModal] = useState(false);
	const [employmentMapEditModal, setEmploymentMapEditModal] = useState(false);
	const [matricEducation, setMatricEducation] = useState(false);
	const [postMatricEducation, setPostMatricEducation] = useState(false);
	const [ugEducation, setUgEducation] = useState(false);
	const [pgEducation, setPgEducation] = useState(false);
	const [phdEducation, setPhdEducation] = useState(false);
	const [itModal, setItModal] = useState(false);
	const [itEditModal, setItEditModal] = useState(false);
	const [itSkillDeleteModal, setItSkillDeleteModal] = useState(false);
	const [projectModal, setProjectModal] = useState(false);
	const [projectEditModal, setProjectEditModal] = useState(false);
	const [projectDeleteModal, setProjectDeleteModal] = useState(false);
	const [profileSummaryModal, setProfileSummaryModal] = useState(false);
	const [careerModal, setCareerModal] = useState(false);
	const [profileModal, setProfileModal] = useState(false);
	const [onlineProfileModal, setOnlineProfileModal] = useState(false);
	const [workSampleModal, setWorkSampleModal] = useState(false);
	const [whitePaperModal, setWhitePaperModal] = useState(false);
	const [presentationModal, setPresentationModal] = useState(false);
	const [patentModal, setPatentModal] = useState(false);
	const [certificationModal, setCertificationModal] = useState(false);
	const [deleteKeySkill, setDeleteSkill] = useState(false);
	const [selfLoader, setSelfLoader] = useState(false);

	const [countryNames, setCountryNames] = useState([]);
	const [stateNames, setStateNames] = useState([]);
	const [cityNames, setCityNames] = useState([]);


	const [resumeHeadline, setResumeHeadline] = useState({ resume_id: 0, candidate_id: 0, headline: "" });
	const [keySkill, setKeySkills] = useState({ keySkills_id: 0, candidate_id: 0, skills: "", skillsId: 0 });
	const [employments, setEmployments] = useState({ id: 0, employment_id: 0, candidate_id: 0, designation: "", organization: "", current_company: "", work_form: "", work_till: "", job_profile: "", status: '' });
	const [employmentMapping, setEmploymentMapping] = useState({ id: 0, current_company: "", work_form: "", work_till: "", job_profile: "", designation: "", });
	const [itSkill, setItSkill] = useState({ itSkills_id: 0, candidate_id: 0, it_skills: "", version: "", last_used: '', experience: "" });
	const [project, setProject] = useState({ project_id: 0, candidate_id: 0, title: "", education_or_employment: "", client_name: "", project_status: "", work_form: "", work_till: "", project_details: "" });
	const [profileSummary, setProfileSummary] = useState({ profileSummay_id: 0, candidate_id: 0, summary: "" });
	const [personalDetails, setPersonalDetails] = useState({ personalDetails_id: 0, candidate_id: 0, dob: '', gender: "", permanent_address: "", hometown: "", pincode: "", marital_status: "", passport_number: "", description: "", work_permit: "" });
	const [careerProfile, setCareerProfile] = useState({ careerProfile_id: 0, candidate_id: 0, industry: "", functional_area: "", Role: "", employment_type: "", job_type: "", preffered_shift: "", availability_to_join: "", expected_salary: "", country: "", state: "", city: "", });
	const [onlineProfile, setOnlineProfile] = useState({ onlinePrpfile_id: 0, candidate_id: 0, social_profile: "", url: "", description: "", });
	const [workSample, setWorkSample] = useState({ workSample_id: 0, candidate_id: 0, work_sample_title: '', url: '', duration_form: '', duration_to: '', description: '' });
	const [whitePaper, setWhitePaper] = useState({ whitePaper_id: 0, candidate_id: 0, title: "", url: "", publish_on: "", description: "" });
	const [presentations, setPresentations] = useState({ presentation_id: 0, candidate_id: 0, title: "", url: "", description: "" });
	const [patents, setPatents] = useState({ patent_id: 0, candidate_id: 0, title: "", url: "", patent_office: "", status: "", application_number: "", publish_on: "", description: "" });
	const [certifications, setCertifications] = useState({ certification_id: 0, candidate_id: 0, certification_name: "", certification_body: "", year_onable: "" });
	const [educations, setEducations] = useState({ education_id: 0, candidate_id: 0, school_board: "", school_passout_year: 0, college_board: "", college_passout_year: 0, graduation_university: "", graduation_course: "", graduation_passout_year: 0, pg_university: "", pg_course: "", pg_passout_year: 0, phd_university: "", phd_course: "", phd_passout_year: 0 });
	const [currentlyWorking, setCurrentlyWorking] = useState('No');

	const [basicData, setBasicData] = useState({});
	const [userData, setUserData] = useState({});
	const [skillData, setSkillData] = useState([]);
	const [candidateKeySkills, setCandidateKeySkills] = useState([]);

	const [alertState, setAlertState] = useState({});
	const [showError, setShowError] = useState(false);
	const [resumeLoader, setResumeLoader] = useState(false);
	const [keySkillLoader, setKeySkillLoader] = useState(false);
	const [employmentLoader, setEmploymentLoader] = useState(false);
	const [educationLoader, setEducationLoader] = useState(false);
	const [itLoader, setItLoader] = useState(false);
	const [projectLoader, setProjectLoader] = useState(false);
	const [profileSummaryLoader, setProfileSummaryLoader] = useState(false);
	const [careerLoader, setCareerLoader] = useState(false);
	const [profileLoader, setProfileLoader] = useState(false);
	const [onlineProfileLoader, setOnlineProfileLoader] = useState(false);
	const [workSampleLoader, setWorkSampleLoader] = useState(false);
	const [whitePaperLoader, setWhitePaperLoader] = useState(false);
	const [presentationLoader, setPresentationLoader] = useState(false);
	const [patentLoader, setPatentLoader] = useState(false);
	const [certificationLoader, setCertificationLoader] = useState(false);

	const [skillId, setSkillId] = useState(0);
	const [addId, setAddId] = useState();
	const [skillName, setSkillName] = useState('');
	const [deleteId, setDeleteId] = useState(0);
	const [editId, setEditId] = useState(0);
	const [viewSkill, setViewSkill] = useState('');

	const [candidateSkills, setCandidateSkills] = useState([]);
	const [experieceData, setExperienceData] = useState([]);
	const [candidateProject, setCandidateProject] = useState([]);
	const [candidateEmployment, setCandidateEmployment] = useState([]);

	const [year, setYear] = useState([]);
	const [loader, setLoader] = useState(false);


	const [image, setImage] = useState(null);
	const [imageData, setImageData] = useState({});
	const [imageModal, setImageModal] = useState(false);
	const [candidateImage, setCandidateImage] = useState([]);

	const [pdfViewModal, setPdfViewModal] = useState(false);
	const [fullscreen, setFullscreen] = useState(true);
	const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down']
	const [refresh, setRefresh] = useState(false);
	const userId = JSON.parse(localStorage.getItem('userId'))


	function alertClose(event, reason) {
		if (reason === "clickaway") {
			return;
		}
		setAlertState({ open: false });
	}

	const getImageByUserId = async () => {
		try {
			const res = await CandidateImage.getImageUpload(userId);
			setImageData(res.data);

		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getImageByUserId()
	}, [`${userId}`])

	const onImageChange = (img) => {
		setCandidateImage(img.target.files);
		if (img.target.files && img.target.files[0].name) {
			setImageModal(true);
			setImage(URL.createObjectURL(img.target.files[0]));
			console.log(img.target.files, "image onchange")
			console.log(img.target.files[0].name, "image file name")
		}
	}

	const uploadHandler = async () => {
		try {
			const formdata = new FormData();
			formdata.append('candidate_id', userId);
			formdata.append('file', candidateImage[0]);

			const res = await CandidateImage.imageUpload(formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},

			});
			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: "Candidate Profile picture Upload successfully " })
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	}

	const imageUpdateHandler = async () => {
		const ID = await imageData.id;

		try {
			const formdata = new FormData();
			formdata.append('file', candidateImage[0]);

			const res = await CandidateImage.updateUploadImage(ID, formdata, {
				headers: {
					"Content-type": "multipart/form-data",
				},
			});
			if (res.status === 200) {
				setAlertState({ open: true, type: "success", message: "Candidate Profile picture Update successfully " })
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const getBasicData = async () => {
		try {
			const res = await CandidateService.getCandidateById(userId);
			if (res.status === 200) {
				setBasicData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getUserData = async () => {
		try {
			const res = await Users.getUserById(userId);
			if (res.status === 200) {
				setUserData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}


	const getResumeHeadlineData = async () => {

		try {
			const res = await ResumeHeadline.getHeadlineById(userId);
			setResumeHeadline(res.data);
		}
		catch (error) {
			console.log(error);
		}
	}

	const getAllSkill = async () => {
		try {
			const res = await SkillMaster.getSkills();
			if (res.status === 200) {
				setSkillData(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getKeySkillData = async () => {
		try {
			const res = await KeySkills.getKeySkillByUserId(userId);
			setCandidateKeySkills(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getProfilesummaryData = async () => {
		try {
			const res = await ProfileSummary.Candidate_Profile_Summary_By_Id(userId);
			setProfileSummary(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getOnlineprofileData = async () => {
		try {
			const res = await OnlineProfile.Candidate_Online_Profile_By_Id(userId);
			setOnlineProfile(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getWorksampleData = async () => {
		try {
			const res = await WorkSample.Candidate_Work_Sample_By_Id(userId);
			setWorkSample(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getWhitepaperData = async () => {
		try {
			const res = await WhitePaper.Candidate_White_Paper_By_Id(userId);
			setWhitePaper(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getPresentationData = async () => {
		try {
			const res = await Presentation.Candidate_Presentation_By_Id(userId);
			setPresentations(res.data);

		} catch (error) {
			console.log(error);
		}
	}

	const getPatentData = async () => {
		try {
			const res = await Patent.Candidate_Patent_By_Id(userId);
			setPatents(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getCertificationData = async () => {

		try {
			const res = await Certification.Candidate_Certification_By_Id(userId);
			setCertifications(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getCareerprofileData = async () => {
		try {
			const res = await DesiredCareerProfile.Candidate_Career_By_Id(userId);
			setCareerProfile(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getPersonaldetailsData = async () => {
		try {
			const res = await PersonalDetails.Candidate_Personal_Details_By_Id(userId);
			setPersonalDetails(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getItSkillsData = async () => {

		try {
			const res = await ItSkill.Candidate_It_Sklill_By_Id(userId);
			setCandidateSkills(res.data);
		} catch (error) {

		}
	}

	const getProjectsData = async () => {

		try {
			const res = await Project.getProjectByUserId(userId);
			setCandidateProject(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getCandidateEmployment = async () => {
		try {
			const res = await Employment.getEmploymentByUserId(userId);

			if (res.status === 200) {
				setCandidateEmployment(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getEducationData = async () => {
		try {
			const res = await Education.Candidate_Education_By_Id(userId);
			setEducations(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		setLoader(true);
		getBasicData();
		getUserData();
		getResumeHeadlineData();
		getKeySkillData();
		getProfilesummaryData();
		getOnlineprofileData();
		getWorksampleData();
		getWhitepaperData();
		getPresentationData();
		getPatentData();
		getCertificationData();
		getCareerprofileData();
		getPersonaldetailsData();
		getItSkillsData();
		getProjectsData();
		getCandidateEmployment();
		getEducationData();
		setTimeout(() => {
			setLoader(false)
		}, 1500);
	}, [`${userId}`]);
	
	
	useEffect(() => {
		const fetchData = async () => {
			await getResumeHeadlineData();
			await getKeySkillData();
			await getProfilesummaryData();
			await getOnlineprofileData();
			await getWorksampleData();
			await getWhitepaperData();
			await getPresentationData();
			await getPatentData();
			await getCertificationData();
			await getCareerprofileData();
			await getPersonaldetailsData();
			await getItSkillsData();
			await getProjectsData();
			await getCandidateEmployment();
			await getEducationData();
		};
		fetchData();
	}, [refresh]);
	
	const triggerRefresh = () => {
		setRefresh(prev => !prev); // Toggle refresh state to trigger the effect
	};

	const getAllCountryStateCityData = async () => {
		try {
			setCountryNames(Country.getAllCountries());
			setStateNames(State.getAllStates());
			setCityNames(City.getAllCities());
		} catch (error) {
			console.log(error);
		}
	}
	const getAllExperience = async () => {
		try {
			const res = await ExperienceMaster.GetExperience();
			setExperienceData(res.data);
		} catch (error) {
			console.log(error)
		}
	}
	const getYears = async () => {
		try {
			const res = await YearMaster.Get_Year();
			setYear(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getAllSkill();
		getAllCountryStateCityData();
		getAllExperience();
		getYears();
	}, [])


	//  =================================== Resume Handler ==============================

	const resumeHeadlineOnCghange = (e) => {
		setResumeHeadline({ ...resumeHeadline, [e.target.name]: e.target.value });
	}

	const saveResumeHandler = async (e) => {

		setResumeLoader(true);
		e.preventDefault();
		try {
			if (!resumeHeadline.headline || resumeHeadline.headline.length > 250) {
				if (resumeHeadline.headline.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.resumeHeadlineLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.resumeHeadline });
				}
			} else {
				setShowError(true);

				const payload = {};
				payload.resume_id = userId;
				payload.candidate_id = userId;
				payload.headline = resumeHeadline.headline;

				if (payload.candidate_id && payload.resume_id && payload.headline) {
					const data = await ResumeHeadline.Headline(payload);

					if (data.status === 200) {
						
						setResumeModal(false);
						setAlertState({ open: true, type: 'success', message: 'Resume saved successfully' });
						setTimeout(() => {
							setResumeLoader(false);
						}, 1500);
						getResumeHeadlineData();
						triggerRefresh();
					} else {
						setAlertState({ open: true, type: 'error', message: Error.resumeHeadline });
						setResumeLoader(false);
					}

				} else {
					setAlertState({ open: true, type: 'error', message: Error.resumeHeadline });
					setResumeLoader(false);

				}
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: Error.resumeHeadline });
			}
		}
	}

	const updateResumeHandler = async (e) => {
		e.preventDefault();
		setShowError(true);

		try {

			if (!resumeHeadline.headline || resumeHeadline.headline.length > 250) {
				if (resumeHeadline.headline.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.resumeHeadlineLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.resumeHeadline });
				}
			} else {
				setResumeLoader(true);

				const { resume_id, candidate_id, headline } = resumeHeadline;
				const payload = { resume_id, candidate_id, headline };

				if (payload.headline) {
					const res = await ResumeHeadline.Headline_Update(userId, payload);

					if (res.status === 200) {
						setAlertState({ open: true, type: 'success', message: 'Resume Updated Successfully.' });
						setResumeHeadline({ headline: "" });
						setResumeModal(false);
						setShowError(false);
						setTimeout(() => {
							setResumeLoader(false);
						}, 1500);
						getResumeHeadlineData();
						triggerRefresh();
						return res;
					} else {
						setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
						setResumeLoader(false);
					}
				} else {
					setAlertState({ open: true, type: 'error', message: 'Please provide valid inputs.' });
					setResumeLoader(false);
				}
			}
		} catch (err) {
			setResumeLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		 getResumeHeadlineData();

	}


	//   ==================================================== key Skill Handler =======================================================

	const keySkillOnChange = (e) => {

		setKeySkills({
			...keySkill, [e.target.name]: e.target.value
		});

	}

	const keySkillHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {

			if (!keySkill.skills || keySkill.skills.length > 250) {
				if (keySkill.skills.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.keySkillLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.keySkill });
				}
			} else {
				const payload = {};
				payload.candidate_id = userId;
				payload.skills = keySkill.skills;
				payload.skills_id = keySkill.skillsId;
				if (payload.candidate_id && payload.skills) {
					setKeySkillLoader(true);
					const data = await KeySkills.Skill(payload);
					if (data.status === 200) {
						setKeySkills({
							skills: ''
						});
						setKeySkillModal(false);
						setShowError(false);
						setAlertState({ open: true, type: 'success', message: 'Key Skills saved successfully.' });
						getAllSkill();
						getKeySkillData();
						triggerRefresh();
						setTimeout(() => {
							setKeySkillLoader(false);
						}, 1000);
					}
					else {
						setAlertState({ open: true, type: 'error', message: 'Somethimng went wrong' });
					}
				} else {
					setAlertState({ open: true, type: 'error', message: "Please fill all input field(s)." });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const DeleteKeySkillHandler = async () => {
		setKeySkillLoader(true);
		try {
			const res = await KeySkills.deleteKeySkill(deleteId);
			if (res.status === 200) {
				setDeleteSkill(false);
				 getAllSkill();
				 getKeySkillData();
				 triggerRefresh();
				setTimeout(() => {
					setKeySkillLoader(false);
				}, 1000);
			}
		} catch (error) {
			console.log(error)
		}
		setTimeout(() => {
			setKeySkillLoader(false);
		}, 1000);
	}

	const deleteKeySkillHandler = async (Id) => {
		try {
			candidateKeySkills.find((itm) => {
				if (itm.keySkills_id === Id) {
					setViewSkill(itm.skills);
				}
			})
			setKeySkillModal(false);
			setDeleteSkill(true)
			setDeleteId(Id)
		} catch (error) {
			console.log(error)
		}
	}


	// ================================================  profile summary Handler ===================================================

	const profileSummaryOnChange = (e) => {
		setProfileSummary({ ...profileSummary, [e.target.name]: e.target.value });
	}

	const profileSummaryUpdateHandler = async (e) => {

		e.preventDefault();
		setShowError(true);
		try {
			const { profileSummay_id, candidate_id, summary } = profileSummary;
			const payload = { profileSummay_id, candidate_id, summary };

			if (!payload.summary || payload.summary.length > 250 || payload.summary.length < 50) {
				if (payload.summary.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.profileSummaryLimit });
				} else if (payload.summary) {
					setAlertState({ open: true, type: 'error', message: Error.minSummary });
				}
				else {
					setAlertState({ open: true, type: 'error', message: Error.profileSummary });
				}

			} else {
				setProfileSummaryLoader(true);
				const res = await ProfileSummary.Candidate_Profile_Summary_Update(userId, payload);
				if (res.status === 200) {
					setProfileSummaryModal(false);
					setAlertState({ open: true, type: 'success', message: 'Profile Summary Updated Successfully.' });
					getProfilesummaryData();
					setShowError(false);
					triggerRefresh();
					setTimeout(() => {
						setProfileSummaryLoader(false);
					}, 1000);

					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setProfileSummaryLoader(false);
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setProfileSummaryLoader(false);
	}

	const profileSummaryHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.profileSummay_id = userId;
			payload.summary = profileSummary.summary;
			if (!payload.summary || payload.summary.length > 250 || payload.summary.length < 50) {
				if (payload.summary.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.profileSummaryLimit });
				} else if (payload.summary) {
					setAlertState({ open: true, type: 'error', message: Error.minSummary });
				}
				else {
					setAlertState({ open: true, type: 'error', message: Error.profileSummary });
				}
			} else {
				setProfileSummaryLoader(true);
				const data = await ProfileSummary.Candidate_Profile_Summary(payload);
				if (data.status === 200) {
					setProfileSummaryModal(false);
					setAlertState({ open: true, type: 'success', message: 'Profile Summary saved successfully' });
					getProfilesummaryData();
					triggerRefresh();
					setTimeout(() => {
						setProfileSummaryLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setProfileSummaryLoader(false);
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setProfileSummaryLoader(false);
	}

	// ================================================= online profile Handler ========================================================

	const onlineProfileOnChange = (e) => {

		setOnlineProfile({ ...onlineProfile, [e.target.name]: e.target.value });
	}


	const onlineProfileHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.onlinePrpfile_id = userId;
			payload.candidate_id = userId;
			payload.social_profile = onlineProfile.social_profile;
			payload.url = onlineProfile.url;
			payload.description = onlineProfile.description;

			if (!payload.social_profile || payload.social_profile.length > 100) {
				if (payload.social_profile.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.socialProfileLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.socialProfile });
				}
			} else if (!payload.url || payload.url.length > 100) {
				if (payload.url.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			}
			//  else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {
				setOnlineProfileLoader(true);
				const data = await OnlineProfile.Candidate_Online_Profile(payload);

				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Online profile saved successfully' });
					setOnlineProfile({
						social_profile: "",
						url: "",
						description: "",
					});
					setOnlineProfileModal(false);
					setShowError(false);
					 getOnlineprofileData();
					 triggerRefresh();
					setTimeout(() => {
						setOnlineProfileLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const onlineProfileUpdate = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const {
				onlinePrpfile_id,
				candidate_id,
				social_profile,
				url,
				description,

			} = onlineProfile;

			const payload = {

				onlinePrpfile_id,
				candidate_id,
				social_profile,
				url,
				description,
			};

			if (!payload.social_profile || payload.social_profile.length > 100) {
				if (payload.social_profile.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.socialProfileLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.socialProfile });
				}
			} else if (!payload.url || payload.url.length > 100) {
				if (payload.url.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			} 
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {

				setOnlineProfileLoader(true);
				const res = await OnlineProfile.Candidate_Online_Profile_Update(userId, payload);

				if (res.status === 200) {
					setOnlineProfileModal(false);
					setAlertState({ open: true, type: 'success', message: 'Online Profile Updated Successfully.' });

					setOnlineProfile({
						social_profile: "",
						url: "",
						description: "",
					});
					setShowError(false);
					getOnlineprofileData();
					triggerRefresh();
					setTimeout(() => {
						setOnlineProfileLoader(false);
					}, 1000);
					return res;

				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	// ================================================== work sample handler =========================================================

	const workSampleOnChange = (e) => {
		setWorkSample({ ...workSample, [e.target.name]: e.target.value })
	}

	const workSampleHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.workSample_id = userId;
			payload.candidate_id = userId;
			payload.work_sample_title = workSample.work_sample_title;
			payload.url = workSample.url;
			payload.duration_form = workSample.duration_form;
			payload.duration_to = currentlyWorking === 'No' ? workSample.duration_to : "Present";
			payload.currently_working = currentlyWorking;
			payload.description = workSample.description;
			let dt = new Date();
			let d1 = new Date(payload.duration_form);
			let d2 = new Date(payload.duration_to);

			if (!payload.work_sample_title || payload.work_sample_title.length > 100) {
				if (payload.work_sample_title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			} 
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else if (!payload.duration_form || +d < +d1 || +d1 > +dt) {
				if (+d < +d1 || +d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			 else if (!payload.duration_to || +d2 < +d1 || +d < +d2 || +d2 > +dt) {
				if (+d2 < +d1 || +d < +d2 || +d2 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			else {
				setWorkSampleLoader(true);
				const data = await WorkSample.Candidate_Work_Sample(payload);
				if (data.status === 200) {
					setWorkSampleModal(false);
					setAlertState({ open: true, type: 'success', message: 'Work Sample saved successfully' });
					setShowError(false);
					 getWorksampleData();
					 triggerRefresh();
					setTimeout(() => {
						setWorkSampleLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}


	const workSampleProfileUpdate = async (e) => {
		e.preventDefault();
		setShowError(true)
		try {
			const {
				workSample_id,
				candidate_id,
				work_sample_title,
				url,
				duration_form,
				duration_to,
				description
			} = workSample

			const payload = {};
			payload.workSample_id = workSample_id;
			payload.candidate_id = candidate_id;
			payload.work_sample_title = work_sample_title;
			payload.url = url;
			payload.duration_form = duration_form;
			payload.currently_working = currentlyWorking;
			payload.duration_to = currentlyWorking === 'No' ? duration_to : "Present";
			payload.description = description;
			let dt = new Date();
			let d1 = new Date(payload.duration_form);
			let d2 = new Date(payload.duration_to);

			if (!payload.work_sample_title || payload.work_sample_title.length > 100) {
				if (payload.work_sample_title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			}
			//  else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else if (!payload.duration_form || +d < +d1 || +d1 > +dt) {
				if (+d < +d1 || +d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.duration_to || +d2 < +d1 || +d < +d2 || +d2 > +dt) {
				if (+d2 < +d1 || +d < +d2 || +d2 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			else {
				setWorkSampleLoader(true);

				const res = await WorkSample.Candidate_Work_Sample_Update(userId, payload);

				if (res.status === 200) {
					setWorkSampleModal(false);
					setAlertState({ open: true, type: 'success', message: 'Work Sample Updated Successfully.' });
					setShowError(false);
					 getWorksampleData();
					 triggerRefresh();
					setTimeout(() => {
						setWorkSampleLoader(false);
					}, 1000);
					return res;

				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}


	}


	// ============================================== white paper handler ================================================
	const whitePaperOnChange = (e) => {

		setWhitePaper({ ...whitePaper, [e.target.name]: e.target.value });
	}

	const whitePaperHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.whitePaper_id = userId;
			payload.candidate_id = userId;
			payload.title = whitePaper.title;
			payload.url = whitePaper.url;
			payload.publish_on = whitePaper.publish_on;
			payload.description = whitePaper.description;
			let dt = new Date();
			let d1 = new Date(payload.publish_on);
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			} else if (!payload.publish_on || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}

			}
			//  else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// }
			 else {
				setWhitePaperLoader(true);
				const data = await WhitePaper.Candidate_White_Paper(payload);
				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'White Paper saved successfully' });
					setWhitePaperModal(false);
					setShowError(false);
					setWhitePaper({
						whitePaper_id: 0,
						candidate_id: 0,
						title: "",
						url: "",
						publish_on: "",
						description: ""
					});
					 getWhitepaperData();
					 triggerRefresh();
					setTimeout(() => {
						setWhitePaperLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const whitePaperUpdateHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {

			const {
				whitePaper_id,
				candidate_id,
				title,
				url,
				publish_on,
				description
			} = whitePaper;

			const payload = {
				whitePaper_id,
				candidate_id,
				title,
				url,
				publish_on,
				description
			}
			let dt = new Date();
			let d1 = new Date(payload.publish_on);
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			} else if (!payload.publish_on || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}

			} 
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {
				setWhitePaperLoader(true);
				const res = await WhitePaper.Candidate_White_Paper_Update(userId, payload);

				if (res.status === 200) {
					setWhitePaperModal(false);
					setAlertState({ open: true, type: 'success', message: 'White Paper Updated Successfully.' });
					setShowError(false);
					setWhitePaper({
						whitePaper_id: 0,
						candidate_id: 0,
						title: "",
						url: "",
						publish_on: "",
						description: ""
					});
					 getWhitepaperData();
					 triggerRefresh();
					setTimeout(() => {
						setWhitePaperLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
	}


	// ======================================================== presentation Handler ============================================================
	const presentationOnChange = (e) => {

		setPresentations({ ...presentations, [e.target.name]: e.target.value });
	}

	const presentationsHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.presentation_id = userId;
			payload.candidate_id = userId;
			payload.title = presentations.title;
			payload.url = presentations.url;
			payload.description = presentations.description;
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			}
			//  else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// }
			 else {
				setPresentationLoader(true);
				const data = await Presentation.Candidate_Presentation(payload);
				if (data.status === 200) {
					setPresentationModal(false);
					setAlertState({ open: true, type: 'success', message: 'Presentation saved successfully' });
					setPresentations({
						title: "",
						url: "",
						description: ""
					});
					setShowError(false);
					 getPresentationData();
					 triggerRefresh();
					setTimeout(() => {
						setPresentationLoader(false);
					}, 1000);
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const presentationUpdate = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const {
				presentation_id,
				candidate_id,
				title,
				url,
				description
			} = presentations;

			const payload = {
				presentation_id,
				candidate_id,
				title,
				url,
				description
			};

			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} else if (!payload.url || payload.url.length > 250) {
				if (payload.url.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.url });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.URL });
				}
			} 
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {
				setPresentationLoader(true);
				const res = await Presentation.Candidate_Presentation_Update(userId, payload);
				if (res.status === 200) {
					setPresentationModal(false);
					setAlertState({ open: true, type: 'success', message: 'Presentation Updated Successfully.' });
					setPresentations({
						title: "",
						url: "",
						description: ""
					});
					setShowError(false);
					 getPresentationData();
					 triggerRefresh();
					setTimeout(() => {
						setPresentationLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
	}


	// ============================================== patent handler =============================================

	const patentsOnChange = (e) => {

		setPatents({ ...patents, [e.target.name]: e.target.value });
	}

	const patentsHandler = async (e) => {
		e.preventDefault();

		setShowError(true)
		try {
			const payload = {};
			payload.patent_id = userId;
			payload.candidate_id = userId;
			payload.title = patents.title;
			payload.url = patents.url;
			payload.patent_office = patents.patent_office;
			payload.status = patents.status;
			payload.application_number = patents.application_number;
			payload.publish_on = patents.publish_on;
			payload.description = patents.description;
			let dt = new Date();
			let d1 = new Date(payload.publish_on);

			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			}
			//  else if (!payload.url || payload.url.length > 250) {
			// 	if (payload.url.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.url });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.URL });
			// 	}
			// } else if (!payload.patent_office || payload.patent_office.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.patentOfficeLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.patentOffice });
			// 	}
			// } 
			else if (!payload.status) {
				setAlertState({ open: true, type: 'error', message: Error.status });
			} else if (!payload.application_number || payload.application_number.length > 100) {
				if (payload.application_number.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.applicationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.application });
				}
			}
			else if (!payload.publish_on || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// }
			 else {
				setPatentLoader(true);
				const data = await Patent.Candidate_Patent(payload);
				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Patent saved successfully' });
					setPatentModal(false);
					setShowError(false);
					setPatents({
						patent_id: 0,
						candidate_id: 0,
						title: "",
						url: "",
						patent_office: "",
						status: "",
						application_number: "",
						publish_on: "",
						description: ""
					})
					getPatentData();
					triggerRefresh();
					setTimeout(() => {
						setPatentLoader(false);
					}, 1000);

					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const patentUpdateUpdate = async (e) => {
		e.preventDefault();

		setShowError(true);

		try {
			const {
				patent_id,
				candidate_id,
				title,
				url,
				patent_office,
				status,
				application_number,
				publish_on,
				description
			} = patents

			const payload = {
				patent_id,
				candidate_id,
				title,
				url,
				patent_office,
				status,
				application_number,
				publish_on,
				description
			}
			let dt = new Date();
			let d1 = new Date(payload.publish_on);
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} 
			// else if (!payload.url || payload.url.length > 250) {
			// 	if (payload.url.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.url });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.URL });
			// 	}
			// } else if (!payload.patent_office || payload.patent_office.length > 250) {
			// 	if (payload.patent_office.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.patentOfficeLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.patentOffice });
			// 	}
			// }
			 else if (!payload.status) {
				setAlertState({ open: true, type: 'error', message: Error.status });
			} else if (!payload.application_number || payload.application_number.length > 100) {
				if (payload.application_number.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.applicationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.application });
				}
			}
			else if (!payload.publish_on || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {
				setPatentLoader(true);
				const res = await Patent.Candidate_Patent_Update(userId, payload);
				if (res.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Patent Updated Successfully.' });
					setPatentModal(false);
					setShowError(false);
					setPatents({
						patent_id: 0,
						candidate_id: 0,
						title: "",
						url: "",
						patent_office: "",
						status: "",
						application_number: "",
						publish_on: "",
						description: ""
					})
					getPatentData();
					triggerRefresh();
					setTimeout(() => {
						setPatentLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
	}

	//  ================================================  certification handler ================================================
	const certificationOnChange = (e) => {

		setCertifications({ ...certifications, [e.target.name]: e.target.value });
	}

	const certificationsHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const payload = {};
			payload.certification_id = userId;
			payload.candidate_id = userId;
			payload.certification_name = certifications.certification_name;
			payload.certification_body = certifications.certification_body;
			payload.year_onable = certifications.year_onable;
			let dt = new Date();
			let d1 = new Date(payload.year_onable);
			if (!payload.certification_name || payload.certification_name.length > 100) {
				if (payload.certification_name.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} 
			// else if (!payload.certification_body || payload.certification_body.length > 100) {
			// 	if (payload.certification_body.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.bodyLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.body });
			// 	}
			// } 
			else if (!payload.year_onable || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else {
				setCertificationLoader(true);
				const data = await Certification.Candidate_Certification(payload);
				if (data.status === 200) {
					setCertificationModal(false);
					setAlertState({ open: true, type: 'success', message: 'Certification saved successfully' });
					setShowError(false);
					getCertificationData();
					triggerRefresh();
					setCertifications({
						certification_id: 0,
						candidate_id: 0,
						certification_name: "",
						certification_body: "",
						year_onable: ""
					});
					setTimeout(() => {
						setCertificationLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setShowError(false);
	}


	const certificationUpdateHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {

			const {
				certification_id,
				candidate_id,
				certification_name,
				certification_body,
				year_onable

			} = certifications;

			const payload = {
				certification_id,
				candidate_id,
				certification_name,
				certification_body,
				year_onable
			};
			let dt = new Date();
			let d1 = new Date(payload.year_onable);
			if (!payload.certification_name || payload.certification_name.length > 100) {
				if (payload.certification_name.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} 
			// else if (!payload.certification_body || payload.certification_body.length > 100) {
			// 	if (payload.certification_body.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.bodyLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.body });
			// 	}
			// } 
			else if (!payload.year_onable || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else {
				setCertificationLoader(true);
				const res = await Certification.Candidate_Certification_Update(userId, payload);

				if (res.status === 200) {
					setCertificationModal(false);
					setAlertState({ open: true, type: 'success', message: 'Certification Updated Successfully.' });
					setCertifications({
						certification_id: 0,
						candidate_id: 0,
						certification_name: "",
						certification_body: "",
						year_onable: ""
					});
					setShowError(false);
					getCertificationData();
					triggerRefresh();
					setTimeout(() => {
						setCertificationLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setCertificationLoader(false);
	}

	// =============================================================  career profile handler ========================================================
	const careerProfileOnChange = (e) => {

		setCareerProfile({ ...careerProfile, [e.target.name]: e.target.value });
	}

	const careerProfileHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const payload = {};
			payload.careerProfile_id = userId;
			payload.candidate_id = userId;
			payload.industry = careerProfile.industry;
			payload.functional_area = careerProfile.functional_area;
			payload.Role = careerProfile.Role;
			payload.employment_type = careerProfile.employment_type;
			payload.job_type = careerProfile.job_type;
			payload.preffered_shift = careerProfile.preffered_shift;
			payload.availability_to_join = careerProfile.availability_to_join;
			payload.expected_salary = careerProfile.expected_salary;
			payload.country = 'India';
			payload.state = 'Odisha';
			payload.city = careerProfile.city;
			let dt = new Date();
			let d1 = new Date(payload.availability_to_join);

			if (!payload.industry) {
				setAlertState({ open: true, type: 'error', message: Error.industry });
			}
			//  else if (!payload.functional_area) {
			// 	setAlertState({ open: true, type: 'error', message: Error.functionalArea });
			// }
			 else if (!payload.Role) {
				setAlertState({ open: true, type: 'error', message: Error.role });
			} 
			// else if (!payload.employment_type) {
			// 	setAlertState({ open: true, type: 'error', message: Error.employmentType });
			// }
			 else if (!payload.job_type) {
				setAlertState({ open: true, type: 'error', message: Error.jobType });
			}
			//  else if (!payload.preffered_shift) {
			// 	setAlertState({ open: true, type: 'error', message: Error.shift });
			// }
			 else if (!payload.availability_to_join || +dt > +d1) {
				if (+dt > +d1) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			//  else if (!payload.expected_salary) {
			// 	setAlertState({ open: true, type: 'error', message: Error.Salary });
			// } 
			// else if (!payload.city) {
			// 	setAlertState({ open: true, type: 'error', message: Error.city });
			// }
			 else {
				setCareerLoader(true);
				const data = await DesiredCareerProfile.Candidate_Career(payload);
				if (data.status === 200) {
					setCareerModal(false);
					setAlertState({ open: true, type: 'success', message: 'Desired Career saved successfully' });
					getCareerprofileData();
					triggerRefresh();
					setTimeout(() => {
						setCareerLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setCareerLoader(false);
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setCareerLoader(false);
	}


	const careerProfileUpdateHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {

			const {
				careerProfile_id,
				candidate_id,
				industry,
				functional_area,
				Role,
				employment_type,
				job_type,
				preffered_shift,
				availability_to_join,
				expected_salary,
				country,
				state,
				city
			} = careerProfile;

			const payload = {
				careerProfile_id,
				candidate_id,
				industry,
				functional_area,
				Role,
				employment_type,
				job_type,
				preffered_shift,
				availability_to_join,
				expected_salary,
				country,
				state,
				city
			}
			let dt = new Date();
			let d1 = new Date(payload.availability_to_join);

			if (!payload.industry) {
				setAlertState({ open: true, type: 'error', message: Error.industry });
			} else if (!payload.functional_area) {
				setAlertState({ open: true, type: 'error', message: Error.functionalArea });
			} else if (!payload.Role) {
				setAlertState({ open: true, type: 'error', message: Error.role });
			} else if (!payload.employment_type) {
				setAlertState({ open: true, type: 'error', message: Error.employmentType });
			} else if (!payload.job_type) {
				setAlertState({ open: true, type: 'error', message: Error.jobType });
			} else if (!payload.preffered_shift) {
				setAlertState({ open: true, type: 'error', message: Error.shift });
			} else if (!payload.availability_to_join || +dt > +d1) {
				if (+dt > +d1) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.expected_salary) {
				setAlertState({ open: true, type: 'error', message: Error.Salary });
			} else if (!payload.city) {
				setAlertState({ open: true, type: 'error', message: Error.city });
			} else {
				setCareerLoader(true);
				const res = await DesiredCareerProfile.Candidate_Career_Update(userId, payload);
				if (res.status === 200) {
					setCareerModal(false);
					setAlertState({ open: true, type: 'success', message: 'Desired Career Updated Successfully.' });
					getCareerprofileData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setCareerLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setCareerLoader(false);
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setCareerLoader(false);

	}



	// =========================================================== personal details handler =====================================================================

	const personalDetailsOnChange = (e) => {

		setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
	}

	const personalDetailsHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const payload = {};
			payload.personalDetails_id = userId;
			payload.candidate_id = userId;
			payload.dob = personalDetails.dob;
			payload.gender = personalDetails.gender;
			payload.permanent_address = personalDetails.permanent_address;
			payload.hometown = personalDetails.hometown;
			payload.pincode = personalDetails.pincode;
			payload.marital_status = personalDetails.marital_status;
			payload.description = personalDetails.description;
			payload.work_permit = personalDetails.work_permit;
			payload.passport_number = personalDetails.passport_number;
			let dt = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
			let d1 = new Date(payload.dob);
			if (!payload.dob || +d1 > +dt) {
				if (+d1 > +dt) {
					setAlertState({ open: true, type: 'error', message: Error.ageLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}

			} else if (!payload.gender) {
				setAlertState({ open: true, type: 'error', message: Error.gender });
			} else if (!payload.permanent_address || payload.permanent_address.length > 250) {
				if (payload.permanent_address.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.addressLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.address });
				}
			} else if (!payload.pincode || payload.pincode.length !== 6 || (pincodeDirectory.lookup(payload.pincode).length === 0) || pincodeDirectory.lookup(payload.pincode)[0].stateName !== 'ODISHA') {
				if (payload.pincode.length !== 6) {
					setAlertState({ open: true, type: 'error', message: Error.invalidPin });
				} else if (pincodeDirectory.lookup(payload.pincode).length === 0) {
					setAlertState({ open: true, type: 'error', message: `${payload.pincode} doesn't belong to Odisha` });
				}
				else if (pincodeDirectory.lookup(payload.pincode)[0].stateName !== 'ODISHA') {
					setAlertState({ open: true, type: 'error', message: `${payload.pincode} doesn't belong to Odisha` });
				}
				else {
					setAlertState({ open: true, type: 'error', message: Error.pincode });
				}
			} else if (!payload.hometown || payload.hometown.length > 250) {
				if (payload.hometown.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.cityLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.city });
				}
			} else if (!payload.marital_status) {
				setAlertState({ open: true, type: 'error', message: Error.maritalStatus });
			}
			//  else if (!payload.passport_number || payload.passport_number.length > 100) {
			// 	if (payload.passport_number.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.passportLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.passport });
			// 	}
			// }
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } else if (!payload.work_permit) {
			// 	setAlertState({ open: true, type: 'error', message: Error.country });
			// } 
			else {
				setProfileLoader(true)
				const data = await PersonalDetails.Candidate_Personal_Details(payload);
				if (data.status === 200) {
					setProfileModal(false);
					setAlertState({ open: true, type: 'success', message: 'Personal Details saved successfully' });
					getPersonaldetailsData();
					triggerRefresh();
					setTimeout(() => {
						setProfileLoader(false);
					}, [1000])
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setProfileLoader(false);
				}
				setProfileLoader(false)
			}

		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setProfileLoader(false)
	}

	const personalDetailUpdateHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const {
				personalDetails_id,
				candidate_id,
				dob,
				gender,
				permanent_address,
				hometown,
				pincode,
				marital_status,
				passport_number,
				description,
				work_permit
			} = personalDetails;
			const payload = {
				personalDetails_id,
				candidate_id,
				dob,
				gender,
				permanent_address,
				hometown,
				pincode,
				marital_status,
				passport_number,
				description,
				work_permit
			}
			if (!payload.dob) {
				setAlertState({ open: true, type: 'error', message: Error.date });
			} else if (!payload.gender) {
				setAlertState({ open: true, type: 'error', message: Error.gender });
			} else if (!payload.permanent_address || payload.permanent_address.length > 250) {
				if (payload.permanent_address.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.addressLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.address });
				}
			} else if ((pincodeDirectory.lookup(payload.pincode).length === 0) || pincodeDirectory.lookup(payload.pincode)[0].stateName !== 'ODISHA') {

				if (pincodeDirectory.lookup(payload.pincode).length === 0) {
					setAlertState({ open: true, type: 'warning', message: `${payload.pincode} doesn't belong to Odisha` });
				}
				else if (pincodeDirectory.lookup(payload.pincode)[0].stateName !== 'ODISHA') {
					setAlertState({ open: true, type: 'warning', message: `${payload.pincode} doesn't belong to Odisha` });

				}
			} else if (!payload.hometown || payload.hometown.length > 250) {
				if (payload.hometown.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.cityLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.city });
				}
			} else if (!payload.marital_status) {
				setAlertState({ open: true, type: 'error', message: Error.maritalStatus });
			}
			//  else if (!payload.passport_number || payload.passport_number.length > 100) {
			// 	if (payload.passport_number.length > 100) {
			// 		setAlertState({ open: true, type: 'error', message: Error.passportLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.passport });
			// 	}

			// } 
			// else if (!payload.description || payload.description.length > 250) {
			// 	if (payload.description.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } else if (!payload.work_permit) {
			// 	setAlertState({ open: true, type: 'error', message: Error.country });
			// }
			 else {
				setProfileLoader(true);
				const res = await PersonalDetails.Candidate_Personal_Details_Update(userId, payload);
				if (res.status === 200) {
					setProfileModal(false);
					setAlertState({ open: true, type: 'success', message: 'Personal Details Updated Successfully.' });
					setShowError(false);
					getPersonaldetailsData();
					triggerRefresh();
					setTimeout(() => {
						setProfileLoader(false);
					}, [1000])
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setProfileLoader(false);
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setProfileLoader(false);
	}


	// ========================================================== It Skill Handler ==================================================================
	const itSkillOnChange = (e) => {

		setItSkill({ ...itSkill, [e.target.name]: e.target.value });
	}

	const itSkillHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		const userId = JSON.parse(localStorage.getItem('userId'))
		try {

			const payload = {};
			payload.itSkills_id = 0;
			payload.candidate_id = userId;
			payload.it_skills = itSkill.it_skills;
			payload.version = itSkill.version;
			payload.last_used = itSkill.last_used;
			payload.experience = itSkill.experience;
			let d = new Date();
			let d1 = new Date(payload.last_used);
			if (!payload.it_skills || payload.it_skills.length > 100) {
				if (payload.it_skills.length > 100) {
					setAlertState({ open: true, type: "error", message: Error.skillLimit })
				} else {
					setAlertState({ open: true, type: "error", message: Error.skill })
				}
			}
			// else if (!payload.version || payload.version.length > 100) {
			// 	if (payload.version.length > 100) {
			// 		setAlertState({ open: true, type: "error", message: Error.versionLimit })
			// 	} else {
			// 		setAlertState({ open: true, type: "error", message: Error.version })
			// 	}
			// }
			//  else if (!payload.last_used || +d < +d1) {
			// 	if (+d < +d1) {
			// 		setAlertState({ open: true, type: "error", message: Error.invalidDate })
			// 	} else {
			// 		setAlertState({ open: true, type: "error", message: Error.date })
			// 	}
			// }
			else if (!payload.experience) {
				setAlertState({ open: true, type: "error", message: Error.experience })
			} else {
				setItLoader(true);
				const data = await ItSkill.Candidate_It_Sklill(payload);
				if (data.status === 200) {
					setItModal(false);
					setShowError(false);
					setAlertState({ open: true, type: "success", message: "It Skill saved successfully." });
					setItSkill({
						itSkills_id: 0,
						candidate_id: 0,
						it_skills: "",
						version: "",
						last_used: '',
						experience: ""
					})
					getItSkillsData();
					triggerRefresh();
					setTimeout(() => {
						setItLoader(false);
					}, 1500);
					return data;
				} else {
					setAlertState({ open: true, type: "error", message: "Something went wrong." });
					setItLoader(false);
				}
			}

		} catch (err) {
			setItLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}


	const editSkill = async (id) => {
		try {
			candidateSkills.find((val) => {
				if (val.itSkills_id === id) {
					setItSkill(val)
					setSkillId(id);
					setItEditModal(true)
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

	const itSkillUpdateHandler = async (e) => {
		e.preventDefault();

		setShowError(true)
		try {

			const {
				itSkills_id,
				candidate_id,
				it_skills,
				version,
				last_used,
				experience,
			} = itSkill;
			const payload = {
				itSkills_id,
				candidate_id,
				it_skills,
				version,
				last_used,
				experience,
			};
			let d = new Date();
			let d1 = new Date(payload.last_used);
			if (!payload.it_skills || payload.it_skills.length > 100) {
				if (payload.it_skills.length > 100) {
					setAlertState({ open: true, type: "error", message: Error.skillLimit })
				} else {
					setAlertState({ open: true, type: "error", message: Error.skill })
				}
			} 
			// else if (!payload.version || payload.version.length > 100) {
			// 	if (payload.version.length > 100) {
			// 		setAlertState({ open: true, type: "error", message: Error.versionLimit })
			// 	} else {
			// 		setAlertState({ open: true, type: "error", message: Error.version })
			// 	}
			// } else if (!payload.last_used || +d < +d1) {
			// 	if (+d < +d1) {
			// 		setAlertState({ open: true, type: "error", message: Error.invalidDate })
			// 	} else {
			// 		setAlertState({ open: true, type: "error", message: Error.date })
			// 	}
			// }
			 else if (!payload.experience) {
				setAlertState({ open: true, type: "error", message: Error.experience })
			} else {
				setItLoader(true);
				const res = await ItSkill.Candidate_It_Sklill_Update(skillId, payload);
				if (res.status === 200) {
					setItEditModal(false);
					setAlertState({ open: true, type: 'success', message: 'It Skill Updated Successfully.' });
					setItSkill({
						itSkills_id: 0,
						candidate_id: 0,
						it_skills: "",
						version: "",
						last_used: '',
						experience: ""
					})
					setShowError(false);
					getItSkillsData();
					triggerRefresh();
					setTimeout(() => {
						setItLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setItLoader(false);
				}
			}
		} catch (err) {
			setItLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}

	const deleteSkill = async (itm) => {
		try {
			setSkillName(itm.it_skills);
			setSkillId(itm.itSkills_id)
			setItSkillDeleteModal(true)

		} catch (error) {
			console.log(error)
		}
	}

	const deleteHandler = async () => {

		try {
			setItLoader(true);
			const res = await ItSkill.deleteCandidateSkill(skillId);
			if (res.status === 200) {
				setItSkillDeleteModal(false);
				setAlertState({ open: true, type: 'success', message: 'Data Deleted Successfully.' });
				getItSkillsData();
				triggerRefresh();
				setTimeout(() => {
					setItLoader(false);
				}, 1000);
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setItLoader(false);
		}, 1000);
	}


	// ==========================================================  project Handler ============================================================

	const projectOnChange = (e) => {

		setProject({ ...project, [e.target.name]: e.target.value });
	}

	const projectHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {
			const payload = {};
			payload.candidate_id = userId;
			payload.title = project.title;
			payload.education_or_employment = project.education_or_employment;
			payload.client_name = project.client_name;
			payload.project_status = project.project_status;
			payload.work_form = project.work_form;
			payload.work_till = project.project_status === 'Finished' ? project.work_till : 'Present';
			payload.project_details = project.project_details;
			let d = new Date();
			let d1 = new Date(payload.work_form);
			let d2 = new Date(payload.work_till);
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			} 
			// else if (!payload.education_or_employment) {
			// 	setAlertState({ open: true, type: 'error', message: Error.tag });
			// } else if (!payload.client_name || payload.client_name.length > 30) {
			// 	if (payload.client_name.length > 30) {
			// 		setAlertState({ open: true, type: 'error', message: Error.nameLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.name });
			// 	}
			// }
			 else if (!payload.project_status) {
				setAlertState({ open: true, type: 'error', message: Error.status });
			} else if (!payload.work_form || +d < +d1) {
				if (+d < +d1) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.work_till || +d2 < +d1 || +d < +d2) {
				if (+d2 < +d1 || +d < +d2) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} 
			// else if (!payload.project_details || payload.project_details.length > 250) {
			// 	if (payload.project_details.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {

				const data = await Project.addProject(payload);
				if (data.status === 200) {

					setProjectModal(false);
					setAlertState({ open: true, type: 'success', message: "Project Added Successfully." });
					setProjectLoader(true);
					getProjectsData();
					triggerRefresh();
					setShowError(false);
					setProject({
						project_id: 0,
						candidate_id: 0,
						title: "",
						education_or_employment: "",
						client_name: "",
						project_status: "",
						work_form: "",
						work_till: '',
						project_details: ""
					})
					setTimeout(() => {
						setProjectLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: "Something went wrong." });
					setProjectLoader(false);
				}
			}
		} catch (err) {
			setProjectLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
	}


	const projectUpdateHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		try {

			const payload = {};
			payload.candidate_id = userId;
			payload.title = project.title;
			payload.education_or_employment = project.education_or_employment;
			payload.client_name = project.client_name;
			payload.project_status = project.project_status;
			payload.work_form = project.work_form;
			payload.work_till = project.project_status === 'Finished' ? project.work_till : 'Present';
			payload.project_details = project.project_details;
			let d = new Date();
			let d1 = new Date(payload.work_form);
			let d2 = new Date(payload.work_till);
			if (!payload.title || payload.title.length > 100) {
				if (payload.title.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.titleLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.title });
				}
			}
			//  else if (!payload.education_or_employment) {
			// 	setAlertState({ open: true, type: 'error', message: Error.tag });
			// } else if (!payload.client_name || payload.client_name.length > 30) {
			// 	if (payload.client_name.length > 30) {
			// 		setAlertState({ open: true, type: 'error', message: Error.nameLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.name });
			// 	}
			// } 
			else if (!payload.project_status) {
				setAlertState({ open: true, type: 'error', message: Error.status });
			} else if (!payload.work_form || +d < +d1) {
				if (+d < +d1) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.work_till || +d2 < +d1 || +d < +d2) {
				if (+d2 < +d1 || +d < +d2) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			//  else if (!payload.project_details || payload.project_details.length > 250) {
			// 	if (payload.project_details.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.description });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.Description });
			// 	}
			// } 
			else {
				setSelfLoader(true);
				const res = await Project.updateProject(editId, payload);
				if (res.status === 200) {
					setProjectEditModal(false);
					setAlertState({ open: true, type: 'success', message: 'Data Updated Successfully.' });
					setShowError(false);
					getProjectsData();
					triggerRefresh();
					setProject({
						project_id: 0,
						candidate_id: 0,
						title: "",
						education_or_employment: "",
						client_name: "",
						project_status: "",
						work_form: "",
						work_till: '',
						project_details: ""
					})
					setTimeout(() => {
						setSelfLoader(false);
					}, 1500);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setSelfLoader(false);
				}
			}
		} catch (err) {
			setSelfLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
	}


	const projectDeleteHandler = async (Id) => {
		try {
			candidateProject.find((val) => {
				if (val.project_id === Id) {
					setProject(val);
					setDeleteId(Id);
					setProjectDeleteModal(true);
				}
			})

		} catch (error) {
			console.log(error);
		}
	}

	const DeleteProject = async () => {
		try {
			setSelfLoader(true);
			const res = await Project.deleteProject(deleteId);
			if (res.status === 200) {
				setProjectDeleteModal(false);
				getProjectsData();
				triggerRefresh();
				setTimeout(() => {
					setSelfLoader(false);
				}, 1000);
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setSelfLoader(false);
		}, 1500);
	}

	const projectEdit = async (Id) => {
		try {
			candidateProject.find((val) => {
				if (val.project_id === Id) {
					setProject(val);
					setEditId(Id);
					setProjectEditModal(true);

				}
			})
		} catch (error) {
			console.log(error)
		}
	}


	// =============================================================  Employment Handler ==================================================================

	const employmentOnChange = (e) => {

		setEmployments({ ...employments, [e.target.name]: e.target.value });
	}

	const employmentMappingOnChange = (e) => {
		setEmploymentMapping({ ...employmentMapping, [e.target.name]: e.target.value });
	}

	const employmentHandler = async (e) => {
		e.preventDefault();
		setShowError(true)
		try {

			const payload = {};
			payload.employment_id = new Date().toString();
			payload.candidate_id = userId;
			payload.status = 'Show';
			payload.job_profile = employments.job_profile;
			payload.designation = employments.designation;
			payload.current_company = employments.current_company;
			payload.organization = employments.organization;
			payload.work_form = employments.work_form;
			payload.work_till = employments.current_company === 'no' ? employments.work_till : 'Present';
			let d = new Date();
			let d1 = new Date(payload.work_form);
			let d2 = new Date(payload.work_till);
			if (!payload.designation || payload.designation.length > 100) {
				if (payload.designation.length) {
					setAlertState({ open: true, type: 'error', message: Error.designationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.designation });
				}
			} else if (!payload.organization || payload.organization.length > 100) {
				if (payload.organization.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.organizationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.organization });
				}
			} else if (!payload.current_company) {
				setAlertState({ open: true, type: 'error', message: Error.currentCompany });
			} else if (!payload.work_form || +d1 > +d) {
				if (+d1 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.work_till || +d1 > +d2 || +d2 > +d) {
				if (+d1 > +d2 || +d2 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			// else if (!payload.job_profile || payload.job_profile.length > 250) {
			// 	if (payload.job_profile.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.descriptionLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.jobProfile });
			// 	}
			// }
			 else {
				setEmploymentLoader(true);
				const data = await Employment.addEmployment(payload);
				if (data.status === 200) {
					setEmploymentModal(false);
					setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
					setEmployments({
						employment_id: 0,
						candidate_id: 0,
						designation: "",
						organization: "",
						current_company: "",
						work_form_year: 0,
						work_form_month: "",
						work_till_year: 0,
						work_till_month: "",
						job_profile: "",
						total_experience: ""
					});
					setShowError(false);
					getCandidateEmployment();
					triggerRefresh();
					setTimeout(() => {
						setEmploymentLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					setEmploymentLoader(false);
				}
			}
		} catch (err) {
			setEmploymentLoader(false);

			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}
		setShowError(true)
	}


	const employmentUpdateHandler = async (e) => {
		e.preventDefault();
		setShowError(true);
		try {
			const payload = {};
			payload.candidate_id = employments.candidate_id;
			payload.status = employments.status;
			payload.employment_id = employments.employment_id;
			payload.job_profile = employments.job_profile;
			payload.designation = employments.designation;
			payload.current_company = employments.current_company;
			payload.organization = employments.organization;
			payload.work_form = employments.work_form;
			payload.work_till = employments.current_company === 'no' ? employments.work_till : 'Present';
			let d = new Date();
			let d1 = new Date(payload.work_form);
			let d2 = new Date(payload.work_till);
			if (!payload.designation || payload.designation.length > 100) {
				if (payload.designation.length) {
					setAlertState({ open: true, type: 'error', message: Error.designationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.designation });
				}
			} else if (!payload.organization || payload.organization.length > 100) {
				if (payload.organization.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.organizationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.organization });
				}
			} else if (!payload.current_company) {
				setAlertState({ open: true, type: 'error', message: Error.currentCompany });
			} else if (!payload.work_form || +d1 > +d) {
				if (+d1 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.work_till || (!payload.work_till === 'Present' && +d1 > +d2 || +d2 > +d) || +d1 > +d2 || +d2 > +d) {
				if ((!payload.work_till === 'Present' && +d1 > +d2 || +d2 > +d) || +d1 > +d2 || +d2 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			// else if (!payload.job_profile || payload.job_profile.length > 250) {
			// 	if (payload.job_profile.length > 250) {
			// 		setAlertState({ open: true, type: 'error', message: Error.descriptionLimit });
			// 	} else {
			// 		setAlertState({ open: true, type: 'error', message: Error.jobProfile });
			// 	}
			// }
			 else {
				setEmploymentLoader(true);
				const res = await Employment.updateEmployment(editId, payload);

				if (res.status === 200) {
					setEmploymentEditModal(false);
					setEmploymentMapEditModal(false);
					setEmploymentMapModal(false);
					setAlertState({ open: true, type: 'success', message: 'Data Updated Successfully.' });
					setEmployments({
						employment_id: 0,
						candidate_id: 0,
						designation: "",
						organization: "",
						current_company: "",
						work_form_year: 0,
						work_form_month: "",
						work_till_year: 0,
						work_till_month: "",
						job_profile: "",
						total_experience: ""
					});
					setEmploymentMapping({
						id: 0,
						employment_id: 0,
						designation: "",
						current_company: "",
						work_form_year: 0,
						work_form_month: "",
						work_till_year: 0,
						work_till_month: "",
						job_profile: "",
					});
					setShowError(false);
					getCandidateEmployment();
					triggerRefresh();
					setTimeout(() => {
						setEmploymentLoader(false);
					}, 1000);
					return res;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}




	const employmentMappingHandler = async (e) => {
		e.preventDefault();

		setShowError(Error);
		try {
			const payload = {};
			payload.employment_id = addId;
			payload.candidate_id = userId;
			payload.status = 'Hide'
			payload.organization = employments.organization;
			payload.job_profile = employmentMapping.job_profile;
			payload.designation = employmentMapping.designation;
			payload.current_company = employmentMapping.current_company;
			payload.work_form = employmentMapping.work_form;
			payload.work_till = employmentMapping.current_company === 'no' ? employmentMapping.work_till : 'Present';
			let d = new Date();
			let d1 = new Date(payload.work_form);
			let d2 = new Date(payload.work_till);
			if (!payload.designation || payload.designation.length > 100) {
				if (payload.designation.length) {
					setAlertState({ open: true, type: 'error', message: Error.designationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.designation });
				}
			} else if (!payload.organization || payload.organization.length > 100) {
				if (payload.organization.length > 100) {
					setAlertState({ open: true, type: 'error', message: Error.organizationLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.organization });
				}
			} else if (!payload.current_company) {
				setAlertState({ open: true, type: 'error', message: Error.currentCompany });
			} else if (!payload.work_form || +d1 > +d) {
				if (+d1 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			} else if (!payload.work_till || +d1 > +d2 || +d2 > +d) {
				if (+d1 > +d2 || +d2 > +d) {
					setAlertState({ open: true, type: 'error', message: Error.invalidDate });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.date });
				}
			}
			else if (!payload.job_profile || payload.job_profile.length > 250) {
				if (payload.job_profile.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.descriptionLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.jobProfile });
				}
			} else {
				setEmploymentLoader(true);
				const data = await Employment.addEmployment(payload);
				if (data.status === 200) {
					setEmploymentMapModal(false);
					setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
					setShowError(false);
					setEmploymentMapping({
						id: 0,
						employment_id: 0,
						designation: "",
						current_company: "",
						work_form_year: 0,
						work_form_month: "",
						work_till_year: 0,
						work_till_month: "",
						job_profile: "",
					});
					getCandidateEmployment();
					triggerRefresh();
					setTimeout(() => {
						setEmploymentLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'error', message: err.response.data.message });
			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}
		}

	}


	const AddEmployment = async (Id) => {
		try {
			candidateEmployment.find((val) => {
				if (val.employment_id === Id) {
					setEmployments(val);
					setAddId(Id);
					setEmploymentMapModal(true);
				}
			})
		} catch (error) {
			console.log(error);
		}
	}

	const EditEmployment = async (Id) => {
		try {

			candidateEmployment.find((val) => {
				if (val.id === Id) {
					setEmployments(val);
					setEditId(Id);
					setEmploymentEditModal(true);
				}
			})
		} catch (error) {
			console.log(error);
		}
	}

	const DeleteEmployment = async (Id) => {
		EmploymentIds = candidateEmployment.filter((val) => { if (val.id === Id) { return val; } }).map(val => val.employment_id);
		EmploymetsArray = candidateEmployment.filter((val) => { if (val.employment_id === EmploymentIds[0] && val.status === 'Hide') { return val } }).map(val => val.id);
		DeleteEmp = candidateEmployment.filter((val) => { if (val.id === Id) { return val; } }).map(val => val.status);
		minId = Math.min.apply(Math, EmploymetsArray);

		try {
			setDeleteId(Id);
			setEmploymentDeleteModal(true);
			candidateEmployment.find((val) => {
				if (val.id === minId) {
					setEmployments(val);
				}
			})
		} catch (error) {
			console.log(error);
		}
	}

	const DeleteEmploymentHandler = async () => {
		setEmploymentDeleteModal(false)

		try {
			if (DeleteEmp[0] === 'Show') {
				const payload = {};
				payload.candidate_id = employments.candidate_id;
				payload.status = 'Show';
				payload.employment_id = employments.employment_id;
				payload.job_profile = employments.job_profile;
				payload.designation = employments.designation;
				payload.current_company = employments.current_company;
				payload.organization = employments.organization;
				payload.work_form = employments.work_form;
				payload.work_till = employments.current_company === 'no' ? employments.work_till : 'Present';
				if (payload.current_company && payload.designation && payload.organization && payload.job_profile && payload.work_form && payload.work_till) {
					const res = await Employment.updateEmployment(minId, payload);
					if (res.status === 200) {
						const res = await Employment.deleteEmployment(deleteId);
						if (res.status === 200) {
							getCandidateEmployment();
						}

					} else {
						setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
					}
				} else {
					const res = await Employment.deleteEmployment(deleteId);
					if (res.status === 200) {
						getCandidateEmployment();
						triggerRefresh();
					}
				}
			} else {
				const res = await Employment.deleteEmployment(deleteId);
				if (res.status === 200) {
					getCandidateEmployment();
				}
			}

		} catch (error) {
			console.log(error);
		}
		getCandidateEmployment();
	}

	// ======================================================== Education Handler =================================================================

	const educationsOnChange = (e) => {

		setEducations({ ...educations, [e.target.name]: e.target.value });
	}

	const matricHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		const userId = JSON.parse(localStorage.getItem('userId'))
		try {

			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = "NA";
			payload.college_passout_year = 0;
			payload.graduation_university = "NA";
			payload.graduation_course = "NA";
			payload.graduation_passout_year = 0;
			payload.pg_university = "NA";
			payload.pg_course = "NA";
			payload.pg_passout_year = 0;
			payload.phd_university = "NA";
			payload.phd_course = 'NA';
			payload.phd_passout_year = 0;

			if (!payload.school_board || payload.school_board.length > 250) {
				if (payload.school_board.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.schoolLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.school });
				}
			} else if (!payload.school_passout_year || parseInt(payload.school_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.school_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education(payload);
				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Data saved successfully' });
					setMatricEducation(false);
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong' });
					setEducationLoader(false);
				}
			}
		} catch (error) {
			console.log(error)
			setEducationLoader(false);

		}
	}

	const matricEditHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		const userId = JSON.parse(localStorage.getItem('userId'))

		try {
			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = educations.college_board;
			payload.college_passout_year = educations.college_passout_year;
			payload.graduation_university = educations.graduation_university;
			payload.graduation_course = educations.graduation_course;
			payload.graduation_passout_year = educations.graduation_passout_year;
			payload.pg_university = educations.pg_university;
			payload.pg_course = educations.pg_course;
			payload.pg_passout_year = educations.pg_passout_year;
			payload.phd_university = educations.phd_university;
			payload.phd_course = educations.phd_course;
			payload.phd_passout_year = educations.phd_passout_year;

			if (!payload.school_board || payload.school_board.length > 250) {
				if (payload.school_board.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.schoolLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.school });
				}
			} else if (!payload.school_passout_year || parseInt(payload.school_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.school_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education_Update(userId, payload);
				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Data updated successfully' });
					setMatricEducation(false);
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Please Provide All Input Fields' });
					setEducationLoader(false);
				}
			}


		} catch (error) {
			console.log(error)
			setEducationLoader(false);

		}
	}


	const postMatricHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		const userId = JSON.parse(localStorage.getItem('userId'))

		try {
			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = educations.college_board;
			payload.college_passout_year = educations.college_passout_year;
			payload.graduation_university = educations.graduation_university;
			payload.graduation_course = educations.graduation_course;
			payload.graduation_passout_year = educations.graduation_passout_year;
			payload.pg_university = educations.pg_university;
			payload.pg_course = educations.pg_course;
			payload.pg_passout_year = educations.pg_passout_year;
			payload.phd_university = educations.phd_university;
			payload.phd_course = educations.phd_course;
			payload.phd_passout_year = educations.phd_passout_year;

			if (!payload.college_board || payload.college_board.length > 250 || payload.college_board === 'NA') {
				if (payload.college_board.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.collegeLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.college });
				}
			} else if (!payload.college_passout_year || parseInt(payload.college_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.college_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education_Update(userId, payload);
				if (data.status === 200) {
					setPostMatricEducation(false);
					setAlertState({ open: true, type: 'success', message: 'Data submited successfully' });
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
				}
			}
		} catch (error) {
			console.log(error);
			setMatricEducation(false);
		}
	}


	const graduationHandler = async (e) => {
		e.preventDefault();

		setShowError(true);
		const userId = JSON.parse(localStorage.getItem('userId'))

		try {
			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = educations.college_board;
			payload.college_passout_year = educations.college_passout_year;
			payload.graduation_university = educations.graduation_university;
			payload.graduation_course = educations.graduation_course;
			payload.graduation_passout_year = educations.graduation_passout_year;
			payload.pg_university = educations.pg_university;
			payload.pg_course = educations.pg_course;
			payload.pg_passout_year = educations.pg_passout_year;
			payload.phd_university = educations.phd_university;
			payload.phd_course = educations.phd_course;
			payload.phd_passout_year = educations.phd_passout_year;

			if (!payload.graduation_university || payload.graduation_university.length > 250 || payload.graduation_university === 'NA') {
				if (payload.graduation_university.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.universityLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.university });
				}
			} else if (!payload.graduation_course || payload.graduation_course.length > 250 || payload.graduation_course === 'NA') {
				if (payload.graduation_course.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.courseLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.course });
				}
			}
			else if (!payload.graduation_passout_year || parseInt(payload.graduation_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.graduation_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education_Update(userId, payload);
				if (data.status === 200) {
					setAlertState({ open: true, type: 'success', message: 'Data submited successfully' });
					setUgEducation(false);
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong' });
				}
			}
		} catch (error) {
			console.log(error);
			setMatricEducation(false);
		}
	}


	const pgHandler = async (e) => {
		e.preventDefault();

		const userId = JSON.parse(localStorage.getItem('userId'))
		setShowError(true);
		try {
			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = educations.college_board;
			payload.college_passout_year = educations.college_passout_year;
			payload.graduation_university = educations.graduation_university;
			payload.graduation_course = educations.graduation_course;
			payload.graduation_passout_year = educations.graduation_passout_year;
			payload.pg_university = educations.pg_university;
			payload.pg_course = educations.pg_course;
			payload.pg_passout_year = educations.pg_passout_year;
			payload.phd_university = educations.phd_university;
			payload.phd_course = educations.phd_course;
			payload.phd_passout_year = educations.phd_passout_year;

			if (!payload.pg_university || payload.pg_university.length > 250 || payload.pg_university === 'NA') {
				if (payload.pg_university.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.universityLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.university });
				}
			} else if (!payload.pg_course || payload.pg_course.length > 250 || payload.pg_course === 'NA') {
				if (payload.pg_course.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.courseLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.course });
				}
			}
			else if (!payload.pg_passout_year || parseInt(payload.pg_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.pg_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education_Update(userId, payload);
				if (data.status === 200) {
					setPgEducation(false);
					setAlertState({ open: true, type: 'success', message: 'Data submited successfully' });
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
					return data;
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong' });
					setEducationLoader(false)
				}
			}
		} catch (error) {
			console.log(error)
			setMatricEducation(false);
		}
	}


	const phdHandler = async (e) => {
		e.preventDefault();

		const userId = JSON.parse(localStorage.getItem('userId'))
		setShowError(true);
		try {
			const payload = {};
			payload.education_id = userId;
			payload.candidate_id = userId;
			payload.school_board = educations.school_board;
			payload.school_passout_year = educations.school_passout_year;
			payload.college_board = educations.college_board;
			payload.college_passout_year = educations.college_passout_year;
			payload.graduation_university = educations.graduation_university;
			payload.graduation_course = educations.graduation_course;
			payload.graduation_passout_year = educations.graduation_passout_year;
			payload.pg_university = educations.pg_university;
			payload.pg_course = educations.pg_course;
			payload.pg_passout_year = educations.pg_passout_year;
			payload.phd_university = educations.phd_university;
			payload.phd_course = educations.phd_course;
			payload.phd_passout_year = educations.phd_passout_year;

			if (!payload.phd_university || payload.phd_university.length > 250 || payload.phd_university === 'NA') {
				if (payload.phd_university.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.universityLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.university });
				}
			} else if (!payload.phd_course || payload.phd_course.length > 250 || payload.phd_course === 'NA') {
				if (payload.phd_course.length > 250) {
					setAlertState({ open: true, type: 'error', message: Error.courseLimit });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.course });
				}
			}
			else if (!payload.phd_passout_year || parseInt(payload.phd_passout_year) > new Date().getFullYear()) {
				if (parseInt(payload.phd_passout_year) > new Date().getFullYear()) {
					setAlertState({ open: true, type: 'error', message: Error.invalidYear });
				} else {
					setAlertState({ open: true, type: 'error', message: Error.year });
				}
			} else {
				setEducationLoader(true);
				const data = await Education.Candidate_Education_Update(userId, payload);
				if (data.status === 200) {
					setPhdEducation(false);
					setAlertState({ open: true, type: 'success', message: 'Data submited successfully' });
					setEducations({
						education_id: 0,
						candidate_id: 0,
						school_board: "",
						school_passout_year: 0,
						college_board: "",
						college_passout_year: 0,
						graduation_university: "",
						graduation_course: "",
						graduation_passout_year: 0,
						pg_university: "",
						pg_course: "",
						pg_passout_year: 0,
						phd_university: "",
						phd_course: "",
						phd_passout_year: 0
					});
					getEducationData();
					triggerRefresh();
					setShowError(false);
					setTimeout(() => {
						setEducationLoader(false);
					}, 1000);
				} else {
					setAlertState({ open: true, type: 'error', message: 'Something went wrong' });
				}
			}
		} catch (error) {
			console.log(error)
			setMatricEducation(false);

		}
	}


	// ============================================================================================================================================================

	let cityList = [...new Set(pincodeDirectory.lookup(personalDetails.pincode).map(val => val.taluk))]
	let cityLists = [];
	let stateList = [];
	let countryCode = countryNames.filter((val) => { if (val.name === 'India') { return val } })
	if (countryCode.length > 0) {

		stateList = stateNames.filter((val) => { if (val.countryCode === countryCode[0].isoCode) { return val } });
		if (stateList.length > 0) {
			let stateCode = stateList.filter((val) => { if (val.name === 'Odisha') { return val; } })
			if (stateCode.length > 0) {
				cityLists = cityNames.filter((val) => { if (val.stateCode === stateCode[0].isoCode && val.countryCode === countryCode[0].isoCode) { return val } });
			}
		}

	}

	var p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16;
	p1 = resumeHeadline.resume_id > 0 ? 5 : 0;
	p2 = candidateSkills.map(val => val).length > 0 ? 5 : 0;
	p3 = candidateEmployment.length > 0 ? 10 : 0;
	p4 = educations.education_id > 0 ? 10 : 0;
	p5 = candidateSkills.map(val => val).length > 0 ? 5 : 0;
	p6 = candidateProject.length > 0 ? 10 : 0;
	p7 = profileSummary.profileSummay_id > 0 ? 5 : 0;
	p8 = personalDetails.personalDetails_id > 0 ? 10 : 0;
	p9 = careerProfile.careerProfile_id > 0 ? 10 : 0;
	p10 = onlineProfile.onlinePrpfile_id > 0 ? 5 : 0;
	p11 = workSample.workSample_id > 0 ? 5 : 0;
	p12 = presentations.presentation_id > 0 ? 5 : 0;
	p13 = patents.patent_id > 0 ? 5 : 0;
	p14 = certifications.certification_id > 0 ? 5 : 0;
	p15 = whitePaper.whitePaper_id > 0 ? 5 : 0;
	p16 = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9 + p10 + p11 + p12 + p13 + p14 + p15;
	const percentage = p16 + "%";



	let d = new Date();
	let dt = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
	let dt1 = new Date(workSample.duration_form);
	let dt2 = +dt1 > +d ? null : workSample.currently_working === 'Yes' ? d : new Date(workSample.duration_to);
	let d1 = new Date(whitePaper.publish_on);
	let d2 = new Date(patents.publish_on);
	let d3 = new Date(certifications.year_onable);
	let d4 = new Date(personalDetails.dob);
	let d5 = new Date(careerProfile.availability_to_join);
	let d6 = new Date(project.work_form);
	let d7 = new Date(project.work_till);
	let d8 = new Date(itSkill.last_used);
	let d9 = new Date(employments.work_form);
	let d10 = new Date(employments.work_till);
	let d11 = new Date(employmentMapping.work_form);
	let d12 = new Date(employmentMapping.work_till);



	return (
		<>
			<Header activelist="jobs-my-resume" />
			<AlertMessage
				open={alertState.open}
				type={alertState.type}
				message={alertState.message}
				onclose={alertClose}
			/>
			{loader ?
				<div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
					<ThreeCircles
						height="80"
						width="80"
						color="#2e55fa"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
						ariaLabel="three-circles-rotating"
						outerCircleColor=""
						innerCircleColor=""
						middleCircleColor=""
					/>
				</div>

				:
				<div>
					<div className="page-content">
						<div className="overlay-black-dark profile-edit p-t50 p-b20" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-7 candidate-info">
										<div className="candidate-detail">
											<div className="canditate-des text-center">
												<Link to={"#"}>
													{
														!imageData.image ?
															<img alt="" src="images/logo/icon3.jpg" />
															:
															(image ?
																<img src={image} style={{ width: '100%', height: "100%", borderRadius: "50%" }} />
																:

																<img src={imageData.image} style={{ width: "100%", height: "100%", borderRadius: "50%" }} />)

													}


													{/* <img src='images/blog/grid/pic1.jpg'/> */}

												</Link>
												<div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
													<input
														className="update-flie"
														type="file"
														onChange={(e) => onImageChange(e)}
														accept="image/x-png, image/gif, image/jpeg"
													/>
													<i className="fa fa-pencil"></i>
												</div>
											</div>
											<div className="text-white browse-job text-left">
												<h4 className="m-b0">{basicData ? basicData.name : userData.name}
												</h4>
												<p className="m-b15">{basicData ? basicData.professional_title : "NA"}</p>
												<ul className="clearfix">
													<li><i className="ti-location-pin"></i> {basicData ? basicData.city + " , " + basicData.state + " , " + basicData.country : "NA"}</li>
													<li><i className="ti-mobile"></i> {basicData ? "+91 " + basicData.phone : "+91 " + userData.mobile}</li>
													<li><i className="ti-briefcase"></i> {basicData ? basicData.experience : "NA"}</li>
													<li><i className="ti-email"></i> {basicData ? basicData.email : userData.email}</li>
												</ul>
												<div className="progress-box m-t10">
													<div className="progress-info">Profile Strength (Average)<span>{percentage}</span></div>
													<div className="progress">
														<div className="progress-bar bg-primary" style={{ width: percentage }} role="progressbar"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{resumeHeadline.resume_id > 0 && candidateKeySkills.length > 0 && candidateEmployment.length > 0 && educations.education_id > 0 && candidateSkills.length > 0 && candidateProject.length > 0 && onlineProfile.onlinePrpfile_id > 0 && workSample.workSample_id > 0 && whitePaper.whitePaper_id > 0 && presentations.presentation_id > 0 && patents.patent_id > 0 && certifications.certification_id > 0 && careerProfile.careerProfile_id > 0 && personalDetails.personalDetails_id > 0 ? null :
										<div className="col-lg-4 col-md-5" style={{ marginTop: '-3%' }}>
											<a href="#">
												<div className="pending-info text-white p-3" >
													<h6 style={{ fontSize: 14 }}>Pending Action</h6>
													<ul className="list-check secondry" style={{ fontSize: 12 }}>
														{resumeHeadline.resume_id > 0 ? null : <li>Resume Headline</li>}
														{candidateKeySkills.length > 0 ? null : <li>Key Skills</li>}
														{candidateEmployment.length > 0 ? null : <li>Employment</li>}
														{educations.education_id > 0 ? null : <li>Education</li>}
														{candidateSkills.length > 0 ? null : <li>IT Skills</li>}
														{candidateProject.length > 0 ? null : <li>Projects</li>}
														{onlineProfile.onlinePrpfile_id > 0 && workSample.workSample_id > 0 && whitePaper.whitePaper_id > 0 && presentations.presentation_id > 0 && patents.patent_id > 0 && certifications.certification_id > 0 ? null : <li>Accomplishments</li>}
														{careerProfile.careerProfile_id > 0 ? null : <li>Desired Career Profile</li>}
														{personalDetails.personalDetails_id > 0 ? null : <li>Personal Details</li>}
													</ul>
												</div>
											</a>
										</div>}
								</div>
							</div>
						</div>


						<div className="content-block">
							<div className="section-full browse-job content-inner-2 bg-white">
								<div className="container">
									<div className="row">
										<div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
											<div className="sticky-top bg-white">
												<div className="candidate-info onepage">
													<ul>
														<li><a className="scroll-bar nav-link" href="#resume_headline_bx">
															<span>Resume Headline</span></a></li>
														<li><a className="scroll-bar nav-link" href="#key_skills_bx">
															<span>Key Skills</span></a></li>
														<li><a className="scroll-bar nav-link" href="#employment_bx">
															<span>Employment</span></a></li>
														<li><a className="scroll-bar nav-link" href="#education_bx">
															<span>Education</span></a></li>
														<li><a className="scroll-bar nav-link" href="#it_skills_bx">
															<span>IT Skills</span></a></li>
														<li><a className="scroll-bar nav-link" href="#projects_bx">
															<span>Projects</span></a></li>
														<li><a className="scroll-bar nav-link" href="#profile_summary_bx">
															<span>Profile Summary</span></a></li>
														<li><a className="scroll-bar nav-link" href="#accomplishments_bx">
															<span>Accomplishments</span></a></li>
														<li><a className="scroll-bar nav-link" href="#desired_career_profile_bx">
															<span>Desired Career Profile</span></a></li>
														<li><a className="scroll-bar nav-link" href="#personal_details_bx">
															<span>Personal Details</span></a></li>

													</ul>
												</div>
											</div>
										</div>
										{/* =========================================================== Resume Headline ============================================================ */}

										<div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
											<div id="resume_headline_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Resume Headline {resumeHeadline.resume_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#resumeheadline" className="site-button add-btn button-sm" onClick={() => setResumeModal(true)}>{resumeHeadline.resume_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {resumeHeadline.resume_id > 0 ? "Edit" : "Add"}</a>
												</div>
												{
													resumeLoader ?
														<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														resumeHeadline.resume_id > 0 ?
															<p className="m-b0">{resumeHeadline.headline}</p>
															:
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add Resume Headline and make your profile Strength stronger.</p>
												}


												<Modal show={resumeModal} onHide={setResumeModal} className="modal fade modal-bx-info editor" >
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title" id="ResumeheadlineModalLongTitle">Resume Headline</h5>
															<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {  setResumeModal(false); setShowError(false);getResumeHeadlineData() }}>
																<span aria-hidden="true" >&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<p>It is the first thing recruiters notice in your profile. Write concisely what makes you unique and right person for the job you are looking for.</p>
															<form>
																<div className="row">
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<textarea
																				className="form-control"
																				placeholder="Type Description"
																				name='headline'
																				value={resumeHeadline.headline}
																				onChange={resumeHeadlineOnCghange}
																			></textarea>
																			{resumeHeadline.headline ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.resumeHeadline}</span> : null)}
																			{resumeHeadline.headline ? (resumeHeadline.headline.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.resumeHeadlineLimit}</span> : null) : null}
																		</div>
																	</div>
																</div>
															</form>
														</div>
														<div className="modal-footer">
															<button type="button" className="site-button red" data-dismiss="modal" onClick={() => {  setResumeModal(false); setShowError(false) ;getResumeHeadlineData()}}>Cancel</button>
															{
																resumeHeadline.resume_id > 0 ?
																	<button type="button" className="site-button orange" onClick={updateResumeHandler}>Update</button>
																	:
																	<button type="button" className="site-button green" onClick={saveResumeHandler}>Save</button>
															}

														</div>
													</div>
												</Modal>

											</div>
											<div id="key_skills_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Key Skills {candidateKeySkills.length > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#keyskills" className="site-button add-btn button-sm" onClick={() => { setKeySkillModal(true) }}> <i className="fa fa-plus m-r5"></i> Add</a>
												</div>

												<div className="job-time mr-auto">
													{
														keySkillLoader ?
															<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
																<Oval
																	height={20}
																	width={20}
																	color="#4fa94d"
																	wrapperStyle={{}}
																	wrapperClass=""
																	visible={true}
																	ariaLabel='oval-loading'
																	secondaryColor="#4fa94d"
																	strokeWidth={2}
																	strokeWidthSecondary={2}
																/>
															</div> :

															candidateKeySkills.length > 0 ?
																candidateKeySkills.map((val) => {
																	return (
																		<a className='mr-2'><span>{val.skills}</span></a>
																	)
																})
																:
																<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your key Skill and make your profile Strength stronger.</p>
													}


												</div>

												<Modal show={keySkillModal} onHide={setKeySkillModal} className="modal fade modal-bx-info editor" >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="KeyskillsModalLongTitle">Key Skills</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setKeySkillModal(false); setShowError(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<p>It is the first thing recruiters notice in your profile. Write concisely what makes you unique and right person for the job you are looking for.</p>
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<input
																					list='skill'
																					type="text"
																					className="form-control tags_input"
																					name='skills'
																					value={keySkill.skills}
																					onChange={keySkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				<datalist id='skill'>
																					{
																						skillData.map((val) => {
																							return (
																								<option className='drop-down' value={val.skillName} ><span key={val.id}>{val.skillName}</span></option>
																							)
																						})
																					}

																				</datalist>
																				{keySkill.skills ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skill}</span> : null)}
																				{keySkill.skills ? (keySkill.skills.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skillLimit}</span> : null) : null}
																			</div>
																		</div>
																	</div>
																</form>
																<div className="mt-2 mr-auto" >
																	{
																		candidateKeySkills.length > 0 ?
																			candidateKeySkills.map((val) => {
																				return (
																					<Link to={"#"} ><span className="mr-2 ">{val.skills}  <i className="fa fa-times-circle " style={{ color: 'red' }} onClick={() => deleteKeySkillHandler(val.keySkills_id)}></i></span></Link>
																				)
																			})
																			:
																			<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your key Skill and make your profile Strength stronger.</p>
																	}
																</div>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setKeySkillModal(false); setShowError(false) }}>Cancel</button>
																<button type="button" className="site-button green" onClick={keySkillHandler}>Save</button>
															</div>
														</div>
													</div>
												</Modal>
												<Modal show={deleteKeySkill} onHide={setDeleteSkill} className="modal fade modal-bx-info" style={{ marginTop: '5%' }}>
													<div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Delete Key Skill</h5>
																<button type="button" className="close" onClick={() => setDeleteSkill(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<p className="text-center" > Do you want to remove <span style={{ color: 'red' }}> {viewSkill}</span> from your skills list ? </p>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-danger" onClick={DeleteKeySkillHandler} >Delete</button>
																<button type="button" className="btn btn-primary" onClick={() => setDeleteSkill(false)}>Close</button>
															</div>
														</div>
													</div>
												</Modal>


											</div>
											<div id="employment_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Employment {candidateEmployment.length > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#employment" className="site-button add-btn button-sm" onClick={() => { setEmploymentModal(true) }}>
														<i className="fa fa-plus m-r5"></i> Add
													</a>
												</div>
												{
													employmentLoader ?
														<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														candidateEmployment.length > 0 ?
															<div>
																<div style={{ width: "100%", padding: 15 }}>
																	{
																		candidateEmployment.sort(((a, b) => a.organization.toString().localeCompare(b.organization.toString()))).sort(((a, b) => a.employment_id.toString().localeCompare(b.employment_id.toString()))).map((val, ind) => {
																			return (
																				<div  >
																					<div style={(ind + 1) % 2 ? { backgroundColor: '#f9f9f9', paddingLeft: 15 } : { backgroundColor: '#fff', paddingLeft: 15 }}>
																						<div style={val.status === 'Hide' ? { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', marginBottom: '0%' } : { display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '-1.8%' }}>
																							{
																								val.status === 'Hide' ? null :
																									<div>
																										<h6 className="font-14  " style={{ color: 'blue' }}>@{val.organization} </h6>

																									</div>
																							}
																							<div style={{ marginTop: '0.3%' }}>
																								{
																									val.status === 'Hide' ? null :
																										<Link className=' ml-3 ' onClick={() => { AddEmployment(val.employment_id) }} ><i className="fa fa-plus-circle" style={{ color: 'blue' }}></i></Link>
																								}

																							</div>
																						</div>
																					</div>
																					<div style={(ind + 1) % 2 ? { backgroundColor: '#f9f9f9', paddingBottom: 10, paddingLeft: 15 } : { backgroundColor: '#fff', paddingBottom: 10, paddingLeft: 15 }}>
																						<div style={val.status === 'Hide' ? { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginTop: '-3%', } : { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginTop: '0%', }}>
																							<h6 className="font-12 m-b0 " style={{color:"#000000"}} >{val.designation} </h6>
																							<div>
																								<Link className=' mr-3' onClick={() => EditEmployment(val.id)}><i className="fa fa-pencil " style={{ color: 'orange' }}></i></Link>
																								<Link className=' mr-3' onClick={() => DeleteEmployment(val.id)}><i className="fa fa-trash " style={{ color: 'red' }}></i></Link>
																							</div>
																						</div>
																						<p className="m-b0 font-10 " style={{ marginTop: '-1.4%', marginBottom: 2 }}>{val.work_form} To {val.current_company === "no" ? (val.work_till) : 'Present'} </p>
																						<div style={{ lineHeight: '8px', marginTop: '-0.8%' }}>
																							<span className="font-12" style={{ marginTop: '-1%' }}>{val.job_profile} </span>
																						</div>
																					</div>
																					<br />
																				</div>

																			)
																		})
																	}

																</div>

															</div> :
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your Employment and make your profile Strength stronger.</p>

												}

												<Modal className="modal fade modal-bx-info editor" show={employmentModal} onHide={setEmploymentModal} >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="EmploymentModalLongTitle">Add Employment</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setEmploymentModal(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Your Designation <span style={{ color: 'red' }}>*</span></label>
																				<input
																					type="email"
																					className="form-control"
																					name='designation'
																					placeholder="Enter Your Designation"
																					value={employments.designation}
																					onChange={employmentOnChange}
																					style={{color:"#000000"}}
																				/>
																				{employments.designation ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designation}</span> : null)}
																				{employments.designation ? (employments.designation.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designationLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Your Organization <span style={{ color: 'red' }}>*</span></label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Your Organization"
																					name='organization'
																					value={employments.organization}
																					onChange={employmentOnChange}
																					style={{color:"#000000"}}
																				//  disabled ={employments.employment_id ? true : false}

																				/>
																				{employments.organization ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.organization}</span> : null)}
																				{employments.organization ? (employments.organization.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.organizationLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Is this your current company <span style={{ color: 'red' }}>*</span> ?</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="employ_yes"
																								name="current_company"
																								value='yes'
																								onChange={employmentOnChange}
																								
																							/>
																							<label className="custom-control-label" for="employ_yes">Yes</label>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="employ_no"
																								name="current_company"
																								value='no'
																								onChange={employmentOnChange}
																							/>
																							<label className="custom-control-label" for="employ_no">No</label>
																						</div>
																					</div>
																				</div>
																				{employments.current_company ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentCompany}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Started Working From <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off" type="date"
																					className="form-control"
																					name='work_form'
																					value={employments.work_form}
																					onChange={employmentOnChange}
																					style={{color:"#000000"}}
																				/>
																				{employments.work_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{employments.work_form ? +d9 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																			</div>
																		</div>
																		{employments.current_company === "no" ?
																			<div className="col-lg-12 col-md-12">
																				<div className="form-group">
																					<label>Worked Till <span style={{ color: 'red' }}>*</span></label>
																					<input
																						autoComplete="off" type="date"
																						className="form-control"
																						name='work_till'
																						value={employments.work_till}
																						onChange={employmentOnChange}
																						style={{color:"#000000"}}
																					/>
																					{employments.work_till ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																					{employments.work_till ? (+d10 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}
																					{employments.work_till ? (+d9 <= +d10 ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}

																				</div>
																			</div> : null}
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Describe your Job Profile</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name='job_profile'
																					value={employments.job_profile}
																					onChange={employmentOnChange}
																					autoComplete="off"
																					style={{color:"#000000"}}
																				></textarea>
																				{/* {employments.job_profile ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobProfile}</span> : null)}
																				{employments.job_profile ? (employments.job_profile.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.descriptionLimit}</span> : null) : null}														 */}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setEmploymentModal(false) }}>Cancel</button>
																<button type="button" className="site-button green" onClick={employmentHandler}>Save</button>
															</div>
														</div>
													</div>
												</Modal>

												<Modal className="modal fade modal-bx-info editor" show={employmentEditModal} onHide={setEmploymentEditModal} >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="EmploymentModalLongTitle">Add Employment</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setEmploymentEditModal(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Your Designation</label>
																				<input
																					type="email"
																					className="form-control"
																					name='designation'
																					placeholder="Enter Your Designation"
																					value={employments.designation}
																					onChange={employmentOnChange}
																					style={{color:"#000000"}}
																				/>
																				{employments.designation ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designation}</span> : null)}
																				{employments.designation ? (employments.designation.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designationLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Your Organization</label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Your Organization"
																					name='organization'
																					value={employments.organization}
																					onChange={employmentOnChange}
																					disabled
																					style={{color:"#000000"}}
																				/>
																				{employments.organization ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.organization}</span> : null)}
																				{employments.organization ? (employments.organization.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.organizationLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Is this your current company?</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="employ_yes"
																								name="current_company"
																								value='yes'
																								onChange={employmentOnChange}
																								checked={employments.current_company === "yes" ? true : false}
																								style={{color:"#000000"}}
																							/>
																							<label className="custom-control-label" for="employ_yes">Yes</label>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="employ_no"
																								name="current_company"
																								value='no'
																								onChange={employmentOnChange}
																								checked={employments.current_company === "no" ? true : false}
																							/>
																							<label className="custom-control-label" for="employ_no">No</label>
																						</div>
																					</div>
																				</div>
																				{employments.current_company ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentCompany}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Started Working From</label>
																				<input
																					autoComplete="off" type="date"
																					className="form-control"
																					name='work_form'
																					value={employments.work_form}
																					onChange={employmentOnChange}
																					style={{color:"#000000"}}
																				/>
																				{employments.work_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{employments.work_form ? +d9 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																			</div>
																		</div>
																		{employments.current_company === "no" ?
																			<div className="col-lg-12 col-md-12">
																				<div className="form-group">
																					<label>Worked Till</label>
																					<input
																						autoComplete="off" type="date"
																						className="form-control"
																						name='work_till'
																						value={employments.work_till}
																						onChange={employmentOnChange}
																						style={{color:"#000000"}}
																					/>
																					{employments.work_till ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																					{employments.work_till ? (+d10 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}
																					{employments.work_till ? (+d9 <= +d10 ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}

																				</div>
																			</div> : null}
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Describe your Job Profile</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name='job_profile'
																					value={employments.job_profile}
																					onChange={employmentOnChange}
																					autoComplete="off"
																					style={{color:"#000000"}}
																				></textarea>
																				{/* {employments.job_profile ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobProfile}</span> : null)}
																				{employments.job_profile ? (employments.job_profile.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.descriptionLimit}</span> : null) : null}															 */}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setEmploymentEditModal(false) }}>Cancel</button>
																<button type="button" className="site-button orange" onClick={employmentUpdateHandler}>Update</button>
															</div>
														</div>
													</div>
												</Modal>

												<Modal className="modal fade modal-bx-info editor" show={employmentMapModal} onHide={setEmploymentMapModal} >
													<div className="modal-dialog my-0 mx-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="EmploymentModalLongTitle">Add Employment</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setEmploymentMapModal(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<label>Your Designation</label>
																			<input
																				type="email"
																				className="form-control"
																				placeholder="Enter Your Designation"
																				name='designation'
																				value={employmentMapping.designation}
																				onChange={employmentMappingOnChange}
																				style={{color:"#000000"}}
																			/>
																			{employmentMapping.designation ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designation}</span> : null)}
																			{employmentMapping.designation ? (employmentMapping.designation.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.designationLimit}</span> : null) : null}
																		</div>
																	</div>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<label>Your Organization</label>
																			<input
																				type="email"
																				className="form-control"
																				placeholder="Enter Your Organization"
																				name='organization'
																				value={employments.organization}
																				disabled
																				style={{color:"#000000"}}
																			/>

																		</div>
																	</div>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<label>Is this your current company?</label>
																			<div className="row">
																				<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																					<div className="custom-control custom-radio">
																						<input
																							type="radio"
																							className="custom-control-input"
																							id="employ_yes"
																							name="current_company"
																							value='yes'
																							onChange={employmentMappingOnChange}
																							checked={employmentMapping.current_company === "yes" ? true : false}
																						/>
																						<label className="custom-control-label" for="employ_yes">Yes</label>
																					</div>
																				</div>
																				<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																					<div className="custom-control custom-radio">
																						<input
																							type="radio"
																							className="custom-control-input"
																							id="employ_no"
																							name="current_company"
																							value='no'
																							onChange={employmentMappingOnChange}
																							checked={employmentMapping.current_company === "no" ? true : false}
																						/>
																						<label className="custom-control-label" for="employ_no">No</label>
																					</div>
																				</div>
																			</div>
																			{employmentMapping.current_company ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentCompany}</span> : null)}
																		</div>
																	</div>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<label>Started Working From</label>
																			<input
																				autoComplete="off" type="date"
																				className="form-control"
																				name='work_form'
																				value={employmentMapping.work_form}
																				onChange={employmentMappingOnChange}
																				style={{color:"#000000"}}
																			/>
																			{employmentMapping.work_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																			{employmentMapping.work_form ? +d11 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																		</div>
																	</div>
																	{employments.current_company === "no" ?
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Worked Till</label>
																				<input
																					autoComplete="off" type="date"
																					className="form-control"
																					name='work_till'
																					value={employmentMapping.work_till}
																					onChange={employmentMappingOnChange}
																					style={{color:"#000000"}}
																				/>
																				{employmentMapping.work_till ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{employmentMapping.work_till ? (+d12 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}
																				{employmentMapping.work_till ? (+d11 <= +d12 ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span>) : null}
																			</div>
																		</div> : null}
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group">
																			<label>Describe your Job Profile</label>
																			<textarea
																				className="form-control"
																				placeholder="Type Description"
																				name='job_profile'
																				value={employmentMapping.job_profile}
																				onChange={employmentMappingOnChange}
																				autoComplete="off"
																				style={{color:"#000000"}}
																			></textarea>
																			{employmentMapping.job_profile ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobProfile}</span> : null)}
																			{employmentMapping.job_profile ? (employmentMapping.job_profile.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.descriptionLimit}</span> : null) : null}
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setEmploymentMapModal(false) }}>Cancel</button>
																<button type="button" className="site-button green" onClick={employmentMappingHandler}>Submit</button>
															</div>
														</div>
													</div>
												</Modal>
												<Modal show={employmentDeleteModal} onHide={setEmploymentDeleteModal} className="modal fade modal-bx-info">
													<div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Delete Employment</h5>
																<button type="button" className="close" onClick={() => setEmploymentDeleteModal(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<p className="text-center" style={{ color: 'red' }}> Are you sure to delete this employment ? </p>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-danger" onClick={DeleteEmploymentHandler} >Delete</button>
																<button type="button" className="btn btn-primary" onClick={() => { setEmploymentDeleteModal(false); }}>Close</button>
															</div>
														</div>
													</div>
												</Modal>
											</div>
											<div id="education_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Education {educations.education_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 10%</span>}</h5>
													{
														educations.education_id ? null :
															<Link to={"#"} onClick={() => setMatricEducation(true)} className="site-button add-btn button-sm">
																<i className="fa fa-plus m-r5"></i>Add
															</Link>
													}
												</div>

												<div className="row">
													<div className="col-lg-12 col-md-12 col-sm-12">
														{/* <div className="clearfix m-b20">
											<label className="m-b0">London - 12th</label>
											<span className="clearfix font-13">2019</span>
										</div> */}

													</div>
												</div>
												{
													educations.education_id > 0 ?
														<div className="clearfix m-b20">
															<h6 className="m-b0 font-14">Education Details</h6>
															{educations.school_board === null ? null : <span className="clearfix font-12" style={{ width: '100%' }} >{"Completed 10th From " + educations.school_board + " in " + educations.school_passout_year}{educations.school_board === null ? null : <i className="fa fa-pencil ml-3" style={{ float: 'right', color: 'orange' }} onClick={() => setMatricEducation(true)}></i>}</span>}
															{educations.school_board === null ? null : educations.college_board === 'NA' ? <Link className="clearfix font-12" onClick={() => setPostMatricEducation(true)}>Add 12th Education <br /></Link> : <span className="clearfix font-12" style={{ width: '100%' }}>{"Completed 12th From " + educations.college_board + " in " + educations.college_passout_year} {educations.college_board === "NA" ? null : <i className="fa fa-pencil ml-3" style={{ float: 'right', color: 'orange' }} onClick={() => setPostMatricEducation(true)}></i>}</span>}
															{educations.college_board === 'NA' ? null : educations.graduation_university === 'NA' ? <Link className="clearfix font-12" onClick={() => setUgEducation(true)}>Add UG Education <br /></Link> : <span className="clearfix font-12" style={{ width: '100%' }}>{"Graduated in " + educations.graduation_course + " from " + educations.graduation_university + " in " + educations.graduation_passout_year} {educations.graduation_course === "NA" ? null : <i className="fa fa-pencil ml-3" style={{ float: 'right', color: 'orange' }} onClick={() => setUgEducation(true)}></i>}</span>}
															{educations.graduation_university === 'NA' ? null : educations.pg_university === 'NA' ? <Link className="clearfix font-12" onClick={() => setPgEducation(true)}>Add PG Education <br /></Link> : <span className="clearfix font-12" style={{ width: '100%' }}>{"Graduated in " + educations.pg_course + " from " + educations.pg_university + " in " + educations.pg_passout_year}{educations.pg_course === "NA" ? null : <i className="fa fa-pencil ml-3" style={{ float: 'right', color: 'orange' }} onClick={() => setPgEducation(true)}></i>}</span>}
															{educations.pg_university === 'NA' ? null : educations.phd_university === 'NA' ? <Link className="clearfix font-12" onClick={() => setPhdEducation(true)}>Add PhD Education <br /></Link> : <span className="clearfix font-12" style={{ width: '100%' }}>{"Completed PhD in " + educations.phd_course + " from " + educations.phd_university + " in " + educations.phd_passout_year}{educations.phd_course === "NA" ? null : <i className="fa fa-pencil ml-3" style={{ float: 'right', color: 'orange' }} onClick={() => setPhdEducation(true)}></i>}</span>}


														</div> :
														<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your Education and make your profile Strength stronger.</p>
												}




												<Modal className="modal fade modal-bx-info editor" show={matricEducation} onHide={setMatricEducation}>
													<div className="modal-dialog my-0  mx-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Education</h5>
																<button type="button" className="close" onClick={() => { setMatricEducation(false); setShowError(false); }}><span>&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<label style={{ color: '#000', fontSize: 18, marginLeft: 10 }}>10th Education:</label>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group pl-3 pr-3">
																				<label >School Name <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter Your Board"
																					name='school_board'
																					value={educations.school_board}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.school_board ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.school}</span> : null)}
																				{educations.school_board ? (educations.school_board.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.schoolLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group pl-3 pr-3">
																				<label>Year Of Passout <span style={{ color: 'red' }}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='school_passout_year'
																					value={educations.school_passout_year}
																					onChange={educationsOnChange}
																					style={{ height: 'auto' ,color:'#000000'}}
																				>
																					<option value={""} disabled>---SELECT---</option>
																					{
																						year.reverse().map((val) => {
																							return (
																								<option key={val.id}  style={{color:"#000000"}}>{val.year}</option>
																							);
																						})
																					}
																				</Form.Control>
																				{educations.school_passout_year ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.year}</span> : null)}
																				{educations.school_passout_year ? parseInt(educations.school_passout_year) > new Date().getFullYear() ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidYear}</span> : null : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">

																		</div>
																	</div>

																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setMatricEducation(false); setShowError(false); }}>Cancel</button>
																{
																	educations.education_id > 0 ?
																		<button type="button" className="site-button orange" onClick={matricEditHandler} >Submit</button> :
																		<button type="button" className="site-button green" onClick={matricHandler} >Submit</button>
																}

															</div>
														</div>
													</div>
												</Modal>


												<Modal className="modal fade modal-bx-info editor" show={postMatricEducation} onHide={setPostMatricEducation}>
													<div className="modal-dialog my-0 mx-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Education</h5>
																<button type="button" className="close" onClick={() => { setPostMatricEducation(false); setShowError(false) }}><span>&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<label style={{ color: '#000', fontSize: 18, marginLeft: 10 }}>10+2 Education/Diploma :</label>
																		<div className="col-lg-12 col-md-12 ">
																			<div className="form-group pl-3 pr-3">
																				<label>College Name <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter Your Board"
																					name='college_board'
																					value={educations.college_board}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.college_board !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.college}</span> : null)}
																				{educations.college_board ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.college}</span> : null)}
																				{educations.college_board ? (educations.college_board.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.collegeLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12  ">
																			<div className="form-group pl-3 pr-3">
																				<label>Year Of Passout <span style={{ color: 'red' }}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='college_passout_year'
																					value={educations.college_passout_year}
																					onChange={educationsOnChange}
																					style={{ height: 'auto' ,color:'#000000'}}
																				>
																					<option value={""} disabled>---SELECT---</option>
																					{
																						year.reverse().map((val) => {
																							return (
																								<option key={val.id}  style={{color:"#000000"}}>{val.year}</option>
																							);
																						})
																					}
																				</Form.Control>
																				{educations.college_passout_year ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.year}</span> : null)}
																				{educations.college_passout_year ? parseInt(educations.college_passout_year) > new Date().getFullYear() ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidYear}</span> : null : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">

																		</div>
																	</div>

																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setPostMatricEducation(false); setShowError(false) }}>Cancel</button>
																{
																	educations.college_passout_year === 0 ?
																		<button type="button" className="site-button orange" onClick={postMatricHandler} >Submit</button> :
																		<button type="button" className="site-button green" onClick={postMatricHandler} >Submit</button>
																}

															</div>
														</div>
													</div>
												</Modal>
												<Modal className="modal fade modal-bx-info editor" show={ugEducation} onHide={setUgEducation}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Education </h5>
																<button type="button" className="close" onClick={() => { setUgEducation(false); setShowError(false) }}><span>&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<label>Graduation</label>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>University <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Select Course"
																					name='graduation_university'
																					value={educations.graduation_university}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.graduation_university !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.graduation_university ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.graduation_university ? (educations.graduation_university.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.universityLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Course <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Select Course"
																					name='graduation_course'
																					value={educations.graduation_course}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.graduation_course !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.graduation_course ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.graduation_course ? (educations.graduation_course.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.courseLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Year Of Passout <span style={{ color: 'red' }}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='graduation_passout_year'
																					value={educations.graduation_passout_year}
																					onChange={educationsOnChange}
																					style={{ height: 'auto',color:'#000000' }}
																				>
																					<option value={""}>---SELECT---</option>
																					{
																						year.reverse().map((val) => {
																							return (
																								<option key={val.id}  style={{color:"#000000"}}>{val.year}</option>
																							);
																						})
																					}
																				</Form.Control>
																				{educations.graduation_passout_year ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.year}</span> : null)}
																				{educations.graduation_passout_year ? parseInt(educations.graduation_passout_year) > new Date().getFullYear() ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidYear}</span> : null : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">

																		</div>
																	</div>

																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setUgEducation(false); setShowError(false) }}>Cancel</button>
																{
																	educations.graduation_passout_year > 0 ?
																		<button type="button" className="site-button orange" onClick={graduationHandler} >Submit</button> :
																		<button type="button" className="site-button green" onClick={graduationHandler} >Submit</button>
																}

															</div>
														</div>
													</div>
												</Modal>


												<Modal className="modal fade modal-bx-info editor" show={pgEducation} onHide={setPgEducation}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Education</h5>
																<button type="button" className="close" onClick={() => { setPgEducation(false); setShowError(false) }}><span>&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<label>Post Graduation</label>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>University <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Select Course"
																					name='pg_university'
																					value={educations.pg_university}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.pg_university !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.pg_university ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.pg_university ? (educations.pg_university.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.universityLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Course <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Select Course"
																					name='pg_course'
																					value={educations.pg_course}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.pg_course !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.pg_course ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.pg_course ? (educations.pg_course.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.courseLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Year Of Passout <span style={{ color: 'red' }}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='pg_passout_year'
																					value={educations.pg_passout_year}
																					onChange={educationsOnChange}
																					style={{ height: 'auto',color:'#000000' }}
																				>
																					<option value={""}>---SELECT---</option>
																					{
																						year.reverse().map((val) => {
																							return (
																								<option key={val.id}  style={{color:"#000000"}}>{val.year}</option>
																							);
																						})
																					}
																				</Form.Control>
																				{educations.pg_passout_year ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.year}</span> : null)}
																				{educations.pg_passout_year ? parseInt(educations.pg_passout_year) > new Date().getFullYear() ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidYear}</span> : null : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">

																		</div>
																	</div>

																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setPgEducation(false); setShowError(false) }}>Cancel</button>
																{
																	educations.pg_passout_year === 0 ?
																		<button type="button" className="site-button orange" onClick={pgHandler} >Submit</button> :
																		<button type="button" className="site-button green" onClick={pgHandler} >Submit</button>
																}

															</div>
														</div>
													</div>
												</Modal>

												<Modal className="modal fade modal-bx-info editor" show={phdEducation} onHide={setPhdEducation}>
													<div className="modal-dialog my-0 mx-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Education</h5>
																<button type="button" className="close" onClick={() => { setPhdEducation(false); setShowError(false) }}><span>&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<label>Doctorate/PhD</label>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>University <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter University"
																					name='phd_university'
																					value={educations.phd_university}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.phd_university !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.phd_university ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.university}</span> : null)}
																				{educations.phd_university ? (educations.phd_university.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.universityLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Course <span style={{ color: 'red' }}>*</span></label>
																				<input
																					autoComplete="off" type="text"
																					className="form-control"
																					placeholder="Select Course"
																					name='phd_course'
																					value={educations.phd_course}
																					onChange={educationsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{educations.phd_course !== 'NA' ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.phd_course ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.course}</span> : null)}
																				{educations.phd_course ? (educations.phd_course.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.courseLimit}</span> : null) : null}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Year Of Passout <span style={{ color: 'red' }}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='phd_passout_year'
																					value={educations.phd_passout_year}
																					onChange={educationsOnChange}
																					style={{ height: 'auto',color:'#000000' }}
																				>

																					<option value={""}>---SELECT---</option>
																					{
																						year.reverse().map((val) => {
																							return (
																								<option key={val.id}  style={{color:"#000000"}}>{val.year}</option>
																							);
																						})
																					}
																				</Form.Control>
																				{educations.phd_passout_year ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.year}</span> : null)}
																				{educations.phd_passout_year ? parseInt(educations.phd_passout_year) > new Date().getFullYear() ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidYear}</span> : null : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">

																		</div>
																	</div>

																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setPhdEducation(false); setShowError(false) }}>Cancel</button>
																{
																	educations.phd_passout_year === 0 ?
																		<button type="button" className="site-button green" onClick={phdHandler} >Submit</button> :
																		<button type="button" className="site-button orange" onClick={phdHandler} >Submit</button>
																}

															</div>
														</div>
													</div>
												</Modal>
											</div>
											<div id="it_skills_bx" className="job-bx table-job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">IT Skills  {candidateSkills.map(val => val).length > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#itskills" className="site-button add-btn button-sm" onClick={() => { setItModal(true); }}><i className="fa fa-plus m-r5"></i> Add</a>
												</div>
												{
													itLoader ?
														<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														candidateSkills.map(val => val).length === 0 ?
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your It Skills and make your profile Strength stronger.</p> :
															<table>
																<thead>
																	<tr>
																		<th>Skills</th>
																		<th>Version</th>
																		<th>Last Used</th>
																		<th>Experience</th>
																		<th>Action</th>
																	</tr>
																</thead>
																<tbody>
																	{

																		candidateSkills.map((val) => {
																			return (
																				<tr>
																					<td>{val.it_skills}</td>
																					<td>{val.version}</td>
																					<td>{val.last_used}</td>
																					<td>{val.experience} </td>
																					<td>
																						<a className="m-l15 font-14" data-toggle="modal" data-target="#itskills" href="#" onClick={() => { editSkill(val.itSkills_id) }} ><i className="fa fa-pencil" style={{ color: 'orange' }}></i></a>
																						<a className="m-l15 font-14" data-toggle="modal" data-target="#itskills" href="#" onClick={() => { deleteSkill(val) }}><i className="fa fa-trash" style={{ color: 'red' }}></i></a>
																					</td>
																				</tr>
																			)
																		})
																	}
																</tbody>
															</table>
												}
												<Modal className="modal fade modal-bx-info editor" show={itModal} onHide={setItModal} >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="ItskillsModalLongTitle">IT Skills</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setItModal(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>IT Skills <span style={{ color: 'red' }}>*</span> </label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter IT Skills"
																					name='it_skills'
																					value={itSkill.it_skills}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{itSkill.it_skills ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skill}</span> : null)}
																				{itSkill.it_skills ? (itSkill.it_skills.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skillLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Version </label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Version"
																					name='version'
																					value={itSkill.version}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {itSkill.version ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.version}</span> : null)}
																				{itSkill.version ? (itSkill.version.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.versionLimit}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Last Used</label>
																				<input
																					autoComplete="off"
																					type="date"
																					className="form-control"
																					name='last_used'
																					value={itSkill.last_used}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {itSkill.last_used ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)} */}
																				{/* {itSkill.last_used ? +d8 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-6">
																			<div className="form-group">
																				<label>Experience <span style={{ color: 'red' }}>*</span></label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<select
																							className="form-control"
																							autoComplete="off"
																							name='experience'
																							value={itSkill.experience}
																							onChange={itSkillOnChange}
																							style={{ height: 'auto',color:'#000000' }}
																						>
																							<option value={''}>---SELECT---</option>
																							{
																								experieceData.map((val) => {
																									return (
																										<option key={val.id} style={{color:"#000000"}}>{val.exp_year}</option>
																									);
																								})
																							}
																						</select>

																						{itSkill.experience ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.experience}</span> : null)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setItModal(false) }}>Cancel</button>
																<button type="button" className="site-button green" onClick={itSkillHandler}>Save</button>
															</div>
														</div>
													</div>
												</Modal>
												<Modal className="modal fade modal-bx-info editor" show={itEditModal} onHide={setItEditModal} >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="ItskillsModalLongTitle">IT Skills</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setItEditModal(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>IT Skills <span style={{color:'red'}}>*</span></label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter IT Skills"
																					name='it_skills'
																					value={itSkill.it_skills}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{itSkill.it_skills ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skill}</span> : null)}
																				{itSkill.it_skills ? (itSkill.it_skills.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.skillLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Version</label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Version"
																					name='version'
																					value={itSkill.version}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {itSkill.version ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.version}</span> : null)}
																				{itSkill.version ? (itSkill.version.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.versionLimit}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Last Used</label>
																				<input
																					autoComplete="off"
																					type="date"
																					className="form-control"
																					name='last_used'
																					value={itSkill.last_used}
																					onChange={itSkillOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {itSkill.last_used ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{itSkill.last_used ? +d8 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-6">
																			<div className="form-group">
																				<label>Experience <span style={{color:'red'}}>*</span></label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<select
																							className="form-control"
																							autoComplete="off"
																							name='experience'
																							value={itSkill.experience}
																							onChange={itSkillOnChange}
																							style={{ height: 'auto',color:"#000000" }}
																						>
																							<option value={''}>---SELECT---</option>
																							{
																								experieceData.map((val) => {
																									return (
																										<option key={val.id} style={{color:"#000000"}}>{val.exp_year}</option>
																									);
																								})
																							}
																						</select>

																						{itSkill.experience ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.experience}</span> : null)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setItEditModal(false) }}>Cancel</button>
																<button type="button" className="site-button orange" onClick={itSkillUpdateHandler}>Update</button>
															</div>
														</div>
													</div>
												</Modal>

												<Modal show={itSkillDeleteModal} onHide={setItSkillDeleteModal} className="modal fade modal-bx-info">
													<div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Delete Skill</h5>
																<button type="button" className="close" onClick={() => setItSkillDeleteModal(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<p className="text-center"> Are you sure to delete <span style={{ color: 'red' }}>{skillName}</span> ?</p>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-danger" onClick={deleteHandler}>Delete</button>
																<button type="button" className="btn btn-primary" onClick={() => setItSkillDeleteModal(false)}>Close</button>
															</div>
														</div>
													</div>
												</Modal>
											</div>
											<div id="projects_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Projects {candidateProject.length > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#projects" className="site-button add-btn button-sm" onClick={() => { setProjectModal(true); }}>
														<i className="fa fa-plus m-r5"></i> Add
													</a>
												</div>
												{

													projectLoader ?
														<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														candidateProject.length > 0 ?
															candidateProject.map((val, ind) => {
																return (
																	selfLoader && val.project_id === editId ?
																		<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																			<Oval
																				height={20}
																				width={20}
																				color="#4fa94d"
																				wrapperStyle={{}}
																				wrapperClass=""
																				visible={true}
																				ariaLabel='oval-loading'
																				secondaryColor="#4fa94d"
																				strokeWidth={2}
																				strokeWidthSecondary={2}
																			/>
																		</div> :

																		<div key={ind} >
																			<div style={(ind + 1) % 2 ? { width: '100%', backgroundColor: '#f9f9f9', padding: 15 } : { width: '100%', backgroundColor: '#fff', padding: 15 }}>
																				<div style={{ width: '100%', display: 'flex', justifyContent: 'row', marginBottom: 3 }}>
																					<div style={{ width: '90%', display: 'flex', justifyContent: 'row', alignItems: 'center', }}>
																						<p className="font-16 m-b0" style={{ color: 'blue', fontWeight: 'bold' }}>{val.title}</p>
																					</div>
																					<div style={{ width: '10%', display: 'flex', justifyContent: 'row', justifyContent: 'flex-end' }}>
																						<Link className=' mr-3' onClick={() => projectEdit(val.project_id)} ><i className="fa fa-pencil " style={{ color: 'orange' }}></i></Link>
																						<Link className=' mr-3' onClick={() => projectDeleteHandler(val.project_id)} ><i className="fa fa-trash " style={{ color: 'red' }}></i></Link>
																					</div>
																				</div>
																				<div style={{ marginTop: "-1%", marginBottom: 3 }}>
																					<p className="m-b0 font-14">{val.work_form} To {val.project_status === 'In Progress' ? 'In Progress' : val.work_till}  </p>
																				</div>
																				<div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-1%", marginBottom: 3 }}>
																					<label className="font-14 m-b0"style={{color:"#000000"}}>Employment/Education :</label>
																					<p className="m-b0 font-14 ml-1">{val.education_or_employment}</p>
																				</div>
																				<div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-1%", marginBottom: 3 }}>
																					<label className="font-14 m-b0" style={{color:"#000000"}}>Client :</label>
																					<p className="m-b0 font-14 ml-1">{val.client_name}</p>
																				</div>
																				<div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: "-1%", marginBottom: 3, height: 'auto' }}>
																					<label className="font-14 m-b0" style={{ width: '11%',color:"#000000" }}>Description :</label>
																					<p className="m-b0 font-14" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '89%', lineHeight: '18px' }} >{val.project_details}</p>
																				</div>
																			</div>
																		</div>
																)
															})
															:
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add your Project and make your profile Strength stronger.</p>}
												<Modal className="modal fade modal-bx-info editor" show={projectModal} onHide={setProjectModal}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="ProjectsModalLongTitle">Add Projects</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setProjectModal(false); }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Project Title <span style={{color:'red'}}>*</span></label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Project Title"
																					name='title'
																					value={project.title}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{project.title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																				{project.title ? (project.title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Tag this project with your Employment/Education</label>
																				<select
																					className="form-control"
																					name='education_or_employment'
																					value={project.education_or_employment}
																					onChange={projectOnChange}
																					style={{ height: 'auto',color:"#000000" }}
																				>
																					<option value={""} disabled>======== Select ========</option>
																					<option  style={{color:"#000000"}}>Class 10th</option>
																					<option  style={{color:"#000000"}}>Class 12th</option>
																					<option  style={{color:"#000000"}}>Gaduation</option>
																					<option  style={{color:"#000000"}}>Post Graduation</option>
																					<option  style={{color:"#000000"}}>Employment</option>
																				</select>
																				{/* {project.education_or_employment ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.tag}</span> : null)} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Client</label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Client Name"
																					name='client_name'
																					value={project.client_name}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {project.client_name ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null)}
																				{project.client_name ? (project.client_name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Project Status <span style={{color:'red'}}>*</span></label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="inprogress"
																								name="project_status"
																								value='In Progress'
																								onChange={projectOnChange}
																								style={{color:"#000000"}}
																								checked={project.project_status === 'In Progress' ? true : false}
																							/>
																							<label className="custom-control-label" for="inprogress">In Progress</label>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="finished"
																								name="project_status"
																								value='Finished'
																								onChange={projectOnChange}
																								checked={project.project_status === 'Finished' ? true : false}
																							/>
																							<label className="custom-control-label" for="finished">Finished</label>
																						</div>
																					</div>
																				</div>
																				{project.project_status ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.status}</span> : null)}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-6">
																			<div className="form-group">
																				<label>Started Working From <span style={{color:'red'}}>*</span></label>
																				<input
																					autoComplete="off"
																					type="date"
																					className="form-control"
																					name='work_form'
																					value={project.work_form}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{project.work_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{project.work_form ? +d7 >= +d6 || +d6 <= +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																			</div>
																		</div>

																		{project.project_status === 'In Progress' ? null :
																			<div className="col-lg-12 col-md-12">
																				<div className="form-group">
																					<label>Worked Till <span style={{color:'red'}}>*</span></label>
																					<input
																						autoComplete="off"
																						type="date"
																						className="form-control"
																						name='work_till'
																						value={project.work_till}
																						onChange={projectOnChange}
																						style={{color:"#000000"}}
																					/>
																					{project.work_till ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																					{project.work_till ? (+d7 >= +d6 && +d7 < +d) ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																				</div>
																			</div>}

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Details of Project</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name='project_details'
																					value={project.project_details}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				></textarea>
																				{/* {project.project_details ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																				{project.project_details ? (project.project_details.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setProjectModal(false) }}>Cancel</button>
																<button type="button" className="site-button green" onClick={projectHandler}>Save</button>
															</div>
														</div>
													</div>
												</Modal>

												<Modal className="modal fade modal-bx-info editor" show={projectEditModal} onHide={setProjectEditModal}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="ProjectsModalLongTitle">Add Projects</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setProjectEditModal(false); }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Project Title <span style={{color:'red'}}>*</span></label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Project Title"
																					name='title'
																					value={project.title}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{project.title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																				{project.title ? (project.title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Tag this project with your Employment/Education</label>
																				<select
																					className="form-control"
																					name='education_or_employment'
																					value={project.education_or_employment}
																					onChange={projectOnChange}
																					style={{ height: 'auto',color:"#000000" }}
																				>
																					<option value={""}disabled  style={{color:"#000000"}}>======== Select ========</option>
																					<option style={{color:"#000000"}}>Class 10th</option>
																					<option style={{color:"#000000"}}>Class 12th</option>
																					<option style={{color:"#000000"}}>Gaduation</option>
																					<option style={{color:"#000000"}}>Post Graduation</option>
																					<option style={{color:"#000000"}}>Employment</option>
																				</select>
																				{/* {project.education_or_employment ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.tag}</span> : null)} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Client</label>
																				<input
																					type="email"
																					className="form-control"
																					placeholder="Enter Client Name"
																					name='client_name'
																					value={project.client_name}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {project.client_name ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null)}
																				{project.client_name ? (project.client_name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Project Status <span style={{color:'red'}}>*</span></label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="inprogress"
																								name="project_status"
																								value='In Progress'
																								onChange={projectOnChange}
																								style={{color:"#000000"}}
																								checked={project.project_status === 'In Progress' ? true : false}
																							/>
																							<label className="custom-control-label" for="inprogress">In Progress</label>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								type="radio"
																								className="custom-control-input"
																								id="finished"
																								name="project_status"
																								value='Finished'
																								onChange={projectOnChange}
																								style={{color:"#000000"}}
																								checked={project.project_status === 'Finished' ? true : false}
																							/>
																							<label className="custom-control-label" for="finished">Finished</label>
																						</div>
																					</div>
																				</div>
																				{project.project_status ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.status}</span> : null)}
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-6">
																			<div className="form-group">
																				<label>Started Working From <span style={{color:'red'}}>*</span></label>
																				<input
																					autoComplete="off"
																					type="date"
																					className="form-control"
																					name='work_form'
																					value={project.work_form}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				/>
																				{project.work_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{project.work_form ? +d7 >= +d6 || +d6 <= +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																			</div>
																		</div>

																		{project.project_status === 'In Progress' ? null :
																			<div className="col-lg-12 col-md-12">
																				<div className="form-group">
																					<label>Worked Till <span style={{color:'red'}}>*</span></label>
																					<input
																						autoComplete="off"
																						type="date"
																						className="form-control"
																						name='work_till'
																						value={project.work_till}
																						onChange={projectOnChange}
																						style={{color:"#000000"}}
																					/>
																					{project.work_till ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																					{project.work_till ? (+d7 <= +d6 || +d7 < +d) ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																				</div>
																			</div>}

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Details of Project</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name='project_details'
																					value={project.project_details}
																					onChange={projectOnChange}
																					style={{color:"#000000"}}
																				></textarea>
																				{/* {project.project_details ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																				{project.project_details ? (project.project_details.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setProjectEditModal(false) }}>Cancel</button>
																<button type="button" className="site-button orange" onClick={projectUpdateHandler}>Update</button>
															</div>
														</div>
													</div>
												</Modal>

												<Modal show={projectDeleteModal} onHide={setProjectDeleteModal} className="modal fade modal-bx-info">
													<div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Delete Project</h5>
																<button type="button" className="close" onClick={() => setProjectDeleteModal(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<p className="text-center"> Are you sure to delete <span style={{ color: 'red' }}> {project.title} </span> ? </p>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-danger" onClick={DeleteProject} >Delete</button>
																<button type="button" className="btn btn-primary" onClick={() => { setProjectDeleteModal(false); }}>Close</button>
															</div>
														</div>
													</div>
												</Modal>


											</div>
											<div id="profile_summary_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b15">Profile Summary {profileSummary.profileSummay_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 5%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#profilesummary" className="site-button add-btn button-sm" onClick={() => { setProfileSummaryModal(true); getProfilesummaryData(); }}> {profileSummary.profileSummay_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {profileSummary.profileSummay_id > 0 ? "Edit" : "Add"}</a>
												</div>
												{

													profileSummaryLoader ?
														<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														profileSummary.profileSummay_id > 0 ?
															<p className="m-b0">{profileSummary.summary}</p>
															:
															<p className="m-b0">Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.</p>
												}
												<Modal show={profileSummaryModal} onHide={setProfileSummaryModal} className="modal fade modal-bx-info editor" >
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="ProfilesummaryModalLongTitle">Profile Summary</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setProfileSummaryModal(false); getProfilesummaryData(); }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">

																<p>Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.</p>

																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Details of Project</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name="summary"
																					value={profileSummary.summary}
																					onChange={profileSummaryOnChange}
																					style={{color:"#000000"}}
																				></textarea>
																				{profileSummary.summary ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.profileSummary}</span> : null)}
																				{profileSummary.summary ? (profileSummary.summary.length < 50 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minSummary}</span> : null) : null}
																				{profileSummary.summary ? (profileSummary.summary.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.profileSummaryLimit}</span> : null) : null}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" data-dismiss="modal" onClick={() => { setProfileSummaryModal(false); getProfilesummaryData() }}>Cancel</button>
																{
																	profileSummary.profileSummay_id ?
																		<button type="button" className="site-button orange" onClick={profileSummaryUpdateHandler} >Update</button> :
																		<button type="button" className="site-button green" onClick={profileSummaryHandler} >Save</button>
																}
															</div>
														</div>
													</div>
												</Modal>
											</div>
											<div id="accomplishments_bx" className="job-bx m-b30">
												<h5 className="m-b10">Accomplishments {onlineProfile.onlinePrpfile_id > 0 && workSample.workSample_id > 0 && whitePaper.whitePaper_id > 0 && presentations.presentation_id > 0 && patents.patent_id > 0 && certifications.certification_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 30%</span>}</h5>
												<div className="list-row">
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">Online Profile</h6>
															<a href="#" data-toggle="modal" data-target="#accomplishments" className="site-button add-btn button-sm" onClick={() => { setOnlineProfileModal(true) }}>
																{onlineProfile.onlinePrpfile_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {onlineProfile.onlinePrpfile_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															onlineProfileLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :
																onlineProfile.onlinePrpfile_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Profile Name :</label>
																					<div style={{ width: '85%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{onlineProfile.social_profile}</p>
																					</div>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Link :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{onlineProfile.url}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', lineHeight: '8px' }}>
																				<label className="font-12 m-b0 mt-1" style={{color:"#000000"}}>About :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{onlineProfile.description}</p>
																				</div>
																			</div>

																		</div>
																	</div>
																	:


																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add link to Online profiles (e.g. Linkedin, Facebook etc.).</p>
														}
														<Modal show={onlineProfileModal} onHide={setOnlineProfileModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="AccomplishmentsModalLongTitle">Online Profiles</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setOnlineProfileModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Social Profile <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="Social Profile Name"
																							name='social_profile'
																							value={onlineProfile.social_profile}
																							onChange={onlineProfileOnChange}
																							style={{color:"#000000"}}
																						/>
																						{onlineProfile.social_profile ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.socialProfile}</span> : null)}
																						{onlineProfile.social_profile ? (onlineProfile.social_profile.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.socialProfileLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>URL <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="e.g. www.google.com"
																							name='url'
																							value={onlineProfile.url}
																							onChange={onlineProfileOnChange}
																							style={{color:"#000000"}}
																						/>
																						{onlineProfile.url ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.URL}</span> : null)}
																						{onlineProfile.url ? (onlineProfile.url.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Description </label>
																						<textarea
																							className="form-control"
																							placeholder="Type Description" name='description'
																							value={onlineProfile.description}
																							onChange={onlineProfileOnChange}
																							style={{color:"#000000"}}
																						></textarea>
																						{/* {onlineProfile.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																						{onlineProfile.description ? (onlineProfile.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																	<div className="modal-footer">
																		<button type="button" className="site-button" data-dismiss="modal" onClick={() => { setOnlineProfileModal(false) }}>Cancel</button>
																		{
																			onlineProfile.onlinePrpfile_id > 0 ?
																				<button type="button" className="site-button orange" onClick={onlineProfileUpdate} >Update</button> :
																				<button type="button" className="site-button green" onClick={onlineProfileHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">Work Sample</h6>
															<a href="#" data-toggle="modal" data-target="#worksample" className="site-button add-btn button-sm" onClick={() => { setWorkSampleModal(true) }}>
																{workSample.workSample_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {workSample.workSample_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															workSampleLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :

																workSample.workSample_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Work Title :</label>
																					<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '1px' }}>{workSample.work_sample_title}</p>
																					</div>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Link :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '1px' }}>{workSample.url}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Duration :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '1px' }}>{workSample.duration_form} to {workSample.duration_to}    </p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>About :</label>
																				<div style={{ width: '88%', overflowWrap: 'break-word', lineHeight: '8px' }}>
																					<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '1px' }}>{workSample.description}</p>
																				</div>
																			</div>
																		</div>
																	</div>

																	:
																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add link to your Projects (e.g. Github links etc.).</p>}
														<Modal show={workSampleModal} onHide={setWorkSampleModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="WorksampleModalLongTitle">Work Sample</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setWorkSampleModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Work Title  <span style={{color:'red'}}>*</span></label>
																						<input
																							type="text"
																							className="form-control"
																							placeholder="Enter Title"
																							name='work_sample_title'
																							value={workSample.work_sample_title}
																							onChange={workSampleOnChange}
																							style={{color:"#000000"}}
																						/>
																						{workSample.work_sample_title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																						{workSample.work_sample_title ? (workSample.work_sample_title.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>URL <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="www.google.com"
																							name='url'
																							value={workSample.url}
																							onChange={workSampleOnChange}
																							style={{color:"#000000"}}
																						/>
																						{workSample.url ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.URL}</span> : null)}
																						{workSample.url ? (workSample.url.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<div className="custom-control custom-checkbox">
																							<input
																								type="checkbox"
																								className="custom-control-input"
																								id="check1"
																								value={currentlyWorking}
																								onChange={(e) => {
																									if (e.target.checked === true) {
																										setCurrentlyWorking('Yes')
																									} else {
																										setCurrentlyWorking('No')
																									}
																								}}
																								style={{color:"#000000"}}
																								defaultChecked={workSample.currently_working === "Yes" ? true : false}
																							/>
																							<label className="custom-control-label" for="check1">I am currently working on this  <span style={{color:'red'}}>*</span></label>
																						</div>
																					</div>
																				</div>
																				<div className="col-lg-6 col-md-6">
																					<div className="form-group">
																						<label>Duration From  <span style={{color:'red'}}>*</span></label>
																						<input
																							autoComplete="off"
																							type="date"
																							className="form-control"
																							placeholder="Enter date"
																							name='duration_form'
																							value={workSample.duration_form}
																							onChange={workSampleOnChange}
																							style={{color:"#000000"}}
																						/>
																						{workSample.duration_form ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																						{workSample.duration_form ? (+dt1 <= +dt2 && +dt1 <= +d) ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																					</div>
																				</div>
																				{
																					currentlyWorking === 'Yes' ? null :
																						<div className="col-lg-6 col-md-6">
																							<div className="form-group">
																								<label>Duration To  <span style={{color:'red'}}>*</span></label>
																								<input
																									autoComplete="off"
																									type="date"
																									className="form-control"
																									placeholder="Enter date"
																									name='duration_to'
																									value={workSample.duration_to === 'Present' ? new Date() : workSample.duration_to}
																									onChange={workSampleOnChange}
																									style={{color:"#000000"}}
																								/>
																								{workSample.duration_to ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																								{workSample.duration_to ? (+dt1 <= +dt2 && +dt2 <= +d) ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																							</div>
																						</div>
																				}
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Description</label>
																						<textarea
																							className="form-control"
																							placeholder="Type Description"
																							name='description'
																							value={workSample.description}
																							onChange={workSampleOnChange}
																							style={{color:"#000000"}}
																						></textarea>
																						{/* {workSample.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																						{workSample.description ? (workSample.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																	<div className="modal-footer">
																		<button type="button" className="site-button" data-dismiss="modal" onClick={() => { setWorkSampleModal(false) }}>Cancel</button>
																		{
																			workSample.workSample_id > 0 ?
																				<button type="button" className="site-button orange" onClick={workSampleProfileUpdate} >Update</button> :
																				<button type="button" className="site-button green" onClick={workSampleHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">White Paper / Research Publication / Journal Entry</h6>
															<a href="#" data-toggle="modal" data-target="#journalentry" className="site-button add-btn button-sm" onClick={() => { setWhitePaperModal(true) }}>
																{whitePaper.whitePaper_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {whitePaper.whitePaper_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															whitePaperLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :
																whitePaper.whitePaper_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowWrap: 'break-word' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Title :</label>
																					<div style={{ width: '85%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{whitePaper.title}</p>
																					</div>
																				</div>
																				<div style={{ width: '10%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Link :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '2px' }}>{whitePaper.url}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowWrap: 'break-word' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Published On :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}> {whitePaper.publish_on}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', lineHeight: '8px' }}>
																				<label className="font-12 m-b0 mt-1" style={{color:"#000000"}}>About :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1 " style={{ lineHeight: '15px', marginTop: '1px' }}>{whitePaper.description}</p>
																				</div>
																			</div>

																		</div>

																	</div> :
																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add links to your Online publications.</p>
														}
														<Modal show={whitePaperModal} onHide={setWhitePaperModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="JournalentryModalLongTitle">White Paper / Research Publication / Journal Entry</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setWhitePaperModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Title <span style={{color:'red'}}>*</span></label>
																						<input
																							type="text"
																							className="form-control"
																							placeholder="Enter Title"
																							name='title'
																							value={whitePaper.title}
																							onChange={whitePaperOnChange}
																							style={{color:"#000000"}}
																						/>
																						{whitePaper.title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																						{whitePaper.title ? (whitePaper.title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>URL <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="www.google.com"
																							name='url'
																							value={whitePaper.url}
																							onChange={whitePaperOnChange}
																							style={{color:"#000000"}}
																						/>
																						{whitePaper.url ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.URL}</span> : null)}
																						{whitePaper.url ? (whitePaper.url.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Published On <span style={{color:'red'}}>*</span></label>
																						<input
																							autoComplete="off"
																							type="date"
																							className="form-control"
																							placeholder="www.google.com"
																							name='publish_on'
																							value={whitePaper.publish_on}
																							onChange={whitePaperOnChange}
																							style={{color:"#000000"}}
																						/>
																						{whitePaper.publish_on ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																						{whitePaper.publish_on ? +d1 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																					</div>
																					<div className="col-lg-12 col-md-12">
																						<div className="form-group">
																							<label>Description</label>
																							<textarea
																								className="form-control"
																								placeholder="Type Description"
																								name='description'
																								value={whitePaper.description}
																								onChange={whitePaperOnChange}
																								style={{color:"#000000"}}
																							></textarea>
																							{/* {whitePaper.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																							{whitePaper.description ? (whitePaper.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																						</div>
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																	<div className="modal-footer">
																		<button type="button" className="site-button" data-dismiss="modal" onClick={() => { setWhitePaperModal(false) }}>Cancel</button>
																		{
																			whitePaper.whitePaper_id > 0 ?
																				<button type="button" className="site-button orange" onClick={whitePaperUpdateHandler} >Update</button> :
																				<button type="button" className="site-button green" onClick={whitePaperHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">Presentation</h6>
															<a href="#" data-toggle="modal" data-target="#presentation" className="site-button add-btn button-sm" onClick={() => { setPresentationModal(true) }}>
																{presentations.presentation_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {presentations.presentation_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															presentationLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :

																presentations.presentation_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Title :</label>
																					<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12  ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{presentations.title}</p>
																					</div>
																				</div>

																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Link :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px' }}>{presentations.url}</p>
																				</div>
																			</div>

																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>About :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px' }}>{presentations.description}</p>
																				</div>
																			</div>

																		</div>
																	</div> :
																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add links to your Online presentations (e.g. Slideshare presentation links etc.).</p>
														}
														<Modal show={presentationModal} onHide={setPresentationModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="PresentationModalLongTitle">Presentation</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setPresentationModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Title <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="Enter Title"
																							name='title'
																							value={presentations.title}
																							onChange={presentationOnChange}
																							style={{color:"#000000"}}
																						/>
																						{presentations.title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																						{presentations.title ? (presentations.title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>URL <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="www.google.com"
																							name='url'
																							value={presentations.url}
																							onChange={presentationOnChange}
																							style={{color:"#000000"}}
																						/>
																						{presentations.url ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.URL}</span> : null)}
																						{presentations.url ? (presentations.url.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Description</label>
																						<textarea
																							className="form-control"
																							placeholder="Type Description"
																							name='description'
																							value={presentations.description}
																							onChange={presentationOnChange}
																							style={{color:"#000000"}}
																						>
																						</textarea>
																						{/* {presentations.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																						{presentations.description ? (presentations.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																	<div className="modal-footer">
																		<button type="button" className="site-button" data-dismiss="modal" onClick={() => { setPresentationModal(false) }}>Cancel</button>
																		{
																			presentations.presentation_id > 0 ?
																				<button type="button" className="site-button orange" onClick={presentationUpdate} >Update</button> :
																				<button type="button" className="site-button green" onClick={presentationsHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">Patent</h6>
															<a href="#" data-toggle="modal" data-target="#patent" className="site-button add-btn button-sm" onClick={() => { setPatentModal(true) }}>
																{patents.patent_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {patents.patent_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															patentLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :
																patents.patent_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Title :</label>
																					<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{patents.title}</p>
																					</div>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Link :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{patents.url}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Patent Office :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{patents.patent_office}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Status :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{patents.status}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Application Number :</label>
																				<div style={{ width: '85%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{patents.application_number}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Published On :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>  {patents.publish_on}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', lineHeight: '8px' }}>
																				<label className="font-12 m-b0 mt-1" style={{color:"#000000"}}>About :</label>
																				<div style={{ width: '90%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '0.3px' }}>{patents.description}</p>
																				</div>
																			</div>
																		</div>
																	</div> :
																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add details of Patents you have filed.</p>
														}

														<Modal show={patentModal} onHide={setPatentModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="PatentModalLongTitle">Patent</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setPatentModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Title <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="Enter Title"
																							name='title'
																							value={patents.title}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						/>
																						{patents.title ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																						{patents.title ? (patents.title.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>URL</label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="www.google.com"
																							name='url'
																							value={patents.url}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						/>
																						{/* {patents.url ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.URL}</span> : null)}
																						{patents.url ? (patents.url.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.url}</span> : null) : null} */}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Patent Office </label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="Enter Patent Office"
																							name='patent_office'
																							value={patents.patent_office}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						/>
																						{/* {patents.patent_office ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.patentOffice}</span> : null)}
																						{patents.patent_office ? (patents.patent_office.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.patentOfficeLimit}</span> : null) : null} */}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Status <span style={{color:'red'}}>*</span></label>
																						<div className="row">
																							<div className="col-lg-6 col-md-6">
																								<div className="custom-control custom-radio">
																									<input
																										type="radio"
																										className="custom-control-input"
																										id="check2"
																										name="status"
																										value='Patent Issued'
																										onChange={patentsOnChange}
																										style={{color:"#000000"}}
																										checked={patents.status === 'Patent Issued' ? true : false}
																									/>

																									<label className="custom-control-label" for="check2">Patent Issued</label>
																								</div>
																							</div>
																							<div className="col-lg-6 col-md-6">
																								<div className="custom-control custom-radio">
																									<input
																										type="radio"
																										className="custom-control-input"
																										id="check3"
																										name="status"
																										value='Patent pending'
																										onChange={patentsOnChange}
																										style={{color:"#000000"}}
																										checked={patents.status === 'Patent pending' ? true : false}
																									/>
																									<label className="custom-control-label" for="check3">Patent pending</label>
																								</div>
																							</div>
																						</div>
																						{patents.status ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.status}</span> : null)}

																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Application Number <span style={{color:'red'}}>*</span></label>
																						<input
																							type="email"
																							className="form-control"
																							placeholder="Enter Application Number"
																							name='application_number'
																							value={patents.application_number}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						/>
																						{patents.application_number ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.application}</span> : null)}
																						{patents.application_number ? (patents.application_number.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.applicationLimit}</span> : null) : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Published On <span style={{color:'red'}}>*</span></label>
																						<input
																							autoComplete="off"
																							type="date"
																							className="form-control"
																							placeholder="Enter Application Number"
																							name='publish_on'
																							value={patents.publish_on}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						/>
																						{patents.publish_on ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																						{patents.publish_on ? +d2 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Description</label>
																						<textarea
																							className="form-control"
																							placeholder="Type Description"
																							name='description'
																							value={patents.description}
																							onChange={patentsOnChange}
																							style={{color:"#000000"}}
																						></textarea>
																						{/* {patents.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																						{patents.description ? (patents.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																	<div className="modal-footer">
																		<button type="button" className="site-button" data-dismiss="modal" onClick={() => { setPatentModal(false) }}>Cancel</button>
																		{
																			patents.patent_id > 0 ?
																				<button type="button" className="site-button orange" onClick={patentUpdateUpdate} >Update</button> :
																				<button type="button" className="site-button green" onClick={patentsHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
													<div className="list-line">
														<div className="d-flex">
															<h6 className="font-14 m-b5">Certification</h6>
															<a href="#" data-toggle="modal" data-target="#certification" className="site-button add-btn button-sm" onClick={() => { setCertificationModal(true) }}>
																{certifications.certification_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {certifications.certification_id > 0 ? 'Edit' : " Add"}
															</a>
														</div>
														{
															certificationLoader ?
																<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<Oval
																		height={20}
																		width={20}
																		color="#4fa94d"
																		wrapperStyle={{}}
																		wrapperClass=""
																		visible={true}
																		ariaLabel='oval-loading'
																		secondaryColor="#4fa94d"
																		strokeWidth={2}
																		strokeWidthSecondary={2}
																	/>
																</div> :

																certifications.certification_id > 0 ?
																	<div>
																		<div style={{ width: '100%', backgroundColor: '#f9f9f9', padding: 8, marginTop: 5 }}>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
																				<div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																					<label className="font-12 m-b0" style={{color:"#000000"}}>Certification Name :</label>
																					<div style={{ width: '80%', overflowWrap: 'break-word', }}>
																						<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{certifications.certification_name}</p>
																					</div>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																				<label className="font-12 m-b0" style={{color:"#000000"}}>Certification Body :</label>
																				<div style={{ width: '80%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '2px' }}>{certifications.certification_body}</p>
																				</div>
																			</div>
																			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', lineHeight: '8px' }}>
																				<label className="font-12 m-b0 mt-1" style={{color:"#000000"}}>Year Of Onlabel :</label>
																				<div style={{ width: '80%', overflowWrap: 'break-word', }}>
																					<p className="m-b0 font-12 ml-1" style={{ lineHeight: '15px', marginTop: '1.6px' }}>{certifications.year_onable}</p>
																				</div>
																			</div>
																		</div>
																	</div> :
																	<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add details of Certification you have filed.</p>}
														<Modal show={certificationModal} onHide={setCertificationModal} className="modal fade modal-bx-info editor" >
															<div className="modal-dialog my-0 mx-0" role="document">
																<div className="modal-content">
																	<div className="modal-header">
																		<h5 className="modal-title" id="CertificationModalLongTitle">Certification</h5>
																		<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setCertificationModal(false) }}>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div className="modal-body">
																		<form>
																			<div className="row">
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Certification Name <span style={{color:'red'}}>*</span></label>
																						<input
																							type="text"
																							className="form-control"
																							placeholder="Enter Certification Name"
																							name='certification_name'
																							value={certifications.certification_name}
																							onChange={certificationOnChange}
																							style={{color:"#000000"}}
																						/>
																						{certifications.certification_name ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.title}</span> : null)}
																						{certifications.certification_name ? (certifications.certification_name.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.titleLimit}</span> : null) : null}

																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12">
																					<div className="form-group">
																						<label>Certification Body</label>
																						<input
																							type="text"
																							className="form-control"
																							placeholder="Enter Certification Body"
																							name='certification_body'
																							value={certifications.certification_body}
																							onChange={certificationOnChange}
																							style={{color:"#000000"}}
																						/>
																						{/* {certifications.certification_body ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.body}</span> : null)}
																						{certifications.certification_body ? (certifications.certification_body.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.bodyLimit}</span> : null) : null} */}
																					</div>
																				</div>
																			</div>
																			<div className="col-lg-12 col-md-12">
																				<div className="form-group">
																					<label>Year Onlabel <span style={{color:'red'}}>*</span></label>
																					<input
																						autoComplete="off" type="date"
																						className="form-control"
																						placeholder="Enter Certification Body"
																						name='year_onable'
																						value={certifications.year_onable}
																						onChange={certificationOnChange}
																						style={{color:"#000000"}}
																					/>
																					{certifications.year_onable ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																					{certifications.year_onable ? +d3 < +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}

																				</div>
																			</div>

																		</form>
																	</div>
																	<div className="modal-footer">
																		{
																			certifications.certification_id > 0 ?
																				<button type="button" className="site-button orange" onClick={certificationUpdateHandler} >Update</button> :
																				<button type="button" className="site-button green" onClick={certificationsHandler} >Save</button>
																		}
																	</div>
																</div>
															</div>
														</Modal>
													</div>
												</div>
											</div>
											<div id="desired_career_profile_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b30">Desired Career Profile {careerProfile.careerProfile_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 10%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#desiredprofile" className="site-button add-btn button-sm" onClick={() => { setCareerModal(true) }}>
														{careerProfile.careerProfile_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>} {careerProfile.careerProfile_id > 0 ? "Edit" : "Add"}
													</a>
												</div>
												<Modal className="modal fade modal-bx-info editor" show={careerModal} onHide={setCareerModal}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="DesiredprofileModalLongTitle">Desired Career Profile </h5>
																<button type="button" className="close" onClick={() => { setCareerModal(false); setShowError(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Industry <span style={{color:'red'}}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='industry'
																					value={careerProfile.industry}
																					onChange={careerProfileOnChange}
																					style={{ height: 'auto',color:"#000000" }}
																				>
																					<option value={''} disabled>---SELECT---</option>
																					<option style={{color:"#000000"}}>Accounting / Finance</option>
																					<option style={{color:"#000000"}}>Banking / Financial Services / Broking</option>
																					<option style={{color:"#000000"}}>Education / Teaching / Training</option>
																					<option style={{color:"#000000"}}>IT-Hardware &amp; Networking</option>
																					<option style={{color:"#000000"}}>Other</option>
																				</Form.Control>
																				{careerProfile.industry ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.industry}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Functional Area / Department</label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='functional_area'
																					value={careerProfile.functional_area}
																					onChange={careerProfileOnChange}
																					style={{ height: 'auto',color:"#000000" }}
																				>
																					<option value={''} disabled>---SELECT---</option>
																					<option style={{color:"#000000"}}>Agent</option>
																					<option style={{color:"#000000"}}>Architecture / Interior Design</option>
																					<option style={{color:"#000000"}}>Beauty / Fitness / Spa Services</option>
																					<option style={{color:"#000000"}}>IT Hardware / Technical Support</option>
																					<option style={{color:"#000000"}}>IT Software - System Programming</option>
																					<option style={{color:"#000000"}}>Other</option>
																				</Form.Control>
																				{/* {careerProfile.functional_area ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.functionalArea}</span> : null)} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Role <span style={{color:'red'}}>*</span></label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='Role'
																					value={careerProfile.Role}
																					onChange={careerProfileOnChange}
																					style={{ height: 'auto',color:'#000000' }}
																				>
																					<option value={''} disabled>---SELECT---</option>
																					<option style={{color:"#000000"}}>Creative</option>
																					<option style={{color:"#000000"}}>Web Designer</option>
																					<option style={{color:"#000000"}}>Graphic Designer</option>
																					<option style={{color:"#000000"}}>National Creative Director</option>
																					<option style={{color:"#000000"}}>Fresher</option>
																					<option style={{color:"#000000"}}>Other</option>
																				</Form.Control>
																				{careerProfile.Role ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.role}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Job Type <span style={{color:'red'}}>*</span></label>
																				<div className="row">
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"

																								type="radio"
																								className="custom-control-input"
																								id="permanent"
																								name="job_type"
																								value="Permanent"
																								onChange={careerProfileOnChange}
																								
																								checked={careerProfile.job_type === "Permanent" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="permanent">Permanent</label>
																						</div>
																					</div>
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="contractual"
																								name="job_type"
																								value='Contractual'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.job_type === "Contractual" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="contractual">Contractual</label>
																						</div>
																					</div>
																				</div>
																				{careerProfile.job_type ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.jobType}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Employment Type</label>
																				<div className="row">
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="fulltime"
																								name="employment_type"
																								value='Full-Time'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.employment_type === "Full-Time" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="fulltime">Full Time</label>
																						</div>
																					</div>
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="parttime"
																								name="employment_type"
																								value='Part-Time'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.employment_type === "Part-Time" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="parttime">Part Time</label>
																						</div>
																					</div>
																				</div>
																				{/* {careerProfile.employment_type ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.employmentType}</span> : null)} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Preferred Shift</label>
																				<div className="row">
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="day"
																								name="preffered_shift"
																								value='Day'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.preffered_shift === "Day" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="day">Day</label>
																						</div>
																					</div>
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="night"
																								name="preffered_shift"
																								value='Night'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.preffered_shift === "Night" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="night">Night</label>
																						</div>
																					</div>
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="flexible"
																								name="preffered_shift"
																								value='Part Time'
																								onChange={careerProfileOnChange}
																								checked={careerProfile.preffered_shift === "Part Time" ? true : false}
																							/>
																							<label className="custom-control-label" htmlFor="flexible">Part Time</label>
																						</div>
																					</div>
																				</div>
																				{/* {careerProfile.preffered_shift ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.shift}</span> : null)} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-6">
																			<div className="form-group">

																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<label>Availability to Join <span style={{color:'red'}}>*</span></label>
																						<input
																							autoComplete="off" type="date"
																							className="form-control"
																							placeholder="Enter Certification Body"
																							name='availability_to_join'
																							value={careerProfile.availability_to_join}
																							onChange={careerProfileOnChange}
																							style={{color:"#000000"}}
																						/>
																						{careerProfile.availability_to_join ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																						{careerProfile.availability_to_join ? +d5 > +d ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidDate}</span> : null}
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<label>Expected Salary p.a.</label>
																						<Form.Control
																							autoComplete="off" as="select"
																							name='expected_salary'
																							value={careerProfile.expected_salary}
																							onChange={careerProfileOnChange}
																							style={{ height: 'auto',color:'#000000' }}
																						>
																							<option value={''} disabled>---SELECT---</option>
																							<option style={{color:"#000000"}}>1 lakh</option>
																							<option style={{color:"#000000"}}>2 lakh</option>
																							<option style={{color:"#000000"}} >5 lakh</option>
																							<option style={{color:"#000000"}}>4 lakh</option>
																							<option style={{color:"#000000"}}>5 lakh</option>
																							<option style={{color:"#000000"}}>6 lakh</option>
																							<option style={{color:"#000000"}}>7 lakh</option>
																							<option style={{color:"#000000"}}>8 lakh</option>
																							<option style={{color:"#000000"}}>9 lakh</option>
																							<option style={{color:"#000000"}}>10 lakh</option>
																							<option style={{color:"#000000"}}>11 lakh</option>
																							<option style={{color:"#000000"}}>12 lakh</option>
																							<option style={{color:"#000000"}}>15 lakh</option>
																							<option style={{color:"#000000"}}>14 lakh</option>
																							<option style={{color:"#000000"}}>15 lakh</option>
																							<option style={{color:"#000000"}}>16 lakh</option>
																							<option style={{color:"#000000"}}>17 lakh</option>
																							<option style={{color:"#000000"}}>18 lakh</option>
																							<option style={{color:"#000000"}}>19 lakh</option>
																							<option style={{color:"#000000"}}>20 lakh</option>
																						</Form.Control>
																						{/* {careerProfile.expected_salary ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Salary}</span> : null)} */}

																					</div>


																				</div>
																			</div>
																		</div>

																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Desired Location</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<label style={{ fontSize: 14 }}>Country</label>
																						<Form.Control
																							autoComplete="off" as="select"
																							name='country'
																							value='India'
																							onChange={careerProfileOnChange}
																							style={{ height: 'auto',color:'#000000' }}
																						>
																							<option value='' disabled>---SELECT---</option>
																							{
																								countryNames.map((val) => {
																									return (
																										<option key={val.id} style={{color:"#000000"}}>{val.name}</option>
																									)
																								})
																							}
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<label style={{ fontSize: 14 }}>State</label>
																						<Form.Control
																							autoComplete="off" as="select"
																							name='state'
																							value='Odisha'
																							onChange={careerProfileOnChange}
																							style={{ height: 'auto',color:'#000000' }}
																						>
																							<option value=''>---SELECT---</option>
																							{
																								stateNames.map((val) => {
																									return (
																										<option key={val.id} style={{color:"#000000"}}>{val.name}</option>
																									)
																								})
																							}
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<label style={{ fontSize: 14 }}>City</label>
																						<Form.Control
																							autoComplete="off" as="select"
																							name='city'
																							value={careerProfile.city}
																							onChange={careerProfileOnChange}
																							style={{ height: 'auto',color:"#000000"}}
																						>
																							<option value=''disabled>---SELECT---</option>
																							{
																								cityLists.map((val) => {
																									return (
																										<option key={val.id} style={{color:"#000000"}}>{val.name}</option>
																									)
																								})
																							}

																						</Form.Control>
																						{/* {careerProfile.city ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.city}</span> : null)} */}
																					</div>
																				</div>

																			</div>
																		</div>

																	</div>
																</form>

															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setCareerModal(false); setShowError(false) }}>Cancel</button>
																{
																	careerProfile.careerProfile_id > 0 ?
																		<button type="button" className="site-button orange" onClick={careerProfileUpdateHandler}  >Update</button> :
																		<button type="button" className="site-button green" onClick={careerProfileHandler} >Save</button>
																}

															</div>
														</div>
													</div>
												</Modal>
												{
													careerLoader ?
														<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														careerProfile.careerProfile_id > 0 ?
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-6">
																	<div className="clearfix m-b20">
																		<label className="m-b0">Industry</label>
																		<span className="clearfix font-13">{careerProfile.industry}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Role</label>
																		<span className="clearfix font-13">{careerProfile.Role}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Employment Type</label>
																		<span className="clearfix font-13">{careerProfile.employment_type}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Availability to Join</label>
																		<span className="clearfix font-13">{careerProfile.availability_to_join}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Desired Location</label>
																		<span className="clearfix font-13">{careerProfile.city + " ,"} {careerProfile.state + ' ,'} {careerProfile.country}</span>
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-6">
																	<div className="clearfix m-b20">
																		<label className="m-b0">Functional Area</label>
																		<span className="clearfix font-13">{careerProfile.functional_area}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Job Type</label>
																		<span className="clearfix font-13">{careerProfile.job_type}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Desired Shift</label>
																		<span className="clearfix font-13">{careerProfile.preffered_shift}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Expected Salary</label>
																		<span className="clearfix font-13">{careerProfile.expected_salary}</span>
																	</div>

																</div>
															</div> :
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add details of Career Profile you have filed.</p>}
											</div>
											<div id="personal_details_bx" className="job-bx m-b30">
												<div className="d-flex">
													<h5 className="m-b30">Personal Details  {personalDetails.personalDetails_id > 0 ? <i className="fa fa-check-circle" style={{ color: 'blue' }}></i> : <span className='ml-2' style={{ color: 'blue' }}> 10%</span>}</h5>
													<a href="#" data-toggle="modal" data-target="#personaldetails" className="site-button add-btn button-sm" onClick={() => { setProfileModal(true) }}>
														{personalDetails.personalDetails_id > 0 ? <i className="fa fa-pencil m-r5"></i> : <i className="fa fa-plus m-r5"></i>}{personalDetails.personalDetails_id > 0 ? "Edit" : "Add"}
													</a>
												</div>
												<Modal className="modal fade modal-bx-info editor" show={profileModal} onHide={setProfileModal}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="PersonaldetailsModalLongTitle">Personal Details</h5>
																<button type="button" className="close" onClick={() => { setProfileModal(false); setShowError(false) }}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Date of Birth <span style={{color:'red'}}>*</span></label>
																				<input
																					autoComplete="off"
																					type="date"
																					className="form-control"
																					placeholder="Enter Pincode"
																					name='dob'
																					value={personalDetails.dob}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{personalDetails.dob ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.date}</span> : null)}
																				{personalDetails.dob ? +d4 < +dt ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.ageLimit}</span> : null}

																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Gender <span style={{color:'red'}}>*</span></label>
																				<div className="row">
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="male"
																								name="gender"
																								value='Male'
																								onChange={personalDetailsOnChange}
																								checked={personalDetails.gender === 'Male' ? true : false}
																								
																							/>
																							<label className="custom-control-label" htmlFor="male">Male</label>
																						</div>
																					</div>
																					<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																						<div className="custom-control custom-radio">
																							<input
																								autoComplete="off"
																								type="radio"
																								className="custom-control-input"
																								id="female"
																								name="gender"
																								value='Female'
																								onChange={personalDetailsOnChange}
																								checked={personalDetails.gender === 'Female' ? true : false}
																								
																							/>
																							<label className="custom-control-label" htmlFor="female">Female</label>
																						</div>
																					</div>
																				</div>
																				{personalDetails.gender ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.gender}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Permanent Address <span style={{color:'red'}}>*</span></label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter Your Permanent Address"
																					name='permanent_address'
																					value={personalDetails.permanent_address}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{personalDetails.permanent_address ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.address}</span> : null)}
																				{personalDetails.permanent_address ? (personalDetails.permanent_address.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.addressLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Pincode <span style={{color:'red'}}>*</span></label>
																				<input
																					autoComplete="off"
																					type="number" min={0}
																					className="form-control"
																					placeholder="Enter Pincode"
																					name='pincode'
																					value={personalDetails.pincode}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{personalDetails.pincode ? pincodeDirectory.lookup(personalDetails.pincode).length > 0 ? (pincodeDirectory.lookup(personalDetails.pincode)[0].stateName === 'ODISHA' ? null : <span style={{ color: 'red', fontSize: 11, fontWeight: 'bold', marginLeft: 5 }}>{personalDetails.pincode} doesn't belong to Odisha</span>) : <span style={{ color: 'red', fontSize: 12 }}>{personalDetails.pincode} doesn't belong to Odisha</span> : null}
																				{personalDetails.pincode ? null : (showError ? (!personalDetails.pincode ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.pincode}</span> : null) : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Hometown <span style={{color:'red'}}>*</span></label>
																				<input
																					list='ct'
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter Hometown"
																					name='hometown'
																					value={personalDetails.hometown}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				/>
																				<datalist id='ct' >
																					{
																						cityList.map((item, index) => {
																							return (
																								<option style={{ backgroundColor: 'blue', color: '#fff' }} key={index} >{item}</option>
																							)
																						})
																					}
																				</datalist>
																				{personalDetails.hometown ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.city}</span> : null)}
																				{personalDetails.hometown ? (personalDetails.hometown.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cityLimit}</span> : null) : null}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Marital Status <span style={{color:'red'}}>*</span></label>
																				<select
																					className="form-control"
																					name='marital_status'
																					value={personalDetails.marital_status}
																					onChange={personalDetailsOnChange}
																					style={{ height: 'auto' ,color:"#000000"}}
																				>
																					<option value=''disabled>===== Select ======</option>
																					<option style={{color:"#000000"}}>Married</option>
																					<option style={{color:"#000000"}}>Single / Unmarried</option>
																				</select>
																				{personalDetails.marital_status ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maritalStatus}</span> : null)}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Passport Number</label>
																				<input
																					autoComplete="off"
																					type="text"
																					className="form-control"
																					placeholder="Enter Passport Number"
																					name='passport_number'
																					value={personalDetails.passport_number}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				/>
																				{/* {personalDetails.passport_number ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.passport}</span> : null)}
																				{personalDetails.passport_number ? (personalDetails.passport_number.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.passportLimit}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>What assistance do you need</label>
																				<textarea
																					className="form-control"
																					placeholder="Type Description"
																					name='description'
																					value={personalDetails.description}
																					onChange={personalDetailsOnChange}
																					style={{color:"#000000"}}
																				></textarea>
																				{/* {personalDetails.description ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Description}</span> : null)}
																				{personalDetails.description ? (personalDetails.description.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.description}</span> : null) : null} */}
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Work Permit for Other Countries</label>
																				<Form.Control
																					autoComplete="off" as="select"
																					name='work_permit'
																					value={personalDetails.work_permit}
																					onChange={personalDetailsOnChange}
																					style={{ height: 'auto',color:'#000000' }}
																				>
																					<option disabled>====Select====</option>
																					<option style={{color:"#000000"}}>India</option>
																				</Form.Control>
																				{/* {personalDetails.work_permit ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.country}</span> : null)} */}
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button red" onClick={() => { setProfileModal(false); setShowError(false) }}>Cancel</button>
																{
																	personalDetails.personalDetails_id > 0 ?
																		<button type="button" className="site-button orange" onClick={personalDetailUpdateHandler}  >Update</button> :
																		<button type="button" className="site-button green" onClick={personalDetailsHandler} >Save</button>
																}
															</div>
														</div>
													</div>
												</Modal>
												{

													profileLoader ?
														<div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															<Oval
																height={20}
																width={20}
																color="#4fa94d"
																wrapperStyle={{}}
																wrapperClass=""
																visible={true}
																ariaLabel='oval-loading'
																secondaryColor="#4fa94d"
																strokeWidth={2}
																strokeWidthSecondary={2}
															/>
														</div> :
														personalDetails.personalDetails_id > 0 ?
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-6">
																	<div className="clearfix m-b20">
																		<label className="m-b0">Date of Birth</label>
																		<span className="clearfix font-13">{personalDetails.dob}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Gender</label>
																		<span className="clearfix font-13">{personalDetails.gender}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Marital Status</label>
																		<span className="clearfix font-13">{personalDetails.marital_status}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Passport Number</label>
																		<span className="clearfix font-13">{personalDetails.passport_number}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Assistance Needed</label>
																		<span className="clearfix font-13">{personalDetails.description}</span>
																	</div>

																</div>
																<div className="col-lg-6 col-md-6 col-sm-6">
																	<div className="clearfix m-b20">
																		<label className="m-b0">Permanent Address</label>
																		<span className="clearfix font-13">{personalDetails.permanent_address}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Area Pin Code</label>
																		<span className="clearfix font-13">{personalDetails.pincode}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Hometown</label>
																		<span className="clearfix font-13">{personalDetails.hometown}</span>
																	</div>
																	<div className="clearfix m-b20">
																		<label className="m-b0">Work permit of other country</label>
																		<span className="clearfix font-13">{personalDetails.work_permit}</span>
																	</div>
																</div>
															</div> :
															<p className="m-b0" style={{ textTransform: 'capitalize', textAlign: 'center', color: 'blue' }}>Add  Your Personal Details and make your profile Strength stronger.</p>}
											</div>
											<div id="attach_resume_bx" class="job-bx m-b30">
												<h5 class="m-b10">Resume</h5>
												<p>Jobyard Making a resume for You</p>
												<div className='d-flex justify-content-center '>



													<Link to={"/pdf-viewer"}><button type='button' className='site-button  button-sm' >Preview</button></Link>
													{/* <PDFDownloadLink document={<ResumePdfView />}>
														{({ loading }) => (loading ? <button type='button' className='site-button  button-sm' >loading</button> : <button type='button' className='site-button  button-sm'>Download</button>)}
													</PDFDownloadLink> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal show={imageModal} onHide={setImageModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
						<div className="modal-dialog my-0 mx-0" role="document">
							<div className="modal-content">
								<div className="modal-header" >
									<h5 className="modal-title" id="RegionMaster">Image Upload</h5>
									<button type="button" className="close" onClick={() => { setImageModal(false) }} >
										<span aria-hidden="true" >&times;</span>
									</button>
								</div>
								<div className="modal-body">
									{
										imageData.image ?
											<p className="text-center" style={{ color: 'red' }}> Are you sure to Update this Image ? </p> :
											<p className="text-center" style={{ color: 'red' }}> Are you sure to Upload this Image ? </p>

									}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" onClick={() => { setImageModal(false); setImage(null) }}>Cancel</button>
									{
										imageData.image ?
											<button type="button" className="btn btn-success" onClick={imageUpdateHandler}  >Update</button> :
											<button type="button" className="btn btn-success" onClick={uploadHandler}  >Upload</button>

									}
								</div>
							</div>
						</div>
					</Modal>

					<Modal className="modal fade modal-bx-info editor" show={pdfViewModal} onHide={setPdfViewModal} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
						<div className="modal-dialog my-0 mx-0" role="document" style={{ maxWidth: '100%', maxHeight: "100%" }}>
							<div className="modal-content" style={{ minHeight: '100vh' }}>
								<div className="modal-header">
									<h5 className="modal-title" id="DesiredprofileModalLongTitle">Resume Preview</h5>
									<button type="button" className="close" onClick={() => { setPdfViewModal(false) }}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div >
										<PDFViewer style={{ width: '100%', heigth: "400px", overflow: "hidden" }}>
											<ResumePdfView />
										</PDFViewer>
									</div>

								</div>
								<div className="modal-footer">
									<button type="button" className="site-button red" onClick={() => { setPdfViewModal(false) }}>Cancel</button>
									<PDFDownloadLink document={<ResumePdfView />}>
										{({ loading }) => (loading ? <button type='button' className='site-button' >loading</button> : <button type='button' className='site-button '>Download</button>)}
									</PDFDownloadLink>




								</div>
							</div>
						</div>
					</Modal>

					<Footer />
				</div>}
		</>
	)
}

export default MyResume;