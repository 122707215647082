import React from 'react'
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import User from './PdfViewer/PdfUserDetails';
import PersonalDetails from './PdfViewer/PdfPersonalDetails';
import KeySkillsService from '../../services/candidate_resume/KeySkills';
import Education from '../../services/candidate_resume/Education';
import { useState } from 'react';
import { useEffect } from 'react';
import ItSkills from '../../services/candidate_resume/ItSkills';
import ProfileSummary from '../../services/candidate_resume/ProfileSummary';
import Project from '../../services/candidate_resume/Project';
import Employment from '../../services/candidate_resume/Employment';
import Certification from './PdfViewer/PdfCertification';
import Contact from './PdfViewer/PdfContact';
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
  },

});



const ResumePdfView = () => {

  const [KeySkills, setKeySkills] = useState({});
  const [education, setEducation] = useState({});
  const [itSkills, setItskills] = useState([]);
  const [profileSummery, setProfileSummary] = useState({});
  const [project, setProject] = useState([]);
  const [employment, setEmployment] = useState([])

  const userId = window.localStorage.getItem("userId")
  const userName = window.localStorage.getItem("userName")

  const getKeySkills = async () => {
    try {
      const res = await KeySkillsService.getKeySkillByUserId(userId)

      if (res.status === 200) {
        setKeySkills(res.data)
      }
    } catch (error) {
      console.log(error)
    }

  }


  const getEducation = async () => {
    try {
      const res = await Education.Candidate_Education_By_Id(userId)
      if (res.status) {
        setEducation(res.data)
      }
    } catch (error) {
      console.log(error)
    }

  }

  const getItSkills = async () => {
    try {
      const res = await ItSkills.Candidate_It_Sklill_By_Id(userId)
      if (res.status === 200) {
        setItskills(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProfileSummery = async () => {
    try {
      const res = await ProfileSummary.Candidate_Profile_Summary_By_Id(userId)
      if (res.status === 200) {
        setProfileSummary(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const getProjectsData = async () => {
    try {
      const res = await Project.getProjectByUserId(userId);
      if (res.status === 200) {
        setProject(res.data);
      }
    } catch (error) {
      console.log(error)
    }


  }


  const getEmployment = async () => {

    try {
      const res = await Employment.getEmploymentByUserId(userId)
      if (res.status === 200) {
        setEmployment(res.data)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getKeySkills();
    getEducation();
    getItSkills();
    getProfileSummery();
    getProjectsData();
    getEmployment()
  }, [userId])

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>

          {/* Left Side */}
          <View style={{
            margin: 10,
            padding: 10,
            paddingTop: 20,
            width: '70%',
          }}>
            {/* Profile Summary */}

            <View >
              <Text style={{ fontSize: 12 }}>Profile Summery</Text>
              <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '100%',
                  backgroundColor: '#084c41',
                }}
              ></View>
              <Text style={{ fontSize: 10 }}>{profileSummery.summary}</Text>


            </View>
            {/* Employment History */}
            <View>
              <Text style={{ fontSize: 12, paddingTop: 10 }}>Employment History</Text>
              <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '100%',
                  backgroundColor: '#084c41',
                }}
              ></View>
              {employment.map((val, ind) => {
                return (
                  <View key={ind} style={{ paddingBottom: 10 }}>
                    <Text style={{ fontSize: 11, }}> {ind+1}.
                      {val.organization}
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {val.designation}
                    </Text>
                    <Text style={{ fontSize: 8, color: "gray" }}>{val.work_form} {val.work_till}</Text>
                    <Text style={{ fontSize: 10, paddingTop: 3 }}>{val.job_profile}</Text>
                  </View>
                )

              })}

            </View>
            {/* Education */}
            <View>
              <Text style={{ fontSize: 12, paddingTop: 10 }}>Education</Text>
              <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '100%',
                  backgroundColor: '#084c41',
                }}
              ></View>



              <View>
                {education.school_board === "NA" ? null : <Text style={{ fontSize: 10, paddingBottom: 2 }}>1. Completed 10th from  {education.school_board} in {education.school_passout_year}</Text>}
                {education.college_board === "NA" ? null : <Text style={{ fontSize: 10, paddingBottom: 2 }}>2. Completed 12th from  {education.college_board} in {education.college_passout_year}</Text>}
                {education.graduation_university === "NA" ? null : <Text style={{ fontSize: 10, paddingBottom: 2 }}>3. Graduated in {education.graduation_course} from {education.graduation_university} in {education.graduation_passout_year}</Text>}
                {education.pg_university === "NA" ? null : <Text style={{ fontSize: 10, paddingBottom: 2 }}>4. Post Graduated in {education.pg_course} from  {education.pg_university} in {education.pg_passout_year}</Text>}
                {education.phd_university === "NA" ? null : <Text style={{ fontSize: 10, paddingBottom: 2 }}>5. Completed Phd in {education.phd_course} from  {education.phd_university} in {education.phd_passout_year}</Text>}
              </View>


            </View>


            {/* It Skills */}
            <View>
              <Text style={{ fontSize: 12, paddingTop: 10 }}>It Skills</Text>
              <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '100%',
                  backgroundColor: '#084c41',
                }}
              ></View>
              {
                itSkills.map((val, ind) => {
                  return (
                    <View key={ind} style={{ paddingBottom: 5 }}>
                      <Text style={{ fontSize: 10 }}> {ind + 1}. Skills: {val.it_skills}, Version: {val.version} </Text>
                      <Text style={{ fontSize: 10 }}>   Last Used :{val.last_used} , Experience :{val.experience} </Text>
                    </View>
                  )
                })
              }

            </View>
           
            {/* Project  */}
           
           
            <View>
              <Text style={{ fontSize: 12, paddingTop: 10 }}>Project</Text>
              <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '100%',
                  backgroundColor: '#084c41',
                }}
              ></View>
              {project.map((val,ind)=>{
                  return(

                    <View key={ind}>
                        <Text style={{fontSize:11}}>Project Title:{val.title}</Text>
                        <Text  style={{fontSize:8,color:'gray',paddingBottom:5}}> {val.work_form} to {val.work_till}</Text>
                        <Text style={{fontSize:10,paddingLeft:3}}>Employment/Education :<Text style={{color:'gray'}}>{val.education_or_employment}</Text></Text>
                        <Text style={{fontSize:10,paddingLeft:3}}>Client :<Text style={{color:'gray'}}>{val.client_name}</Text></Text>
                        <Text style={{fontSize:10,paddingLeft:3}}>Project Details:<Text style={{color:'gray'}}>{val.project_details}</Text></Text>
                    </View>
                  )
              })}
            </View>

          </View>



          {/* Right Side */}


          <View style={{
            width: '30%',
            backgroundColor: '#2e55fa'
          }}>
            <View>
              <User />
              <Certification/>
              <PersonalDetails/>
              <Contact/>
            </View>

          </View>
        </Page>
      </Document>

    </>
  )
}

export default ResumePdfView