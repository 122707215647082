import axios from 'axios';

const AddFreeJob=(payload)=> axios.post('/free-job-alert',payload);

const GetFreeJob=()=>axios.get('/free-job-alert');


const UpdateFreeJob=(id,payload)=>axios.put(`/free-job-alert/`+id,payload);

const DeleteFreeJob=(id)=>axios.delete(`/free-job-alert/`+id);


export default {AddFreeJob,GetFreeJob,UpdateFreeJob,DeleteFreeJob};