import React from 'react'
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useState } from 'react';
import PersonalDetails from '../../../services/candidate_resume/PersonalDetails';
import { useEffect } from 'react';

const PdfPersonalDetails = () => {
 
    const [personalDetails,setPersonalDetails]=useState({})

    const userId=window.localStorage.getItem("userId")
    
    
    const getPersonaldetails=async()=>{
       try {
        const res=await PersonalDetails.Candidate_Personal_Details_By_Id(userId)
        if(res.status===200){
            setPersonalDetails(res.data)
        }
       } catch (error) {
            console.log(error)
       }
        
       
    }


    useEffect(()=>{
        getPersonaldetails();
    },[userId])
    return (
    <>



  <View style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '25'}}>
    <Text style={{fontSize:12 ,paddingBottom:'5',color:'#fff'}}>Personal Details</Text>
    <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width:'40%',
                 
                  backgroundColor: '#000',
                }}
              ></View>
    <Text style={{fontSize:10,color:'#fff'}}>{personalDetails.dob}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{personalDetails.gender}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{personalDetails.hometown}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{personalDetails.permanent_address}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{personalDetails.pincode}</Text>
  </View>
  



    </>
  )
}

export default PdfPersonalDetails