import React, { useState, useEffect } from 'react';
import AdminSideBar from './Component/AdminSideBar';
import { Link } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../components/AlertMessage';

import MonthMasterService from '../../services/Master/MonthMaster';
import DateMasterService from '../../services/Master/DateMaster';
import YearMasterService from '../../services/Master/YearMaster';
import RegionMasterService from '../../services/Master/RegionMaster';
import LocationMasterService from '../../services/Master/LocationMaster';
import CategoryMasterService from '../../services/Master/CategoryMaster';
import SkillMasterService from '../../services/Master/SkillMaster';
import ExperienceMasterService from '../../services/Master/ExperienceMaster';
import Education from '../../services/Education';
import JobType from '../../services/JobType';

const AdminAllMasters = () => {

    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });

    const [addMonth, setAddMonth] = useState(false);
    const [months, setMonths] = useState([]);
    const [dates, setdates] = useState([]);
    const [editDate, setEditDate] = useState(false);
    const [addDate, setAddDate] = useState(false);
    const [years, setYears] = useState([]);
    const [region, setRegion] = useState([]);
    const [location, setLocation] = useState([]);
    const [sector, setSector] = useState([]);
    const [skill, setSkill] = useState([]);


    const [id, setId] = useState();
    const [month_name, setMonth_name] = useState();
    const [date_name, setDate_name] = useState();
    const [year_name, setYear_name] = useState();
    const [region_name, setRegion_name] = useState();
    const [location_name, setLocation_name] = useState();
    const [sector_name, setSector_name] = useState();
    const [skill_name, setSkill_name] = useState();
    const [experience_name, setExperience_name] = useState();

    const [editMonth, setEditMonth] = useState(false);
    const [editYear, setEditYear] = useState(false);
    const [addYear, setAddYear] = useState(false);
    const [editRegion, setEditRegion] = useState(false);
    const [addRegion, setAddRegion] = useState(false);
    const [addLocation, setAddLocation] = useState(false);
    const [editSector, setEditSector] = useState(false);
    const [deleteSector, setDeleteSector] = useState(false);
    const [addSector, setAddSector] = useState(false);
    const [editSkill, setEditSkill] = useState(false);
    const [addSkill, setAddSkill] = useState(false);
    const [experience, setExperience] = useState([]);
    const [editExperience, setEditExperience] = useState(false);
    const [deleteExperience, setDeleteExperience] = useState(false);
    const [addExperience, setAddExperience] = useState(false);

    const [deleteMonth, setDeleteMonth] = useState(false);
    const [deleteDate, setDeleteDate] = useState(false);
    const [deleteYear, setDeleteYear] = useState(false);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [editLocation, setEditLocation] = useState(false);
    const [deleteLocation, setDeleteLocation] = useState(false);
    const [deleteSkill, setDeleteSkill] = useState(false);

    const [monthModal, setMonthModal] = useState();
    const [dateModal, setdateModal] = useState();
    const [yearModal, setYearModal] = useState();
    const [regionModal, setRegionModal] = useState();
    const [locationModal, setLocationModal] = useState();
    const [sectorModal, setSectorModal] = useState();
    const [skillModal, setSkillModal] = useState();
    const [experienceModal, setExperienceModal] = useState();


    const [qualification, setQualification] = useState([])
    const [qualicationData, setQualificationdata] = useState('')
    const [qualificationModal, setQualificationModal] = useState(false)

    const [qualification_name, setQualificationName] = useState()
    const [EditQualificationModal, setEditQualificationModal] = useState(false);
    const [DeleteQualificationModal, setDeleteQualificationModal] = useState(false)

    const [addJobType, setAddJobType] = useState(false);
    const [editJobType, setEditJobType] = useState(false);
    const [deleteJobType, setDeleteJobType] = useState(false);
    const [jobTypes, setJobTypes] = useState([]);
    const [jobType, setJobType] = useState('');
    const [jobTypeId, setJobTypeId] = useState(0);
    const [type, setType] = useState('');


    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };


    const MonthEditHandler = (Id) => {
        months.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setMonth_name(val.monthName);
                setEditMonth(true);

            }
        })


    }

    const MonthDeleteHandler = (Id) => {
        months.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteMonth(true);

            }
        })
    }

    const SectorEditHandler = (Id) => {
        sector.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setSector_name(val.category);
                setEditSector(true);

            }
        })
    }

    const ExperienceEditHandler = (Id) => {
        experience.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setExperience_name(val.exp_year);
                setEditExperience(true);

            }
        })
    }

    const SectorDeleteHandler = (Id) => {
        sector.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteSector(true);

            }
        })
    }

    const YearEditHandler = (Id) => {
        years.map((val) => {
            if (val.id === Id) {
                setYear_name(val.year)
                setId(val.id);
                setEditYear(true);
            }
        })
    }


    const YearDeleteHandler = (Id) => {
        years.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteYear(true);
            }
        })
    }


    const DateEditHandler = (Id) => {
        dates.map((val) => {
            if (val.id === Id) {
                setDate_name(val.date)
                setId(val.id);
                setEditDate(true);
            }
        })
    }

    const RegionEditHandler = (Id) => {
        region.map((val) => {
            if (val.id === Id) {
                setId(val.id)
                setRegion_name(val.state)
                setEditRegion(true);

            }
        })
    }

    const RegionDeleteHandler = (Id) => {
        region.map((val) => {
            if (val.id === Id) {
                setId(val.id)
                setDeleteRegion(true);

            }
        })
    }

    const DateDeleteHandler = (Id) => {
        dates.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteDate(true);
            }
        })
    }

    const LocationEitHandler = (Id) => {
        location.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setLocation_name(val.city);
                setEditLocation(true);

            }
        })
    }


    const LocationDeleteHandler = (Id) => {
        location.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteLocation(true);

            }
        })
    }

    const SkillEditHandler = (Id) => {
        skill.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setSkill_name(val.skillName);
                setEditSkill(true);

            }
        })
    }

    const SkillDeleteHandler = (Id) => {
        skill.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteSkill(true);

            }
        })
    }

    const ExperienceDeleteHandler = (Id) => {
        experience.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteExperience(true);

            }
        })
    }

    const sectorHandler = async () => {
        try {
            const payload = {};
            payload.category = sectorModal;
            if (payload.category) {
                const res = await CategoryMasterService.AddCategory(payload);
                if (res.status === 200) {
                    setSectorModal("");

                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }

    const locationHandler = async () => {
        try {
            const payload = {};
            payload.city = locationModal;
            if (payload.city) {
                const res = await LocationMasterService.AddLocation(payload);
                if (res.status === 200) {
                    setLocationModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }
    const addQualificationHandler = async () => {
        try {
            const payload = {};
            payload.education = qualicationData;
            if (payload.education) {
                const res = await Education.addEducation(payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Add Qualification successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            console.log(err);
        }
    }
    const monthHandler = async () => {
        try {
            const payload = {};
            payload.monthName = monthModal;
            if (payload.monthName) {
                const res = await MonthMasterService.Add_Month(payload);
                if (res.status === 200) {
                    setMonthModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return (err);
        }
    }

    const regionHandler = async () => {
        try {
            const payload = {};
            payload.state = regionModal;
            if (payload.state) {
                const res = await RegionMasterService.AddRegion(payload);
                if (res.status === 200) {
                    setRegionModal("");

                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }

    const skillHandler = async () => {
        try {
            const payload = {};
            payload.skillName = skillModal;
            if (payload.skillName) {
                const res = await SkillMasterService.addSkills(payload);
                if (res.status === 200) {
                    setSkillModal("");

                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }

    const experienceHandler = async () => {
        try {

            const payload = {};
            payload.exp_year = experienceModal;
            if (payload.exp_year) {
                const res = await ExperienceMasterService.AddExperience(payload);
                if (res.status === 200) {
                    setExperienceModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }


        } catch (err) {
            return (err);
        }
    }

    const MonthEditMethod = async () => {
        try {
            const payload = {};
            payload.monthName = month_name;
            var Id = parseInt(id);
            if (payload.monthName) {
                const res = await MonthMasterService.Update_Month(Id, payload);
                if (res.status === 200) {
                    setMonth_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteMonthMethod = async () => {
        try {
            var Id = parseInt(id);
            const res = await MonthMasterService.Delete_Month(Id);
            if (res.status === 200) {

                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const EditRegionMethod = async () => {
        try {
            const payload = {};
            payload.state = region_name;
            var Id = parseInt(id);
            if (payload.state) {
                const res = await RegionMasterService.UpdateRegion(Id, payload);
                if (res.status === 200) {
                    setRegion_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            }
        } catch (err) {
            return err;
        }

    }

    const EditLocationMethod = async () => {
        try {
            const payload = {};
            payload.city = location_name;
            var Id = parseInt(id);
            if (payload.city) {
                const res = await LocationMasterService.UpdateLocation(Id, payload);
                if (res.status === 200) {
                    setLocation_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const EditHandler = (Id) => {
        qualification.find((val) => {

            if (val.id === Id) {
                setId(Id);
                setQualificationName(val.education);
                setEditQualificationModal(true);


            }
        })
    }


    const EditQualificationHandler = async () => {
        try {
            const payload = {};
            payload.education = qualification_name;
            var Id = parseInt(id);
            if (payload.education) {
                const res = await Education.UpdateQualification(Id, payload);

                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: ' Update  Qualification successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteLocationMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await LocationMasterService.DeleteLocation(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const deleteSectorMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await CategoryMasterService.DeleteCategory(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const EditSkillMethod = async () => {
        try {
            const payload = {};
            payload.skillName = skill_name;
            var Id = parseInt(id);
            if (payload.skillName) {
                const res = await SkillMasterService.UpdateSkills(Id, payload);
                if (res.status === 200) {
                    setSkill_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }

    }

    const dateHandler = async () => {
        try {

            const payload = {};
            payload.date = parseInt(dateModal);
            if (payload.date) {
                const res = await DateMasterService.AddDate(payload);
                if (res.status === 200) {
                    setdateModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }

    const DateEditMethod = async () => {
        try {
            const payload = {};
            payload.date = parseInt(date_name);
            var Id = parseInt(id);
            if (payload.date) {
                const res = await DateMasterService.UpdateDates(Id, payload);
                if (res.status === 200) {
                    setDate_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }


        } catch (err) {
            return err;
        }
    }

    const deleteDateMethod = async () => {
        try {
            var Id = parseInt(id);
            const res = await DateMasterService.DeleteDates(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const deleteYearMethod = async () => {
        try {
            var Id = parseInt(id);
            const res = await YearMasterService.DeleteYear(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const yearEditMethod = async () => {
        try {
            const payload = {};
            payload.year = parseInt(year_name);
            var Id = parseInt(id);
            if (payload.year) {
                const res = await YearMasterService.UpdateYear(Id, payload);
                if (res.status === 200) {
                    setYear_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteSkillMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await SkillMasterService.DeleteSkills(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }

    }



    const yearHandler = async () => {
        try {

            const payload = {};
            payload.year = parseInt(yearModal);
            if (payload.year) {
                const res = await YearMasterService.Add_Year(payload);
                if (res.status === 200) {
                    setYearModal("");

                    setAlertState({ open: true, type: 'success', message: 'Data added successfully.' });
                    window.location.reload();

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }


        } catch (err) {
            return (err);
        }
    }

    const deleteRegionMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await RegionMasterService.DeleteRegion(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }

    }


    const EditSectorMethod = async () => {
        try {
            const payload = {};
            payload.category = sector_name;
            var Id = parseInt(id);
            if (payload.category) {
                const res = await CategoryMasterService.UpdateCategory(Id, payload);
                if (res.status === 200) {
                    setSector_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }


        } catch (err) {
            return err;
        }
    }

    const EditExperienceMethod = async () => {
        try {
            const payload = {};
            payload.exp_year = experience_name;
            var Id = parseInt(id);
            if (payload.exp_year) {
                const res = await ExperienceMasterService.UpdateExperience(Id, payload);
                if (res.status === 200) {
                    setExperience_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteExperienceMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await ExperienceMasterService.DeleteExperience(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }




    const QualificationDeleteHandler = (Id) => {
        qualification.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteQualificationModal(true);


            }
        })
    }


    const deleteQualification = async () => {
        try {

            var Id = parseInt(id);
            const res = await Education.DeleteQualification(Id);
            if (res.status === 200) {
                setAlertState({ open: true, type: 'success', message: ' delete Qualification successfully.' });
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }

    const getAllMonths = async () => {
        try {
            const res = await MonthMasterService.Get_Month();
            if (res.status === 200) {
                setMonths(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllYears = async () => {
        try {
            const res = await YearMasterService.Get_Year();

            if (res.status === 200) {
                setYears(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllDates = async () => {
        try {
            const res = await DateMasterService.GetDate();
            if (res.status === 200) {
                setdates(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllLocation = async () => {
        try {
            const res = await LocationMasterService.GetLocation();
            if (res.status === 200) {
                setLocation(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllRegion = async () => {
        try {
            const res = await RegionMasterService.GetRegion();
            if (res.status === 200) {
                setRegion(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllExperience = async () => {
        try {
            const res = await ExperienceMasterService.GetExperience();
            if (res.status === 200) {
                setExperience(res.data);
            }
        } catch (err) {
            return err;
        }

    }

    const getAllSkill = async () => {
        try {
            const res = await SkillMasterService.getSkills();
            if (res.status === 200) {
                setSkill(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    const getAllSector = async () => {
        try {
            const res = await CategoryMasterService.getCategory();
            if (res.status === 200) {
                setSector(res.data);
            }
        } catch (err) {
            return err;
        }
    }


    useEffect(() => {
        getAllMonths();
        getAllYears();
        getAllDates();
        getAllLocation();
        getAllRegion();
        getAllExperience();
        getAllSkill();
        getAllSector();
    }, []);
    const getAllQualification = async () => {
        try {
            const res = await Education.getEducation();
            if (res.status === 200) {
                setQualification(res.data);
            }
        } catch (err) {
            return err;
        }
    }
    useEffect(() => {
        getAllQualification();

    }, []);

    const getAllJobType = async () => {
        try {

            const res = await JobType.getJobTypes();

            setJobTypes(res.data)


        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getAllJobType();
    }, [])



    const EditJobtypeHandler = (id) => {
        try {

            jobTypes.find((val) => {
                if (val.id === id) {
                    setEditJobType(true);
                    setJobTypeId(id)
                    setJobType(val.jobType)
                }
            })


        } catch (error) {
            console.log(error);
        }
    }

    const DeleteHandler = async (id) => {
        try {

            jobTypes.find((val) => {
                if (val.id === id) {
                    setDeleteJobType(true);
                    setJobTypeId(id)
                }
            })


        } catch (error) {
            console.log(error);
        }
    }

    const AddHandler = async () => {
        try {
            if (!type) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            } else {

                const payload = {};

                payload.jobType = type;

                const res = await JobType.addJobType(payload);

                if (res.status === 200) {
                    clr();
                    setAddJobType(false)
                    window.location.reload();
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

                }
            }

        } catch (error) {
            console.log(error);
        }

    }

    const EditJobTypeMethod = async () => {
        try {

            if (!jobType) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            } else {

                var iid = parseInt(jobTypeId)

                const payload = {};

                payload.jobType = jobType;

                const res = await JobType.editJobType(iid, payload);

                if (res.status === 200) {
                    setJobTypeId(0)

                    setEditJobType(false)
                    window.location.reload();

                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const deleteJobTypeMethod = async () => {
        try {
            var iid = parseInt(jobTypeId)

            const res = await JobType.deleteJobType(iid);

            if (res.status === 200) {
                setDeleteJobType(false);
                setJobTypeId(0)
                window.location.reload();

            }
        } catch (error) {
            console.log(error);
        }
    }
    const clr = () => {
        setType('');
    }

    return (
        <>
            <Header activelist="admin-all-masters"/>
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>All Master</li>
								</ul>
							</div> */}
                <div className="content-block">
                    <div className="section-full browse-job content-inner-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">

                                    <AdminSideBar activemenu="admin-all-masters"/>
                                </div>
                                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                    <div id="month-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Month Master</h5>

                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddMonth(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary '>
                                                    <th>Sl No.</th>
                                                    <th>Months</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    months.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className="job-name" key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{val.monthName}</td>
                                                                <td >
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => MonthEditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => MonthDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-month-master'>...View All</Link></div>



                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addMonth} onHide={setAddMonth} className="modal fade modal-bx-info  ">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Month </h5>
                                                        <button type="button" className="close" onClick={() => { setAddMonth(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Month</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Month."
                                                                            value={monthModal}
                                                                            onChange={(e) => setMonthModal(e.target.value)}
                                                                            style={{color:'black'}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddMonth(false) }} >Cancel</button>
                                                        <button type="button" className=" btn btn-success" onClick={monthHandler} >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*================================================== Edit Modal ==================================================== */}
                                        <Modal show={editMonth} onHide={setEditMonth} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true" >
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Month </h5>
                                                        <button type="button" className="close" onClick={() => { setEditMonth(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Month</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Month."

                                                                            value={month_name}
                                                                            onChange={(e) => setMonth_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditMonth(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={MonthEditMethod} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteMonth}
                                            onHide={setDeleteMonth}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Month</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteMonth(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Month </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteMonth(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteMonthMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal>

                                    </div>

                                    <div id="date-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Date Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddDate(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className="table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Dates</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dates.slice(0, 4).map((val, ind) => {
                                                    return (
                                                        <tr className='job-name' key={ind}>
                                                            <td>{ind + 1}</td>
                                                            <td>{val.date}</td>
                                                            <td>
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => DateEditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => DateDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>

                                                    )
                                                })}


                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-date-master' >...View All</Link></div>




                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addDate} onHide={setAddDate} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Date </h5>
                                                        <button type="button" className="close" onClick={() => { setAddDate(false) }} >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>

                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Date</label>
                                                                        <input type="number"
                                                                            className="form-control"
                                                                            placeholder="Enter Date."
                                                                            value={dateModal}
                                                                            onChange={(e) => setdateModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddDate(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={dateHandler} >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ================================================== Edit Modal ==================================================== */}
                                        <Modal show={editDate} onHide={setEditDate} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Date </h5>
                                                        <button type="button" className="close" onClick={() => { setEditDate(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Date</label>
                                                                        <input type="number"
                                                                            className="form-control"
                                                                            placeholder="Enter Date."
                                                                            value={date_name}
                                                                            onChange={(e) => setDate_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditDate(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={DateEditMethod} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteDate}
                                            onHide={setDeleteDate}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Date</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteDate(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Date </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteDate(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteDateMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >




                                    </div>

                                    <div id="year-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Year Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddYear(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Years</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    years.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{val.year}</td>
                                                                <td>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => YearEditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => YearDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-year-master' >...View All</Link></div>




                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addYear} onHide={setAddYear} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Year </h5>
                                                        <button type="button" className="close" onClick={() => { setAddYear(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Year</label>
                                                                        <input type="number"
                                                                            className="form-control"
                                                                            placeholder="Enter Year."
                                                                            value={yearModal}
                                                                            onChange={(e) => setYearModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddYear(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={yearHandler}  >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*================================================== Edit Modal ==================================================== */}
                                        <Modal show={editYear} onHide={setEditYear} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Year</h5>
                                                        <button type="button" className="close" onClick={() => { setEditYear(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Year</label>
                                                                        <input type="number"
                                                                            className="form-control"
                                                                            placeholder="Enter Year."
                                                                            value={year_name}
                                                                            onChange={(e) => setYear_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditYear(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={yearEditMethod}  >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteYear}
                                            onHide={setDeleteYear}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Year</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteYear(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Year </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteYear(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteYearMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >

                                    </div>

                                    <div id="region-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Region Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddRegion(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className="table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Region</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    region.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{val.state}</td>
                                                                <td>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => RegionEditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => RegionDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-region-master' >...View All</Link></div>




                                        {/* ======================================================== Add Region ==================================================== */}
                                        <Modal show={addRegion} onHide={setAddRegion} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Region </h5>
                                                        <button type="button" className="close" onClick={() => { setAddRegion(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Region</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Region."
                                                                            value={regionModal}
                                                                            onChange={(e) => setRegionModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddRegion(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={regionHandler} >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ================================================== Edit Modal ==================================================== */}
                                        <Modal show={editRegion} onHide={setEditRegion} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Region </h5>
                                                        <button type="button" className="close" onClick={() => { setEditRegion(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Region</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Region."
                                                                            value={region_name}
                                                                            onChange={(e) => setRegion_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditRegion(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={(EditRegionMethod)} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}
                                        <Modal
                                            show={deleteRegion}
                                            onHide={setDeleteRegion}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Region</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteRegion(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this region </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteRegion(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteRegionMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >


                                    </div>

                                    <div id="location-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Location Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddLocation(true)}>
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Location</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    location.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className=' job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{val.city}</td>
                                                                <td>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => LocationEitHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => LocationDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-location-master' >...View All</Link></div>



                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addLocation} onHide={setAddLocation} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Location </h5>
                                                        <button type="button" className="close" onClick={() => { setAddLocation(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Location</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Location."
                                                                            value={locationModal}
                                                                            onChange={(e) => setLocationModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddLocation(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={locationHandler}  >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*================================================== Edit Modal ==================================================== */}
                                        <Modal show={editLocation} onHide={setEditLocation} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Location </h5>
                                                        <button type="button" className="close" onClick={() => { setEditLocation(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Location</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Location."
                                                                            value={location_name}
                                                                            onChange={(e) => setLocation_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditLocation(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={EditLocationMethod}  >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteLocation}
                                            onHide={setDeleteLocation}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Location</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteSector(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Location </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteLocation(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteLocationMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >

                                    </div>

                                    <div id="sector-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Category Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddSector(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Category Type</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sector.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{val.category}</td>
                                                                <td>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => SectorEditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => SectorDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-category-master' >...View All</Link></div>


                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addSector} onHide={setAddSector} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Category </h5>
                                                        <button type="button" className="close" onClick={() => { setAddSector(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>category</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Sector."
                                                                            value={sectorModal}
                                                                            onChange={(e) => setSectorModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddSector(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={sectorHandler}  >Save</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*================================================== Edit Modal ==================================================== */}
                                        <Modal show={editSector} onHide={setEditSector} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Sector </h5>
                                                        <button type="button" className="close" onClick={() => { setEditSector(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Category</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Sector."
                                                                            value={sector_name}
                                                                            onChange={(e) => setSector_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditSector(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={EditSectorMethod} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*========================================================== Delete Handler ========================================  */}

                                        <Modal
                                            show={deleteSector}
                                            onHide={setDeleteSector}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Category</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteSector(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Category </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteSector(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteSectorMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >


                                    </div>

                                    <div id="skill-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Skill Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddSkill(true)} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th >Skills</th>
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    skill.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td >{val.skillName}</td>
                                                                <td >
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => SkillEditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => SkillDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-skill-master' >...View All</Link></div>




                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addSkill} onHide={setAddSkill} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Add Skill </h5>
                                                        <button type="button" className="close" onClick={() => { setAddSkill(false) }} >
                                                            <span aria-hidden="true"  >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Skill</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Skills."
                                                                            value={skillModal}
                                                                            onChange={(e) => setSkillModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setAddSkill(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-success" onClick={skillHandler}  >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/*================================================== Edit Modal ==================================================== */}
                                        <Modal show={editSkill} onHide={setEditSkill} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Skill </h5>
                                                        <button type="button" className="close" onClick={() => { setEditSkill(false) }} >
                                                            <span aria-hidden="true"  >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Skill</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Skills."
                                                                            value={skill_name}
                                                                            onChange={(e) => setSkill_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setEditSkill(false) }} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={EditSkillMethod} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteSkill}
                                            onHide={setDeleteSkill}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Skills</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteSkill(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Skill </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteSkill(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteSkillMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >

                                    </div>




                                    <div id="experience-master-bx" className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Experience Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => { setAddExperience(true) }} >
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>

                                        <table className="table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th>Sl No.</th>
                                                    <th>Experience</th>
                                                    <th style={{ paddingLeft: "5%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    experience.slice(0, 4).map((val, ind) => {
                                                        return (
                                                            <tr className=' job-name' key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td style={{ paddingRight: '3%' }}>{val.exp_year}</td>
                                                                <td style={{ paddingLeft: "5%" }}>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => ExperienceEditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => ExperienceDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        <div className='df-rr'><Link className='f-sz' to='/admin-experience-master' >...View All</Link></div>



                                        {/* =================================================== Add Modal ======================================================= */}
                                        <Modal show={addExperience} onHide={setAddExperience} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="Master">Add Experience </h5>
                                                        <button type="button" className="close" onClick={() => { setAddExperience(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Experience</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Month."
                                                                            value={experienceModal}
                                                                            onChange={(e) => setExperienceModal(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        
                                                            <button type="button" className="btn btn-danger" onClick={() => { setAddExperience(false) }} >Cancel</button>
                                                            <button type="button" className="btn btn-success" onClick={experienceHandler}>Save</button>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ================================================== Edit Modal ==================================================== */}
                                        <Modal show={editExperience} onHide={setEditExperience} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog my-0" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header" >
                                                        <h5 className="modal-title" id="RegionMaster">Edit Experience </h5>
                                                        <button type="button" className="close" onClick={() => { setEditExperience(false) }} >
                                                            <span aria-hidden="true" >&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                                                        <form>
                                                            <div className="row">


                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Experience</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Experience."
                                                                            value={experience_name}
                                                                            onChange={(e) => setExperience_name(e.target.value)} style={{color:'black'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={() => setEditExperience(false)} >Cancel</button>
                                                        <button type="button" className="btn btn-warning" onClick={EditExperienceMethod} >Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                                        </Modal>
                                        {/* ============================================== Delete Modal ================================================= */}

                                        <Modal
                                            show={deleteExperience}
                                            onHide={setDeleteExperience}
                                            backdrop=""
                                            size='md'
                                            className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

                                        >
                                            <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                                                <div className="modal-content "  >
                                                    <div class="modal-header">
                                                        <div class="logo-img">
                                                            <img alt="" src="images/logo/icon2.png" />
                                                        </div>
                                                        <h5 class="modal-title">Delete Experience</h5>
                                                        <button type="button" className="close" onClick={() => setDeleteExperience(false)} data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <div className="modal-body" >
                                                        {/* <h5 className="modal-title" >Job details: </h5> */}
                                                        <p style={{ color: "red" }}> Do you want to delete this Experience </p>



                                                    </div>
                                                    <div className="modal-footer">
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                        </div>
                                                        <button type='button' className='btn btn-info' onClick={() => setDeleteExperience(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                                                        <button type="button" className="btn btn-danger" onClick={deleteExperienceMethod}>Delete</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
                                        </Modal >

                                    </div>

                                    <div className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Qualification Type Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setQualificationModal(true)}>
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>
                                        <table className="table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th >Sl No.</th>
                                                    <th >Qualification Type</th>
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    qualification.map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind} >
                                                                <td >{ind + 1} </td>
                                                                <td>{val.education}</td>
                                                                <td >
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}><i className="fa fa-pencil"></i></button>
                                                                    <button className=" btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => QualificationDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div className='df-rr'><Link className='f-sz' to='/admin-qualification-master' >...View All</Link></div>

                                    </div>
                                    <div className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Job Type Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddJobType(true)}>
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>
                                        <table className=" table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th >Sl No.</th>
                                                    <th >Job Type</th>
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    jobTypes.map((val, ind) => {
                                                        return (
                                                            <tr className='job-name' key={ind}>
                                                                <td className=' mb-1'><span className='fc-blk' >{ind + 1}</span> </td>
                                                                <td className=' mb-1'><span className='fc-blk'>{val.jobType}</span></td>
                                                                <td className='mb-1'>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => EditJobtypeHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => DeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        <div className='df-rr'><Link className='f-sz' to='/admin-jobtype-master' >...View All</Link></div>

                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Modal show={qualificationModal} onHide={setQualificationModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Add Qualification </h5>
                            <button type="button" className="close" onClick={() => { setQualificationModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Qualification</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Qualification."
                                                value={qualicationData}
                                                onChange={(e) => setQualificationdata(e.target.value)}
                                                style={{color:'black'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => setQualificationModal(false)} >Cancel</button>
                            <button type="button" className="btn btn-success" onClick={addQualificationHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>

            <Modal show={EditQualificationModal} onHide={setEditQualificationModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Qualification </h5>
                            <button type="button" className="close" onClick={() => { setEditQualificationModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Qualification</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Qualification."
                                                value={qualification_name}
                                                onChange={(e) => setQualificationName(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setEditQualificationModal(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning" onClick={EditQualificationHandler} >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>


            <Modal
                show={DeleteQualificationModal}
                onHide={setDeleteQualificationModal}
                backdrop=""
                size='md'
                className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

            >
                <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content "  >
                        <div class="modal-header">
                            <div class="logo-img">
                                <img alt="" src="images/logo/icon2.png" />
                            </div>
                            <h5 class="modal-title">Delete Qualification</h5>
                            <button type="button" className="close" onClick={() => setDeleteQualificationModal(false)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" >
                            {/* <h5 className="modal-title" >Job details: </h5> */}
                            <p style={{ color: "red" }}> Do you want to delete this Qualification </p>



                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type='button' className='btn btn-info' onClick={() => setDeleteQualificationModal(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteQualification}>Delete</button>

                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            </Modal >
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addJobType} onHide={setAddJobType} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Add Job Type </h5>
                            <button type="button" className="close" onClick={() => { setAddJobType(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Job Type</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Job Type."
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                                style={{color:'black'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setAddJobType(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success" onClick={AddHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ================================================== Edit Modal ==================================================== */}
            <Modal show={editJobType} onHide={setEditJobType} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Job Type </h5>
                            <button type="button" className="close" onClick={() => { setEditJobType(false) }} >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Job type</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Job Type."
                                                value={jobType}
                                                onChange={(e) => setJobType(e.target.value)}
                                                style={{color:'black'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setEditJobType(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning" onClick={EditJobTypeMethod} >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>


            {/* ======================================================================= Delete Modal ===================================================================================== */}


            <Modal
                show={deleteJobType}
                onHide={setDeleteJobType}
                backdrop=""
                size='md'
                className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

            >
                <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content "  >
                        <div class="modal-header">
                            <div class="logo-img">
                                <img alt="" src="images/logo/icon2.png" />
                            </div>
                            <h5 class="modal-title">Delete JobType</h5>
                            <button type="button" className="close" onClick={() => setDeleteJobType(false)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" >
                            {/* <h5 className="modal-title" >Job details: </h5> */}
                            <p style={{ color: "red" }}> Do you want to delete this JobType </p>



                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type='button' className='btn btn-info' onClick={() => setDeleteJobType(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteJobTypeMethod}>Delete</button>

                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            </Modal >





            <Footer />
        </>
    );
}
export default AdminAllMasters;