export default {
    name :'Please provide a name',
    nameLimit :'Name should be under 30 characters',
    email : 'Please provide a email',
    emailLimit:'Email should be under 100 character',
    invalidEmail :'Invalid email address',
    registeredEmail :'This email is already registered.',
    mobile :'Please provide mobile number',
    invalidMobile : 'Invalid mobile number',
    registeredMobile : 'This mobile number is already registered',
    password : 'Please provide a password',
    minPass :'Password should be mimimum 8 characters',
    maxPass :'Password should be under 100 character',
    lowercase:'Password should be contain 1 lower case',
    uppercase:"Password should be contain 1 Upper case",
    matchedPassword :"Password doesn't matched",
    confirmPassword :'Please confirm your password',
    invalidPassword :'Invalid password',
    invalidCaptcha :'Invalid Captcha',
    Captcha :'Please enter captcha',
    companyName :'Please provide company name',
    companyNameLimit : 'Company name should be under 250 characters',
    companyCategory : 'Please provide company category',
    companyAddress :'Please provide comapy address',
    companyAddressLimit : 'Address should be under 250 characters',
    pincode :'Please provide pincode',
    invalidPin:'Invalid Pincode',
    city: 'Please provide city',
    cityLimit :'City should be under 250 characters',
    state:'Please provide state',
    country :'Please provide country',
    role:'Please provide role',
    roleLimit:'Role should be under 100 characters',
    url:'Url should be under 250 characters.',
    description :'Description should be under 250 characters',
    Description:'Please provide description',
    gstLimit:'GST number sgould be under 100 characters',
    cin:'Please provide CIN',
    cinLimit:'CIN should be under 100 characters',
  
    // job post
  
    jobTitle :'Please provide job title.',
    jobTitleLimit:'Job title should be under 100 characters',
    jobTags:'Please provide job tag.',
    jobTagLimit:'Job tag should be under 100 characters',
    jobTypes :'Please select job type.',
    jobCategory:'Please select job category',
    functionalArea :'Please select functional area',
    minSal:'Please provide minimum salary.',
    maxSal:'Please provide maximum salary',
    salary:'Maximum salary should be greater than Minimum salary',
    currentSalary:"Expected salary should be grater then the Current Salary",
    salLimit:'Salary should be under 8 digits',
    experience:'Please select experience', 
    qualification:'Please select qualification',
    region:'Please select Region',
    state:'Please select state',
    address:'Please provide address',
    addressLimit:'Address should be under 250 characters',
    time:'Please select time',
    lastdate:'Please provide lastdate for Applying job',
    vacancy:'Plese provide vacancy',
    vacancyLimit:'Vacancy should be under 10 digit.',
    gender:'Please provide gender',
    workPlaceType:'Please provide work place type',
    jobDescription :'Please provide job description',
    jobDescriptionLimit :'Description should be under 250 characters',
    jobRequirement:'Please provide job requirements',
    jobRequirementLimit:'Job requirement should be under 250 characters.',
    keySkill :'Please provide keySkill.',
    keySkillLimit:'Key skill should be under 250 characters.',
    interViewFee:'Please select interview fee option.',
    area:"Please provide Area",
    flat:"Please provide Flat No.",
    workTimeStart:"Please provide start working time ",
    WorkTimeEnd:"Please provide Exit Working time",
    companyName:"Please Provide Company Name",
    HrName:"Please provide Recruiter Name",
    Email:"Please Provid Recruiter Email",
    Whatsapp:"Please provide Recruiter Whatsapp Number",
    whatsappLimit:"Invalid Whatsapp Number",
    Recruitercompany:"Please Provide Recruiter company name ",
    workingTym:"Working till time is gratter than the working from time",
   
    // candidate resume
  
    resumeHeadline:'Please provide resume headline',
    resumeHeadlineLimit:'Resume headline should be under 250 characters.',
    designation:'Please provide your designation.',
    designationLimit:'Designation should be under 100 characters',
    organization:'Please provide organization.',
    organizationLimit:'Organization should be under 100 characters',
    currentCompany:'Please select current company status',
    year:'Please select year',
    invalidYear:'Invalid Year',
    month:'Please select month',
    jobProfile:'Provide job profile',
    descriptionLimit:'Description should be under 250 characters',
    validYear:"Invalid Year ",
    validMonth:'Invalid Month',
    profileSummary:'Please provide profile summary',
    profileSummaryLimit:'Profile summary should be under 250 characters.',
    minSummary:'Profile summary should be more than 50 characters.',
    URL:'Please provide url',
    socialProfile:'Please provide social profile name e.g facebook.',
    socialProfileLimit:'Social profile should be under 100 characters.',
    title:"Please provide title",
    titleLimit:'Title should be under 100 character',
    date:'Please select date',
    invalidDate:'Invalid date',
    currentDate:'Please Enter Current Date',
    application:'Please provide application number.',
    applicationLimit:'Application number should be under 100 characters.',
    patentOffice :'Please provide patent office',
    patentOfficeLimit:'Pantent office should be under 250 characters.',
    status:'Please provide status',
    body:'Please provide body',
    bodyLimit:'Body should be under 100 characters.',
    maritalStatus:'Please provide marital status.',
    passport:'Please provide passport number.',
    passportLimit:'Passport number should be under 100 characters.',
    ageLimit:'Age should be minimum 15+ years.',
    industry:"Please select industry.",
    functionalArea:'Please select functional area',
    role:"Please select role",
    employmentType:'Please select employment type.',
    shift:'Please select sift',
    Salary:'Please select salary',
    tag:'Please select tag',
    skill:'Please provide skill',
    skillLimit:'Skill should be under 100 characters.',
    version:'Please provide version.',
    versionLimit:'Version should be under 100 characters.',
    school:'Please provide school name',
    schoolLimit:'School name should be under 250 characters.',
    college:'Please provide college name',
    collegeLimit:'College name should be under 250 characters.',
    university:'Please provide university.',
    universityLimit:'University should be under 250 characters.',
    course:'Please provide course',
    courseLimit:'Course should be under 250 characters',
    jobtitle:'Please provide Jobtitle',
    //Candidate profile
  
    language:'Please provide laguage',
    languageLimit:'Language should be under 100 characters.',
    validAge:'Age should be under 100 years',
    age:'Please provide age',
    

    // Admin blog

    blogTitle:"Please Provide Blog Title",
    blogTitleLimit:"Blog Title should be under 100 Charecter",
    blogCategory:"Please Provide Blog Category",
    blogCategoryLimit:"Blog Category Limit Should be Under 30 Charecter",
    authorName:"Please Provide Author Name",
    authorNameLimit:"Author Name Limit should be under 30 Charecter",
    blogdate:"Please Provide Blog Date",
    quotation:"Please Provide quotation",
    quotationLimit:"Quotation should be under 250 Charecter",
    content:"Please Provide Content",
    contentLimit:"Content Should be under 1000 character",
    secondContent:"Please Provide Content 2",
    thirdContent:"Please Provide Content 3",
    secondContentLimit:"Second content Limit should be under 1000 character",
    thirdContentLimit:"Third content Limit should be under 1000 character",

  }
  
  
  
  