import React, { useState, useEffect } from 'react'
import Header from '../../components/Layout/Header';
import AdminSideBar from './Component/AdminSideBar';
import Footer from '../../components/Layout/Footer';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AlertMessage from '../../components/AlertMessage';
import Users from '../../services/Users';
import CandiCandidateService from '../../services/admin/ReportCanidateService';
import ReactPaginate from 'react-paginate';


const AdminManageCandidate = () => {

    const [satausData, setStatusData] = useState('');
    const [userData, setUserData] = useState([]);
    const [candidateData, setCandidateData] = useState([]);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationToken, setVerificationToken] = useState('');
    const [emailVerified, setEmailVerified] = useState('');
    const [mobile, setMobile] = useState('');
    const [roleId, setRoleId] = useState('');
    const [permission, setPermission] = useState(false);
    const [radioData, setRadioData] = useState('');
    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });
    const [pageNumber, setPageNumber] = useState(0);
    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [keyword,setKeyword]=useState();

    const itemsPerPage = 10

    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const startIndex = pageNumber * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const jobdata = userData.slice(startIndex, endIndex);


    const submitHandler = async () => {
        try {
            const payload = {};

            payload.id = id;
            payload.name = name;
            payload.email = email;
            payload.password = password;
            payload.verificationToken = verificationToken;
            payload.emailVerified = emailVerified;
            payload.mobile = mobile;
            payload.roleId = roleId;
            payload.status = radioData === 'Block' ? 'Blocked' : radioData;

            if (radioData) {
                const res = await Users.updateUser(id, payload);
                if (res.status === 200) {
                    setPermission(false);
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please select option' });
            }


        } catch (err) {
            return err;
        }
    }

    const getUserData = async () => {
        try {

            const res = await Users.getUser();
            if (res.status === 200) {
                setUserData(res.data);
            }

        } catch (err) {
            return err;
        }
    }
    const getCanidateData = async () => {
        try {
            const res = await CandiCandidateService.getReportCandidate();
            if (res.status === 200) {
                setCandidateData(res.data);
            }

        } catch (err) {
            return err;
        }
    }

    useEffect(() => {
        getUserData();
        getCanidateData();
    }, []);

    const permitModal = (idx) => {
        userData.map((itm) => {
            if (itm.id === idx) {
                setId(itm.id);
                setName(itm.name);
                setEmail(itm.email);
                setPassword(itm.password);
                setVerificationToken(itm.verificationToken);
                setEmailVerified(itm.emailVerified);
                setMobile(itm.mobile);
                setRoleId(itm.roleId);
                setPermission(true);

            }
        })
    }

    const radioHandler = (e) => {
        setRadioData(e.target.value);
    }
    const sortingHandler = (col) => {


        if (sortOrder === "asc") {
            const sorted = jobdata.sort((a, b) =>
                a[col] > b[col] ? 1 : -1


            );
            setSortedData(sorted);
            setSortOrder('dsc')
        } else {
            const sorteds = jobdata.sort((a, b) =>
                a[col] < b[col] ? 1 : -1

            );
            setSortedData(sorteds);
            setSortOrder('asc')

        }
    };



    return (
        <>
            <Header activelist="admin-manage-candidate"/>


            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">

                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container" PaginatedItems itemsPerPage={5} >

                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30">
                                        <AdminSideBar activemenu="admin-manage-candidate"/>
                                    </div>

                                    <div className="col-xl-9 col-lg-8 m-b30">
                                        <div className="job-bx clearfix">


                                            <div className="job-bx-title clearfix">
                                                <h5 className="font-weight-700 pull-left text-uppercase">Manage Candidate</h5>
                                                <div className="float-right">
                                                    {/* <span className="select-title">Sort by freshness</span> */}

                                                    <div className="">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="Search by Job Title"
                                                            value={keyword}
                                                            onChange={(e) => setKeyword(e.target.value)}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>



                                                </div>
                                            </div>

                                            {sortOrder === 'asc' ?


                                            <table className="table-job-bx cv-manager company-manage-job">
                                                <thead >
                                                    <tr>

                                                        <th onClick={() => sortingHandler('name')} style={{ fontSize: 12, fontWeight: 800 }}>Name<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }} onClick={() => sortingHandler('email')}>Email <span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }}>Reports </th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }} >Status </th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        jobdata.filter((val) => { if (val.roleId === 1 && (!satausData || satausData === 'Status' || val.status === satausData)  && (!keyword || keyword && val.name.toLowerCase().includes(keyword.toLowerCase()))) { return val; } }).map((val, ind) => {

                                                            return (

                                                                <tr key={ind} >

                                                                    <td className="job-name">

                                                                        <Link to="#" style={{ fontSize: "12px", fontWeight: 800 }}>{val.name}.</Link>

                                                                    </td>



                                                                    <td className="application text-primary" style={{ fontSize: "12px" }}>{val.email}</td>

                                                                    <td style={{ fontSize: "12px" }}><Link style={{ color: 'red',fontSize: "12px" }} to={`/report/candidate-by/employer/${val.id}`}>Reports <span style={{ fontWeight: 'bolder' }}>({candidateData.filter((itm) => { if (itm.candidate_id === val.id) { return val; } }).map(val => val).length})</span></Link> </td>
                                                                    {val.status === "Active" ? <td style={{ color: 'green',fontSize: "12px" }}>{val.status}</td> :
                                                                        <td style={{ color: 'red',fontSize: "12px" }}>Blocked</td>

                                                                    }
                                                                    <td className="job-links mt-2 ml-2">


                                                                        <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permitModal(val.id)}></i></Link>

                                                                    </td>
                                                                </tr>

                                                            )

                                                        })
                                                    }



                                                </tbody>


                                                <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(userData.length / itemsPerPage)}
                                                        marginPagesDisplayed={7}
                                                        pageRangeDisplayed={userData.length}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                    /></div>
                                            </table>
                                            :



                                            <table className="table-job-bx cv-manager company-manage-job">
                                                <thead >
                                                    <tr>

                                                        <th onClick={() => sortingHandler('name')} style={{ fontSize: 12, fontWeight: 800 }}>Name<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }} onClick={() => sortingHandler('email')}>Email <span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }}>Reports </th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }} >Status </th>
                                                        <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        sortedData.filter((val) => { if (val.roleId === 1 && (!satausData || satausData === 'Status' || val.status === satausData) && (!keyword || keyword && val.name.toLowerCase().includes(keyword.toLowerCase()))) { return val; } }).map((val, ind) => {

                                                            return (

                                                                <tr key={ind} >

                                                                    <td className="job-name">

                                                                        <Link to="#" style={{ fontSize: "12px", fontWeight: 800 }}>{val.name}.</Link>

                                                                    </td>



                                                                    <td className="application text-primary" style={{ fontSize: "12px" }}>{val.email}</td>

                                                                    <td style={{fontSize:12}}><Link style={{ color: 'red' ,fontSize: "12px"}} to={`/report/candidate-by/employer/${val.id}`}>Reports <span style={{ fontWeight: 'bolder' }}>({candidateData.filter((itm) => { if (itm.candidate_id === val.id) { return val; } }).map(val => val).length})</span></Link> </td>

                                                                    {
                                                                    val.status === "Active" ? 
                                                                    <td style={{ color: 'green',fontSize: "12px" }}>{val.status}</td> :
                                                                        <td style={{ color: 'red',fontSize: "12px" }}>Blocked</td>

                                                                    }
                                                                    <td className="job-links mt-2 ml-2">


                                                                        <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permitModal(val.id)}></i></Link>

                                                                    </td>
                                                                </tr>

                                                            )

                                                        })
                                                    }



                                                </tbody>


                                                <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                    <ReactPaginate
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(userData.length / itemsPerPage)}
                                                        marginPagesDisplayed={7}
                                                        pageRangeDisplayed={userData.length}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                    /></div>
                                            </table>

                                        }






                                        </div>


                                    </div>





                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>









            <Footer />







           
            <Modal show={permission} onHide={setPermission} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document" >
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title">Set Job Status</h5>
                            <button type="button" className="close" onClick={() => setPermission(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <Form.Control
                                as="select" custom
                                className="custom-select"

                                value={radioData}
                                onChange={radioHandler}
                                style={{ height: "45px" }}
                            >
                                <option >-----------------------------Select Status----------------------------</option>
                                <option className='fc-grn'>Active</option>
                                <option className=' fc-red'>Block</option>

                            </Form.Control>
                        </div>
                        <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={submitHandler}  >Submit</button>
                            <button type="button" className="btn btn-secondary" onClick={() => setPermission(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default AdminManageCandidate;