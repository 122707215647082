
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import JobPostService from '../../services/JobPost';
import AlertMessage from '../../components/AlertMessage';
import { Row,Col } from 'react-bootstrap';


const CategorySkillJobs = () => {
  const [joblist,setJoblist]=useState([]);
  const [alertState,setAlertState]=useState('');
  const [radio , setRadio]=useState('');
  const [active, setActive] = useState(null);


  const navigate=useNavigate();


	const radioOnChange=(e)=>{
		setRadio(e.target.value);
	}

	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({open:false});
	};

	
	const getAllJobs=async()=>{
		try {
			const res = await JobPostService.getJobPost();
			if(res.status===200){
				setJoblist(res.data)
			}



		} catch (error) {
			return error;
			
		}
	}

	useEffect(()=>{
		getAllJobs();

	},[]);

	var company=joblist.filter(val=>{if(val.job_category==="Information Technology"  && val.status === 'Active'){return val}}).map(val=>val.job_tags);

	company.sort();
	var current = null;
	var arr=[];
	var cnt = 0;
	for (var i = 0; i < company.length; i++) {
		if (company[i]!== current) {
			if (cnt > 0) {
				arr.push(current);
				
			}
			current = company[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		arr.push(current);
	}

	var company1=joblist.filter(val=>{if(val.job_category!=="Information Technology" && val.status === 'Active'){return val}}).map(val=>val.job_tags);

	company1.sort();
	var current1 = null;
	var arr1=[]
	var cnt1 = 0;
	for (var j = 0; j < company1.length; j++) {
		if (company1[j]!== current1) {
			if (cnt1 > 0) {
				arr1.push(current1);
				
			}
			current1 = company1[j];
			cnt1 = 1;
		} else {
			cnt1++;
		}
	}
	if (cnt1 > 0) {

		arr1.push(current1);
	}
	const searchHandler=async()=>{
		try{

			if(radio){

				window.localStorage.setItem('tags',radio);
				window.localStorage.setItem('city','');
				window.localStorage.setItem('categories','');
				window.localStorage.setItem('all','');
				window.localStorage.setItem('companyData','');
				window.localStorage.setItem('designation','');


      		   navigate('/browse-job-grid');

			}else{
			setAlertState({open:true, type:'warning', message:'Please select skill.'});

			}
			

		}catch(err){
			return err;
		}

	}
	return (

	<>
	 
	 
	<Header activelist="category-skill-jobs"/>
	  <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>

	 <div className="page-content">
        {/*  inner page banner */}
        <div className="dez-bnr-inr jobs-category overlay-black-middle" >
            <div className="container">
                <div className="dez-bnr-inr-entry">
					
					<div className="category-jobs-info">
						<div className="nav">
							<ul>
								<li><Link to="/category-all-jobs">All Jobs</Link></li>
								<li><Link to="/category-company-jobs">Jobs by Company</Link></li>
								<li ><Link to="/category-Jobs">Jobs by Category</Link></li>
								<li ><Link to="/category-location-jobs">Jobs by Location</Link></li>
								<li ><Link to="/category-designation-jobs">Jobs by Designation</Link></li>
								<li className="active"><Link to="/category-skill-jobs">Jobs by Skill</Link></li>
							</ul>
						</div>
					</div>
                </div>
            </div>
        </div>
		
		
		
		<div className="content-block">																
			<div className="section-full content-inner jobs-category-bx">
				<div className="container">
					<div className="row">	
					{
						arr.map(val=>val).length === 0 ? null :
											 
						<div className="col-lg-12 m-b30">						
							<div className="job-bx bg-white">
								<div className="job-bx-title clearfix">
									<h6 className="text-uppercase">Browse Jobs by IT Skills</h6>
									</div>									
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr.map((item)=>(	
								           <li className="col-lg-4 col-md-4" key={item.id}  >											
										   <div className="job-post-inf ">
											 <div className="d-flex m-b10">
											   <div className="job-post-info">
											     <ul>
													<div  style={{display:"flex",alignItems:"center"}}>
									 				  <li style={{listStyle:"none"}}>							
														 <label htmlFor='radio4' >
															<div style={ radio === item ? {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"} :{width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}} onClick={()=>{setRadio(item)}}>
																<div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span style={{color:"blue"}}>{item}</span></div>													
															</label>
									                    </li>
													   </div>
												    </ul>							
											   </div>
											 </div>
										   </div>
										 </li>
								))}	
							</ul>	  
									</div>   								
								</div>
                         }
						 {
							arr1.map(val=>val).length === 0 ? null :

								<div className="col-lg-12 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs By Non-IT Skills</h6>
										</div>
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none "}}>
								          {arr1.map((item,index)=>(	
								        	     <li className="col-lg-4 col-md-4" key={index} >
												 <div className="job-post-inf ">
												   <div className="d-flex m-b10">
													 <div className="job-post-info">
													 <ul>
														<div  style={{display:"flex",alignItems:"center"}}>
											 			  <li style={{listStyle:"none"}}>																  
															 <label htmlFor={'radio4'+item} >
																<div style={ radio === item ? {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"} :{width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}} onClick={()=>{setRadio(item)}}>
																	<div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span style={{ color:"#2e55fa"}}>{item}</span></div>																	  
																  </label>
																</li >
															   </div>
															</ul>												  
													 </div>
												   </div>
												 </div>
											   </li>
								            ))}	
							          </ul>	   
									</div>    
								</div>
                              }
							</div>
						</div>
						{
							arr.map(val=>val).length === 0 && arr1.map(val=>val).length === 0 ?
							<center ><span  style={{borderRadius:"50px",color:'#2e55fa'}} >No Job Found.</span></center>		
						:
						<center ><button type="button"  className="site-button btn-block col-lg-2 col-md-2" onClick={searchHandler} style={{borderRadius:"50px"}} >Find Job</button></center>		
                        }
					</div>					
				</div>					
		</div> 
		<Footer/>		
    </>   
  )
}

export default CategorySkillJobs;