import axios from 'axios';

const AddDate=(payload)=> axios.post('/masters/date',payload);

const GetDate=()=>axios.get('/masters/date');

const UpdateDates=(id,payload)=>axios.put(`/masters/date/`+id,payload);

const DeleteDates=(id)=>axios.delete(`/masters/date/`+id);

export default {AddDate,GetDate,UpdateDates,DeleteDates};