import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import PageTitle from '../../components/Layout/PageTitle';
// import CompanyManage from '../Employer/Companymanage';
import BrowseCandidate from '../../services/BrowseCandidate';
import HashLoader from "react-spinners/HashLoader";


function JobCandidateProfileList() {

    const [data, setData] = useState([]);
    const [dataServices, setDataServices] = useState([]);
    const [loader, setLoader] = useState(false);


    const { id } = useParams();
    var Id = parseInt(id);
    const getCandidateDetails = async () => {
        setLoader(true)
        try {
            const allJobTypes = await BrowseCandidate.browseCandidate();
            if (allJobTypes.status === 200) {
                setDataServices(allJobTypes.data);
            }

        } catch (err) {
            return err;
        }
        setLoader(false)
    }

    useEffect(() => {
        getCandidateDetails();
    }, []);



    // const getAppliedJobData = async () => {

    //     try {
    //         const res = await CompanyManage.getCandidateProfileList();

    //         console.log(res.data);

    //         setData(res.data);

    //     } catch (err) {
    //         return err;
    //     }
    // }

    // useEffect(() => {
    //     getAppliedJobData();

    //     console.log(data, "********");
    // }, []);


    function formatNumber(number) {
        if (number >= 10000000) {
          return (number / 10000000).toFixed(1) + 'Cr';
        } else if (number >= 100000) {
          return (number / 100000).toFixed(1) + 'L';
        } 
        else if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'K';
        }else {
          return number;
        }
      }
    return (
        <>
            <Header />
            {
                loader ?
                    <div style={{ width: '100%', height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <HashLoader color={'blue'} loading={loader} size={50} />
                    </div> :
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                            <PageTitle activeName="Candidate Lists" motherName="Home" />
                        </div>
                        {
                            dataServices.filter((item) => {
                                if (item.job_id === Id) {
                                    return item;
                                }
                            }).map(val => val).length === 0 ?
                                <div className="col-xl-12 col-lg-12 mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h6 style={{ color: '#2e55fa' }}>No Candidate Found</h6>
                                </div>
                                :
                                <div className="content-block">
                                    <div className="section-full bg-white p-t50 p-b20">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 m-b30">
                                                    <div className="job-bx browse-job clearfix">
                                                        <div className="job-bx-title clearfix">
                                                            <h5 className="font-weight-700 pull-left text-uppercase">Candidate List</h5>

                                                        </div>
                                                        <ul className="post-job-bx browse-job-grid post-resume row">
                                                            {
                                                                dataServices.filter((item) => {
                                                                    if (item.job_id === Id) {
                                                                        return item;
                                                                    }
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <>

                                                                            <li className="col-lg-4 col-md-4" key={index}>
                                                                                <div className="post-bx">
                                                                                    <div className="d-flex m-b20">
                                                                                        <div className="job-post-info">
                                                                                            <h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
                                                                                            <p className="m-b5 font-13">
                                                                                                <Link to="#" className="text-primary"> {item.designation} </Link>
                                                                                                {item.organization ? " " + "at" + " " + item.organization : null}</p>
                                                                                            <ul>
                                                                                                <li><i className="fa fa-map-marker"></i>{item.region},{item.location}</li>
                                                                                                <li><i className="fa fa-money"></i> {formatNumber(item.maximum_salary)}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="job-time m-t15 m-b10">
                                                                                        <p className="mb-x font-15">
                                                                                            {item.job_title ? "Applied for :" + ' ' + item.job_title : null}
                                                                                        </p>
                                                                                    </div>
                                                                                    {/* <a href="files/pdf-sample.pdf" target="blank" className="job-links">
                                                                                        <i className="fa fa-download"></i>
                                                                                    </a> */}
                                                                                </div>
                                                                            </li>


                                                                            {/* <li className="col-lg-4 col-md-4" key={index}>
                                                                                <div className="post-bx">
                                                                                    <div className="d-flex m-b20">
                                                                                        <div className="job-post-company">
                                                                                            <Link to={''}><span>
                                                                                                <img alt="" src="images/banner/bnr1.jpg" />
                                                                                            </span></Link>
                                                                                        </div>
                                                                                        <div className="job-post-info">
                                                                                            <h5 className="m-b0"><Link to={`/candidate-applied-job-detail/${item.id}`}>{item.candidate_name}</Link></h5>
                                                                                            <p className="m-b5 font-14 text-primary"><i className="fa fa-home mr-1 fa-1x"></i>
                                                                                                {item.designation} {item.organization ? " " + "at" + " " + item.organization : null}
                                                                                            </p>
                                                                                            <ul className='fz'>
                                                                                                <li><i className="fa fa-map-marker"></i>{item.location}, {item.region}</li>
                                                                                                <li><i className="fa fa-money"></i> ₹ {item.maximum_salary}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="job-time m-t15 m-b10">
                                                                                        <p className="mb-x font-15">
                                                                                            {item.job_title ? "Applied for :" + ' ' + item.job_title : null}
                                                                                        </p>


                                                                                    </div>

                                                                                </div>
                                                                            </li> */}
                                                                        </>
                                                                    )


                                                                })
                                                            }
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>}
                    </div>}

            <Footer />
        </>
    )
}
export default JobCandidateProfileList;

