import axios from 'axios';

const Candidate_Certification = (payload) => axios.post('/candidate-certification/add', payload);

const Candidate_Certification_By_Id=(candidate_id)=>axios.get(`/candidate-certification/`+candidate_id);

const Candidate_Certification_Update=(candidate_id,payload)=>axios.put(`/candidate-certification/`+candidate_id,payload);

export default { Candidate_Certification,
                 Candidate_Certification_By_Id,
                 Candidate_Certification_Update
                
                };