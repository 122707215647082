import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';

const UserTermandCondition = () => {
  return (
    <>
    <Header/>
 <div>
      <div style={{
        width:'100%',
        paddingTop:30,
        paddingLeft:'10%',
        paddingRight:'10%',
        marginBottom:"10%",
        display:'flex',
        flexDirection:'column',
        backgroundColor:'#fff'
      }}>
       

         <h1>TERMS And Condition<hr/></h1>
         <h3>1. Usage of JobYard</h3>
         <p>The JobYard Platform (including any mobile based applications, website and web applications) is provided by Yoosys India either directly or through its affiliates including but not limited to Yoosys Solution Private Limited ("Yoosys India"). Through the JobYard Platform any person with a verified account can post jobs ("Job Poster") to the JobYard Platform, access and participate in the services provided by JobYard. By using JobYard Platform, you consent to the terms of the Terms of Service in addition to our Privacy Policy.</p>
         <p>A Job Poster accessing the JobYard Platform shall be bound by these Terms of Service, and all other rules, regulations and terms of use referred to herein or provided by JobYard in relation to any services provided via the JobYard Platform ("JobYard Services").</p>
         <p>JobYard shall be entitled to modify these Terms of Service, rules, regulations and terms of use referred to herein or provided by JobYard in relation to any JobYard Services, at any time, by posting the same on the JobYard Platform. Use of JobYard constitutes the Job Poster's acceptance of such modified Terms of Service, rules, regulations and terms of use referred to herein or provided by JobYard in relation to any JobYard Services, as may be amended from time to time. JobYard may, at its sole discretion, also notify the Job Poster of any change or modification in these Terms of Service, rules, regulations and terms of use referred to herein or provided by JobYard, by way of sending an email to the Job Poster's registered email address or posting notifications in the Job Poster accounts. The Job Poster may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms of Service, rules, regulations and terms of use referred to herein or provided by JobYard. If such options are not exercised by the Job Poster within the time frame prescribed in the email or notification, the Job Poster will be deemed to have accepted the modified Terms of Service, rules, regulations and terms of use referred to herein or provided by JobYard.</p>
         <p>Certain JobYard Services being provided on JobYard may be subject to additional rules and regulations set down in that respect. To the extent that these Terms of Service are inconsistent with the additional conditions set down, the additional conditions shall prevail. JobYard may, at its sole and absolute discretion:</p>
         <ul className='ml-5'>
             <li>
               <p>Restrict, suspend, or terminate any Job Poster's access to all or any part of the JobYard Platform or JobYard Services;</p>
             </li>
             <li>
               <p>Change, suspend, or discontinue all or any part of the JobYard Platform or JobYard Services;</p>
             </li>
             <li>
               <p>Reject, move, or remove any material that may be submitted by a Job Poster;</p>
             </li>
             <li>
               <p>Change, suspend, or discontinue all or any part of the JobYard Platform or JobYard Services;</p>
             </li> <li>
               <p>Move or remove any content that is available on the JobYard Platform;</p>
             </li>
             <li>
               <p>Deactivate or delete a Job Poster's account and all related information and files on the account;</p>
             </li> <li>
               <p>Establish general practices and limits concerning use of JobYard Platform;</p>
             </li>
             <li>
               <p>Assign its rights and liabilities to all Job Poster accounts hereunder to any entity (post such assignment intimation of such assignment shall be sent to all Job Posters to their registered email ids).</p>
             </li> 
         </ul>
         <p>In the event any Job Poster breaches, or JobYard reasonably believes that such Job Poster has breached these Terms of Service, or has illegally or improperly used the JobYard Platform or JobYard Services, JobYard may, at its sole and absolute discretion, and without any notice to the Job Poster, restrict, suspend or terminate such Job Poster's access to all or any part of the JobYard Platform, deactivate or delete the Job Poster's account and all related information on the account, delete any content posted by the Job Poster on JobYard and further, take technical and legal steps as it deems necessary.</p>
         <p>If JobYard charges its Job Posters a platform fee in respect of any JobYard Services, JobYard shall, without delay, repay such platform fee in the event of suspension or removal of the Job Poster's account or JobYard Services on account of any negligence or deficiency on the part of JobYard, but not if such suspension or removal is affected due to:</p>
         <ul className='ml-5'>
          <li>
            <p>any breach or inadequate performance by the Job Poster of any of these Terms of Service; or</p>
          </li>
          <li>
            <p>any circumstances beyond the reasonable control of JobYard.</p>
          </li>
         </ul>
         <p>By accepting these Terms of Service Job Posters are providing their consent to receiving communications such as announcements, administrative messages and advertisements from JobYard or any of its partners, licensors or associates.</p>
         <h3>2. Participation</h3>
         <p>When accessing and interacting with the JobYard Platform and JobYard Services a Job Poster will be able to post jobs to source and hire potential candidates and employees for the Job Poster's organization, view the profiles of suitable candidates who have the requisite skills and experience for the particular job(s) that the Job Poster is hiring for and schedule interviews with potential candidates/employees.</p>
         <p>To post a job a Job Poster shall be required to provide information about the job such as the job role, whether the job is part-time or full time, the days of work, work timings, the salary range for the job, the number of openings for the said job the minimum education level and the level of experience required for the job. While JobYard does not tolerate or allow for discrimination on the basis of gender, JobYard also recognizes that certain jobs might be gender specific and Job Posters have the option of specifying whether a job is gender specific. By agreeing to these Terms of Service and while posting a job on the JobYard Platform, Job Posters undertake that all information posted about a job will at all times be accurate, not be misleading that the Job Poster is not indulging in any discriminatory practices. The Job Poster understands and acknowledges that any incorrect information or misrepresentations about a job will affect the efficacy of the JobYard Platform and JobYard Services and that JobYard shall have the right to remove a job posting or suspend the Job Poster’s account.</p>
         <p>The job applications by the candidates on the JobYard Platform shall remain active only for a period of 60 (sixty) days from the date of application to the job posts and upon the expiry of the said period of 60 (sixty) days, such job applications shall be archived ("Archived Job Applications"). The Job Posters shall not have access to the list to the list of such candidates or to the Archived Job Applications upon the expiry of 60 (sixty) days. The candidates, however, may re-apply to a job post (if still active) after the expiry of 60 (sixty) days from the date such candidate made their first application to the same job post.</p>
         <p>Job Posters agree that they shall at all times be bound by and adhere to the Code of Conduct while posting jobs through the JobYard Platform and while using the JobYard Services.</p>
         <h3>3. Intellectual Property</h3>
         <p>The intellectual property rights ("Intellectual Property Rights")in all software underlying the JobYard Platform and the JobYard Services and material published on the JobYard Platform, including (but not limited to) software, advertisements, content (whether written, audio and/or visual), photographs, graphics, images, illustrations, graphs, charts, marks, logos, audio or video clippings, animations etc. is owned by JobYard, its affiliates, partners, licensors and/or associates. Job Posters may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on JobYard either in whole or in part without express written license from JobYard</p>
         <p>Job Posters are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available via the JobYard Platform ("Job Posters' Content"). Each Job Poster represents and warrants that they own all Intellectual Property Rights in the Job Posters’ Content and that no part of the Job Poster's Content infringes any third-party rights. Job Posters further confirm and undertake to not display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the JobYard Platform, without written authorization from such third party. Job Posters agree to indemnify and hold harmless JobYard, its directors, employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the JobYard Platform, by such Job Poster or through the Job Poster's commissions or omissions</p>
         <p>Job Posters hereby grant to JobYard and its affiliates, partners, licensors and associates a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Job Posters' Content for any of the following purposes:</p>
         <ul className='ml-5'>
            <li>
              <p>displaying Job Posters' Content on JobYard</p>
            </li>
            <li>
              <p>distributing Job Posters' Content, either electronically or via other media, to potential candidates, and/or</p>
            </li>
            <li>
              <p>storing Job Posters' Content in a remote database accessible by end users, for a charge.</p>
            </li>
            <li>
              <p>This license shall apply to the distribution and the storage of Job Posters' Content in any form, medium, or technology.</p>
            </li>
         </ul>
         <p>All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the JobYard Platform belonging to any person (including a Job Poster), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to JobYard.</p>
         <h3>4. Third Party Sites, Services and Products</h3>
         <p>Links to other Internet sites or mobile applications owned and operated by third parties may be provided via the JobYard Platform. Job Posters' use of each of those sites is subject to the conditions, if any, posted by those sites. JobYard does not exercise control over any Internet sites or mobile applications apart from the JobYard Platform and cannot be held responsible for any content residing in any third-party Internet site or mobile application. JobYard's inclusion of third-party content or links to third-party Internet sites or mobile applications is not an endorsement by JobYard of such third-party Internet site or mobile application.</p>
         <p>Job Posters' correspondence, transactions/offers or related activities with third parties including but not limited to potential candidates, payment providers and verification service providers, are solely between the Job Poster and that third party. Job Posters' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the Job Poster shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. The Job Poster agrees that JobYard will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.</p>
         <p>The JobYard Platform contains content that is created by JobYard as well as content provided by third parties (including potential candidates). JobYard does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not relied upon by the Job Posters in utilizing the JobYard Services provided on the JobYard Platform.</p>
         <h3>5. Participation</h3>
         <p>All information collected from Job Posters, such as registration (including but not limited to email addresses, mobile phone numbers, government identity documentation) and payment information, is subject to JobYard's Privacy Policy which is available at Privacy Policy.</p>
         <p>We do not share personal information of any individual with other companies/entities without obtaining permission. We may share all such information that we have in our possession in accordance with our Privacy Policy</p>
         <p>Once the personal information has been shared with you, you shall, at all times, be responsible to secure such information.</p>
         <p>You warrant and represent that you shall not disclose or transfer personal information shared by us to any sub-processors without ensuring that adequate and equivalent safeguards to the personal information.</p>
         <p>You, hereby agree and acknowledge that you will use the information shared with you only for the purpose of the Services. You shall not use such information for any personal or other business purposes. In the event you are found to be misusing the information shared with you, we shall, at our sole discretion, delete your account with immediate effect and you will be blocked from using/ accessing JobYard Platform in future.</p>
         <h3>6. Job Poster Conduct</h3>
         <p>Job Posters agree to abide by these Terms of Service and all other rules, regulations and terms of use of the Website. In the event Job Poster does not abide by these Terms of Service and all other rules, regulations and terms of use, JobYard may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:</p>
         <ul className='ml-5'>
          <li>
            <p>restricting, suspending, or terminating any Job Poster's access to all or any part of JobYard Services;</p>
          </li>
          <li>
          <p>deactivating or deleting a Job Poster's account and all related information and files on the account. Any amount remaining unused in the Job Poster's account on the date of deactivation or deletion shall be transferred to the Job Poster's bank account on record with JobYard subject to a processing fee (if any) applicable on such transfers as set out herein.</p>
          </li>
         </ul>
         <p>Job Posters agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by JobYard). Job Posters further agree to update and keep updated their registration information and information related to job posts made on the JobYard Platform.</p>
         <p>A Job Poster shall not register or operate more than one Job Poster account with JobYard.</p>
         <p>Job Posters agree to ensure that they can receive all communication from JobYard by marking e-mails or sending SMSs from JobYard. JobYard shall not be held liable if any e-mail/SMS remains unread by a Job Poster as a result of such e-mail getting delivered to the Job Poster's junk or spam folder.</p>
         <p>Any password issued by JobYard to a Job Poster may not be revealed to anyone else. Job Posters may not use anyone else's password. Job Posters are responsible for maintaining the confidentiality of their accounts and passwords. Job Posters agree to immediately notify JobYard of any unauthorized use of their passwords or accounts or any other breach of security.</p>
         <p>Job Posters agree to exit/log-out of their accounts at the end of each session. JobYard shall not be responsible for any loss or damage that may result if the Job Poster fails to comply with these requirements.</p>
         <p>Job Posters agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorized third-party software designed to modify or interfere with the JobYard Services and/or JobYard experience or assist in such activity.</p>
         <p>Job Posters agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying JobYard or JobYard’s Services.</p>
         <p>Job Posters agree that without JobYard's express written consent, they shall not modify or cause to be modified any files or software that are part of JobYard's Services or the JobYard Platform.</p>
         <p>Job Posters agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support JobYard or the JobYard’s Services (each a "Server"); or (2) the enjoyment of JobYard Services by any other Job Poster or person.</p>
         <p>Job Posters agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt JobYard Services or any other person's use or enjoyment of JobYard Services.</p>
         <p>Job Posters shall not attempt to gain unauthorised access to Job Poster accounts, Servers or networks connected to JobYard Services by any means other than the Job Poster interface provided by JobYard, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of JobYard Services.</p>
         <p>A Job Poster shall not publish any content that is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.</p>
         <p>Without limiting the foregoing, Job Posters agree not to use JobYard for any of the following:</p>
         <ul className='ml-5'>
          <li>
            <p>To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;</p>
          </li>
          <li>
            <p>To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;</p>
          </li>
          <li>
            <p>To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, JobYard, any software, hardware, or telecommunications equipment;</p>
          </li>
          <li>
            <p>To advertise, offer or sell any goods or services for any commercial purpose on JobYard including but not limited to multi-level marketing for a third party, promoting business of a third party, selling financial products such as loans, insurance, promoting demat account openings, without the express written consent of JobYard;</p>
          </li>
          <li>
            <p>To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;</p>
          </li>
          <li>
            <p>To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;</p>
          </li>
          <li>
            <p>To collect or store personal information about other Job Posters;</p>
          </li>
          <li>
            <p>To collect or store information about potential candidates;</p>
          </li>
          <li>
            <p>To mine information relating to potential candidates with the aim of creating a database of potential candidates whether or not such database is used or meant to be used by the Job Poster or any third party associated with the Job Poster or to whom such Job Poster makes such mined information available, for either a commercial purpose of for the Job Poster’s own use at a future date;</p>
          </li>
          <li>
            <p>To interfere with or disrupt the JobYard and/or the JobYard Platform, JobYard servers, or JobYard networks;</p>
          </li>
          <li>
            <p>To impersonate any person or entity, including, but not limited to, a representative of JobYard, or falsely state or otherwise misrepresent Job Poster's affiliation with a person or entity;</p>
          </li>
          <li>
            <p>To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through JobYard or to manipulate Job Poster's presence on the JobYard Platform;</p>
          </li>
          <li>
            <p>To take any action that imposes an unreasonably or disproportionately large load on JobYard’s infrastructure;</p>
          </li>
          <li>
            <p>To engage in any illegal activities.</p>
          </li>
          <li>
            <p>To engage in any action that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation.</p>
          </li>
         </ul>
         <p>If a Job Poster chooses a username that, in JobYard's considered opinion is obscene, indecent, abusive or that might subject JobYard to public disparagement or scorn, or a name which is an official team/league/franchise names and/or name of any sporting personality, as the case may be, JobYard reserves the right, without prior notice to the Job Poster, to restrict usage of such names, which in JobYard’s opinion fall within any of the said categories and/or change such username and intimate the Job Poster or delete such username and posts from JobYard, deny such Job Poster access to JobYard, or any combination of these options.</p>
         <p>Unauthorized access to the JobYard Platform is a breach of these Terms of Service, and a violation of the law. Job Posters agree not to access the JobYard Platform by any means other than through the interface that is provided by JobYard via the JobYard Platform for use in accessing the JobYard Platform. Job Posters agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of the JobYard Platform, JobYard Services or any information available for access through the JobYard Platform or JobYard Services, except those automated means that JobYard has approved in advance and in writing.</p>
         <p>Use of the JobYard Platform is subject to existing laws and legal processes. Nothing contained in these Terms of Service shall limit JobYard's right to comply with governmental, court, and law-enforcement requests or requirements relating to Job Posters' use of JobYard.</p>
         <p>Persons below the age of eighteen (18) years are not allowed to register with the JobYard Platform. All persons interested in becoming JobYard Job Posters might be required by JobYard to disclose their age at the time of getting access to the JobYard Platform. If a person declares a false age, JobYard shall not be held responsible and such person shall, in addition to forfeiting any and all rights over their JobYard account, shall indemnify and hold JobYard, its Directors, officers, employees, agents, affiliates harmless of any and all losses that may be suffered by JobYard its Directors, officers, employees, agents, affiliates by virtue of such false declaration being made. In case the person making the false declaration is below the age of 18 such person’s legal guardians shall indemnify and hold JobYard, its Directors, officers, employees, agents, affiliates harmless of any and all losses that may be suffered by JobYard its Directors, officers, employees, agents, affiliates by virtue of such false declaration having been made by said person.</p>
         <p>JobYard may not be held responsible for any content contributed by Job Posters on the JobYard Platform.</p>
         <h3>7. Eligibility</h3>
         <p>The JobYard Platform is open only to persons above the age of 18 years.</p>
         <p>The JobYard Platform is open only to persons, currently residing in India.</p>
         <p>Persons who wish to participate must have a valid email address and/or mobile phone number.</p>
         <p>JobYard may on receipt of information bar a person from accessing their JobYard account if such person is found to be in violation of any part of these Terms of Service or theCode of Conduct.</p>
         <p>Only those Job Posters who have successfully registered on the JobYard Platform shall be eligible to Post Jobs.</p>
         <h3>8. Dispute and Dispute Resolution</h3>
         <p>If any dispute arising out of, or in connection with, the JobYard Services provided by JobYard via the JobYard Platform, the construction, validity, interpretation and enforceability of these Terms of Service, or the rights and obligations of the Job Poster(s) or JobYard, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below arises between the Job Poster(s) and JobYard ("Dispute"), the disputing parties hereto shall endeavor to settle such Dispute amicably. The attempt to bring about an amicable settlement shall be considered to have failed if not resolved within 30 (thirty) days from the date of communicating the Dispute in writing.</p>
         <p>Nothing shall preclude any Party from seeking interim or permanent equitable or injunctive relief, or both, from the competent courts at New Delhi, having jurisdiction to grant relief on any Disputes. The pursuit of equitable or injunctive relief shall not be a waiver of the duty of the Parties to pursue any remedy (including for monetary damages) through the arbitration described herein.</p>
         <p>The arbitration award will be final and binding on the Parties.</p>
         <h3>9. Release and Limitations of Liability</h3>
         <p>Job Posters shall access the JobYard Services provided via the JobYard Platform voluntarily and at their own risk. JobYard shall, under no circumstances be held responsible or liable on account of any loss or damage sustained by Job Posters or any other person or entity during the course of access to the JobYard Platform.</p>
         <p>By accessing the JobYard Platform and JobYard Services provided therein, Job Posters hereby release from and agree to indemnify JobYard, and/or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access of the JobYard Platform and the JobYard Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.</p>
         <p>JobYard accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the data/information collated and published on the JobYard Platform.</p>
         <p>Job Posters shall be solely responsible for any consequences which may arise due to their access of JobYard Services by conducting an illegal act or due to non-conformity with these Terms of Service and other rules and regulations in relation to JobYard Services, including provision of incorrect personal details. Job Posters also undertake to indemnify JobYard and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by JobYard on account of such act on the part of the Job Posters.</p>
         <p>Job Posters shall indemnify, defend, and hold JobYard harmless from any third party/entity/organization claims arising from or related to such Job Poster's engagement with the JobYard Platform. In no event shall JobYard be liable to any Job Poster for acts or omissions arising out of or related to Job Poster's engagement with the JobYard Platform.</p>
         <p>In consideration of JobYard allowing Job Posters to access the JobYard Platform, to the maximum extent permitted by law, the Job Posters waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against JobYard, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of JobYard Services.</p>
         <h3>10. Disclaimers</h3>
         <p>To the extent permitted under law, neither JobYard nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material.</p>
         <p>To the extent permitted under law, JobYard shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, products, JobYard Services or sites.</p>
         <p>Any material accessed, downloaded or otherwise obtained through the JobYard Platform is done at the Job Poster's discretion, competence, acceptance and risk, and the Job Poster will be solely responsible for any potential damage to Job Poster's computer system or loss of data that results from a Job Poster's download of any such material.</p>
         <p>JobYard shall make best endeavours to ensure that the JobYard(s) is error-free and secure, however, neither JobYard nor any of its partners, licensors or associates makes any warranty that:</p>
         <ul className='ml-5'>
           <li>
              <p>the JobYard Platform will meet Job Posters' requirements,</p>
           </li>
           <li>
              <p>JobYard Platform will be uninterrupted, timely, secure, or error free</p>
           </li>
           <li>
              <p>the results that may be obtained from the use of JobYard Platform will be accurate or reliable; and</p>
           </li>
           <li>
              <p>the quality of any products, JobYard Services, information, or other material that Job Posters purchase or obtain through the JobYard Platform will meet Job Posters' expectations.</p>
           </li>
         </ul>
         <p>In case JobYard discovers any error, JobYard reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the Job Poster or deduction from the Job Poster's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, JobYard agrees to notify the Job Poster of the error and of the exercise of the remedy(ies) to rectify the same.</p>
         <p>To the extent permitted under law, neither JobYard nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.</p>
         <p>Any JobYard Services being hosted or provided, or intended to be hosted on the JobYard platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated, if such permission or authority is either not obtained or denied either before or after the availability of the relevant JobYard Services are hosted or provided.</p>
         <p>To the extent permitted under law, in the event of suspension or closure of any JobYard Services Job Posters shall not be entitled to make any demands, claims, on any nature whatsoever.</p>
      </div>
      <Footer/>
    </div>

        
    </>
  )
}

export default UserTermandCondition