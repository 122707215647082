import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import PageTitle from '../../components/Layout/PageTitle';
import { Link, useParams } from 'react-router-dom';
import Users from '../../services/Users';
import ProfileVisiterService from '../../services/ProfileVisiterService';

var bnr ="images/banner/bnr.jpg"

const EmployerDetails = () => {

    const [userData , setUserData] = useState([]);
    const [visiterData , setVisiterData] = useState([]);
    const [dateData , setDateData] = useState("");
   const {id} = useParams();



    const getAllUsers=async()=>{
        try{
               const res = await Users.getUser();
              if(res.status === 200){
                  setUserData(res.data);
               }
        }catch(err){
            return err;
        }
    }

    const getVisiterData=async()=>{
        try{
         const res = await ProfileVisiterService.GetProfileVisiters();
         if(res.status === 200){
             setVisiterData(res.data);
         }
        }catch(err){
            return err;
        }
    }


    useEffect(()=>{
     getAllUsers();
     getVisiterData();
    },[])

	
    var company = visiterData.filter((val)=>{if(val.candidate_id === parseInt(id)){return val}}).map(val=>val.employer_id)
	company.sort();
	var current = null;
	var Employer = [];
	var cnt = 0;
	for (var i = 0; i < company.length; i++) {
		if (company[i]!== current) {
			if (cnt > 0) {

				Employer.push(current);

			}
			current = company[i];
			cnt = 1;
		} else {
			cnt++;
		}
	}
	if (cnt > 0) {
		Employer.push(current);
	}

    
    var Employerlists = [];
	for (var x = 0; x < userData.length; x++) {
		var match = false;
		for (var y = 0; y < Employer.length; y++) {
			if (userData[x].id === Employer[y]) {
				match = true;
				break;
			}
		}
		if (match) {
			Employerlists.push(userData[x])
		}
	}

    if(dateData){
		var today = new Date();
		var previousDate =new Date(new Date().setDate(today.getDate() - parseInt(dateData)));

	}
  return (
    <>
     <Header/>
     <div className="page-content bg-white">
			<div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bnr + ")" }}>
                    <PageTitle activeName="Profile Visiters" motherName="Home" />
                </div>
                 <div className="content-block">
					<div className="section-full bg-white p-t50 p-b20">
						<div className="container">
							<div className="row">
								<div className="col-xl-12 col-lg-12 m-b30">
									<div className="job-bx browse-job clearfix">
										<div className="job-bx-title clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Profile Visiters</h5>
											<div className="float-right">
												<span className="select-title">Sort by freshness</span>
												<select style={{fontSize:12}}
														value={dateData}
														onChange={(e)=>{setDateData(e.target.value)}}
														>
															<option value="" >All</option>
															<option value="60" >Last 2 Months</option>
															<option value="30" >Last Months</option>
															<option value="7" >Last Weeks</option>
															<option value="3" >Last 3 Days</option>
														</select>
											</div>
											</div>
                                            <ul className="post-job-bx browse-job-grid post-resume row">

{
      Employerlists.filter((val)=>{if((!previousDate || (previousDate && Date.parse(previousDate) <= Date.parse(val.created_on)))){return val}}).map((item,index) => {
        return (
            <>
            
                <li className="col-lg-4 col-md-4" key={index}>
        <div className="post-bx">
            <div className="d-flex m-b20">
            <div className="job-post-company">
            
            </div>
                <div className="job-post-info">
                    <h5 className="m-b0"><Link to={`#`}>{item.name}</Link></h5>
                    <ul className="m-b5 font-14 text-primary">
                    <li className='mt-2'> <i className="fa fa-phone mr-1 fa-1x"></i>
                        {item.mobile ? "+91"+item.mobile:"+91xxxxxxxxxx"} </li>
                        <li className='mt-2'>  <i className="fa  fa-envelope ml-1 fa-1x"></i> {item.email}</li>
                        <li className='mt-2'><i className="fa  fa-home ml-1 fa-1x"></i>{ item.company_name ? "Hiring For: "+item.company_name :null}</li>
                        
                         </ul>
                   
                </div>
            </div>
            <div className="job-time m-t15 m-b10">
        
                        {/* <Link  to={"/files/pdf-sample.pdf"} target="blank" className="job-links">
                         <i className="fa fa-download"></i>
                       </Link> */}
            </div>
         
        </div>
    </li>
            </>
        )


    })
}

</ul>

                                     </div>
                                 </div>
                            </div>
                       </div>
                  </div>
             </div>
         </div>
     <Footer/>
    </>
  )
}

export default EmployerDetails;