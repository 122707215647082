import React, { useState, useEffect } from 'react';
import Header1 from '../../../components/Layout/Header';
import AdminSideBar2 from './AdminSideBar2';
import Footer from '../../../components/Layout/Footer';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AlertMessage from '../../../components/AlertMessage';
import ExperienceMasterService from '../../../services/Master/ExperienceMaster';




const AdminExperience = () => {

    const [addExperience, setAddExperience] = useState(false);
    const [experience, setExperience] = useState([]);
    const [id, setId] = useState();
    const [experience_name, setExperience_name] = useState();
    const [editExperience, setEditExperience] = useState(false);
    const [deleteExperience, setDeleteExperience] = useState(false);
    const [experienceModal, setExperienceModal] = useState('');
    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });



    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const getAllExperience = async () => {
        try {
            const res = await ExperienceMasterService.GetExperience();
            if (res.status === 200) {
                setExperience(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    useEffect(() => {
        getAllExperience();
    }, []);


    const EditHandler = (Id) => {
        experience.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setExperience_name(val.exp_year);
                setEditExperience(true);

            }
        })
    }

    const ExperienceDeleteHandler = (Id) => {
        experience.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteExperience(true);

            }
        })
    }

    const experienceHandler = async () => {
        try {

            const payload = {};
            payload.exp_year = experienceModal;
            if (payload.exp_year) {
                const res = await ExperienceMasterService.AddExperience(payload);
                if (res.status === 200) {
                    setExperienceModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }


        } catch (err) {
            return (err);
        }
    }

    const EditExperienceMethod = async () => {
        try {
            const payload = {};
            payload.exp_year = experience_name;
            var Id = parseInt(id);
            if (payload.exp_year) {
                const res = await ExperienceMasterService.UpdateExperience(Id, payload);
                if (res.status === 200) {
                    setExperience_name("");
                    setAlertState({ open: true, type: 'success', message: 'Data updated successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }

    const deleteExperienceMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await ExperienceMasterService.DeleteExperience(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }
    }



    return (
        <>

            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Experience Master</li>
								</ul>
							</div> */}
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                <AdminSideBar2 activelist="admin-experience-master" />
                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12 ">
                                <div className=" job-bx bg-white m-b30">
                                    <div className='d-flex'	>
                                        <h5 className="m-b15">Experience Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddExperience(true)} >
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>
                                    <table className=' table-job-bx cv-manager company-manage-job '>
                                        <thead>
                                            <tr className="table-primary">
                                                <th >Sl No.</th>
                                                <th >Experiences</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                experience.map((val, ind) => {
                                                    return (
                                                        <tr className='table-hover ' key={ind} >
                                                            <td> {ind + 1}</td>
                                                            <td >{val.exp_year}</td>
                                                            <td >
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => ExperienceDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addExperience} onHide={setAddExperience} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Add Experience </h5>
                            <button type="button" className="close" onClick={() => { setAddExperience(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Experience</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Experience."
                                                value={experienceModal}
                                                onChange={(e) => setExperienceModal(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setAddExperience(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success" onClick={experienceHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>

            {/*================================================== Edit Modal ==================================================== */}
            <Modal show={editExperience} onHide={setEditExperience} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Experience </h5>
                            <button type="button" className="close" onClick={() => { setEditExperience(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Experience</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Experience."
                                                value={experience_name}
                                                onChange={(e) => setExperience_name(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setEditExperience(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning" onClick={EditExperienceMethod}  >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>

            {/* ============================================== Delete Modal ================================================= */}


            
            <Modal show={deleteExperience} onHide={setDeleteExperience} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">

                <div className="modal-dialog my-0 mx-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster"> Delete Experience</h5>

                            <button type="button" className="close" onClick={() => { setDeleteExperience(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'> Do You want to delete this Experience </span></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary sm" onClick={() => { setDeleteExperience(false) }} >Cancel</button>
                            <button type="button" className=" btn btn-danger sm" onClick={deleteExperienceMethod} >Delete</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />

        </>
    )
}
export default AdminExperience;

