import React from 'react';
import { Link } from 'react-router-dom';

const AdminSideBar2 = (props) => {
    const userName = localStorage.getItem("userName");

    const activeMenu=props.activelist
    const MastersSideMenu = [{  link: 'admin-year-master', menuName: 'Year Master' },
    {  link: 'admin-month-master', menuName: 'Month Master' },
    { link: 'admin-date-master', menuName: 'Date Master' },
    {  link: 'admin-location-master', menuName: 'Location Master' },
    {  link: 'admin-category-master', menuName: 'Category Master' },
    {  link: 'admin-region-master', menuName: 'Region Master' },
    {  link: 'admin-skill-master', menuName: 'Skill Master' },
    { link: 'admin-jobtype-master', menuName: 'Jobtype Master' },
    {  link: 'admin-experience-master', menuName: 'Experience Master' },
    {  link: 'admin-qualification-master', menuName: 'Qualification Master' },
   
    ];
    const MastersMenuList = MastersSideMenu.map((item, ind) => {
        return (
          <li key={ind}><Link to={"/" + item.link}  style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}>
    
            <span>{item.menuName}</span></Link>
          </li>
        );
      });
    return (
        <div className="sticky-top">
            <div className="candidate-info company-info">
            <div className="candidate-detail text-center">
            <div className="canditate-des">
              <Link to={"#"}>
                <img alt="" src="/images/logo/icon3.jpg" />
              </Link>
              <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
                <input type="file" className="update-flie" />
                <i className="fa fa-pencil"></i>
              </div>
            </div>
            <div className="candidate-title">
              <h4 className="m-b5"><Link to={"#"}>@{userName}</Link></h4>
            </div>
          </div>
                <ul >

                {MastersMenuList}
                    {/* <li style={{ border: "1px groove #eaecd5" }}><Link to={"/admin-year-master"} className="active">
                        <i className="" aria-hidden="true"></i>
                        <span>Year Masters</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5" }}><Link to={"/admin-month-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Month Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5" }}><Link to={"/admin-date-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Date Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5" }}><Link to={"/admin-location-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Location Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5" }}><Link to={"/admin-category-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Category / Industry Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5"}}><Link to={"/admin-region-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Region Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5"}}><Link to={"/admin-skill-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Skill Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5"}}><Link to={"/admin-jobtype-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Job Type Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5"}}><Link to={"/admin-experience-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Experience Master</span></Link></li>
                    <li style={{ border: "1px groove #eaecd5"}}><Link to={"/admin-qualification-master"}>
                        <i className="" aria-hidden="true"></i>
                        <span>Qualification Master</span></Link></li> */}

                </ul>
            </div>
        </div>
    )
};

export default AdminSideBar2;