import axios from 'axios';

const AddExperience=(payload)=> axios.post('/masters/experience',payload);

const GetExperience=()=>axios.get('/masters/experience');


const UpdateExperience=(id,payload)=>axios.put(`/masters/experience/`+id,payload);

const DeleteExperience=(id)=>axios.delete(`/masters/experience/`+id);


export default {AddExperience,GetExperience,UpdateExperience,DeleteExperience};