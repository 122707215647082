import axios from 'axios';

const Candidate_Work_Sample = (payload) => axios.post('/candidate-work-sample/add', payload);


const Candidate_Work_Sample_By_Id=(candidate_id)=>axios.get(`/candidate-work-sample/`+candidate_id);

const Candidate_Work_Sample_Update=(candidate_id,payload)=>axios.put(`/candidate-work-sample/`+candidate_id,payload);

export default { Candidate_Work_Sample,
                 Candidate_Work_Sample_By_Id,
                 Candidate_Work_Sample_Update
                
                };