import React from 'react';
 
export default (state, action) => {
   switch(action.type) {
       case 'LOG_IN':
           return {
                loginStatus: true, 
                role: action.payload
           }
       case 'LOG_OUT':
           return {
                loginStatus: false, 
                role: 'none'
           }
       default:
           return state;
   }
}