import React, { useState,useEffect } from "react";
import CandidateSideNav from "./component/CandidateSideNav";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import Users from '../../services/Users';
import AlertMessage from "../../components/AlertMessage";
import { ThreeCircles } from 'react-loader-spinner';
import Error from "../../constants/ErrorMessage";

 
const JobsChangePassword = () => {
  const [password, setPassword] = useState({ old_password: "",
  																           new_password: "",  
    																				 confirm_new_password: "" });
  const [userdata, setUserData] = useState({});
  const [alertState, setAlertState] = useState({});
  const [loader , setLoader] = useState(false);
  const [viewError , setViewError] = useState(false);
  const [showPasswordOld,setShowPasswordOld]=useState(false);
  const [showPasswordNew,setShowPasswordNew]=useState(false);
  const [showPasswordConfirm,setShowPasswordConfirm]=useState(false)
const [error,setError]=useState("")

  const userId = JSON.parse(localStorage.getItem("userId"));
  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }

 
  const passwordOnChangeHandler = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
    var lowerCase = /[a-z]/g;
		var upperCase = /[A-Z]/g;
		var numbers = /[0-9]/g;
		var special=/[ -/:-@[-`{-~#^$% ]/g;

		
		if(!password.new_password.match(lowerCase) ){
			setError("Password should contains lowercase letters!")
		}else if(!password.new_password.match(upperCase)){
			setError("Password should contain uppercase letters!")
		}else if(!password.new_password.match(numbers)){
			setError("Password should contains numbers also!")
		}else if(!password.new_password.match(special)){
			setError("Password should contains one special charecter")
		}else {
			setError("Password is Strong")
		}
  };


  const PasswordVisibility =()=>{
    setShowPasswordOld((showPasswordOld)=>!showPasswordOld)
  }

  const PasswordVisibilityNew= ()=>{
    setShowPasswordNew((showPasswordNew)=>!showPasswordNew)
  }

  const PasswordVisibilityConfirm =()=>{
    setShowPasswordConfirm((showPasswordConfirm)=>!showPasswordConfirm)
  }
  const getUserData = async () => {
    
    const res = await Users.getUserById(userId);


    setUserData(res.data);

    
  };

  useEffect(() => {
    setLoader(true);
    getUserData();
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, [`${userId}`]);

  const updateHandler = async () => {
    try {
      setViewError(true);

      if(!password.old_password || password.old_password.length < 8 || password.old_password.length > 100 || password.old_password !== userdata.password){
				if(password.old_password.length < 8){
					setAlertState({open:true, type:'warning', message:'Password should be minimum 8 characters.'});
				}else if(password.old_password.length > 100){
					setAlertState({open:true, type:'warning', message:'Password should be under 100 characters'});
				}else if(password.old_password !== userdata.password){
					setAlertState({open:true, type:'error', message:'Invalid password.'});

        }
				else{
					setAlertState({open:true, type:'warning', message:'Please provide a password.'});
				}
			}else if(password.new_password==="" || password.new_password.length < 8 || password.new_password.length > 100){
			
			  if(password.new_password.length < 8){
					setAlertState({open:true, type:'warning', message:'Password should be minimum 8 characters.'});
				 }else if(password.new_password.length >100 ){
					setAlertState({open:true, type:'warning', message:'Password should be under 100 characters'});
				 }else{
					setAlertState({open:true, type:'warning', message:'Please provide a password.'});
				 }
			}else if(password.confirm_new_password==="" || password.confirm_new_password.length < 8 || password.new_password !== password.confirm_new_password){
			   if(password.new_password !== password.confirm_new_password){
					setAlertState({open:true, type:'warning', message:"Password doesn't match."});
				 }else{
					setAlertState({open:true, type:'warning', message:'Please provide a password.'});
				 }
			}else{
        setLoader(true);
				const payload={}; 
				payload.id=userId;	
				payload.password=password.new_password;
        payload.confirmPassword = password.confirm_new_password;	
				const res=await Users.updateUser(userId,payload);				 
				 if(res.status===200)
				{
			   setAlertState({open:true, type:'success', message:'password changed'});
         setViewError(false)
			   setPassword({
			  	 old_password:'',
			  	 new_password:'',
			  	 confirm_new_password:'',
			   });
          setTimeout(() => {
            setLoader(false)
          }, 1500);

					 }
			}

    } catch (err) {
      setLoader(false)

      if (
        err.response &&
        err.response.data &&
        err.response.data.status === 400
      ) {
        setAlertState({
          open: true,
          type: "error",
          message: err.response.data.message});
        
      } else {
        setAlertState({
          open: true,
          type: "error",
          message: "Something went wrong."});
        
      }
    }
  };

  return (
    <>
      <Header activelist="jobs-change-password"/>
      {loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>
     
   :
   <div style={{borderTop:'3px solid #2e55fa'}}> 

      <div className="page-content bg-white">

        <div className="content-block">
        
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <CandidateSideNav activemenu="jobs-change-password" />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Change Password
                      </h5>
                      <Link
                        to="/jobs-alerts"
                        className="site-button right-arrow button-sm float-right"
                      >
                        Back
                      </Link>
                    </div>
				<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label style={{marginBottom:"3%"}}>Old Password</label>
                            <div style={{marginLeft:"96.5%",marginBottom:"-5%"}}><Link  onClick={PasswordVisibility}>{ showPasswordOld ?<i class="fa-regular fa-eye"></i>:<i class="fa-regular fa-eye-slash"></i>}</Link></div>
                            <input
                              type={showPasswordOld ? "text":"password"}
                              className="form-control"
                              placeholder="Enter your old password"
                              name="old_password"
                              value={password.old_password}
                              onChange={(e)=>{passwordOnChangeHandler(e);setViewError(false)}}
                            />
                            {password.old_password ? null :(viewError ? ( !password.old_password ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.password}</span> :null) :null) }
														{password.old_password ?(password.old_password.length < 8 ?  <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.minPass}</span> :null ) :null}
														{password.old_password ?(password.old_password.length >100 ?  <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.maxPass}</span> :null ) :null}
                            {/* {password.old_password ? (viewError ? ( password.old_password !== userdata.password ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.invalidPassword}</span> :null) :null) :null} */}

                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label style={{marginBottom:"5%"}}>New Password </label>
                            <div style={{marginLeft:"93%",marginBottom:"-9.5%" }}><Link  onClick={PasswordVisibilityNew}>{ showPasswordNew ?<i class="fa-regular fa-eye"></i>:<i class="fa-regular fa-eye-slash"></i>}</Link></div>
                            <input
                              type={showPasswordNew ?"text":"password"}
                              className="form-control"
                              placeholder="Enter a new password"
                              name="new_password"
                              value={password.new_password}
                              onChange={passwordOnChangeHandler}
                            />
                            {password.new_password ? null :(viewError ? ( !password.new_password ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.password}</span> :null) :null) }
														{password.new_password ?(password.new_password.length < 8 ?  <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.minPass}</span> :null ) :null}
														{password.new_password ?(password.new_password.length >100 ?  <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.maxPass}</span> :null ) :null}
                            {/* <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{error}</span> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label style={{marginBottom:"5%"}}>Confirm New Password</label>
                            <div style={{marginLeft:"93%",marginBottom:"-9.5%" }}><Link  onClick={PasswordVisibilityConfirm}>{ showPasswordConfirm ?<i class="fa-regular fa-eye"></i>:<i class="fa-regular fa-eye-slash"></i>}</Link></div>
                            <input
                              type={showPasswordConfirm ?"text":"password"}
                              className="form-control"
                              placeholder="Enter confirm password"
                              name="confirm_new_password"
                              value={password.confirm_new_password}
                              onChange={passwordOnChangeHandler}
                            />
                            {password.confirm_new_password ? null :(viewError ? ( !password.confirm_new_password ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.confirmPassword}</span> :null) :null) }
														{password.confirm_new_password ? (password.confirm_new_password !== password.new_password ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.matchedPassword}</span> :null )  :null}
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                          type="button"
                            className="site-button"
                            onClick={updateHandler}
                          >
                            Update Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Browse Jobs END --> */}
        </div>
      </div>

      <Footer />
      </div>}
    </>
  );
};

export default JobsChangePassword;
