import axios from "axios";



const postUserCompanyMapping= (payload) => axios.post('/user-company-mapping/add', payload);

const getUserCompanyById = (id) => axios.get(`/user-company-mapping/`+id);

const getAllUserCompany = () => axios.get(`/user-company-mapping`);

const updateUserCompany=(id,payload)=> axios.put(`/user-company-mapping/`+id,payload)

export default {postUserCompanyMapping,getUserCompanyById,getAllUserCompany,updateUserCompany}