import axios from 'axios';

const AddProfileVisiters=(payload)=> axios.post('/candidate/profile-visiters',payload);

const GetProfileVisiters=()=>axios.get('/candidate/profile-visiters');


const UpdateProfileVisiters=(id,payload)=>axios.put(`/candidate/profile-visiters/`+id,payload);

const DeleteProfileVisiters=(id)=>axios.delete(`/candidate/profile-visiters/`+id);


export default {AddProfileVisiters,GetProfileVisiters,UpdateProfileVisiters,DeleteProfileVisiters};