import React, { useEffect, useState } from 'react'
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import PageTitle from '../../components/Layout/PageTitle';
import { useParams } from 'react-router-dom';
import { Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BrowseCandiateService from '../../services/BrowseCandiate';
import AlertMessage from '../../components/AlertMessage';
import ReportCandidateService from '../../services/admin/ReportCanidateService';
import Users from '../../services/Users';
import Education from '../../services/candidate_resume/Education';
import ProfileVisiterService from '../../services/ProfileVisiterService'
import CandidateProfile from '../../services/CandidateProfile'

let roleId;

const CandidateDetails = () => {

    const [jobData, setJobData] = useState({});
    const [alertState, setAlertState] = useState({ });
    const [reportModal, setReportModal] = useState(false);
    const [reason, setReason] = useState('');
    const [reportCandidateLists, setReportCandidateLists] = useState([]);
    const [userData, setUserData] = useState({});
    const [qualification, setQualification] = useState({});
    const [visiters, setVisters] = useState([]);
    const [candidateData, setCandidateData] = useState({})


    const { id } = useParams();
    const navigate = useNavigate();
    const userId = JSON.parse(localStorage.getItem('userId'));

    roleId = (JSON.parse(window.localStorage.getItem("roleId")));

    const getAppliedJobData = async () => {
        try {
            const res = await BrowseCandiateService.getBrowseCandidateById(id);
            

            if (res.status === 200) {
                setJobData(res.data)
                const result = await CandidateProfile.getCandidateById(res.data.candidate_id);
                if (result.status === 200) {
                    setCandidateData(result.data);
                    
                }
            }
        } catch (err) {
            return err;
        }
    }

console.log()
    const getQualification = async () => {
        try {
            const res = await Education.Candidate_Education_By_Id(id);
            if (res.status === 200) {
                setQualification(res.data);
               
            }
        } catch (err) {
            return err;
        }
    }
   

    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const getAllReportCandidate = async () => {
        try {
            const res = await ReportCandidateService.getReportCandidate();
            if (res.status === 200) {
                setReportCandidateLists(res.data);

            }

        } catch (error) {
            return error;
        }
    }

    // const getCandidateDetails=async()=>{
    //     try {
    //         const res = await CandidateProfile.Get_Candidate_By_Id(id);
    //         if(res.status === 200){
    //             setCandidateData(res.data);
    //             console.log(res,'@ADhfhfd');
    //         }
    //     } catch (error) {
    //        console.log(error) 
    //     }
    // }


    useEffect(() => {
        getAppliedJobData();
        getQualification();
    }, [`${id}`]);

    const getUserData = async () => {
        try {
            const res = await Users.getUserById(`${userId}`);
           
            if (res.status === 200) {
                setUserData(res.data);
            }
        } catch (err) {
            return err;
        }
    }


    const getVisiter = async () => {
        try {
            const res = await ProfileVisiterService.GetProfileVisiters();
            if (res.status === 200) {
                setVisters(res.data)
                    ;
            }
        } catch (err) {
            return err;
        }
    }


    useEffect(() => {
        getAllReportCandidate();
        getVisiter();

    }, [])



    useEffect(() => {
        getUserData();

    }, [`${userId}`])

    if (userData.length === 0) {
        getUserData();

    }



    if (userData.id) {

        const payload = {};
        payload.candidate_id = id;
        payload.employer_id = userData.id;



        ProfileVisiterService.AddProfileVisiters(payload);



    }

    const DeleteHandler = async () => {
        try {
            const res = await BrowseCandiateService.DeleteBrowseCandidate(id);

            if (res.status === 200) {
                navigate('/browse-candidates');
            }
        } catch (err) {
            return err;
        }
    }

    const Reporthandler = (idx) => {
        setReportModal(true);
    }

    const reportCandidateHandler = async () => {
        try {
            const payload = {};


            payload.user_id = userId;
            payload.job_id = jobData.job_id;
            payload.candidate_id = jobData.candidate_id;
            payload.job_title = jobData.job_title;
            payload.candidate_name = jobData.candidate_name;
            payload.minimum_salary = jobData.minimum_salary;
            payload.maximum_salary = jobData.maximum_salary;
            payload.region = jobData.region;
            payload.location = jobData.location;
            payload.designation = jobData.designation;
            payload.organization = "NA";
            payload.keySkills = jobData.keySkills;
            payload.age = jobData.age;
            payload.mobile = jobData.mobile;
            payload.email = jobData.email;
            payload.available_year = "NA";
            payload.available_month = "NA";
            payload.experience = "NA";
            payload.job_type = jobData.job_type;
            payload.reason = reason;
            payload.employee_name = userData.name;
            payload.employee_email = userData.email;
            payload.employee_mobile = userData.mobile;

            

            if (payload.reason) {

                const res = await ReportCandidateService.postReportCandidate(payload);
                if (res.status === 200) {
                    setReportModal(false);
                    window.location.reload();
                    setAlertState({ open: true, type: 'success', message: 'Candidate reported successfully' });

                }

            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please select reason' });

            }

        } catch (error) {
            return error;
        }
    }
    function formatNumber(number) {
        if (number >= 10000000) {
          return (number / 10000000).toFixed(1) + 'Cr';
        } else if (number >= 100000) {
          return (number / 100000).toFixed(1) + 'L';
        } 
        else if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'K';
        }else {
          return number;
        }
      }

    // console.log(reportCandidateLists.filter((val) => { if (val.user_id === userId && val.candidate_id === jobData.candidate_id) { return val; } }).map(val => val).length, '@length')
    return (
        <>
            <Header />
            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            <div className="page-content bg-white">
                <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                    <PageTitle activeName="Candidate Details" motherName="Home" />
                </div>
                <div className="content-block">
                    <div className="section-full content-inner-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                                        <h4 className="text-black font-weight-700 p-t10 m-b15 text-center">Candidate Details</h4>
                                        <hr />
                                        <Row>
                                            <div className="col-lg-4 ml-5">
                                                <ul>
                                                    <li><i className="ti-briefcase"></i><strong className="font-weight-700 text-black">Name</strong> <span className="text-black-light"> {candidateData.name} </span></li>
                                                    <li><i className="ti-bookmark-alt"></i><strong className="font-weight-700 text-black">Designation </strong><span className="text-black-light">{candidateData.professional_title} </span></li>
                                                    <li><i className="ti-layers-alt"></i><strong className="font-weight-700 text-black"> Organization </strong><span className="text-black-light">{candidateData.company}</span></li>
                                                    <li><i className="ti-layers-alt"></i><strong className="font-weight-700 text-black"> Skills </strong><span className="text-black-light">{candidateData.languages} </span></li>

                                                </ul>
                                            </div>
                                            <div className="col-lg-4 ml-4">
                                                <ul>
                                                    <li><i className="ti-email"></i><strong className="font-weight-700 text-black">Age </strong><span className="text-black-light">{candidateData.age} </span></li>
                                                    <li><i className="ti-dashboard"></i><strong className="font-weight-700 text-black">Email </strong><span className="text-black-light">{candidateData.email} </span></li>
                                                    <li><i className="ti-money"></i><strong className="font-weight-700 text-black">Mobile </strong><span className="text-black-light"> {candidateData.phone} </span></li>
                                                    <li><i className="ti-location-pin"></i><strong className="font-weight-700 text-black">Address </strong><span className="text-black-light">{candidateData.city},{candidateData.country} </span></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-3 ml-2">
                                                <ul>
                                                    <li><i className="ti-email"></i><strong className="font-weight-700 text-black">Current Salary </strong><span className="text-black-light">{formatNumber(candidateData.current_salary)}</span></li>
                                                    <li><i className="ti-calendar"></i><strong className="font-weight-700 text-black">Expected Salary </strong><span className="text-black-light">{formatNumber(candidateData.expected_salary)} </span> </li>
                                                    <li><i className="ti-calendar"></i><strong className="font-weight-700 text-black">Experience </strong><span className="text-black-light">{candidateData.experience} </span> </li>
                                                    {/* <li><i className="ti-calendar"></i><strong className="font-weight-700 text-black">Qualification </strong><span className="text-black-light">{candidateData.Education} </span> </li> */}

                                                </ul>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {roleId === 3 ?  null :
                <div className='text-center mb-2'>
                    <button className="site-button mt-2" style={{ borderRadius: 30 }} onClick={DeleteHandler}>Remove</button>
                    {
                        reportCandidateLists.filter((val) => { if (val.user_id === userId && val.candidate_id === jobData.candidate_id && val.job_id === jobData.job_id) { return val; } }).map(val => val).length > 0 ?
                            <p style={{ color: 'red', marginTop: '5px' }}>You reported this candidate </p> :
                            <button className='site-button  ml-2 mt-2' style={{ borderRadius: 30,backgroundColor:"#efbb20" }} onClick={() => Reporthandler(jobData.id)}>Report</button>

                    }

                </div> }

            <Footer />

            <Modal show={reportModal} onHide={setReportModal} className="modal fade modal-bx-info">
                <div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Report Candidate</h5>

                            <button type="button" className="close" onClick={() => setReportModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <h6> Select reason for report :</h6>

                            <input
                                className='mr-2'
                                placeholder='Enter Reason'
                                type='text'
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                style={{ width: '100%', border: '2px solid black' }}
                            />

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={reportCandidateHandler} >Report</button>
                            <button type="button" className="btn btn-primary" onClick={() => setReportModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CandidateDetails;
