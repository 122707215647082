import axios from 'axios';

const Candidate_Personal_Details = (payload) => axios.post('/candidate-personal-details/add', payload);

const Candidate_Personal_Details_By_Id=(candidate_id)=>axios.get(`/candidate-personal-details/`+candidate_id);

const Candidate_Personal_Details_Update=(candidate_id,payload)=>axios.put(`/candidate-personal-details/`+candidate_id,payload);


export default { Candidate_Personal_Details,
                 Candidate_Personal_Details_By_Id,
                 Candidate_Personal_Details_Update
                
                };