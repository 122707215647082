import React, { useState, useEffect, version } from "react";
import { Link } from "react-router-dom";
import CandidateProfile from "../../services/CandidateProfile";
import EmployerProfileServices from "../../services/EmployerProfile";
import Users from "../../services/Users";
import EmployerTeam from "../../services/EmployerTeam";
import "../../assets/css/dark-layout.css"
import jQuery from "jquery";
import UserCompany from "../../services/UserCompany";


let userId;
let roleId;
let loginStatus = false;
let CandidateActiveColor;
let JobsActiveColor;


const Header = (props) => {
  const [userStateData, setUserStateData] = useState({});
  const [candidateData, setCandidateData] = useState({});
  const [userData, setUserData] = useState({});

  const [employerTeam, setEmployerTeam] = useState([]);
  const [comapanyData, setCompanyData] = useState({})
  const [allUser, setAllUser] = useState([]);
  const [mobileLoginView, setMobileLoginView] = useState(false)
  const [candidateActive, setCandidateActive] = useState("Y")
  const [JobsActive,setJobsActive]= useState("N")

  userId = JSON.parse(window.localStorage.getItem("userId"));
  roleId = JSON.parse(window.localStorage.getItem("roleId"));
  loginStatus = JSON.parse(window.localStorage.getItem("loginStatus"));



  const activeMenu = props.activelist;
 


  const HeaderMenu = [{ link: 'admin-all-masters', menuName: 'All Masters' },
  { link: 'admin-post-job', menuName: 'Post A Job' },
  { link: 'admin-alert-job', menuName: 'Alert A Job' },
  { link: 'admin-manage-job', menuName: 'Manage jobs' },
  { link: 'admin-manage-employer', menuName: 'Manage Employers' },
  { link: 'admin-manage-candidate', menuName: 'Manage Candidates' },
  { link: 'admin-add-blog', menuName: 'Post A Blog' }

  ];
  const HeadermenuList = HeaderMenu.map((item, ind) => {
    return (
      <li key={ind} >
        <Link style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}

          to={"/" + item.link}
        >

          <span >{item.menuName}</span>
        </Link>
      </li>

    );
  });
  const EmployerHeaderMenu = [{ link: 'company-profile', menuName: 'Company Profile' },
  { link: 'job-post', menuName: 'Post A Job' },
  { link: 'manage-job', menuName: 'Manage jobs' },
  { link: 'browse-candidates', menuName: 'Browse Candidates' },

  ]




  const employerHeaderMenuList = EmployerHeaderMenu.map((item, ind) => {
    return (
      <li key={ind}><Link to={"/" + item.link} style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}>

        <span>{item.menuName}</span></Link>
      </li>
    );
  });



  const EmployerTeamMenu = [{ link: 'employer-team', menuName: "Employer Team" }]



  const employerTeamMenuList = EmployerTeamMenu.map((item, ind) => {
    return (
      <li key={ind}><Link to={"/" + item.link} style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}>

        <span>{item.menuName}</span></Link>
      </li>
    );
  });


  const candidateMenu = [{ link: 'jobs-profile', menuName: 'My Profile' },
  { link: 'jobs-my-resume', menuName: 'My Resume' },
  { link: 'jobs-saved-jobs', menuName: 'Saved Jobs' },
  { link: 'jobs-applied-jobs', menuName: 'Applied Jobs' },
  { link: 'jobs-alerts', menuName: 'Job Alerts' },
  { link: 'jobs-change-password', menuName: 'Change Password' },

  ];


  const candidateMenuList = candidateMenu.map((item, ind) => {
    return (
      <li key={ind}><Link to={"/" + item.link} onClick={()=>CandidateColorChangeHandler()} style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}>

        <span>{item.menuName}</span></Link>
      </li>
    );
  });


  const JobsMenu = [{ link: 'category-all-jobs', menuName: 'All Jobs' },
  { link: 'category-company-jobs', menuName: 'Company Jobs' },
  { link: 'category-Jobs', menuName: 'Category Jobs' },
  { link: 'category-location-jobs', menuName: 'Location Jobs' },
  { link: 'category-designation-jobs', menuName: 'Designation Jobs' },
  { link: 'category-skill-jobs', menuName: 'Skill Jobs' },

  ];

  const JobsMenuList = JobsMenu.map((item, ind) => {
    return (
      <li key={ind}><Link to={"/" + item.link} onClick={()=>JobsColorChangeHandler()} style={{ position: "relative", color: item.link === activeMenu ? "#2e55fa" : "", backgroundColor: item.link === activeMenu ? "#e1e7ff" : "" }}>

        <span>{item.menuName}</span></Link>
      </li>
    );
  });





  const ButtonActiveHandler = (active) => {
    setMobileLoginView(active);
  }
  const userCompanyDetails = async () => {
    try {
      const res = await Users.getUserById(userId);
      if (res.status === 200) {
        setCompanyData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }



  const getCompanyData = async () => {
    try {
      if (roleId === 2 || roleId === 4) {
        const res = await UserCompany.getUserCompanyById(userId);
        if (res.status === 200) {
          setUserStateData(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployerTeam = async () => {
    try {
      const res = await EmployerTeam.getEmployerTeam()
      setEmployerTeam(res.data);

    } catch (error) {
      console.log(error);
    }
  }

  const getAllUser = async () => {
    try {
      const res = await Users.getUser();
      setAllUser(res.data);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    userCompanyDetails();
    getEmployerTeam();
    getAllUser();
  }, [])


  const getUserData = async () => {
    try {
      const res = await Users.getUserById(userId)
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log(error)

    }
  }

  const getCandidate = async () => {
    try {
      const res = await CandidateProfile.getCandidateById(userId);
      setCandidateData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyData();
    getUserData();
    getCandidate();
  }, [`${userId}`]);

  let showStatus;
  employerTeam.find((val) => {
    if (val.email === comapanyData.email) {
      allUser.find((itm) => {
        if (itm.id === val.employerId) {
          showStatus = itm.status;
        }
      })
    }
  })

// Theme layout useEffect
  useEffect(() => {
    if (window.localStorage.getItem("version") === "dark") {
      jQuery('.layout-btn').addClass('active');
      jQuery('.layout-btn input').attr('checked', 'checked');
      jQuery('body').addClass('dark-theme');
    }
    else {
      jQuery('.layout-btn').removeClass('active');
      jQuery('body').removeClass('dark-theme');
      jQuery('.layout-btn input').removeAttr('checked', 'checked');
      window.localStorage.setItem("version", "light");
    }

    /* Theme Layout button */
    if (jQuery('.layout-btn').length) {
      jQuery('.layout-btn').on('click', function () {
        jQuery(this).toggleClass('active');

        if (jQuery(this).hasClass('active')) {
          jQuery('body').addClass('dark-theme');
          // setCookie('version','dark');
          window.localStorage.setItem("version", "dark")
        } else {
          jQuery('body').removeClass('dark-theme');
          // setCookie('version','light');
          window.localStorage.setItem("version", "light")
        }
      });
    }


  }, [])

  // Mobile view Menu button
  useEffect(() => {
    var Navicon = document.querySelector('.navicon');
    var sidebarmenu = document.querySelector('.myNavbar ');

    function toggleFunc() {
      sidebarmenu.classList.toggle('show');
      Navicon.classList.toggle('open');
    }
    Navicon.addEventListener('click', toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () { checkLi(this) });
    }

    function checkLi(current) {
      current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
        (current.parentElement !== el) ? el.classList.remove('open') : ''
      );
      setTimeout(() => {
        current.parentElement.classList.toggle('open');
      }, 100);
    }
  }, []);

useEffect(()=>{
 
  setJobsActive(window.localStorage.getItem("JobsActiveColor"))
   setCandidateActive(window.localStorage.getItem("CandidateActiveColor"))
   
   setTimeout(() => {
    
   }, 100);
},[])

  const CandidateColorChangeHandler = () => {


    window.localStorage.setItem("CandidateActiveColor","Y")
    window.localStorage.setItem("JobsActiveColor","N")
  }
  const JobsColorChangeHandler=()=>{

    window.localStorage.setItem("CandidateActiveColor","N")
    window.localStorage.setItem("JobsActiveColor","Y")
  }


 
  return (
    <>
      <div>
        <header className="site-header mo-left header fullwidth">

          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix">
              <div className="container clearfix">

                <div className="logo-header mostion">
                  <a href="/" className="logo"><img src={require('../../assets/logo/jobyardlogo.png')} alt="" /></a>
                  <a href="/" className="logo-white"><img src={require('../../assets/logo/jobyardlogo2.png')} alt="" /></a>
                </div>

                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" onClick={() => { ButtonActiveHandler(true) }} data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div className="extra-nav">
                  <div className="extra-cell">
                    <a href="#" className="layout-btn"><input type="checkbox" /><span className="mode-label"></span></a>
                    {loginStatus === false || loginStatus === null ?
                      <Link to="/register" className="site-button"><i className="fa fa-user"></i> Sign Up</Link>
                      : null}
                    {loginStatus === false || loginStatus === null ?
                      <Link to="/login" title="READ MORE" rel="bookmark" data-toggle="modal" data-target="#car-details" className="site-button"><i className="fa fa-lock"></i> login </Link>
                      : null}
                  </div>
                </div>


                <div className="header-nav navbar-collapse myNavbar collapse justify-content-start" id="navbarNavDropdown">

                  <div className="logo-header">
                    <a href="/" className="logo"><img src={require('../../assets/logo/jobyardlogo.png')} alt="" /></a>
                    <a href="/" className="logo-white"><img src={require('../../assets/logo/jobyardlogo2.png')} alt="" /></a>

                  </div>

                  <ul className="nav navbar-nav" >

                    {/* <li >
									<Link activeClassName='is-active' to={"/"}>Home </Link>			
							  	</li> */}
                    {roleId === 1 ? (
                      <>

                        <li  className={candidateActive === "Y" ? "active" : ""}>
                          <Link to="#">
                            For Candidates <i className="fa fa-chevron-down"></i>
                          </Link>
                          <ul className="sub-menu">

                            {candidateMenuList}
                            {/* <li>
                              <a href={"/jobs-profile"} className="dez-page">
                                My Profile
                              </a>
                            </li>
                            <li>
                              <a href={"/jobs-my-resume"} className="dez-page">
                                My Resume
                              </a>
                            </li>
                            <li>
                              <a href={"/jobs-saved-jobs"} className="dez-page">
                                Saved Job
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/jobs-applied-jobs"}
                                className="dez-page">
                                Applied Job
                              </a>
                            </li>
                            <li>
                              <a href={"/jobs-alerts"} className="dez-page">
                                Jobs Alert
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/jobs-change-password"}
                                className="dez-page"
                              >
                                Change Password
                              </a>
                            </li> */}
                          </ul>
                        </li>
                        <li className={JobsActive === "Y" ?"active" :""}>
                          <Link to="#" >
                            Jobs <i className="fa fa-chevron-down"></i>
                          </Link>
                          <ul className="sub-menu">

                            {JobsMenuList}


                            {/* <li>
                              <a
                                href={"/category-all-jobs"}
                                className="dez-page">
                                All Jobs
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/category-company-jobs"}
                                className="dez-page">
                                Company Jobs
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/category-designation-jobs"}
                                className="dez-page">
                                Designations Jobs
                              </a>
                            </li>
                            <li>
                              <a href={"/category-Jobs"} className="dez-page">
                                Category Jobs
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/category-location-jobs"}
                                className="dez-page">
                                Location Jobs
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/category-skill-jobs"}
                                className="dez-page">
                                Skill Jobs
                              </a>
                            </li> */}
                          </ul>
                        </li>
                      </>
                    ) :



                      roleId === 2 || roleId === 4 ?
                        ((userStateData.id && userData.status === 'Active' || userData.status === 'On Verification') || ((userData.status === 'Active' || userData.status === 'On Verification') && (showStatus === 'Active' || showStatus === 'On Verification')) ?
                          <li className="active">
                            <Link to={"#"}>For Employers <i className="fa fa-chevron-down"></i></Link>
                            <ul className="sub-menu">

                              {employerHeaderMenuList}
                              {/* <li><Link to={"/company-profile"} className="dez-page">Company Profile </Link></li>
                              <li><Link to={"/job-post"} className="dez-page">Post A Jobs </Link></li>
                              <li><Link to={"/manage-job"} className="dez-page">Manage jobs </Link></li>
                              <li><Link to={"/browse-candidates"} className="dez-page">Browse Candidates</Link></li> */}
                              {
                                userStateData.id && (userData.status === 'Active' || userData.status === 'On Verification') && roleId == 2 ?
                                  employerTeamMenuList
                                  : null
                              }
                            </ul>
                          </li> : <li className="active">
                            <Link to={"#"}>For Employers <i className="fa fa-chevron-down"></i></Link>
                            <ul className="sub-menu">
                              <li><Link to={"/company-profile"} style={{color:"#2e55fa",backgroundColor:"#e1e7ff"}} className="dez-page">Company Profile </Link></li>

                            </ul>
                          </li>)

                        : roleId === 3 ? (
                          <li className="active">
                            <Link className="is-active" to={"#"}>
                              For Admin <i className="fa fa-chevron-down"></i>
                            </Link>
                            <ul className="sub-menu">
                              {HeadermenuList}

                              {/* <li>
                                <Link to={"#"} className="dez-page">
                                  Masters <span className="new-page">New</span>
                                </Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link
                                      to={"/admin-all-masters"}
                                      className="dez-page">
                                      All Masters{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-year-master"}
                                      className="dez-page">
                                      Year Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-month-master"}
                                      className="dez-page">
                                      Month Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-date-master"}
                                      className="dez-page">
                                      Date Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-location-master"}
                                      className="dez-page">
                                      Location Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-category-master"}
                                      className="dez-page">
                                      Category Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-region-master"}
                                      className="dez-page">
                                      Region Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-skill-master"}
                                      className="dez-page">
                                      Skill Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-jobtype-master"}
                                      className="dez-page">
                                      Job Type Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/admin-qualification-master"}
                                      className="dez-page">
                                      Qualification Master{""}
                                      <span className="new-page">New</span>
                                    </Link>
                                  </li>

                                </ul>
                              </li>
                              <li>
                                <Link to={"/admin-post-job"} className="dez-page">
                                  Post A Job <span className="new-page">New</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={"/admin-manage-job"} className="dez-page">
                                  Manage Jobs <span className="new-page">New</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={"/admin-alert-job"} className="dez-page">
                                  FreeJobAlert <span className="new-page">New</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/admin-manage-employer"}
                                  className="dez-page">
                                  Manage Employers{""}
                                  <span className="new-page">New</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/admin-manage-candidate"}
                                  className="dez-page">
                                  Manage Candidates{""}
                                </Link>
                              </li>*/}
                            </ul>
                          </li>
                        ) :
                          //   roleId === 4 ?<li>
                          //   <Link to={"#"}>
                          //     For Employers <i className="fa fa-chevron-down"></i>
                          //   </Link>
                          //   <ul className="sub-menu">
                          //     <li>
                          //       <Link to={"/company-profile"} className="dez-page">
                          //         Company Profile
                          //       </Link>
                          //     </li>

                          //     <li>
                          //       <Link to={"/job-post"} className="dez-page">
                          //         Post A Jobs
                          //       </Link>
                          //     </li>
                          //     <li>
                          //       <Link to={"/manage-job"} className="dez-page">
                          //         Manage jobs
                          //       </Link>
                          //     </li>

                          //     <li>
                          //       <Link to={"/browse-candidates"} className="dez-page">
                          //         Browse Candidates
                          //       </Link>
                          //     </li>

                          //   </ul>
                          // </li>

                          //  : 
                          (
                            <>
                              <li className="active">
                                <Link to="#">
                                  Jobs <i className="fa fa-chevron-down"></i>
                                </Link>
                                <ul className="sub-menu">
                                  {JobsMenuList}
                                  {/* <li>
                                    <Link
                                      to={"/category-all-jobs"}
                                      className="dez-page">
                                      All Jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/category-company-jobs"}
                                      className="dez-page">
                                      Company Jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/category-designation-jobs"}
                                      className="dez-page">
                                      Designation Jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={"/category-Jobs"} className="dez-page">
                                      Category Jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/category-location-jobs"}
                                      className="dez-page">
                                      Location Jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"/category-skill-jobs"}
                                      className="dez-page">
                                      Skill Jobs
                                    </Link>
                                  </li> */}
                                </ul>
                              </li>



                              {/* <li>
                                <Link to={"#"}>
                                  Pages <i className="fa fa-chevron-down"></i>
                                </Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link to={"/about-us"} className="dez-page">
                                      About Us
                                    </Link>
                                  </li>

                                  <li>
                                    <Link to={"#"} className="dez-page">
                                      Companies
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      Free Job Alerts
                                    </Link>
                                  </li>

                                </ul>
                              </li> */}
                              {/* <li>
                                <Link to={"#"}>
                                  Blog <i className="fa fa-chevron-down"></i>
                                </Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      classNameic
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      classNameic Sidebar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      Detailed Grid
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      Detailed Grid Sidebar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={"#"}
                                      className="dez-page"
                                    >
                                      Left Image Sidebar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={"#"} className="dez-page">
                                      Blog Details
                                    </Link>
                                  </li>
                                </ul>
                              </li> */}
                            </>
                          )}
                  </ul>
                  {mobileLoginView === true ?
                    <span >
                      {loginStatus === false || loginStatus === null ?
                        <Link to="/register" className="site-button mr-3 ml-4 " style={{ marginTop: "100%" }} ><i className="fa fa-user"></i> Sign Up</Link>
                        : null}
                      {loginStatus === false || loginStatus === null ?
                        <Link to="/login" title="READ MORE" rel="bookmark" data-toggle="modal" data-target="#car-details" className="site-button " style={{ marginTop: "100%" }}><i className="fa fa-lock"></i> login </Link>
                        : null}
                    </span> : null}
                </div>
              </div>
            </div>

          </div>
        </header>
      </div>
    </>
  )
};
export default Header;