import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import JobPostService from '../../services/JobPost';
import Users from '../../services/Users';
import SavedJobServices from '../../services/SavedJob';
import { ThreeCircles ,Oval} from 'react-loader-spinner';



const BrowseJobGrid = () => {
  
	const [joblists , setJoblists]= useState([]);
	const [userData , setUserData] = useState({});
	const [loader , setLoader] = useState(false);
  const [loaderSavedJob , setLoaderSavedJob] = useState(false)
	const [data,setData] =useState([]);
  const [loaderId , setLoaderId] = useState(0);

	const userId = JSON.parse(localStorage.getItem('userId'));
	const getUserData=async()=>{
		try {
			const res=await Users.getUserById(userId);
			if(res.status===200)
			{
				setUserData(res.data)
        
			}
		} catch (error) {
			console.log(error)
			
		}
	}
	
	const getSavedJob = async()=>{
		try {
			const res = await SavedJobServices.getSavedJob(userId);
	
			setData(res.data)
      
		} catch (error) {
			console.log(error);
		}
	}
useEffect(()=>{
  setLoader(true);
	getUserData();
	getSavedJob();
  setTimeout(() => {
    setLoader(false);
  }, 1500);
},[`${userId}`])
	
const getJobs=async()=>{
   
	try{

	  const res = await JobPostService.getJobPost();
	 
	  if(res.status === 200){
		  setJoblists(res.data);
      
	  }

	}catch(err){
		return err;
	}

}

useEffect(()=>{
  getJobs();
},[])


let selected_alljobs;
let selected_company;
let selected_designation;
let selected_tags;
let category;
let location;

	category = window.localStorage.getItem('categories');
	location = window.localStorage.getItem('city');
 	selected_alljobs = window.localStorage.getItem('all');
  selected_company = window.localStorage.getItem('companyData');
  selected_tags = window.localStorage.getItem('tags');
  selected_designation = window.localStorage.getItem('designation');
 
 
    


  
  
  const saveHandler=async(jobs)=>{
    setLoaderId(jobs.id);
    setLoaderSavedJob(true);
    try{
        let job = data.filter((val)=>{if(val.job_id === jobs.id){return val.id}})
    if(job.length > 0 ){
        
        var id = job[0].id;

    const res =	await SavedJobServices.deleteSavedJob(id);
    if(res.status === 200){
     
      getUserData();
      getSavedJob();
      
    }

    }else{
        

        const payload={};

        payload.user_id=userId;
        payload.job_id=parseInt(jobs.id);
        payload.job_title=jobs.job_title;
        payload.companay=jobs.job_company;
        payload.created_on=jobs.created_on;
         
        const res=await SavedJobServices.postSavedJob(payload);  

        if(res.status=== 200){
           
            getUserData();
            getSavedJob();
          
            
        }

    }

    }catch(err){
    
        return err;
    }

    setTimeout(() => {
      setLoaderSavedJob(false);
    }, 1500);
    
}

function formatNumber(number) {
	if (number >= 10000000) {
	  return (number / 10000000).toFixed(1) + 'Cr';
	} else if (number >= 100000) {
	  return (number / 100000).toFixed(1) + 'L';
	} 
	else if (number >= 1000) {
	  return (number / 1000).toFixed(1) + 'K';
	}else {
	  return number;
	}
  }

	return (
    <>
    	<Header/>

	{
				loader ?
				<div style={{width:'100%',height:500,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <ThreeCircles
              height="90"
              width="90"
              color="#2e55fa"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
				</div>:
        <div>


			<div className="page-content bg-white">
				<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(images/banner/bnr1.jpg)" }}>
					{/* <PageTitle motherName="Home" activeName="Jobs On Your Choice" /> */}
				</div>
				{
					joblists.filter((val)=>{
						if(val.status === 'Active' &&(!selected_company||(selected_company && selected_company === val.job_company)) && (!selected_designation||(selected_designation && selected_designation=== val.job_title)) && ((!category|| category && category===val.job_category ) ) && (!selected_tags || (selected_tags && selected_tags === val.job_tags)) && (!location ||( location &&( location=== val.job_area ))) && (!selected_alljobs || (selected_alljobs && (selected_alljobs === val.job_title || selected_alljobs === val.job_tags || selected_alljobs === val.job_company || selected_alljobs === val.job_area || selected_alljobs === val.job_category)))){
							return val;
						}
					}).length === 0 ?

					<div style={{width:'100%',height:200,display:'flex',justifyContent:'center',alignItems:'center',color:'red'}}>
											  	No Job Found
												</div>
				:
				<div className="content-block">	
					<div className="section-full bg-white browse-job p-b50">
						<div className="container">
							<div className="job-bx-title clearfix">
								<h5 className="font-weight-700 pull-left text-uppercase">{joblists.filter((val)=>{
									if(val.status === 'Active'  &&(!selected_company||(selected_company && selected_company === val.job_company)) && (!selected_designation||(selected_designation && selected_designation=== val.job_title)) && ((!category|| category && category===val.job_category ) ) && (!selected_tags || (selected_tags && selected_tags === val.job_tags)) && (!location ||( location &&( location=== val.job_area ))) && (!selected_alljobs || (selected_alljobs && (selected_alljobs === val.job_title || selected_alljobs === val.job_tags || selected_alljobs === val.job_company || selected_alljobs === val.job_area  || selected_alljobs === val.job_category)))){
										return val;
									}
								}).length === 0 ? null : joblists.filter((val)=>{
									if(val.status === 'Active'  &&(!selected_company||(selected_company && selected_company === val.job_company)) && (!selected_designation||(selected_designation && selected_designation=== val.job_title)) && ((!category|| category && category===val.job_category ) ) && (!selected_tags || (selected_tags && selected_tags === val.job_tags)) && (!location ||( location &&( location=== val.job_area ))) && (!selected_alljobs || (selected_alljobs && (selected_alljobs === val.job_title || selected_alljobs === val.job_tags || selected_alljobs === val.job_company || selected_alljobs === val.job_area  || selected_alljobs === val.job_category)))){
										return val;
									}
								}).length +"  "+'Jobs Found'} </h5>
								
								
							</div>
							<ul className="post-job-bx browse-job-grid row">
								{joblists.filter((val)=>{
									if(val.status === 'Active' &&(!selected_company||(selected_company && selected_company === val.job_company)) && (!selected_designation||(selected_designation && selected_designation=== val.job_title)) && ((!category|| category && category===val.job_category ) ) && (!selected_tags || (selected_tags && selected_tags === val.job_tags)) && (!location ||( location &&( location=== val.job_area ))) && (!selected_alljobs || (selected_alljobs && (selected_alljobs === val.job_title || selected_alljobs === val.job_tags || selected_alljobs === val.job_company || selected_alljobs === val.job_area  || selected_alljobs === val.job_category)))){
										return val;
									}
								}).map((item,index)=>(	
									<li className="col-lg-4 col-md-6" key={index}>
										<div className="post-bx">
											<div className="d-flex m-b30">
												<div className="job-post-info">
													<h5 style={{ wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}><Link to={`/job-details/${item.id}`} >{item.job_title}</Link></h5>
                          {/* <h5><Link to={`/job-details`  }>{item.job_title}</Link></h5> */}
													<ul>
														<li><i className="fa fa-map-marker"></i> {item.location}, {item.region}</li>
														<li><i className="fa fa-clock-o"></i> Published on {item.created_on}</li>
														<li><i className="fa fa-bookmark-o"></i> {item.experience} Year Experience</li>

													</ul>
												</div>
											</div>
											<div className="d-flex">
												<div className="job-time mr-auto">
													<Link to ={"#"}><span>{item.job_type}</span></Link>
												</div>
												<div className="salary-bx">
													<span>₹{formatNumber(item.minimum_salary)} - ₹{formatNumber(item.maximum_salary)}</span>
												</div>
											</div>
											{
                         loaderSavedJob && loaderId === item.id  ?
                         <label className="like-btn">
                            <Oval
                            height={20}
                            width={20}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}                      
                           /> 
                           </label>:
												 <label className="like-btn">
													 <input type="checkbox"  />  
                           <div>
                           {userData.roleId === 1 ? (
                                       data.find((val)=>{if(val.job_id === item.id){return val;} }) ? (
                                          <Link>
                                            <i
                                              style={{ color: "red" }}
                                              className="fa fa-heart"
                                              aria-hidden="true"
                                              onClick={() => saveHandler(item)}
                                            ></i>
                                          </Link>
                                        ) : (
                                          <Link>
                                            <i
                                              style={{ color: "blue" }}
                                              className="fa fa-heart-o"
                                              aria-hidden="true"
                                              onClick={() => saveHandler(item)}
                                            ></i>
                                          </Link>
                                        )
                                      ) : null}
                            </div>                                        
											</label>}
											
											
										</div>
									</li>
								))}	
							</ul>
						
						</div>
					</div>
				</div>	}
			</div>
		<Footer/>
    </div>}

    </>
       
    )
}

export default BrowseJobGrid