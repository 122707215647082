import axios from 'axios';

const Candidate_Online_Profile = (payload) => axios.post('/candidate-online-profile/add', payload);

const Candidate_Online_Profile_By_Id=(candidate_id)=>axios.get(`/candidate-online-profile/`+candidate_id);

const Candidate_Online_Profile_Update=(candidate_id,payload)=>axios.put(`/candidate-online-profile/`+candidate_id,payload);


export default { Candidate_Online_Profile,
                 Candidate_Online_Profile_By_Id,
                 Candidate_Online_Profile_Update
                
                };