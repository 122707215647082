import React, { useState, useEffect } from 'react'
import Header1 from '../../../components/Layout/Header';
import AdminSideBar2 from './AdminSideBar2';
import Footer from '../../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../../components/AlertMessage';
import SkillMasterService from '../../../services/Master/SkillMaster';






const AdminSkillMaster = () => {

    const [addSkill, setAddSkill] = useState(false);
    const [skill, setSkill] = useState([]);
    const [id, setId] = useState();
    const [skill_name, setSkill_name] = useState();
    const [editSkill, setEditSkill] = useState(false);
    const [deleteSkill, setDeleteSkill] = useState(false);
    const [skillModal, setSkillModal] = useState();
    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });



    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };


    const getAllSkill = async () => {
        try {
            const res = await SkillMasterService.getSkills();
            if (res.status === 200) {
                setSkill(res.data);
            }
        } catch (err) {
            return err;
        }
    }

    useEffect(() => {
        getAllSkill();
    }, []);

    const EditHandler = (Id) => {
        skill.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setSkill_name(val.skillName);
                setEditSkill(true);

            }
        })
    }

    const SkillDeleteHandler = (Id) => {
        skill.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteSkill(true);

            }
        })
    }

    const skillHandler = async () => {
        try {
            const payload = {};
            payload.skillName = skillModal;
            if (payload.skillName) {
                const res = await SkillMasterService.addSkills(payload);
                if (res.status === 200) {
                    setSkillModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }



        } catch (err) {
            return (err);
        }
    }

    const EditSkillMethod = async () => {
        try {
            const payload = {};
            payload.skillName = skill_name;
            var Id = parseInt(id);
            if (payload.skillName) {
                const res = await SkillMasterService.UpdateSkills(Id, payload);
                if (res.status === 200) {
                    setSkillModal("");
                    setAlertState({ open: true, type: 'success', message: 'Data saved successfully.' });
                    window.location.reload();
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }

    }

    const deleteSkillMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await SkillMasterService.DeleteSkills(Id);
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            return err;
        }

    }



    return (
        <>
            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
         
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">
                {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Skill Master</li>
								</ul>
							</div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">

                                <AdminSideBar2 activelist="admin-skill-master"/>
                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12 ">
                                <div className=" job-bx bg-white m-b30">
                                    <div className='d-flex'	>
                                        <h5 className="m-b15">Skill Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddSkill(true)}>
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>

                                    <table className=' table-job-bx cv-manager company-manage-job '>
                                        <thead>
                                            <tr className="table-primary">
                                                <th >Sl No.</th>
                                                <th >Skills</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                skill.map((val, ind) => {
                                                    return (
                                                        <tr className='table-hover' key={ind} >
                                                            <td >{ind + 1}</td>
                                                            <td >{val.skillName}</td>
                                                            <td >
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => SkillDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            }

                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addSkill} onHide={setAddSkill} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Add Skill </h5>
                            <button type="button" className="close" onClick={() => { setAddSkill(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Skill</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Skills."
                                                value={skillModal}
                                                onChange={(e) => setSkillModal(e.target.value)} style={{color:'black'}} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setAddSkill(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success" onClick={skillHandler}  >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/*================================================== Edit Modal ==================================================== */}
            <Modal show={editSkill} onHide={setEditSkill} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Skill </h5>
                            <button type="button" className="close" onClick={() => { setEditSkill(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Skill</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Skill."
                                                value={skill_name}
                                                onChange={(e) => setSkill_name(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setEditSkill(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning" onClick={EditSkillMethod}  >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ============================================== Delete Modal ================================================= */}

            <Modal
                show={deleteSkill}
                onHide={setDeleteSkill}
                backdrop=""
                size='md'
                className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

            >
                <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content "  >
                        <div class="modal-header">
                            <div class="logo-img">
                                <img alt="" src="images/logo/icon2.png" />
                            </div>
                            <h5 class="modal-title">Delete Skill</h5>
                            <button type="button" className="close" onClick={() => setDeleteSkill(false)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" >

                            <p > Do you want to delete this Skill </p>



                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type='button' className='btn btn-info' onClick={() => setDeleteSkill(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteSkillMethod}>Delete</button>

                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            </Modal >
            <Footer />
        </>
    )
}

export default AdminSkillMaster;