import axios from 'axios';

const registerUser = (payload) => axios.post('/users/register', payload);

const loginUser = (payload) => axios.post('/users/login', payload);

const logoutUser = (payload) => axios.post('/users/logout', payload);

const getUserById =(id) => axios.get(`/users/`+id);

const getUser =() => axios.get(`/users`);

const updateUser=(id,payload) => axios.put(`/users/`+id,payload);

const deleteUser =(id)=> axios.delete(`/users/`+id);

const loginUserByGoogle = (payload) => axios.post('/users/loginByGoogle', payload);

export default { registerUser, loginUser,getUserById,updateUser,getUser,logoutUser,deleteUser,loginUserByGoogle };