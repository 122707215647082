import axios from 'axios';

const postAppliedJob = (payload) => axios.post('/appliedjobs', payload);

const getAppliedJob = () => axios.get('/appliedjobs');

const getAppliedJobById = (id) => axios.get('/appliedjobs/'+id);

const DeleteAppliedJob = (id) => axios.delete(`/appliedjobs/delete/` + id);


export default { postAppliedJob, getAppliedJob, getAppliedJobById, DeleteAppliedJob };