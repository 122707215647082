import React ,{useState,useEffect}from 'react';
import { Link ,Navigate, useNavigate} from 'react-router-dom';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import JobPostService from '../../services/JobPost';
import AlertMessage from '../../components/AlertMessage';
import { Col, Row } from 'react-bootstrap';


var selected_location;
const CategoryLocationJobs = () => {
	const [joblists , setJoblist] =useState([]);
	const [radio , setRadio ] =useState('');
	const [alertState, setAlertState] = useState({});
	

	const navigate=useNavigate();
	
	
	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({open:false});
	};

	const getAllJobs=async()=>{
		try{
           const res= await JobPostService.getJobPost();
		   if(res.status===200){
			   setJoblist(res.data);
		   }
		}catch(err){
			return err;
		}
	}

     useEffect(()=>{
      getAllJobs();
	 },[]);



	 var company=joblists.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_area);
     var arr=[...new Set(company)];
	
	const searchHandler=async()=>{
		try{

			if(radio){
				window.localStorage.setItem('city',radio);
				window.localStorage.setItem('categories','');
				window.localStorage.setItem('all','');
				window.localStorage.setItem('companyData','');
				window.localStorage.setItem('tags','');
				window.localStorage.setItem('designation','');
				navigate("/browse-job-grid");
			}else{
				setAlertState({open:true, type:'error', message:'Please select any filed'});
			}
		}catch(err){
			setAlertState({open:true, type:'error', message:'Something went wrong.',err});
		}
	}
	return (
    <>
    
    <Header activelist="category-location-jobs"/>
	<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>
    <div className="page-content">
        {/*  inner page banner */}
        <div className="dez-bnr-inr jobs-category overlay-black-middle" >
            <div className="container">
                <div className="dez-bnr-inr-entry">					
					<div className="category-jobs-info">
						<div className="nav">
							<ul>
								<li><Link to="/category-all-jobs">All Jobs</Link></li>
								<li><Link to="/category-company-jobs">Jobs by Company</Link></li>
								<li ><Link to="/category-Jobs">Jobs by Category</Link></li>
								<li className="active"><Link to="/category-location-jobs">Jobs by Location</Link></li>
								<li><Link to="/category-designation-jobs">Jobs by Designation</Link></li>
								<li><Link to="/category-skill-jobs">Jobs by Skill</Link></li>
							</ul>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>

  {
	arr.map(val=>val).length === 0 ?
	<center><h6 style={{color:'#2e55fa'}}>No Job Found</h6></center>:
   
    <div className="content-block">
			
			<div className="section-full content-inner jobs-category-bx bg-white">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 m-b30">
							<div className="job-bx bg-white">
								<div className="job-bx-title clearfix">
									<h6 className="text-uppercase">Browse Jobs by Locations
									<Link to={"#"} className="float-right font-12 text-primary"></Link></h6>
								</div>

								      <ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none "}}>
								          {arr.map((item,index)=>(	
								        	     <li className="col-lg-4 col-md-4" key={index} >
												 <div className="job-post-inf ">
												   <div className="d-flex m-b10">
													 <div className="job-post-info">
													 <ul>
													   <div  style={{display:"flex",alignItems:"center"}}>
											             <li style={{listStyle:"none"}}>
														  <label htmlFor={'radio4'+item} >
															 <div style={ radio === item ?  {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"} : {width:"300px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}} onClick={()=>{setRadio(item)}}>
																 <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span style={{ color:"#2e55fa"}}>{item}</span></div>																	  
														  </label>
											               </li>
													     </div>
													    </ul>													  
													 </div>
												   </div>
												 </div>
											   </li>
								            ))}	
							          </ul>																						
							      </div>    						
					     	</div>																	
					   </div>
			      </div>
				    <center ><button type="button"  className="site-button btn-block col-lg-2 col-md-2" onClick={searchHandler} style={{borderRadius:"50px"}}>Find Job</button></center>	
			     </div>			
		      </div>}
        <Footer/>
      
    </>
  )
}

export default CategoryLocationJobs;
export {selected_location};