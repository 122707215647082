import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JobPostService from "../services/JobPost";
import { useNavigate } from "react-router-dom";




const IconBox = [
  { icon: "ti-location-pin", title: 'Design, Art & Multimedia'},
  { icon: "ti-wand", title: 'Teacher'},
  { icon: "ti-wallet", title: 'Accounting / Finance' },
  { icon: "ti-cloud-up", title: 'Human Resource' },
  { icon: "ti-bar-chart", title: 'Telecommunications'},
  { icon: "ti-tablet", title: 'Restaurant / Food Service'},
  { icon: "ti-cloud-up", title: 'Information Technology'},
  { icon: "ti-panel", title: 'Health'},
]


function Jobcategories() {
  const [joblist, setJoblist] = useState([]);
  const navigate = useNavigate();

  const getAllJobs = async () => {
    try {
      const res = await JobPostService.getJobPost();
      if (res.status === 200) {
        setJoblist(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  var categoriesNames = [...new Set(joblist.filter((val) => { if (val.status === "Active") { return val; } }).map(val => val.job_category))]

  const icon= []
  
  for (let i = 0; i < categoriesNames.length; i++) {
    var match = false;
    for (let j = 0; j < IconBox.length; j++) {

      if (categoriesNames[i] === IconBox[j].title) {
        match = true;
        icon.push(IconBox[j]);

      }
    }

  }

  var categories = joblist
    .filter((val) => {
      if (val.status === "Active") {
        return val;
      }
    })
    .map((val) => val.job_category);
  categories.sort();
  var current = null;
  var cnt = 0;
  var arr = [];
  var carr = [];
  for (var i = 0; i < categories.length; i++) {
    if (categories[i] != current) {
      if (cnt > 0) {
        arr.push(current);
        carr.push(cnt);
      }
      current = categories[i];
      cnt = 1;
    } else {
      cnt++;
    }
  }
  if (cnt > 0) {
    arr.push(current);
    carr.push(cnt);
  }

  const submitHandler = () => {
    navigate("/category-all-jobs");
  };

  const categoryHandler = (val) => {
    try {
      window.localStorage.setItem("categories", val.title);
      window.localStorage.setItem("city", "");
      window.localStorage.setItem("all", "");
      window.localStorage.setItem("companyData", "");
      window.localStorage.setItem("tags", "");
      window.localStorage.setItem("designation", "");

      navigate("/browse-job-grid");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row sp20">
      {icon.slice(0, 8).map((val, ind) => {
        return (
          <div key={ind}
            className="col-lg-3 col-md-6 col-sm-6"
            onClick={() => categoryHandler(val)}
          >
            <Link>
              <div className="icon-bx-wraper">
                <div className="icon-content">
                  <div className="icon-md text-primary m-b20">

                   
                    <i className={val.icon}></i>
                  </div>
                  <div className="dez-tilte">{val.title}</div>
                  <p className="m-a0">
                    {categories
                      .filter((itm) => {
                        if (itm === val.title) {
                          return itm;
                        }
                      })
                      .map((val, ind) => val).length > 21
                      ? "20+"
                      : categories
                        .filter((itm) => {
                          if (itm === val.title) {
                            return itm;
                          }
                        })
                        .map((val) => val).length}{" "}
                    Open Positions

                  </p>
                  <div className="rotate-icon">
                    <i className="ti-crown"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
      {
        arr.map(val => val).length === 0 ? null :

          <div className="col-lg-12 text-center m-t30">
            <button className="site-button radius-xl" onClick={submitHandler}>
              All Categories
            </button>
          </div>}
    </div>
  );
}

export default Jobcategories;
