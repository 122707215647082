import React,{useState,useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import AlertMessage from "../AlertMessage";
import CategoryMaster from '../../services/Master/CategoryMaster';
import JobPostService from '../../services/JobPost';
import CountUp from "react-countup";
var searchBucket = {};

const IndexBanner =({job})=>{
    const [alertState, setAlertState] = useState({});
    const [titleInput , setTitleInput] = useState('');
	const [locationInput , setLocationInput] = useState('');
	const [sectorInput , setSectorInput] = useState('');
    const [sector , setSector] = useState([]);
	const [jobList , setJobList] = useState([]);
	const [show , setShow] = useState(false);
    const [showLocation , setShowLocation] = useState(false);
	const [windowSize, setWindowSize] = useState();

    const navigate = useNavigate();

    useEffect(() => {
			const handleResize = () => {
					setWindowSize(window.innerWidth);
			}
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
	}, []);

    function alertClose(event, reason) {
            if (reason === 'clickaway') {
                return;
            }
            setAlertState({open:false});
    };
    

    const onChangeTitle=(title)=>{
		setShow(true);
		setShowLocation(false);
		setTitleInput(title);
	}
	const onChangeLocation=(location)=>{
		setShowLocation(true);
		setShow(false);
		setLocationInput(location)
	}
	const onChangeSector=(sector)=>{
		setShowLocation(false);
		setShow(false);
		setSectorInput(sector);
	}

    const submitHandler=()=>{
        if(!titleInput  && !sectorInput && !locationInput){
			setAlertState({open:true, type:'warning', message:'Please fill atleast one field'});         
        }else{
			searchBucket = {
				title:titleInput,
				location:locationInput,
				sector:sectorInput  
	        };
	        navigate('/browse-job-filter-list'); 
                   
                }	        
         };

    const componentDidMount=()=> {
            var i = 0;
            // Placeholder Animation Start
            var inputSelector = document.querySelectorAll('input, textarea');		
            for (i = 0; i < inputSelector.length; i++) {
                inputSelector[i].addEventListener('focus', function(event) {
                    return this.parentElement.parentElement.classList.add("focused");
                });
            }		
            for (i = 0; i < inputSelector.length; i++) {
                inputSelector[i].addEventListener('blur', function(event) {
                    var inputValue = this.value;	
                    if ( inputValue === '' ) {
                        this.parentElement.parentElement.classList.remove('filled');
                        this.parentElement.parentElement.classList.remove('focused');  
                    } else {
                        this.parentElement.parentElement.classList.add('filled');
                    }
                });
            }
            // Placeholder Animation End
        };
        
    const getAllSector = async()=>{
        try {
            const res = await CategoryMaster.getCategory();
            if(res.status === 200){
                setSector(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
        
        
    const getAllJobData = async()=>{
        try {
            const res = await JobPostService.getJobPost();
            if(res.status === 200){
                setJobList(res.data);
            }
        } catch ({error}) {
            console.log(error)
        }
    }
        
    useEffect(()=>{
        componentDidMount();
        getAllSector();
        getAllJobData();
    },[]);
         
    let Array = jobList.filter((val)=>{if(val.status === 'Active' ){return val}});
    let City = [...new Set(Array.map(val=>val.job_area))];
    let Title = [...new Set(Array.map(val=>val.job_title))];

   

    return(
		<>
						<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            <div className="main-slider dez-bnr-inr dez-bnr-inr-md " style={{backgroundImage:"url(images/main-slider/slide2.jpg)"}}>
				<div className="container">
					<div className="dez-bnr-inr-entry align-m">
						<div className="find-job-bx">
							<Link to={"/browse-job-filter-list"} className="site-button button-sm">Find Jobs, Employment & Career Opportunities</Link>
							<h2>Search Between More Than <br/> <span className="text-primary"> <CountUp end={Array.map(val=>val).length}duration={3} />  </span> Open Jobs.</h2>
							<form className="dezPlaceAni" >
								<div className="row">
									<div className="col-lg-4 col-md-6">
										<div className="form-group">
											<label>Job Title</label>
											<div className="input-group">
												<input
												list="title"
												 type="text" 
												 className="form-control" 
												 placeholder="" 
												 value={titleInput}
												 onChange={(e)=>onChangeTitle(e.target.value)}
												 />
												  <datalist id='title'>
												 {
												Title.map((val)=>{
													return(
														<option key={val}>{val}</option>
														)
													})
												}
												
												 </datalist>
												<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-search"></i></span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6">
										<div className="form-group">
											<label>Location</label>
											<div className="input-group">
												<input 
												list="loc"
												type="text" 
												className="form-control"
												placeholder="" 
												value={locationInput}
												onChange={(e)=>{onChangeLocation(e.target.value)}} 
												autoComplete='off'
												 />
												  <datalist id='loc'>
												 {
												City.map((val)=>{
													return(
														<option key={val}>{val}</option>
														)
													})
												}
											
												 </datalist>
												<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-map-marker"></i></span>
												</div>
											</div>
										</div>
									</div>
									

									<div className="col-lg-3 col-md-6">
										<div className="form-group">
											<label>Sector</label>
											<div className="input-group">
												<input 
												list="sector"
												type="text" 
												className="form-control"
												placeholder="" 
												value={sectorInput}
												onChange={(e)=>onChangeSector(e.target.value)} 
												autoComplete='off'
												 />
												 <datalist id='sector'>
												 {
												sector.map((val)=>{
													return(
														<option key={val.id}>{val.category}</option>
														)
													})
												}
											
												 </datalist>
												<div className="input-group-append">
												  <span className="input-group-text"><i className="fa fa-industry"></i></span>
												</div>
											</div>
										</div>
									</div>
									
									<div className="col-lg-2 col-md-6">
										<button type="button"  className="site-button btn-block"  onClick={submitHandler}>Find Job</button>
									</div>
								</div>
							</form>
						</div>
					</div>	
			    </div>
	        </div>			
	</>
)
}

 export default IndexBanner;
 export {searchBucket};