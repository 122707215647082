import axios from 'axios';

const postSkill= (payload) => axios.post('/skillsmapping', payload);

const getSkillById = (id) => axios.get(`/skillsmapping/`+id);

const getAllSkill = () => axios.get(`/skillsmapping`);


const deleteSkill = (id) => axios.delete('/skillsmapping/delete/'+id);


export default { postSkill,getSkillById,deleteSkill,getAllSkill  };