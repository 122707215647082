import React, { useState } from 'react';
import { Link, Text, View, StyleSheet } from '@react-pdf/renderer';
import Users from '../../../services/Users';
import { useEffect } from 'react';
import CandidateProfile from '../../../services/CandidateProfile';


const userName=window.localStorage.getItem("userName")
const userId=window.localStorage.getItem("userId")

const PdfHeader= () => {
  const [userData,setUserData]=useState({})
  const [candidateProfile,setCandidateProfile]=useState({})

  

  const getUserData=async()=>{
    const res=await Users.getUserById(userId)
        if(res.status ===200){
            setUserData(res.data)
        }
    
  }

  const getCandidateProfile=async()=>{
    try {
      const res=await CandidateProfile.getCandidateById(userId)
      if(res.status===200){
        setCandidateProfile(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getUserData();
    getCandidateProfile()
  },[userId])

    return(

  
    <View style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '25',justifyContent:'center'}}>
      <Text style={{fontSize:14,  textTransform: 'uppercase' ,paddingLeft:'5%',color:'#fff'}}>{userName}</Text>
        <Text style={{fontSize:12, alignItems: 'center',justifyContent:"center",paddingLeft:"5%",color:'#fff'}}>{candidateProfile.professional_title}</Text>
        <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width: '20%',
                 
                  backgroundColor: '#000',
                }}
              ></View>
    </View>

)};
export default PdfHeader