import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config/config';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
const React = require("react");
const axios = require("axios");
const { JOBYARD_SERVICE } = config;

axios.defaults.withCredentials = false;
axios.defaults.baseURL = JOBYARD_SERVICE;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

  <Router>
    <App />
  </Router>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
