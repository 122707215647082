import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
        

const DashBoard = () => {
 
 let userName=window.localStorage.getItem("userName")
 
    return (
    <>
    <Header/>
         <div style={{ borderTop: '3px solid #2e55fa' }}>
            <div className='d-flex justify-content-center mt-4 '>
                <Card style={{width:"95%" ,alignItems:"flex-start",marginBottom:"30%",borderRadius:"20px"}}>
                    
                        <span style={{justifyContent :"center"}}>Hii <Link to="#" style={{color:"blue"}}>{userName}</Link> Welcome to Jobyard</span>
                    
                </Card>
            </div>
                {/* <div >
                    <div style={{display:'flex', justifyContent:"space-evenly", width:"80%",marginBottom:"5%"}}>
                    <Card className="mr-3.1"style={{width:"45.3%",height:"100%" }}>
                            <div>
                                <p>All Jobs</p>
                            </div>
                        </Card>
            
            
                        <Card style={{width:"45.3%",height:"100%"}}>
                            <div>
                                <p>All Jobs</p>
                            </div>
                        </Card>
            
                    </div>
                       


                </div> */}
            
            </div>
    <Footer/>

    </>
  )
}

export default DashBoard;