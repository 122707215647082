import axios from 'axios';

const postReportJob = (payload) => axios.post('/report/jobs', payload);

const getReportJob = () => axios.get('/report/jobs');

const getReportJobById = (id) => axios.get('/report/jobs/' + id);

const UpdateReportJob=(id,payload)=>axios.put(`/report/jobs/`+id,payload);


const DeleteReportJob = (id) => axios.delete(`/report/jobs/` + id);


export default { postReportJob, getReportJob, getReportJobById, DeleteReportJob,UpdateReportJob };