import axios from 'axios';

const postReportCandidate= (payload) => axios.post('/report/candidate', payload);

const getReportCandidate= () => axios.get('/report/candidate');

const getReportCandidateById = (id) => axios.get('/report/candidate/' + id);

const UpdateReportCandidate=(id,payload)=>axios.put(`/report/candidate/`+id,payload);


const DeleteReportCandidate= (id) => axios.delete(`/report/candidate/` + id);


export default { postReportCandidate, getReportCandidate, getReportCandidateById, UpdateReportCandidate,DeleteReportCandidate };