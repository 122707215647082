import axios from 'axios';

const AddLocation=(payload)=> axios.post('/masters/location',payload);

const GetLocation=()=>axios.get('/masters/location');

const UpdateLocation=(id,payload)=>axios.put(`/masters/location/`+id,payload);

const DeleteLocation=(id)=>axios.delete(`/masters/location/`+id);

export default {AddLocation,GetLocation,UpdateLocation,DeleteLocation};