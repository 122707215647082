import axios from 'axios';

const Add_Year = (payload) => axios.post('/masters/year', payload);

const Get_Year=()=>axios.get(`/masters/year`);

const UpdateYear=(id,payload)=>axios.put(`/masters/year/`+id,payload);

const DeleteYear=(id)=>axios.delete(`/masters/year/`+id);

export default {
    
    Add_Year,
    Get_Year,
    UpdateYear,
    DeleteYear


};