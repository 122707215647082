import axios from 'axios';

const browseCandidate = () => axios.get('/browse-candidate');

const browseCandidateById = (id) => axios.get('/browse-candidate/' + id);

const postBrowseCandidate = (payload) => axios.post('/browse-candidate', payload);

const DeleteBrowseCandidate =(id)=>axios.delete(`/browse-candidate/delete/`+id);

const getBrowseCandidate = () => axios.get('/browse-candidate');

// const getBrowseCandidateById = (id) => axios.get('/browse-candidate/'+id);

export default { browseCandidate, browseCandidateById, postBrowseCandidate, DeleteBrowseCandidate, getBrowseCandidate};