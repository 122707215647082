import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import jobDetailsService from "../../services/JobPost";
import keySkillService from "../../services/candidate_resume/KeySkills";
import ExperienceMaster from "../../services/Master/ExperienceMaster";
import YearMaster from "../../services/Master/YearMaster";
import MonthMaster from "../../services/Master/MonthMaster";
import Users from "../../services/Users";
import CandidateProfile from "../../services/CandidateProfile";
import EmployerProfileServices from "../../services/EmployerProfile";
import EmploymentServices from "../../services/candidate_resume/Employment";
import DesiredCareerProfileService from "../../services/candidate_resume/DesiredCareerProfile";
import SavedJobService from "../../services/SavedJob";
import AppliedJobService from "../../services/AppliedJob";
import CandidateProfileServices from "../../services/CandidateProfile";
import BrowseCandiateService from "../../services/BrowseCandiate";
import { Modal, Form } from "react-bootstrap";
import pincodeDirectory from "india-pincode-lookup";
import CandidateService from "../../services/CandidateProfile";
import AlertMessage from "../../components/AlertMessage";
import Error from "../../constants/ErrorMessage";

const JobDetails = () => {
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [showError, setShowError] = useState(false);
  const [jobData, setJobData] = useState({});
  const [userData, setUserData] = useState({});
  const [appliedJob, setAppliedJob] = useState([]);
  const [saveJob, setSaveJob] = useState([]);
  const [alertState, setAlertState] = useState({ });
  const [candidate, setCandidate] = useState({});
  const [candiateData, setCandidateData] = useState({});
  const [candidateSkill, setCandidateSkill] = useState({});
  const [candiates, setCandidates] = useState({});
  const [experienceData, setExperiencedata] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [keySkills, setKeySkills] = useState([]);
  const [profile, setProfile] = useState({
                                            candidate_id: 0,
                                            name: "",
                                            professional_title: "",
                                            age: 0,
                                            languages: "",
                                            current_salary: 0,
                                            expected_salary: 0,
                                            description: "",
                                            phone: "",
                                            email: "",
                                            country: "India",
                                            state: "Odisha",
                                            city: "",
                                            full_address: "",
                                            postcode: 0,
                                            experience: "",
                                            company: "",
                                            availability_to_join: "",
                                         });

  const [description, setDescription] = useState([]);
  const [requirement, setRequirement] = useState([]);
  const [usersData, setUsersData] = useState({});
  const [candidateData, setCandidatesData] = useState({});

  const roleId = window.localStorage.getItem("roleId");
  
  const userId = JSON.parse(localStorage.getItem("userId"));
  
 
  const navigate = useNavigate();
 
  const {id}=useParams();
 
 
 

 
  const onChangeHandler = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  function alertClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setAlertState({ open: false });
  };
 
  
  const getCompanyData = async () => {
    try {
      if (roleId === 2 || roleId === 4) {
        const res = await EmployerProfileServices.getEmployerById(userId);
        if (res.status === 200) {
          setUsersData(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCandidate = async () => {
    try {
      const res = await CandidateProfile.getCandidateById(userId);
      setCandidatesData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyData();
    getCandidate();
  }, [`${userId}`]);

  const getAllData = async () => {
    try {
      setLoader(true);
      const res = await ExperienceMaster.GetExperience();
      setExperiencedata(res.data);
      const result = await YearMaster.Get_Year();
      setYearData(result.data);
      const response = await MonthMaster.Get_Month();
      setMonthData(response.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  const getUserData = async () => {
    try {
      const res = await Users.getUserById(userId);
    
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      return error;
    }
  };
  const getJobDetails = async () => {
    try {
      const res = await jobDetailsService.getJobPostById(`${id}`);
    
      setJobData(res.data);
    } catch (err) {
      return err;
    }
  };

  const getJobDescription = async () => {
    try {
      const res = await jobDetailsService.getJobDescrioption(`${id}`);
  
      setDescription(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobRequirements = async () => {
    try {
      const res = await jobDetailsService.getJobRequirement(`${id}`);
      setRequirement(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getKeySkills = async () => {
    try {
      const res = await jobDetailsService.getKeySkill(`${id}`);
      setKeySkills(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCandidateDetails = async () => {
    try {
      const res = await CandidateProfileServices.getCandidateById(userId);
      if (res.status === 200) {
        setCandidate(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const getCandidateData = async () => {
    try {
      const res = await EmploymentServices.Candidate_Employment_By_Id(userId);
      if (res.status === 200) {
        setCandidateData(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const getCandidateSkill = async () => {
    try {
      const res = await keySkillService.SkillById(userId);
      if (res.status === 200) {
        setCandidateSkill(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const getDesiredData = async () => {
    try {
      const res = await DesiredCareerProfileService.Candidate_Career_By_Id(userId);
      if (res.status === 200) {
        setCandidates(res.data);
      }
    } catch (err) {
      return err;
    }
  };

  const getAllSavedJobs = async () => {
    try {
      const res = await SavedJobService.getSavedJob(userId);
      setSaveJob(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAppliedJobs = async () => {
    try {
      const res = await AppliedJobService.getAppliedJobById(userId);
     
      setAppliedJob(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobDetails();
    getJobDescription();
    getJobRequirements();
    getKeySkills();
  }, [`${id}`]);

  useEffect(() => {
    getCandidateDetails();
    getCandidateData();
    getCandidateSkill();
    getDesiredData();
    getUserData();
    getAppliedJobs();
    getAllSavedJobs();
  }, [userId]);

  const appliedJobHandler = async (jobs) => {
   
    if (userId > 0) {
      const job = appliedJob.filter((val) => {
        if (val.job_id === jobs.id) {
          return jobs.id;
        
        }
      });
     
      if (job.length > 0) {
       
        setAlertState({
          open: true,
          type: "info",
          message: "This job is already applied",
        });
      } else {
        try {
          const payload = {};

          payload.user_id = userId;
          payload.job_id = jobs.id;

          const res = await AppliedJobService.postAppliedJob(payload);

          if (res.status === 200 || res.status === 304) {
            setAlertState({
              open: true,
              type: "success",
              message: "Job Applied successfully",
            });
            if (saveJob.length > 0) {
              var saveJobId = saveJob.filter((val) => {
                if (val.job_id === jobs.id) {
                  return val;
                }
              });
              if (saveJobId.length > 0) {
                const res = await SavedJobService.deleteSavedJob(
                  `${saveJobId[0].id}`
                );

                if (res.status === 200) {
                  const payload = {};
                  payload.id = jobs.id;
                  payload.job_title = jobs.job_title;
                  payload.job_type = jobs.job_type;
                  payload.minimum_salary = jobs.minimum_salary;
                  payload.maximum_salary = jobs.maximum_salary;
                  payload.region = jobs.region;
                  payload.location = jobs.location;
                  payload.description = jobs.description;
                  payload.last_date = jobs.last_date;
                  payload.user_id = jobs.user_id;
                  payload.job_tags = jobs.job_tags;
                  payload.job_category = jobs.job_category;
                  payload.job_company = jobs.job_company;
                  payload.created_on = jobs.created_on;
                  payload.experience = jobs.experience;
                  payload.job_email = jobs.job_email;
                  payload.status = jobs.status;
                  payload.bike_option = jobs.bike_option;
                  payload.rc_option = jobs.rc_option;
                  payload.dl_option = jobs.dl_option;
                  payload.laptop_option = jobs.laptop_option;
                  payload.smartPhone = jobs.smartPhone;
                  payload.qualification = jobs.qualification;
                  payload.clr = "N";
                  payload.pan_card = jobs.pan_card;
                  payload.adhar_card = jobs.adhar_card;
                  payload.back_passbook = jobs.back_passbook;
                  payload.workplace_type = jobs.workplace_type;
                  payload.worktime_start = jobs.worktime_start;
                  payload.worktime_end = jobs.worktime_end;
                  payload.interview_fee = jobs.interview_fee;
                  payload.gender = jobs.gender;
                  payload.sun = jobs.sun;
                  payload.mon = jobs.mon;
                  payload.tue = jobs.tue;
                  payload.wed = jobs.wed;
                  payload.thu = jobs.thu;
                  payload.fri = jobs.fri;
                  payload.sat = jobs.sat;
                  payload.job_area = jobs.job_area;
                  payload.job_flat_no = jobs.job_flat_no;
                  payload.hr_name = jobs.hr_name;
                  payload.hr_company = jobs.hr_company;
                  payload.job_requirements = jobs.job_requirements;
                  payload.vacancy = jobs.vacancy;
                  payload.whatsapp = jobs.vacancy;

                  const res = await jobDetailsService.updateJobPost(
                    jobs.id,
                    payload
                  );
                }
              }
            }
            setTimeout(() => {
              navigate("/jobs-applied-jobs");
            }, 1000);
          }

          const payload_data = {};

          payload_data.user_id = jobData.user_id;
          payload_data.candidate_id = userId;
          payload_data.job_title = jobData.job_title;
          payload_data.candidate_name = candidate.name;
          payload_data.minimum_salary = candidate.current_salary;
          payload_data.maximum_salary = candidate.expected_salary;
          payload_data.region = candidate.country;
          payload_data.location = candidate.city;
          payload_data.designation = candidate.professional_title;
          payload_data.organization = candiateData.organization;
          payload_data.keySkills = candidate.languages;
          payload_data.age = candidate.age;
          payload_data.mobile = candidate.phone;
          payload_data.email = candidate.email;
          payload_data.available_year = candiates.availability_to_join_year;
          payload_data.available_month = candiates.availability_to_join_month;
          payload_data.experience = candiateData.total_experience;
          payload_data.job_type = jobData.job_type;
          payload_data.job_id = jobData.id;

          await BrowseCandiateService.postBrowseCandidate(payload_data);
        } catch (err) {
          return err;
        }
      }
    } else {
      navigate("/register");
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    setShowError(true);
    try {
      const payload = {};
      payload.name = userData.name;
      payload.professional_title = profile.professional_title;
      payload.age = profile.age;
      payload.languages = profile.languages;
      payload.current_salary = profile.current_salary;
      payload.expected_salary = profile.expected_salary;
      payload.description = profile.description;
      payload.phone = userData.mobile;
      payload.email = userData.email;
      payload.country = "India";
      payload.state = "Odisha";
      payload.city = profile.city;
      payload.full_address = profile.full_address;
      payload.postcode = profile.postcode;
      payload.candidate_id = userId;
      payload.experience = profile.experience;
      payload.company = profile.company;
      payload.availability_to_join = profile.availability_to_join;
      let d1 = new Date();
      let d2 = new Date(profile.availability_to_join);

    
      // if (
      //   !payload.professional_title ||
      //   payload.professional_title.length > 100
      // ) {
      //   if (payload.professional_title.length > 100) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.titleLimit,
      //     });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.title });
      //   }
      // } else if (!payload.languages || payload.languages.length > 100) {
      //   if (payload.languages.length > 100) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.languageLimit,
      //     });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.language });
      //   }
      // } else if (!payload.age || payload.age.length > 2) {
      //   if (payload.age.length > 2) {
      //     setAlertState({ open: true, type: "error", message: Error.validAge });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.age });
      //   }
      // } else 
      if (!payload.experience) {
        setAlertState({ open: true, type: "error", message: Error.experience });
      } 
      // else if (!payload.company || payload.company.length > 250) {
      //   if (payload.company.length > 250) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.companyNameLimit,
      //     });
      //   } else {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.companyName,
      //     });
      //   }
      // } else if (!payload.availability_to_join || +d2 < +d1) {
      //   if (+d2 < +d1) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.invalidDate,
      //     });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.date });
      //   }
      // } else if (!payload.current_salary || payload.current_salary.length > 8) {
      //   if (payload.current_salary.length > 8) {
      //     setAlertState({ open: true, type: "error", message: Error.salLimit });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.salary });
      //   }
      // } else if (
      //   !payload.expected_salary ||
      //   payload.expected_salary.length > 8
      // ) {
      //   if (payload.expected_salary.length > 8) {
      //     setAlertState({ open: true, type: "error", message: Error.salLimit });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.Salary });
      //   }
      // } else if (!payload.description || payload.description.length > 250) {
      //   if (payload.description.length > 250) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.description,
      //     });
      //   } else {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.Description,
      //     });
      //   }
      // } else if (!payload.full_address || payload.full_address.length > 250) {
      //   if (payload.full_address.length > 250) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.addressLimit,
      //     });
      //   } else {
      //     setAlertState({ open: true, type: "error", message: Error.address });
      //   }
      // } 
      else if (
        pincodeDirectory.lookup(profile.postcode).length === 0 ||
        pincodeDirectory.lookup(profile.postcode)[0].stateName !== "ODISHA"
      ) {
        if (pincodeDirectory.lookup(profile.postcode).length === 0) {
          setAlertState({
            open: true,
            type: "warning",
            message: `${profile.postcode} doesn't belong to Odisha`,
          });
        } else if (
          pincodeDirectory.lookup(profile.postcode)[0].stateName !== "ODISHA"
        ) {
          setAlertState({
            open: true,
            type: "warning",
            message: `${profile.postcode} doesn't belong to Odisha`,
          });
        }
      } 
      // else if (!payload.city || payload.city.length > 250) {
      //   if (payload.city.length > 250) {
      //     setAlertState({
      //       open: true,
      //       type: "error",
      //       message: Error.cityLimit,
      //     });
      //   } 
      //   else {
      //     setAlertState({ open: true, type: "error", message: Error.city });
      //   }
      // } 
      else {
        const res = await CandidateService.addCandidate(payload);

       
        if (res.status === 200) {
          setAlertState({
            open: true,
            type: "success",
            message: "Data saved successfully",
          });
          window.location.reload();
          return res;
        } else {
          setAlertState({
            open: true,
            type: "error",
            message: "Something went wrong",
          });
        }
      }
    } catch (err) {
      console.log(err);
      setAlertState({
        open: true,
        type: "error",
        message: "Something went wrong.....",
      });
    }
  };

  let cityList = [];
  cityList = [
    ...new Set(
      pincodeDirectory.lookup(profile.postcode).map((val) => val.taluk)
    ),
  ];


  function formatNumber(number) {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(1) + 'Cr';
    } else if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'L';
    } 
    else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    }else {
      return number;
    }
  }

  let d1 = new Date();
	let d2 = new Date(profile.availability_to_join);

  return (
    <>
    <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>
      <Header />

      <div className="page-content bg-white">
        <div className="dez-bnr-inr overlay-black-middle">
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">Job Detail</h1>

              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Job Detail</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full content-inner-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="sticky-top">
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                          <h4 className="text-black font-weight-700 p-t10 ">
                            Job Details
                          </h4>
                          <ul>
                          <li>
                          <i class="fa fa-building-o" aria-hidden="true"></i>
                              <strong className="font-weight-700 text-black">
                               Company
                              </strong>
                              <span className="text-black-light">
                                {" "}
                                {jobData.job_company}
                              </span>
                            </li>
                            <li>
                              <i className="ti-location-pin"></i>
                              <strong className="font-weight-700 text-black">
                                Address
                              </strong>
                              <span className="text-black-light">
                                {" "}
                                {jobData.location},{jobData.region}{" "}
                              </span>
                            </li>
                            <li>
                              <i className="ti-money"></i>
                              <strong className="font-weight-700 text-black">
                                Salary
                              </strong>{" "}
                              ₹ {formatNumber(jobData.minimum_salary)}-
                              {formatNumber(jobData.maximum_salary)} 
                            </li>
                            <li>
                              <i className="ti-shield"></i>
                              <strong className="font-weight-700 text-black">
                                Experience
                              </strong>
                              {jobData.experience} Experience
                            </li>
                            <li>
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                              <strong className="font-weight-600 text-black">
                                Posted On
                              </strong>
                              <span className="text-black-light">
                                {" "}
                                {jobData.created_on}{" "}
                              </span>
                            </li>
                            <li>
                              <i
                                className="fa fa-hourglass-end"
                                aria-hidden="true"
                              ></i>
                              <strong className="font-weight-600 text-black">
                                Vacancy
                              </strong>
                              <span className="text-black-light">
                                {" "}
                                {jobData.vacancy}{" "}
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-tag" aria-hidden="true"></i>
                              <strong className="font-weight-600 text-black">
                                Status
                              </strong>
                              {jobData.status === "Active" ? (
                                <span style={{ color: "green" }}>
                                  {jobData.status}
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {jobData.status}
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="job-info-box">
                    <h3 className="m-t0 m-b10 font-weight-700 title-head">
                      {jobData.job_title}
                    </h3>
                    <ul className="job-info">
                      <li>
                        <strong>Education:</strong> {jobData.qualification}
                      </li>
                      <li>
                        <strong>Deadline:</strong> {jobData.last_date}
                      </li>
                      <li>
                        <i className="ti-location-pin text-black m-r5"></i>{" "}
                        {jobData.region}
                      </li>
                    </ul>
                    <p className="p-t20"></p>
                    <h5 className="font-weight-600">Job Description</h5>
                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                    <ul
                      className="list-num-count no-round "
                      style={{ overflowWrap: "break-word" }}
                    >
                      {description.map((val) => {
                        return (
                          <React.Fragment key={val.id}>
                            <li key={val.id}>{val.description}</li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                    {/* <h5 className="font-weight-600">How to Apply</h5>
                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages.
                    </p> */}
                    <h5 className="font-weight-600">Job Requirements</h5>
                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                    <ul className="list-num-count no-round">
                      {requirement.map((val) => {
                        return (
                          <React.Fragment key={val.id}>
                            <li>{val.requirement}</li>
                          </React.Fragment>
                        );
                      })}
                    </ul>

                    <h6
                      className="font-weight-400"
                      style={{ fontSize: "16px" }}
                    >
                      Key Skills:
                    </h6>
                    <div
                      className="col-lg-12 col-md-12 mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      {keySkills.map((val, index) => {
                        return (
                          <>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                              key={index}
                            >
                              <div
                                className="ml-1 mr-1 pl-2 pr-2 mt-1 mb-1"
                                style={{
                                  border: "1px solid black",
                                  borderRadius: 15,
                                  backgroundColor: "#edf1f5",
                                }}
                              >
                                {val.skill}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <h6
                      className="font-weight-400"
                      style={{ fontSize: "16px" }}
                    >
                      Candidate must have :
                    </h6>
                    <div
                      className="col-lg-12 col-md-12 mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.bike_option === "Yes" ? "Bike" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.rc_option === "Yes" ? "RC" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.laptop_option === "Yes" ? "Laptop" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.dl_option === "Yes" ? "DL" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.smartPhone === "Yes" ? "Smartphone" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.pan_card === "Yes" ? "PAN Card" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.adhar_card === "Yes" ? "Aadhar Card" : null}
                      </div>
                      <div className="ml-1 mr-1  mt-1 mb-1">
                        {jobData.back_passbook === "Yes"
                          ? "Bank Passbook"
                          : null}
                      </div>
                    </div>
                    {candidate.candidate_id > 0 ? (
                      <button
                        className="site-button"
                        onClick={() => appliedJobHandler(jobData)}
                        style={{ borderRadius: 30, marginBottom: "40px" }}
                      >
                        Apply This Job
                      </button>
                    ) : userData.roleId === 1 ? (
                      <button
                        className="site-button"
                        onClick={() => setCandidateProfile(true)}
                        style={{ borderRadius: 30, marginBottom: "40px" }}
                      >
                        Apply This Job
                      </button>
                    ) : userData &&
                      (userData.roleId === 2 ||
                        userData.roleId === 3) ? null : (
                      <div>
                        <button
                          className="site-button green mb-5"
                          onClick={() => {
                            navigate("/register");
                          }}
                        >
                          Register To Apply
                        </button>
                        <button
                          className="site-button ml-3 mb-5"
                          onClick={() => {
                            window.localStorage.setItem('applyJobId', id);
                            navigate("/signin");
                            
                          }}
                        >
                          Login To Apply
                        </button>
                      </div>
                    )}
                    { userData.roleId === 1  ? (
                     
                        <Link
                          style={{ borderRadius: 25, marginBottom: "40px" }}
                          title="READ MORE"
                          to={`https://wa.me/91`+parseInt(jobData.whatsapp)}
                          target="_blank"
                          type="button"
                          className="site-button ml-3 bg-success"
                        >
                          <i className="fa fa-whatsapp  "></i> Whatsapp HR{" "}
                        </Link>
                      
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal
                        show={candidateProfile}
                        onHide={setCandidateProfile}
                        className="modal fade modal-bx-info editor" size="xl" >
                        
                       
                        <div  role="document" >
                          <div className="modal-content " >

                            <div className="modal-header" >
                              <h5 className="modal-title">Candidate Profile</h5>
                              <button
                                type="button"
                                className="close"
                                onClick={() => {
                                                 setCandidateProfile(false);
                                            
                                                
                                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div className="modal-body">
                              <form>
                                <div className="row m-b30">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Your Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={userData.name}
                                        onChange={onChangeHandler}
                                        placeholder="Alexander Weir"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Professional title</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="professional_title"
                                        value={profile.professional_title}
                                        onChange={onChangeHandler}
                                        placeholder="Web Designer"
                                      />
                                     {/* {profile.professional_title ? null : showError ?(!profile.professional_title ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.title}</span> :null)  :null}
														         {profile.professional_title ?(profile.professional_title.length > 100 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.titleLimit}</span> :null)  :null} */}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Age</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="age"
                                        value={profile.age}
                                        onChange={onChangeHandler}
                                        placeholder="32 Year"
										                    min={0}
                                      />
                                     {/* {profile.age ? null : showError ?(!profile.age ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.age}</span> :null)  :null}
														         {profile.age ?(profile.age > 100 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.validAge}</span> :profile.age <16 ?<span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.ageLimit}</span>:null )  :null} */}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Experience <span style={{color:'red'}}>*</span></label>
                                      
                                      <Form.Control
                                        as="select"
                                        name="experience"
                                        value={profile.experience}
                                        onChange={onChangeHandler}
                                        style={{height:'45px'}}
                                      >
                                        <option value={''}>---SELECT---</option>
                                        
                                        {experienceData.map((val, index) => {
                                          return (
                                            <option key={index}>
                                              {val.exp_year}
                                            </option>
                                          );
                                        })}
                                      </Form.Control>
                                      {profile.experience ? null : showError ?(!profile.experience ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.experience}</span> :null)  :null}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Languages</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="languages"
                                        value={profile.languages}
                                        onChange={onChangeHandler}
                                        placeholder="English"
                                      />
                                       {/* {profile.languages ? null : showError ?(!profile.languages ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.language}</span> :null)  :null}
														           {profile.languages ?(profile.languages.length > 250 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.languageLimit}</span> :null)  :null} */}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Comapany Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="company"
                                        value={profile.company}
                                        onChange={onChangeHandler}
                                        placeholder="Infosys"
                                      />
                                     {/* {profile.company ? null : showError ?(!profile.company ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.companyName}</span> :null)  :null}
												          	 {profile.company ?(profile.company.length > 250 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.companyNameLimit}</span> :null)  :null} */}
                                    </div>
                                  </div>
                                  
                                  
                                  <div className="col-lg-12 col-md-12 mb-4">
																<label>Availability</label>
																<input
																			type="date"
																			className="form-control"
																			placeholder="Enter Company Name"
																			name='availability_to_join'
																			value={profile.availability_to_join}
																			onChange={onChangeHandler}
																		/>
																		 {/* {profile.availability_to_join ? null : showError ?(!profile.availability_to_join ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.date}</span> :null)  :null}
																      {profile.availability_to_join ? +d1 < +d2 ? null : <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.invalidDate}</span> :null} */}
																</div>

                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Current Salary (<span style={{fontSize:15}}>&#8377;</span>)</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="current_salary"
                                        value={profile.current_salary}
                                        onChange={onChangeHandler}
                                        placeholder="200$"
                                      />
                                       {/* {profile.current_salary ? null : showError ?(!profile.current_salary ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.Salary}</span> :null)  :null}
														         {profile.current_salary ?(profile.current_salary.length > 8 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.salLimit}</span> :null)  :null} */}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Expected Salary (<span style={{fontSize:15}}>&#8377;</span>)</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="expected_salary"
                                        value={profile.expected_salary}
                                        onChange={onChangeHandler}
                                        placeholder="2500$"
                                      />
                                       {/* {profile.expected_salary ? null : showError ?(!profile.expected_salary ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.Salary}</span> :null)  :null}
														         {profile.expected_salary ?(profile.expected_salary.length > 8 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.salLimit}</span> :null)  :null} 
                                     {profile.expected_salary ? (parseInt(profile.current_salary) > parseInt(profile.expected_salary) ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentSalary}</span> : null) : null} */}
                                    </div>
                                  </div>

                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Description</label>
                                      <textarea
                                        className="form-control"
                                        name="description"
                                        value={profile.description}
                                        onChange={onChangeHandler}
                                      ></textarea>
                                      {/* {profile.description ? null : showError ?( <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.Description}</span> )  :null}
														        {profile.description ?(profile.description.length > 250 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.description}</span> :null)  :null} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="job-bx-title clearfix">
                                  <h5 className="font-weight-700 pull-left text-uppercase">
                                    Contact Information
                                  </h5>
                                </div>
                                <div className="row m-b30">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Phone</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                       
                                        value={userData.mobile}
                                       
                                        placeholder="+1 123 456 7890"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Email Address</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="email_address"
                                        value={userData.email}
                                        onChange={onChangeHandler}
                                        placeholder="info@example.com"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Country</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      
                                        onChange={onChangeHandler}
                                        placeholder="Country Name"
                                        name='country'
																		  	value='India'
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>State</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="state"
                                        value="Odisha"
                                        placeholder="Odisha"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Postcode <span style={{color:'red'}}>*</span></label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="postcode"
                                        value={profile.postcode}
                                        onChange={onChangeHandler}
                                        placeholder="751024"
                                      />
                                       {profile.postcode ? pincodeDirectory.lookup(profile.postcode).length > 0 ?(pincodeDirectory.lookup(profile.postcode)[0].stateName === 'ODISHA' ? null:<span style={{color:'red',fontSize:11 , fontWeight:'bold',marginLeft:5}}>{profile.postcode} doesn't belong to Odisha</span> ) :<span style={{color:'red',fontSize:12}}>{profile.postcode} doesn't belong to Odisha</span>: null}
												              {profile.postcode ? null : (showError ? (!profile.postcode ?<span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.pincode}</span>:null):null)}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>City</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        value={profile.city}
                                        onChange={onChangeHandler}
                                        placeholder="City"
                                      />
                                      {/* {profile.city ? null : showError ?(!profile.city ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.city}</span> :null)  :null}
														        {profile.city ?(profile.city.length > 250 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.cityLimit}</span> :null)  :null} */}
                                    </div>
                                  </div>
                                 
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label>Full Address</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="full_address"
                                        value={profile.full_address}
                                        onChange={onChangeHandler}
                                        placeholder="Patia"
                                      />
                                       {/* {profile.full_address ? null : showError ?(!profile.full_address ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.address}</span> :null)  :null}
                                    {profile.full_address ?(profile.full_address.length > 250 ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.addressLimit}</span> :null)  :null}          */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-12 col-md-12"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                 
                                   
                                    <button
                                      type="button"
                                      className="site-button m-b30 "
                                      onClick={saveHandler}
                                    >
                                      Save
                                    </button>
                                  
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Modal>
        {/* <Modal
          show={candidateProfile}
          onHide={setCandidateProfile}
          className="modal fade modal-bx-info editor"
          size="xl"
        >
          <div role="document">
            <div className="modal-content ">
              <div
                className="modal-header"
                style={{ backgroundColor: " #2e55fa" }}
              >
                <h5 className="modal-title" style={{ color: "#fff" }}>
                  Candidate Profile
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setCandidateProfile(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="row m-b30">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Your Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={userData.name}
                          onChange={onChangeHandler}
                          placeholder="Alexander Weir"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Professional title:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="professional_title"
                          value={profile.professional_title}
                          onChange={onChangeHandler}
                          placeholder="Web Designer"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Age:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="age"
                          value={profile.age}
                          onChange={onChangeHandler}
                          placeholder="32 Year"
                          min={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Experience</label>

                        <Form.Control
                          as="select"
                          name="experience"
                          value={profile.experience}
                          onChange={onChangeHandler}
                          style={{ height: "45px" }}
                        >
                          <option>---SELECT---</option>

                          {experienceData.map((val, index) => {
                            return <option key={index}>{val.exp_year}</option>;
                          })}
                        </Form.Control>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Languages:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="languages"
                          value={profile.languages}
                          onChange={onChangeHandler}
                          placeholder="English"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Comapany Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          value={profile.company}
                          onChange={onChangeHandler}
                          placeholder="Infosys"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 mb-4">
                      <label>Availability:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Company Name"
                        name="availability_to_join"
                        value={profile.availability_to_join}
                        onChange={onChangeHandler}
                      />
                      {profile.availability_to_join ? null : showError ? (
                        !profile.availability_to_join ? (
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: "bold",
                              color: "red",
                              marginLeft: 5,
                            }}
                          >
                            {Error.date}
                          </span>
                        ) : null
                      ) : null}
                      {profile.availability_to_join ? (
                        +d1 < +d2 ? null : (
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: "bold",
                              color: "red",
                              marginLeft: 5,
                            }}
                          >
                            {Error.invalidDate}
                          </span>
                        )
                      ) : null}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Current Salary($):</label>
                        <input
                          type="text"
                          className="form-control"
                          name="current_salary"
                          value={profile.current_salary}
                          onChange={onChangeHandler}
                          placeholder="200$"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Expected Salary($):</label>
                        <input
                          type="text"
                          className="form-control"
                          name="expected_salary"
                          value={profile.expected_salary}
                          onChange={onChangeHandler}
                          placeholder="2500$"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Description:</label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={profile.description}
                          onChange={onChangeHandler}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="job-bx-title clearfix">
                    <h5 className="font-weight-700 pull-left text-uppercase">
                      Contact Information
                    </h5>
                  </div>
                  <div className="row m-b30">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Phone:</label>
                        <input
                          type="number"
                          className="form-control"
                          value={userData.mobile}
                          placeholder="+1 123 456 7890"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Email Address:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email_address"
                          value={userData.email}
                          onChange={onChangeHandler}
                          placeholder="info@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Country:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          value={profile.country}
                          onChange={onChangeHandler}
                          placeholder="Country Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Postcode:</label>
                        <input
                          type="number"
                          className="form-control"
                          name="postcode"
                          value={profile.postcode}
                          onChange={onChangeHandler}
                          placeholder="751024"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          value={profile.city}
                          onChange={onChangeHandler}
                          placeholder="Bhubaneswar"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>State:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          value={profile.state}
                          onChange={onChangeHandler}
                          placeholder="Odisha"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Full Address:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="full_address"
                          value={profile.full_address}
                          onChange={onChangeHandler}
                          placeholder="Patia"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      type="button"
                      className="site-button m-b30 "
                      onClick={saveHandler}
                    >
                      Save{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal> */}
      </div>

      <Footer />
    </>
  );
};

export default JobDetails;
