import React,{ useState ,useEffect}  from 'react'
import Certification from '../../../services/candidate_resume/Certification'
import { Text, View } from '@react-pdf/renderer';

const PdfCertification = () => {

    const [certification,setCertification]=useState({})
    const userId = window.localStorage.getItem("userId")
    const getExperience=async()=>{

        try {
            const res=await Certification.Candidate_Certification_By_Id(userId)
            if(res.status===200){
                setCertification(res.data)
            }
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        getExperience();
    },[userId])
  return (
    <View style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '25'}}>
    <Text style={{fontSize:12 ,paddingBottom:'5',color:'#fff'}}>Certification</Text>
    <View
                style={{
                  height: '1px',
                  marginTop: '13px',
                  marginBottom: '12px',
                  width:'40%',
                 
                  backgroundColor: '#000',
                }}
              ></View>
    <Text style={{fontSize:10,color:'#fff'}}>{certification.certification_name}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{certification.certification_body}</Text>
    <Text style={{fontSize:10,color:'#fff'}}>{certification.year_onable}</Text>
    
  </View>
  )
}

export default PdfCertification