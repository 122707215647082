import React, { useState, useEffect } from 'react'
import Users from '../../../services/Users'
import { Text, View } from '@react-pdf/renderer';


const PdfContact = () => {
    const [contact, setContact] = useState({})


    const userId = window.localStorage.getItem("userId")

    const getContact = async () => {
        try {
            const res = await Users.getUserById(userId)
            if (res.status === 200) {
                setContact(res.data)
            }
        } catch (error) {

        }
    }
    
    useEffect(() => {
        getContact();

    }, [userId])
    return (
        <>
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '25', justifyContent: 'center'
            }}>
                <Text style={{ fontSize: 12, paddingBottom: '5', color: '#fff' }}>Contact Details</Text>
                <View
                    style={{
                        height: '1px',
                        marginTop: '13px',
                        marginBottom: '12px',
                        width: '40%',
                        backgroundColor: '#000',
                    }}
                ></View>
                <Text style={{ fontSize:10,color:'#fff' }}>{contact.email}</Text>
                <Text style={{ fontSize:10,color:'#fff' }}>{contact.mobile}</Text>

            </View>

        </>
    )
}

export default PdfContact