import axios from 'axios';

const Candidate_It_Sklill = (payload) => axios.post('/candidate-it-skills/add', payload);

const Candidate_It_Sklill_By_Id=(candidate_id)=>axios.get(`/candidate-it-skills/`+candidate_id);

const Candidate_It_Sklill_Update=(candidate_id,payload)=>axios.put(`/candidate-it-skills/`+candidate_id,payload);


const  candidateSkill =(id)=> axios.get(`/candidate-it-skills/skill/`+id)

const deleteCandidateSkill = (id) => axios.delete(`/candidate-it-skills/`+id)

export default { Candidate_It_Sklill,
                 Candidate_It_Sklill_By_Id,
                 Candidate_It_Sklill_Update,
                 candidateSkill,
                 deleteCandidateSkill
                
                };