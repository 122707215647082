import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import JobPostServices from "../../services/JobPost";
import SkillServices from "../../services/Master/SkillMaster";
import JobTypeServices from "../../services/JobType";
import ExperienceServices from "../../services/Experience";
import LocationServices from "../../services/Location";
import RegionMasterService from "../../services/Master/RegionMaster";
import CategoryMasterService from "../../services/Master/CategoryMaster";
import * as EmailValidator from "email-validator";
import { Country, State, City } from "country-state-city";
import Error from "../../constants/ErrorMessage";
import EducationServices from "../../services/Education";
import AlertMessage from "../../components/AlertMessage";
import { ThreeCircles } from "react-loader-spinner";
import Users from "../../services/Users";
import Select from "react-dropdown-select";


const UpdateJobPost = () => {
  const [jobData, setJobData] = useState({
    id: 0,
    job_title: "",
    job_email: "",
    job_tags: "",
    job_type: "",
    experience: "",
    job_category: "",
    job_company: "",
    minimum_salary: "",
    maximum_salary: "",
    region: "",
    location: "",
    qualification: "",
    last_date: "",
    vacancy: "",
    workplace_type: "",
    worktime_start: "",
    worktime_end: "",
    interview_fee: "",
    gender: "",
    job_area: "",
    job_flat_no: "",
    whatsapp: "",
    hr_name: "",
    hr_company: "",
  });

  const [educationOption, setEducationOption] = useState([]);
  const [jobTagOptions, setJobTagOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [alertState, setAlertState] = useState({});
  // const [checkButton, setCheckButton] = useState(false);
  const [sector, setSector] = useState([]);
  // const [loader, setLoader] = useState(false);
  // const [description, setDescription] = useState([]);
  // const [requirements, setRequirements] = useState([]);
  // const [keySkill, setKeySkill] = useState([]);
  const [descriptionData, setDescriptionData] = useState("");
  const [requirementsData, setRequirementsData] = useState("");
  const [skillData, setSkillData] = useState("");
  const [updateId, setUpdateId] = useState(0);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [requirementModal, setRequirementModal] = useState(false);
  const [keySkillModal, setKeySkillModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [jobCountry, setJobCountry] = useState("");
  const [countryNames, setCountryNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [jobState, setJobState] = useState();
  const [cityNames, setCityNames] = useState([]);
  const [jobCity, setJobCity] = useState("");
  const [description, setDescriptions] = useState([]);
  const [requirement, setRequirement] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const [editDescription,setEditDescription]=useState([]);
  const [editRequirements,setEditRequirements]=useState([]);
  const [editKeyskills,setEditKeyskills]=useState([]);
  const [showError, setShowError] = useState(false);
  const [showErrorDescription , setShowErrorDescription] = useState(false);
	const [showErrorRequirement , setShowErrorRequirement] = useState(false);
	const [showErrorKeySkill , setShowErrorKeySkill] = useState(false);
  const [descriptionDetails , setDescriptionDetails] = useState({});
  const [jobIddata,setjobIdData] =useState(0)
const [requirementJobId,setRequirementJobId]=useState(0)
const [KeySkillsjobId,setSkillJobId]=useState(0);
  const [sun, setSun] = useState("No");
  const [mon, setMon] = useState("No");
  const [tue, setTue] = useState("No");
  const [wed, setWed] = useState("No");
  const [thu, setThu] = useState("No");
  const [fri, setFri] = useState("No");
  const [sat, setSat] = useState("No");
  const [bike_option, setBike] = useState("No");
  const [rc_option, setRC] = useState("No");
  const [dl_option, setDL] = useState("No");
  const [laptop_option, setLaptop] = useState("No");
  const [smartPhone, setSmartPhone] = useState("No");
  const [pan_card, setPan] = useState("No");
  const [adhar_card, setAdhar] = useState("No");
  const [back_passbook, setPassbook] = useState("No");

  const [check, setCheck] = useState(false);
  const [user, setUser] = useState({});

  const [jobTypeOpt,setJobTypeOpt]=useState('')
  const [categoryOpt,setCategoryOpt]=useState('');
  const [experienceOpt,setExperienceOpt]=useState('');
  const [qualificationOpt,setQualificationOpt]=useState('');
 

  const { id } = useParams();
  const userName = localStorage.getItem("userName");
  const userId = JSON.parse(localStorage.getItem("userId"));

  const inputChange = (e) => {
    setJobData({ ...jobData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  function alertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ open: false });
  }
  const checkHandler = (check) => {
    try {
      setCheck(check);
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      const res = await Users.getUserById(userId);
      setUser(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, [`${userId}`]);

  



 
 
 
 
  const requirementHandler = async () => {
    setShowErrorRequirement(false);
  try {
    if (!requirementsData || requirementsData.length >250) {
      if ( requirementsData.length>250){
       setAlertState({ open: true, type: 'error', message: Error.jobRequirementLimit });
      }else { 
          setAlertState({ open: true, type: 'error', message: 'Please, add a Requirements' })}      
  }else {
    let arr1=[...editRequirements]

    arr1=[...arr1,{ id:updateId,jobId:requirementJobId ,data :requirementsData}];
    setEditRequirements(arr1)
    setRequirementModal(false)
  } 
  } catch (error) {
    console.log(error)
    
  }
  }
      

  const keySkillHandler = async () => {
    setShowErrorKeySkill(false)
    try {
      if (!skillData || skillData.length >250) {
        if ( skillData.length>250){
         setAlertState({ open: true, type: 'error', message: Error.skillLimit });
        }else { 
            setAlertState({ open: true, type: 'error', message: 'Please, add a skill' })}      
    } else {
      
     let arr2= [...editKeyskills]
      
     arr2 =[...arr2,{ id:updateId,jobId:KeySkillsjobId,data:skillData}]

     setEditKeyskills(arr2)
     setKeySkillModal(false)

    }
    } catch (error) {
      console.log(error);
    }
  };
  
  
  


const descriptionHandler =async()=>{
  setShowErrorDescription(false)
  try {

    if (!descriptionData || descriptionData.length >250) {
      if ( descriptionData.length>250){
       setAlertState({ open: true, type: 'error', message: Error.jobDescriptionLimit });
      }else { 
          setAlertState({ open: true, type: 'error', message: 'Please, add a description' })}      
  } else {
    let arr = [...editDescription];

  arr = [ ...arr,{id:updateId,jobId:jobIddata ,data: descriptionData} ];
  
  setEditDescription(arr)
  
  setDescriptionModal(false);
       
          
  }    

  } catch (error) {
    console.log(error)
  }
}



const UpdateDescriptionHandler =async(idx)=>{
 
  description.find((val)=>{ if(val.id === idx){
    setDescriptionData(val.description);
    setjobIdData(val.jobId)
    setDescriptionModal(true)
    setUpdateId(idx)
  }})
}


  const updateRequirementhandler = async (idx) => {
    try {
      requirement.find((val) => {
        if (val.id === idx) {
          setRequirementsData(val.requirement);
          setRequirementJobId(val.jobId)
          setRequirementModal(true);
          setUpdateId(idx);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSkillHandler = async (idx) => {
    try {
      keySkills.find((val) => {
        if (val.id === idx) {
          setSkillData(val.skill);
          setSkillJobId(val.jobId)
          setKeySkillModal(true);
          setUpdateId(idx);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
 

  const jobUpdateHandler = async () => {
    setLoader(true);

    try {
      setShowError(true);
      if (!jobData.job_title || jobData.job_title.length > 100) {
        if (jobData.job_title.length > 100) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.jobTitleLimit,
          });
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.jobTitle,
          });
        }
      } else if (!jobData.job_company || jobData.job_company.length > 250) {
        if (jobData.job_company.length > 250) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.companyNameLimit,
          });
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.companyName,
          });
        }
      } else if (!jobData.job_tags) {
        setAlertState({ open: true, type: "warning", message: Error.jobTags });
      } else if (!jobData.job_type) {
        setAlertState({ open: true, type: "warning", message: Error.jobType });
      } else if (!jobData.job_category) {
        setAlertState({
          open: true,
          type: "warning",
          message: Error.jobCategory,
        });
      } else if (!jobData.experience) {
        setAlertState({
          open: true,
          type: "warning",
          message: Error.experience,
        });
      } else if (!jobData.minimum_salary || jobData.minimum_salary.length > 8) {
        if (jobData.minimum_salary.length > 8) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.salLimit,
          });
        } else {
          setAlertState({ open: true, type: "warning", message: Error.minSal });
        }
      } else if (
        !jobData.maximum_salary ||
        jobData.maximum_salary.length > 8 ||
        parseInt(jobData.minimum_salary) > parseInt(jobData.maximum_salary)
      ) {
        if (jobData.maximum_salary.length > 8) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.salLimit,
          });
        } else if (
          parseInt(jobData.minimum_salary) > parseInt(jobData.maximum_salary)
        ) {
          setAlertState({ open: true, type: "warning", message: Error.salary });
        } else {
          setAlertState({ open: true, type: "warning", message: Error.maxSal });
        }
      } else if (!jobCountry) {
        setAlertState({ open: true, type: "warning", message: Error.region });
      } else if (!jobState) {
        setAlertState({ open: true, type: "warning", message: Error.state });
      } else if (!jobCity) {
        setAlertState({ open: true, type: "warning", message: Error.city });
      } else if (!jobData.job_flat_no || jobData.job_flat_no.length > 250) {
        if (jobData.job_flat_no.length > 250) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.addressLimit,
          });
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.address,
          });
        }
      } else if (!jobData.last_date) {
        setAlertState({ open: true, type: "warning", message: Error.lastdate });
      } else if (!jobData.qualification) {
        setAlertState({
          open: true,
          type: "warning",
          message: Error.qualification,
        });
      } else if (!jobData.worktime_start) {
        setAlertState({ open: true, type: "warning", message: Error.time });
      } else if (!jobData.worktime_end) {
        setAlertState({ open: true, type: "warning", message: Error.time });
      } else if (!jobData.workplace_type) {
        setAlertState({
          open: true,
          type: "warning",
          message: Error.workPlaceType,
        });
      } else if (!jobData.gender) {
        setAlertState({ open: true, type: "warning", message: Error.gender });
      } else if (!jobData.vacancy || jobData.vacancy.length > 10) {
        if (jobData.vacancy.length > 10) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.vacancyLimit,
          });
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.vacancy,
          });
        }
      } else if (!jobData.hr_company || jobData.hr_company.length > 100) {
        if (jobData.hr_company.length > 100) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.companyNameLimit,
          });
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.companyName,
          });
        }
      } else if (!jobData.hr_name || jobData.hr_name.length > 100) {
        if (jobData.hr_name.length > 100) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.nameLimit,
          });
        } else {
          setAlertState({ open: true, type: "warning", message: Error.name });
        }
      } else if (
        jobData.job_email === "" ||
        jobData.job_email.length > 100 ||
        EmailValidator.validate(jobData.job_email) === false
      ) {
        if (EmailValidator.validate(jobData.job_email) === false) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.invalidEmail,
          });
        } else if (jobData.job_email.length > 100) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.emailLimit,
          });
        } else {
          setAlertState({ open: true, type: "warning", message: Error.email });
        }
      } else if (!jobData.whatsapp || jobData.whatsapp.length !== 10) {
        if (jobData.whatsapp.length !== 10) {
          setAlertState({
            open: true,
            type: "warning",
            message: Error.invalidMobile,
          });
        } else {
          setAlertState({ open: true, type: "warning", message: Error.mobile });
        }
      } else if (!jobData.interview_fee) {
        setAlertState({
          open: true,
          type: "warning",
          message: Error.interViewFee,
        });
      } else {
        const jobPost = {};
        jobPost.job_title = jobData.job_title;
        jobPost.job_email = jobData.job_email;
        jobPost.job_company = jobData.job_company;
        jobPost.job_tags = jobData.job_tags;
        jobPost.job_type = jobData.job_type;
        jobPost.job_category = jobData.job_category;
        jobPost.experience = jobData.experience;
        jobPost.minimum_salary = parseInt(jobData.minimum_salary);
        jobPost.maximum_salary = parseInt(jobData.maximum_salary);
        jobPost.region = jobCountry;
        jobPost.location = jobState;
        jobPost.last_date = jobData.last_date;
        jobPost.user_id = jobData.user_id;
        jobPost.bike_option = bike_option;
        jobPost.rc_option = rc_option;
        jobPost.dl_option = dl_option;
        jobPost.laptop_option = laptop_option;
        jobPost.smartPhone = smartPhone;
        jobPost.status = "Review";
        jobPost.qualification = jobData.qualification;
        jobPost.clr = "N";
        jobPost.adhar_card = adhar_card;
        jobPost.pan_card = pan_card;
        jobPost.back_passbook = back_passbook;
        jobPost.sun = sun;
        jobPost.mon = mon;
        jobPost.tue = tue;
        jobPost.wed = wed;
        jobPost.thu = thu;
        jobPost.fri = fri;
        jobPost.sat = sat;
        jobPost.job_area = jobCity;
        jobPost.job_flat_no = jobData.job_flat_no;
        jobPost.worktime_start = jobData.worktime_start;
        jobPost.worktime_end = jobData.worktime_end;
        jobPost.workplace_type = jobData.workplace_type;
        jobPost.gender = jobData.gender;
        jobPost.vacancy = jobData.vacancy;
        jobPost.hr_company = jobData.hr_company;
        jobPost.hr_name = jobData.hr_name;
        jobPost.whatsapp = jobData.whatsapp;
        jobPost.interview_fee = jobData.interview_fee;


        if (
          jobPost.job_title ||
          jobPost.job_type ||
          jobPost.minimum_salary ||
          jobPost.maximum_salary ||
          jobPost.region ||
          jobPost.location ||
          jobPost.last_date ||
          jobPost.job_tags ||
          jobPost.job_category ||
          jobPost.job_company ||
          jobPost.experience ||
          jobPost.job_email ||
          jobPost.status ||
          jobPost.bike_option ||
          jobPost.rc_option ||
          jobPost.dl_option ||
          jobPost.laptop_option ||
          jobPost.smartPhone ||
          jobPost.qualification ||
          jobPost.clr ||
          jobPost.pan_card ||
          jobPost.adhar_card ||
          jobPost.back_passbook ||
          jobPost.workplace_type ||
          jobPost.worktime_start ||
          jobPost.worktime_end ||
          jobPost.interview_fee ||
          jobPost.gender ||
          jobPost.sun ||
          jobPost.mon ||
          jobPost.tue ||
          jobPost.wed ||
          jobPost.thu ||
          jobPost.fri ||
          jobPost.sat ||
          jobPost.job_area ||
          jobPost.job_flat_no ||
          jobPost.hr_name ||
          jobPost.hr_company ||
          jobPost.vacancy ||
          jobPost.whatsapp
        ) {
          setLoader(true);

          const res = await JobPostServices.updateJobPost(id, jobPost);

          if (res.status === 200) {
            

            for(let i=0 ;i< editDescription.length ;i++){
              const payload={};
              payload.jobId=editDescription[i].jobId;
              payload.description=editDescription[i].data

              const result = await JobPostServices.updatejobDescrioption(editDescription[i].id,payload)

             }
            

             for( let j=0 ;j<editRequirements.length;j++){
              const payload ={};
              payload.jobId=editRequirements[j].jobId;
              payload.requirement=editRequirements[j].data
              const result = await JobPostServices.updatejobRequirement(editRequirements[j].id,payload)
            }
              for(let k=0 ;k< editKeyskills.length; k++){

                const payload={}
                payload.jobId=editKeyskills[k].jobId;
                payload.skill=editKeyskills[k].data
                const result=await JobPostServices.updatekeySkill(editKeyskills[k].id,payload)
              }
             
            
            
           
            setAlertState({
              open: true,
              type: "success",
              message: "Job Details Updated",
            });
          }
        } else {
          setAlertState({
            open: true,
            type: "warning",
            message: "Fill all the fields",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false)
   
    navigate('/manage-job')
  };

  const getAllParams = async () => {
    try {
      const skills = await SkillServices.getSkills();
      if (skills.status === 200) {
        setJobTagOptions(skills.data);
      }
      const jobtypes = await JobTypeServices.getJobTypes();

      if (jobtypes.status === 200) {

        setJobTypeOptions(jobtypes.data);
      }

      const education = await EducationServices.getEducation();
      if (education.status === 200) {
        setEducationOption(education.data);

      }
      const experiences = await ExperienceServices.getExperiences();
      if (experiences.status === 200) {
        setExperienceOptions(experiences.data);
      }

      const locations = await LocationServices.getLocations();
      if (locations.status === 200) {
        setRegionOptions(locations.data);
      }

      const res = await RegionMasterService.GetRegion();
      if (res.status === 200) {
        setStates(res.data);
      }

      const resp = await CategoryMasterService.getCategory();
      if (resp.status === 200) {
        setSector(resp.data);
      }
    } catch (err) {
      
      setAlertState({
        open: true,
        type: "error",
        message: "Something went wrong.",
      });
    }

  };
  const getAllCountryStateCityData = async () => {
    try {
      setCountryNames(Country.getAllCountries());
      setStateNames(State.getAllStates());
      setCityNames(City.getAllCities());
    } catch (error) {
      console.log(error);
    }
  };

  const getJobDetails = async () => {
    try {
      const res = await JobPostServices.getJobPostById(id);
      
      setJobData(res.data);

  
      setSun(res.data.sun);
      setMon(res.data.mon);
      setTue(res.data.tue);
      setWed(res.data.wed);
      setThu(res.data.thu);
      setFri(res.data.fri);
      setSat(res.data.sat);
      setBike(res.data.bike_option);
      setAdhar(res.data.adhar_card);
      setPan(res.data.pan_card);
      setPassbook(res.data.back_passbook);
      setRC(res.data.rc_option);
      setDL(res.data.dl_option);
      setLaptop(res.data.laptop_option);
      setSmartPhone(res.data.smartPhone);
      setJobCity(res.data.job_area);
      setJobCountry(res.data.region);
      setJobState(res.data.location);
      setJobTypeOpt(res.data.job_type);
      setCategoryOpt(res.data.job_category);
     setExperienceOpt(res.data.experience);
     setQualificationOpt(res.data.qualification);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobDescription = async () => {
    try {
      const res = await JobPostServices.getJobDescrioption(`${id}`);
      setDescriptions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobRequirements = async () => {
    try {
      const res = await JobPostServices.getJobRequirement(`${id}`);
      setRequirement(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getKeySkills = async () => {
    try {
      const res = await JobPostServices.getKeySkill(`${id}`);
      setKeySkills(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobDetails();
    getJobDescription();
    getJobRequirements();
    getKeySkills();
  }, [`${id}`]);

  useEffect(() => {
    getAllParams();
    getAllCountryStateCityData();
  }, []);

  let cityList = [];
  let stateList = [];
  let countryCode = countryNames.filter((val) => {
    if (val.name === jobCountry) {
      return val;
    }
  });
  console.log(countryCode, "KKK");
  if (countryCode.length > 0) {
    stateList = stateNames.filter((val) => {
      if (val.countryCode === countryCode[0].isoCode) {
        return val;
      }
    });
    if (stateList.length > 0) {
      let stateCode = stateList.filter((val) => {
        if (val.name === jobState) {
          return val;
        }
      });
      if (stateCode.length > 0) {
        cityList = cityNames.filter((val) => {
          if (
            val.stateCode === stateCode[0].isoCode &&
            val.countryCode === countryCode[0].isoCode
          ) {
            return val;
          }
        });
      }
    }
  }
  let date = new Date();
  let date1 = new Date(jobData.last_date);


  const jobtypeonchange=(e)=>{

    setJobTypeOpt(e[0].jobType)

}
const categoryOnchange=(e)=>{
  setCategoryOpt(e[0].category)
 
}
const experienceOnchange=(e)=>{
  setExperienceOpt(e[0].exp_year)
  
}

const qualificationOnchange=(e)=>{
  setQualificationOpt(e[0].education)
}
const CountryOnchange=(e)=>{
  setJobCountry(e[0].state)
}
const StateOnchange=(e)=>{
  setJobState(e[0].city)
}


const CityOnchange=(e)=>{
  setJobCity(e[0].name)

}

  return (
    <>
      <Header />
      <AlertMessage
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        onclose={alertClose}
      />
      {loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>:
     <div style={{borderTop:'3px solid #2e55fa'}}> 
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 m-b30">
                  <div className="sticky-top">
                    <div className="candidate-info company-info">
                      <div className="candidate-detail text-center">
                        <div className="canditate-des">
                          <Link to={""}>
                            <img
                              alt=""
                              src="images/logo/icon3.jpg"
                            />
                          </Link>
                          <div
                            className="upload-link"
                            title="update"
                            data-toggle="tooltip"
                            data-placement="right"
                          >
                            <input type="file" className="update-flie" />
                            <i className="fa fa-pencil"></i>
                          </div>
                        </div>
                        <div className="candidate-title">
                          <h4 className="m-b5">
                            <Link to={""}>@{userName}</Link>
                          </h4>
                        </div>
                      </div>

                      <ul>
                        <li>
                          <Link to={"#"} className="active">
                            <i
                              className="fa fa-file-text-o"
                              aria-hidden="true"
                            ></i>
                            <span>Update Job</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx submit-resume">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Update Post Job
                      </h5>
                      <Link
                        to={"/manage-job"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Back
                      </Link>
                    </div>
                    <form>
                      <h5 style={{ fontSize: "15px", marginBottom: "40px" }}>
                        JOB DETAILS:{" "}
                      </h5>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Job Title <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={jobData.job_title}
                              name="job_title"
                              onChange={inputChange}
                              placeholder="Enter Job Title"
                            />
                            {jobData.job_title ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.jobTitle}
                              </span>
                            ) : null}
                            {jobData.job_title ? (
                              jobData.job_title.length > 100 ? (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.titleLimit}
                                </span>
                              ) : null
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Job Tags <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control tags_input"
                              value={jobData.job_tags}
                              name="job_tags"
                              onChange={inputChange}
                              placeholder="Enter Job Tag"
                            />
                            {jobData.job_tags ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.jobTags}
                              </span>
                            ) : null}
                            {jobData.job_tags ? (
                              jobData.job_tags.length > 100 ? (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.jobTagLimit}
                                </span>
                              ) : null
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Job Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={jobTypeOptions} 
                            labelField="jobType" 
                            valueField="id"
                             value={jobTypeOpt}  
                             onChange={jobtypeonchange}
                               placeholder={jobTypeOpt}
                                className='form-control'
                                searchable={false}/>
                            {/* <Form.Control
                              as="select"
                              custom
                              className="custom-select"
                              style={{ height: "50px" }}
                              value={jobData.job_type}
                              name="job_type"
                              onChange={inputChange}
                            >
                              <option value="" disabled>
                                --Select Job Type--
                              </option>
                              {jobTypeOptions.map((item, index) => {
                                return (
                                  <option key={index}>{item.jobType}</option>
                                );
                              })}
                            </Form.Control> */}
                            {jobData.job_type ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.jobTypes}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Functional Area{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={sector} 
                            labelField="category" 
                            valueField="id"
                             value={categoryOpt}  
                             onChange={categoryOnchange}
                               placeholder={categoryOpt}
                                className='form-control'
                                searchable={false}/>
                            {/* <Form.Control
                              as="select"
                              custom
                              className="custom-select"
                              style={{ height: "50px" }}
                              value={jobData.job_category}
                              name="job_category"
                              onChange={inputChange}
                            >
                            
                              <option value="">
                                --Select Functional Area--
                              </option>
                              {sector.map((val, ind) => {
                                return (
                                  <option key={ind}>{val.category}</option>
                                );
                              })}
                            </Form.Control> */}
                            {jobData.job_category ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.jobCategory}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Minimum Salary ($):
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={jobData.minimum_salary}
                              min="0"
                              name="minimum_salary"
                              onChange={inputChange}
                              placeholder="e.g. 10000"
                            />
                            {jobData.minimum_salary ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.minSal}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Maximum Salary ($):
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={jobData.maximum_salary}
                              min="0"
                              name="maximum_salary"
                              onChange={inputChange}
                              placeholder="e.g. 20000"
                            />
                            {jobData.maximum_salary ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.maxSal}
                              </span>
                            ) : null}
                            {jobData.maximum_salary ? (
                              parseInt(jobData.minimum_salary) >
                              parseInt(jobData.maximum_salary) ? (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.salary}
                                </span>
                              ) : null
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Experience <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={experienceOptions} 
                            labelField="exp_year" 
                            valueField="id"
                             value={experienceOpt}  
                             onChange={experienceOnchange}
                               placeholder={experienceOpt}
                                className='form-control'
                                searchable={false}/>

                           
                            {jobData.experience ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.experience}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Minimum Qualification{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={educationOption} 
                            labelField="education" 
                            valueField="id"
                             value={qualificationOpt}  
                             onChange={qualificationOnchange}
                               placeholder={qualificationOpt}
                                className='form-control'
                                searchable={false}/>

                            {/* <Form.Control
                              as="select"
                              custom
                              className="custom-select"
                              style={{ height: "50px" }}
                              value={jobData.qualification}
                              name="qualification"
                              onChange={inputChange}
                            >
                              {educationOption.map((val, ind) => {
                                return (
                                  <option key={ind}>{val.education}</option>
                                );
                              })}

                              {jobData.qualification ? null : showError ? (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.qualification}
                                </span>
                              ) : null}
                            </Form.Control> */}
                          </div>
                        </div>
                      
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Country <span style={{ color: "red" }}>*</span>
                            </label>

                            <Select 
                            options={states} 
                            labelField="state" 
                            valueField="id"
                             value={jobCountry} 
                             onChange={CountryOnchange}
                               placeholder={jobCountry}
                                className='form-control'
                                searchable={false}/>
                          
                          
                         
                            {jobCountry ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.country}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              State <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={regionOptions} 
                            labelField="city" 
                            valueField="id"
                             value={jobState}  
                             onChange={StateOnchange}
                               placeholder={jobState}
                                className='form-control'
                                searchable={false}/>
                          
                            
                            {jobState ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.state}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              City <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select 
                            options={cityList} 
                            labelField="name" 
                            valueField="stateCode"
                             value={jobCity}  
                             onChange={CityOnchange}
                               placeholder={jobCity}
                                className='form-control'
                                searchable={false}/>
                            {jobCity ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.city}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Area <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="job_area"
                              value={jobData.job_area}
                              onChange={inputChange}
                            />
                            {jobData.job_area ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.area}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Flat no./Plot no./Building Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="job_flat_no"
                              value={jobData.job_flat_no}
                              onChange={inputChange}
                            />
                            {jobData.job_flat_no ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.flat}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Work From <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="time"
                              name="worktime_start"
                              className="form-control"
                              value={jobData.worktime_start}
                              onChange={inputChange}
                            />
                            {jobData.worktime_start ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.workTimeStart}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Work Till <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              name="worktime_end"
                              value={jobData.worktime_end}
                              onChange={inputChange}
                            />

                            {jobData.worktime_end ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.WorkTimeEnd}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12  ">
                          <h6 style={{ fontSize: "15px" }}>Working Days </h6>
                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px", flexWrap: "wrap" }}
                          >
                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b1"
                                  className="custom-control-input"
                                  value={sun}
                                  name="sun"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setSun("Yes");
                                    } else if (e.target.checked === false) {
                                      setSun("No");
                                    }
                                  }}
                                  checked={sun === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b1"
                                >
                                  SUN
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b2"
                                  className="custom-control-input"
                                  value={mon}
                                  name="mon"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setMon("Yes");
                                    } else if (e.target.checked === false) {
                                      setMon("No");
                                    }
                                  }}
                                  checked={mon === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b2"
                                >
                                  MON
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b3"
                                  className="custom-control-input"
                                  value={tue}
                                  name="tue"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setTue("Yes");
                                    } else if (e.target.checked === false) {
                                      setTue("No");
                                    }
                                  }}
                                  checked={tue === "Yes" ? true : false}
                                />
                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b3"
                                >
                                  TUE
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b4"
                                  className="custom-control-input"
                                  value={wed}
                                  name="wed"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setWed("Yes");
                                    } else if (e.target.checked === false) {
                                      setWed("No");
                                    }
                                  }}
                                  checked={wed === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b4"
                                >
                                  WED
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b5"
                                  className="custom-control-input"
                                  value={thu}
                                  name="thu"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setThu("Yes");
                                    } else if (e.target.checked === false) {
                                      setThu("No");
                                    }
                                  }}
                                  checked={thu === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b5"
                                >
                                  THU
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b6"
                                  className="custom-control-input"
                                  value={fri}
                                  name="fri"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFri("Yes");
                                    } else if (e.target.checked === false) {
                                      setFri("No");
                                    }
                                  }}
                                  checked={fri === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b6"
                                >
                                  FRI
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-1 col-md-1 mr-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="b7"
                                  className="custom-control-input"
                                  value={sat}
                                  name="sat"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setSat("Yes");
                                    } else if (e.target.checked === false) {
                                      setSat("No");
                                    }
                                  }}
                                  checked={sat === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="b7"
                                >
                                  SAT
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12  ">
                          <h6 style={{ fontSize: "15px" }}>Required Items</h6>
                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px", flexWrap: "wrap" }}
                          >
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c1"
                                  className="custom-control-input"
                                  value={bike_option}
                                  name="bike"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setBike("Yes");
                                    } else if (e.target.checked === false) {
                                      setBike("No");
                                    }
                                  }}
                                  checked={bike_option === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c1"
                                >
                                  Bike
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c2"
                                  className="custom-control-input"
                                  value={rc_option}
                                  name="rc"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setRC("Yes");
                                    } else if (e.target.checked === false) {
                                      setRC("No");
                                    }
                                  }}
                                  checked={rc_option === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c2"
                                >
                                  RC
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c3"
                                  className="custom-control-input"
                                  value={dl_option}
                                  name="dl"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setDL("Yes");
                                    } else if (e.target.checked === false) {
                                      setDL("No");
                                    }
                                  }}
                                  checked={dl_option === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c3"
                                >
                                  DL
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c4"
                                  className="custom-control-input"
                                  value={smartPhone}
                                  name="smartphone"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setSmartPhone("Yes");
                                    } else if (e.target.checked === false) {
                                      setSmartPhone("No");
                                    }
                                  }}
                                  checked={smartPhone === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c4"
                                >
                                  Smartphone
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c5"
                                  className="custom-control-input"
                                  value={laptop_option}
                                  name="laptop"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setLaptop("Yes");
                                    } else if (e.target.checked === false) {
                                      setLaptop("No");
                                    }
                                  }}
                                  checked={
                                    laptop_option === "Yes" ? true : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c5"
                                >
                                  Laptop
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c6"
                                  className="custom-control-input"
                                  value={adhar_card}
                                  name="adhar_card"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setAdhar("Yes");
                                    } else if (e.target.checked === false) {
                                      setAdhar("No");
                                    }
                                  }}
                                  checked={adhar_card === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c6"
                                >
                                  Aadhar
                                </label>
                              </div>
                            </div>
                          </div>

                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px", flexWrap: "wrap" }}
                          >
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c7"
                                  className="custom-control-input"
                                  value={pan_card}
                                  name="pan_card"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setPan("Yes");
                                    } else if (e.target.checked === false) {
                                      setPan("No");
                                    }
                                  }}
                                  checked={pan_card === "Yes" ? true : false}
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c7"
                                >
                                  PAN
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="c8"
                                  className="custom-control-input"
                                  value={back_passbook}
                                  name="back_passbook"
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setPassbook("Yes");
                                    } else if (e.target.checked === false) {
                                      setPassbook("No");
                                    }
                                  }}
                                  checked={
                                    back_passbook === "Yes" ? true : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="c8"
                                >
                                  Bank A/C
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Company <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={jobData.job_company}
                              name="job_company"
                              onChange={inputChange}
                              placeholder="Enter Company Name"
                            />
                            {jobData.job_company ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.companyName}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Last Date to Apply{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={jobData.last_date}
                              name="last_date"
                              onChange={inputChange}
                            />
                            {jobData.last_date ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.lastdate}
                              </span>
                            ) : null}
                            {jobData.last_date ? (
                              +date1 >= +date ? null : (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.invalidDate}
                                </span>
                              )
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              No. Of Vacancy{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={jobData.vacancy}
                              name="vacancy"
                              onChange={inputChange}
                              placeholder="Enter the no. of vacancy"
                            />
                            {jobData.vacancy ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.vacancy}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 ">
                          <label>Gender</label>
                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px", flexWrap: "wrap" }}
                          >
                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="rr"
                                  className="custom-control-input"
                                  value="Male"
                                  name="gender"
                                  onChange={inputChange}
                                  checked={
                                    jobData.gender === "Male" ? true : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="rr"
                                >
                                  Male
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="rr1"
                                  className="custom-control-input"
                                  value="Female"
                                  name="gender"
                                  onChange={inputChange}
                                  checked={
                                    jobData.gender === "Female" ? true : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="rr1"
                                >
                                  Female
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="r12"
                                  className="custom-control-input"
                                  value="Both"
                                  name="gender"
                                  onChange={inputChange}
                                  checked={
                                    jobData.gender === "Both" ? true : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="r12"
                                >
                                  Both
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 ">
                          <label>Work Place Type</label>
                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="rrw"
                                  className="custom-control-input"
                                  value="Remote"
                                  name="workplace_type"
                                  onChange={inputChange}
                                  checked={
                                    jobData.workplace_type === "Remote"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="rrw"
                                >
                                  Remote
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="rr1w"
                                  className="custom-control-input"
                                  value="On-Site"
                                  name="workplace_type"
                                  onChange={inputChange}
                                  checked={
                                    jobData.workplace_type === "On-Site"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="rr1w"
                                >
                                  On-Site
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="r12w"
                                  className="custom-control-input"
                                  value="Hybrid"
                                  name="workplace_type"
                                  onChange={inputChange}
                                  checked={
                                    jobData.workplace_type === "Hybrid"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="r12w"
                                >
                                  Hybrid
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6 col-md-6">
                                                    <div className="form-group" >
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <label>Job Description</label>
                                                            <Link to="#" className="site-button right-arrow button-sm float-right "  onClick={() => setDescriptionModal(true)} > + Add </Link>
                                                        </div>

                                                        <div className="col-lg-12 col-md-12 form-control pr-4" style={{ height: 150, overflowY: 'scroll', overflowWrap: 'break-word' }}  >

                                                            <ul className="list-num-count no-round">
                                                                {

                                                                    description.map((val) => {
                                                                        return (
                                                                            <>
                                                                                <li >{val.data} <i className="fa fa-times-circle " aria-hidden="true" style={{ color: 'red' }} onClick={() => removeDescription(val.id)}></i></li>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <label>Job Requirements</label>
                                                            <Link to='#' className="site-button right-arrow button-sm float-right"  onClick={() => setRequirementModal(true)} > + Add </Link>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 form-control" style={{ height: 150, overflowY: 'scroll', overflowWrap: 'break-word' }}>
                                                            <ul className="list-num-count no-round">
                                                                {

                                                                    requirements.map((val) => {
                                                                        return (
                                                                            <>
                                                                                <li>{val.data}  <i className="fa fa-times-circle" aria-hidden="true" style={{ color: 'red' }} onClick={() => removeRequirement(val.id)}></i></li>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <label>Key Skills</label>
                                                            <Link to="#" className="site-button right-arrow button-sm float-right " onClick={() => setKeySkillModal(true)} > + Add </Link>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 form-control" style={{ height: 60, overflowX: 'scroll', overflowWrap: 'break-word' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                                {

                                                                    keySkill.map((val) => {
                                                                        return (
                                                                            <>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='mr-2'>{val.data}  <i className="fa fa-times-circle" aria-hidden="true" style={{ color: 'red' }} onClick={() => removeKeySkill(val.id)}></i></div>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                        <div className="col-lg-12 col-md-12 mt-4 mb-4 ">
                          <h5 style={{ fontSize: "15px" }}>
                            RECRUITER DETAILS:{" "}
                          </h5>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {" "}
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={jobData.hr_name}
                              name="hr_name"
                              onChange={inputChange}
                              placeholder="Enter Name"
                            />
                            {jobData.hr_name ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.HrName}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {" "}
                              Email <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={jobData.job_email}
                              name="job_email"
                              onChange={inputChange}
                              required
                              placeholder="info@gmail.com"
                            />
                            {jobData.job_email ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.Email}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {" "}
                              Whatsapp No.{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={jobData.whatsapp}
                              name="whatsapp"
                              maxLength={10}
                              onChange={inputChange}
                              placeholder="Enter  whatsapp no."
                            />
                            {jobData.whatsapp ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.Whatsapp}
                              </span>
                            ) : null}
                            {jobData.whatsapp ? (
                              jobData.whatsapp.length > 10 ? (
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    marginLeft: 5,
                                  }}
                                >
                                  {Error.whatsappLimit}
                                </span>
                              ) : null
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {" "}
                              Company <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={jobData.hr_company}
                              name="hr_company"
                              onChange={inputChange}
                              placeholder="Enter Company Name"
                            />
                            {jobData.hr_company ? null : showError ? (
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: "bold",
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                {Error.Recruitercompany}
                              </span>
                            ) : null}
                          </div>
                        </div>

                           <div className="col-lg-12 col-md-12">       
                        <div className="job-bx submit-resume ">
                            <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Update Job Description
                      </h5>
                    </div>

                    <div style={{ overflowWrap: "break-word", width: "100%" }}>
                      <ol>
                        {description.map((val) => {
                          return (
                            <li className="mt-2 ml-3" key={val.id}>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    width: "90%",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {val.description}
                                </div>
                                <div
                                  style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                  type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={() =>{
                                      UpdateDescriptionHandler(val.id);
                                    

                                    }
                                    }
                                  >
                                    <i
                                      className="fa fa-pencil bg-warning"
                                      style={{ color: "#fff" }}
                                    ></i>
                                  </button>
                                 
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>
                  </div>  

                  <div className="col-lg-12 col-md-12">
                  <div className="job-bx submit-resume mt-3">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Update Job Requirements
                      </h5>
                    </div>
                    <div style={{ overflowWrap: "break-word" }}>
                      <ol>
                        {requirement.map((val) => {
                          return (
                            <li className="mt-2 ml-3" key={val.id}>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    width: "90%",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {val.requirement}
                                </div>

                                <div
                                  style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                  type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={() =>
                                      updateRequirementhandler(val.id)
                                    }
                                  >
                                    <i
                                      className="fa fa-pencil bg-warning"
                                      style={{ color: "#fff" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>

                  </div>
                  <div className="col-lg-12 col-md-12">
                  <div className="job-bx submit-resume mt-3">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Update Job KeySkills
                      </h5>
                    </div>
                    <div style={{ overflowWrap: "break-word" }}>
                      <ol>
                        {keySkills.map((val) => {
                          return (
                            <li className="mt-2 ml-3" key={val.id}>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    width: "90%",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {val.skill}
                                </div>
                                <div
                                  style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                  type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={() => updateSkillHandler(val.id)}
                                  >
                                    <i
                                      className="fa fa-pencil bg-warning"
                                      style={{ color: "#fff" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>

                  </div>

                        <div className="col-lg-12 col-md-12 mt-3 ">
                          <label>Do you charge any fee for interview ?</label>
                          <div
                            className="d-flex "
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="r1"
                                  className="custom-control-input"
                                  value="Yes"
                                  name="interview_fee"
                                  onChange={inputChange}
                                  checked={
                                    jobData.interview_fee === "Yes"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="r1"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="r2"
                                  className="custom-control-input"
                                  value="No"
                                  name="interview_fee"
                                  onChange={inputChange}
                                  checked={
                                    jobData.interview_fee === "No"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  className="custom-control-label fw"
                                  htmlFor="r2"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ml-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <input
                            className="mr-2"
                            type="checkbox"
                            id="j8"
                            value="Y"
                            onChange={(e) => checkHandler(e.target.checked)}
                          />

                          <label className="mt-2" htmlFor="j8">
                            I agree with all terms & conditions of Jobyard
                            platform.
                          </label>
                          <br />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            disabled={check ? false : true}
                            className={
                              check ? "site-button" : "site-button bg-dark"
                            }
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              borderRadius: 20,
                            }}
                            type="button"
                            onClick={jobUpdateHandler}
                          >
                            Update Job Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={requirementModal}
            onHide={() => setRequirementModal(false)}
            backdrop=""
            size="md"
            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"
          >
            <div style={{ width: "100%" }}>
              <div className="modal-content ">
                <div
                  className="modal-header"
                
                >
                  <h5 className="modal-title" >
                    Job Requirements
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {setRequirementModal(false);setShowErrorRequirement(false)}}
                  >
                    <span aria-hidden="true" >
                      &times;
                    </span>
                  </button>
                </div>

                <div className="modal-body">
                  <label>Requirements</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Requirements"
                    value={requirementsData}
                    onChange={(e) => setRequirementsData(e.target.value)}
                  />
                </div>
                <div className="modal-footer">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      type="button"
                      className="btn btn-success "
                      
                      onClick={requirementHandler}
                    >
                      Update
                    </button>
                  </div>

                
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            show={descriptionModal}
            onHide={setDescriptionModal}
            backdrop=""
            size="md"
            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"
          >
            <div style={{ width: "100%" }}>
              <div className="modal-content ">
                <div
                  className="modal-header"
                 
                >
                  <h5 className="modal-title" >
                    Job Description
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {setDescriptionModal(false);setShowErrorDescription(false)}}
                  >
                    <span aria-hidden="true" >
                      &times;
                    </span>
                  </button>
                </div>

                <div className="modal-body">
                    <form>
                        <div className="col-lg-12 col-md-12">
                         <div className="form-group">
                         <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    value={descriptionData}
                    onChange={(e) => setDescriptionData(e.target.value)}
                  />
                  {descriptionDetails? null:(showErrorDescription?<span  style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.jobDescription}</span>:null)}
                  {descriptionDetails.length>250?<span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.jobDescriptionLimit}</span>:null}

                         </div>

                        </div>
                        <div className="modal-footer">
                     <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      type="button"
                      className="btn btn-success "
                    
                      onClick={descriptionHandler}
                    >
                      Update
                    </button>
                  </div>
                </div>
                    </form>
                  
                </div>
                
              </div>
            </div>
          </Modal>

          <Modal
            show={keySkillModal}
            onHide={setKeySkillModal}
            backdrop=""
            size="md"
            className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"
          >
            <div
              className="modal-header"
         
            >
              <h5 className="modal-title" >
                Key Skill
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => {setKeySkillModal(false);setShowErrorKeySkill(false)}}
              >
                <span aria-hidden="true" >
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body">
              <label>Skill</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="skill"
                value={skillData}
                onChange={(e) => setSkillData(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="button"
                  className="btn btn-success "
                 
                  onClick={keySkillHandler}
                >
                 Update
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      </div>
      }
      <Footer />
    </>
  );
};

export default UpdateJobPost;
