import React, { useEffect, useState } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import AlertMessage from '../../components/AlertMessage';
import JobPostService from '../../services/JobPost';
import { Link, useNavigate } from 'react-router-dom';


const cityBox = [
	{ image: 'images/city/angul.jpg', title: 'Angul', },
    { image: 'images/city/baleswar.jpg', title: 'Baleshwar', },
    { image: 'images/city/berhmpur.jpg', title: 'Brahmapur', },
    { image: 'images/city/bhubaneswar.jpg', title: 'Bhubaneshwar', },
    { image: 'images/city/baripada.jpg', title: 'Bhadrak', },
    { image: 'images/city/cuttack.jpg', title: 'Cuttack', },
    { image: 'images/city/jajpur.jpg', title: 'Jajpur', },
    { image: 'images/city/rourkela.jpg', title: 'Raurkela', },
		
	 ] 


function Categoryalljob(){

	const [joblist , setJoblist] = useState([]);
	const [radio , setRadio ] = useState('');
	const [alertState, setAlertState] = useState({});
	const [selectColor , setSelectColor] = useState('');

	const navigate=useNavigate();


	useEffect(() => {
	  navigate('/category-all-jobs', { replace: true });
	}, []);
	const radioOnChange=(e)=>{

		setRadio(e.target.value);
		
	}

	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({open:false});
	};


	const getAllJobs=async()=>{
		try{

			const res= await JobPostService.getJobPost();
			if(res.status===200){
				setJoblist(res.data);
				
			}

		}catch(err){
			return err;
		}

	}

		useEffect(()=>{
		getAllJobs();
		},[]);

		var cityNames = [...new Set(joblist.filter((val)=>{if(val.status === "Active"){return val;}}).map(val=>val.job_area))]

		var cityData =[];
		
		for(let i=0; i<cityNames.length; i++){
			var match = false;
			for(let j=0; j< cityBox.length ; j++){
		   
				if(cityNames[i] === cityBox[j].title){
					match = true;
					cityData.push(cityBox[j]);
					
				}
			}
			
		}
	

var company=joblist.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_company);

var arr=[...new Set(company)];

var designation=joblist.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_title);

var arr_designation=[...new Set(designation)];

var industry=joblist.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_category);

var arr_industry=[...new Set(industry)];

var skills=joblist.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_tags);

var arr_skills=[...new Set(skills)];


const searchHandler=async()=>{
	try{
		if(radio !== ''){
			window.localStorage.setItem('all',radio);
			window.localStorage.setItem('categories','');
		    window.localStorage.setItem('city','');
			window.localStorage.setItem('companyData','');
			window.localStorage.setItem('tags','');
			window.localStorage.setItem('designation','');
			
			
			navigate('/browse-job-grid');
		}else{
			setAlertState({open:true, type:'warning', message:'Please select one fiels.'});
		}
	}catch(err){
		setAlertState({open:true, type:'error', message:'Something went wrong',err});
	}

}



	return(
		<>
			<Header activelist="category-all-jobs"/>
			<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>

			<div className="page-content">
				<div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(images/banner/bnr1.jpg)"}}>
					<div className="container">
						<div className="dez-bnr-inr-entry">
							<div className="category-jobs-info">
								<div className="nav">
									<ul>
										<li className="active"><Link to={"/category-all-jobs"}>All Jobs</Link></li>
										<li><Link to ={"/category-company-jobs"}>Jobs by Company</Link></li>
										<li><Link to={"/category-jobs"}>Jobs by Category</Link></li>
										<li><Link to={"/category-location-jobs"}>Jobs by Location</Link></li>
										<li><Link to={"/category-designation-jobs"}>Jobs by Designation</Link></li>
										<li><Link to={"/category-skill-jobs"}>Jobs by Skill</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-block">
					<div className="section-full content-inner jobs-category-bx">
						<div className="container">
							<div className="row">
								{
									cityData.map(val=>val).length === 0 ? null :
								
								<div className="col-lg-12 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs by Locations
											<Link to={"/category-location-jobs"} className="float-right font-12 text-primary">View All</Link></h6>
										</div>
										<div className="row">
											{cityData.slice(0,4).map((item,index) => (
												<div className="col-lg-3 col-sm-6 col-md-6 m-b30" key={index}>
													
														<div className="city-bx align-items-end d-flex" style={selectColor === item.title ? {backgroundImage: `url(${item.image})`,border:"3px solid green",} : {backgroundImage: `url(${item.image})`}} onClick={()=>{setRadio(item.title);setSelectColor(item.title)}}>
															<div className="city-info">
																<h5>{item.title}</h5>
															</div>
														</div>
													
												</div>
											))}	
											
										</div>   
									</div>    
								</div>}
								{
									arr_industry.map(val=>val).length === 0 ? null :
								
								<div className="col-lg-6 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs by Industry / Sector
											<Link to={"/category-Jobs"} className="float-right font-12 text-primary">View All</Link></h6>
										</div>
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr_industry.slice(0,4).map((item,index)=>(	
								          <li className="col-lg-6 col-md-6" key={index} >
											<div className="job-post-inf ">
											   <div className="d-flex m-b10">
												 <div className="job-post-info">
												   <ul>
												      <div  style={{display:"flex",alignItems:"center"}}>
												   	     <li style={{listStyle:"none"}}>
												              <label htmlFor={'radio4'+item} >
												              <div style={selectColor === item ? {width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"} : {width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}}  onClick={()=>{setRadio(item);setSelectColor(item);}}>
																 <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span  style={{ color:"#2e55fa"}}>{item}</span></div>																 
												   	       </label>
									  			   	     </li>
												      </div>
											       </ul>											 
												</div>
											  </div>
											</div>
										  </li>
							            	))}	
							            </ul>										
								    </div>
								</div>}

								{
									arr.map(val=>val).length === 0 ? null :
								
								<div className="col-lg-6 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs by Companies
											<Link to={"/category-Jobs"} className="float-right font-12 text-primary">View All</Link></h6>
										</div>
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr.slice(0,4).map((item,index)=>(	
								        	<li className="col-lg-6 col-md-6" key={index} >
											<div className="job-post-inf ">
											  <div className="d-flex m-b10">
												<div className="job-post-info">
												  <ul>
												    <div  style={{display:"flex",alignItems:"center"}}>
										             <li style={{listStyle:"none"}}>
													   <label  >
													   <div style={selectColor === item ?{width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"blue",listStyle:"none"}  : {width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,border:'1px solid ',borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",borderColor:"#bfdcff",listStyle:"none"}} onClick={()=>{setRadio(item);setSelectColor(item);}}>
														 <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span  style={{ color:"#2e55fa"}}>{item}</span></div>																 
													   </label>
									                 </li>
												    </div>
												   </ul>												 
												</div>
											  </div>
											</div>
										  </li>
							            	))}	
							            </ul>	
									</div>    
								</div>}
							
							{
								arr_designation.map(val=>val).length === 0 ? null :
							
								<div className="col-lg-6 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs by Designations
											<Link to={"/category-designation-jobs"} className="float-right font-12 text-primary">View All</Link></h6>
										</div>
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr_designation.slice(0,4).map((item,index)=>(	
								           <li className="col-lg-6 col-md-6" key={index} >
											 <div className="job-post-inf ">
											    <div className="d-flex m-b10">
												  <div className="job-post-info">
												     <ul>
													   <div  style={{display:"flex",alignItems:"center"}}>
										                  <li style={{listStyle:"none"}}>
															  <label htmlFor={'radio4'+item} >
															  <div style={selectColor === item ?{width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",border:'1px solid ',borderColor:"blue",listStyle:"none"}:{width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px",border:'1px solid ',borderColor:"#bfdcff",listStyle:"none"} } onClick={()=>{setRadio(item);setSelectColor(item);}}>
																 <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span  style={{ color:"#2e55fa"}}>{item}</span></div>																 
															 </label>
									                       </li>
														</div>
													  </ul>												 
												  </div>
											    </div>
											</div>
										  </li>
							            	))}	
							            </ul>	
									</div>    
								</div>}
								{
									arr_skills.map(val=>val).length === 0 ? null :

					
								<div className="col-lg-6 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="text-uppercase">Browse Jobs by Skills
											<Link to={"/category-skill-jobs"} className="float-right font-12 text-primary">View All</Link></h6>
										</div>
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr_skills.slice(0,4).map((item,index)=>(	
								        	<li className="col-lg-6 col-md-6" key={index} >
											<div className="job-post-inf ">
											  <div className="d-flex m-b10">
												<div className="job-post-info">
												  <ul>
												    <div  style={{display:"flex",alignItems:"center",}}>
										              <li style={{listStyle:"none",}}>
														<label >
														<div style={selectColor === item ? {border:"1px solid blue",width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px" , listStyle:"none"} :  {border:'1px solid #bfdcff',width:"200px",height:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start" ,borderRadius:"5px",backgroundColor:" #fbfbfb",marginLeft:"5px" , listStyle:"none"}   } onClick={()=>{setRadio(item);setSelectColor(item);}}> 
														   <div style={{paddingLeft:"9px",paddingRight:"9px",borderRight:"1px solid #bfdcff",marginRight:'10px',borderColor:"#bfdcff"}}><img src="images/logo/logo17.png" alt="" style={{height:"30px",width:"30px"}}/></div> <span  style={{ color:"#2e55fa"}}>{item}</span></div>																 
													    </label>
									                   </li>
												     </div>
												  </ul>												 
												</div>
											  </div>
											</div>
										  </li>
							            	))}	
							            </ul>	
									</div>    
								</div>
                                }
							</div>
						</div>
					</div>
				</div>		
				{
					cityData.map(val=>val).length === 0 && arr_industry.map(val=>val).length === 0 && arr.map(val=>val).length === 0 && arr_skills.map(val=>val).length === 0 && arr_designation.map(val=>val).length === 0 ? 
					<center ><span style={{borderRadius:"50px",color:"#2e55fa"}} >No Category Found</span></center>		

				:
				<center ><button type="button"  className="site-button btn-block col-lg-2 col-md-2" onClick={searchHandler} style={{borderRadius:"50px"}} >Find Job</button></center>		
				}
			</div>					
			<Footer />
		</>
	)
}
export default Categoryalljob;











