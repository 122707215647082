import axios from 'axios';

const postBrowseCandidate= (payload) => axios.post('/browse-candidate', payload);

const getBrowseCandidate = () => axios.get('/browse-candidate');

const getBrowseCandidateById = (id) => axios.get('/browse-candidate/'+id);

const DeleteBrowseCandidate =(id)=>axios.delete(`/browse-candidate/delete/`+id);

const getBrowseCandidatebyJobAndCandidate =(userId,jobId)=>axios.get(`/browse-candidate/${userId}/${jobId}`);




export default { postBrowseCandidate, getBrowseCandidate ,getBrowseCandidateById,DeleteBrowseCandidate,getBrowseCandidatebyJobAndCandidate};