import React ,{useCallback, useEffect,useMemo,useState} from 'react';
import AlertMessage from "../../components/AlertMessage";
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import CandidateSideNav from './component/CandidateSideNav';
import SavedJobServices from '../../services/SavedJob';
import JobPost from '../../services/JobPost';
import { ThreeCircles } from 'react-loader-spinner';

const SavedJobs = () => {

    const [savedJobData,setSavedJobData] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [jobSkill , setJobSkill] = useState([]);
    const [loader , setLoader] = useState(false);
    const [alertState, setAlertState] = useState({});
    const [duration, setDuration] = useState("");



    const userId = JSON.parse(localStorage.getItem('userId'));

    function alertClose(event, reason) {
        if (reason === "clickaway") {
          return;
        }
        setAlertState({ open: false });
      }

	const getSavedJobData=async()=>{
		
		try{
            const res= await SavedJobServices.getSavedJob(userId);
			setSavedJobData(res.data);
		}catch(err)
		{
			return err;
		}
	}

	useEffect(()=>{
		getSavedJobData();
	},[userId]);

    const getAllJobs =async()=>{
      try {
        const res = await JobPost.getJobPost();
        setJobs(res.data);
      } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.status === 400
          ) {
            setAlertState({
              open: true,
              type: "error",
              message: error.response.data.message});
            
          } else {
            setAlertState({
              open: true,
              type: "error",
              message: "Something went wrong."});
            
          }
      }
    }

    const getAllJobSkills=async()=>{
        try {
            const res = await JobPost.getAllKeySkills();
            setJobSkill(res.data);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.status === 400
              ) {
                setAlertState({
                  open: true,
                  type: "error",
                  message: error.response.data.message});
                
              } else {
                setAlertState({
                  open: true,
                  type: "error",
                  message: "Something went wrong."});
                
              }
        }
    }

    useEffect(()=>{
        setLoader(true)
      getAllJobs();
      getAllJobSkills();

      setTimeout(() => {
        setLoader(false)
      }, 1500);
    },[]);



    let savedJobs = [];

    for(let i=0; i<jobs.length;i++){
        for(let j=0; j<savedJobData.length;j++){
            if(jobs[i].id === savedJobData[j].job_id){
                savedJobs.push(jobs[i])
            }
        }
    }
   
    if(duration){
        var today = new Date();
        var  priorDate  =  new Date(
            new Date().setDate(today.getDate() - parseInt(duration)));
    }
       
    function formatNumber(number) {
      if (number >= 10000000) {
        return (number / 10000000).toFixed(1) + 'Cr';
      } else if (number >= 100000) {
        return (number / 100000).toFixed(1) + 'L';
      } 
      else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'K';
      }else {
        return number;
      }
    }

   
  return (
    <>
     <Header activelist="jobs-saved-jobs"/>
     <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>
     {loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>
     
   :
     <div style={{borderTop:'3px solid #2e55fa'}}> 
     <div class="page-content bg-white">
        <div class="content-block">
			<div class="section-full bg-white p-t50 p-b20">
				<div class="container">
					<div class="row">
				         <CandidateSideNav activemenu="jobs-saved-jobs" />
						<div class="col-xl-9 col-lg-8 m-b30">
							<div class="job-bx-title clearfix">
								<h5 class="font-weight-700 pull-left text-uppercase">{savedJobs.filter((val)=>{if((!priorDate || (priorDate && Date.parse(priorDate) <= Date.parse(val.created_on)))){return val;}}).map(val=>val).length} Job(s) Found</h5>
								<div class="float-right">
									<span class="select-title">Sort by freshness</span>
									<select style={{
															fontSize: 12, boxShadow: "unset !important",
															border: "1px solid #e1e7ff ",
															width: "120px",
															height: "40px !important",
															float: "right",
															borderRadius: "5px",
															fontFamily: "roboto",
															padding: 12,
															textAlign: "Left",
															transition: "all 0.2s ease-in-out 0s",
															verticalAlign: 'middle',
															whiteSpace: "nowrap",
															lineHeight: 1.42857143,
														}}
                                      value={duration}
                                      onChange={(e)=>setDuration(e.target.value )}
                                    >   
                                        <option value={""}>All</option>
										<option value={60}> Last 2 Months </option>
										<option value={30}> Last Months </option>
										<option value={7}> Last Weeks </option>
										<option value={3}> Last 3 Days </option>
									</select>
								</div>
							</div>

							<ul class="post-job-bx browse-job">
                                {
                               savedJobs.filter((val)=>{if( (!priorDate || (priorDate && Date.parse(priorDate) <= Date.parse(val.created_on)))){return val;}}).map(val=>val).length === 0 ?
                               <div  style={{width:"100%", display:"flex",justifyContent:'center',alignItems:'center'}}>
                               <a href="{#}"><img src="images/gallery/noJobFound.png" width={150} height={150} alt=""/></a>
                               </div>:
                                    savedJobs.filter((val)=>{if((!priorDate || (priorDate && Date.parse(priorDate) <= Date.parse(val.created_on)))){return val;}}).map((itm,ind)=>{
                                        return(
                                            <li key={ind}>
                                            <div class="post-bx">
                                                <div class="job-post-info m-a0">
                                                    <h4><a href={`/job-details/${itm.id}`}>{itm.job_title}</a></h4>
                                                    <ul>
                                                        <li><a href="#">@{itm.job_company}</a></li>
                                                        <li><i class="fa fa-map-marker"></i> {itm.job_area}, {itm.location} , {itm.region}</li>
                                                        <li><i class="fa fa-money"></i> ₹ {formatNumber(itm.minimum_salary)} - ₹ {formatNumber(itm.maximum_salary)}</li>
                                                    </ul>
                                                    <div class="job-time m-t15 m-b10">
                                                        {
                                                            jobSkill.filter((item)=>{if(itm.id === item.jobId){return item}}).map((val,ind)=>{
                                                                return(
                                                                <a href="#" key={ind}><span className='mr-1'>{val.skill}</span></a>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </div>
                                                    <div class="posted-info clearfix">
                                                        <p class="m-tb0 text-primary float-left"><span class="text-black m-r10">Posted On:</span>{itm.created_on}</p>
                                                        <a href={`/job-details/${itm.id}`} class="site-button button-sm float-right">Apply Job</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        )
                                    })
                                }
								
								
							</ul>
							
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>  
      <Footer/>
     </div>}
    </>
  )
}

export default SavedJobs