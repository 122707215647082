import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import JobPost from '../../services/JobPost';


const cityBox = [
    {  title: 'Baleshwar' },
    {  title: 'Brahmapur'},
    { title: 'Bhubaneshwar'},
    {  title: 'Cuttack' },
    {  title: 'Jajpur' },
    {  title: 'Raurkela' },
	{title:'Puri'},
	{title:"Anugul"}
]

const Footer = () => {
  const [jobs,setJobs]=useState([])



const navigate=useNavigate()
  const getAllJobs = async () => {
	try {
		const res = await JobPost.getJobPost();
		setJobs(res.data)

	} catch (error) {
		console.log(error)
	}
}
useEffect(() => {
	getAllJobs();
}, [])

const locationHandler = (city) => {
	try {
		window.localStorage.setItem('city', city);
		window.localStorage.setItem('categories', '');
		window.localStorage.setItem('all', '');
		window.localStorage.setItem('companyData', '');
		window.localStorage.setItem('tags', '');
		window.localStorage.setItem('designation', '');


navigate("/browse-job-grid")


	} catch (error) {
		console.log(error)
	}
}




var cityNames = [...new Set(jobs.filter((val)=>{if(val.status === "Active"){return val;}}).map(val=>val.job_area))]
 
var cityData =[];
    
for(let i=0; i<cityNames.length; i++){
	var match = false;
	for(let j=0; j< cityBox.length ; j++){
	
		 if(cityNames[i] === cityBox[j].title){
			match = true;
			cityData.push(cityBox[j]);			 
		}
	}	 
}


	
return (		
	<footer className="site-footer">
	<div className="footer-top">
		<div className="container">
			<div className="row">
				<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
				<div className="container">
			          <div className="row">
					  <div className="logo-header col-12 text-left">
					        <Link to ="/" className="logo"><img src="images/logo/jobyardlogo.png" alt=""/></Link>
                            <Link to="/" className="logo-white"><img src="images/logo/jobyardlogo2.png" alt=""/></Link>
                       </div>
					</div>
					</div>	
					<div className="widget">
					   <h6 className="m-b10 m-l10 text-primary">Follow Us On</h6>
					    <ul className="list-inline">
							<li><Link target="_blank" to="https://www.facebook.com/" className="site-button white facebook circle "><i className="fa fa-facebook"></i></Link></li>
							<li><Link target="_blank" to="https://www.linkedin.com/" className="site-button white linkedin circle "><i className="fa fa-linkedin"></i></Link></li>
							<li><Link target="_blank" to="https://www.instagram.com/" className="site-button white instagram circle "><i className="fa fa-instagram"></i></Link></li>
							<li><Link target="_blank" to="https://twitter.com/" className="site-button white twitter circle "><i className="fa fa-twitter"></i></Link></li>
						</ul>	
					</div>
				</div>
				<div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
					<div className="widget border-0">
						<h5 className="m-b30 text-white">Company</h5>
						<ul className="list-2 w10 list-line">
							<li><Link to={"/about-us"}>About Us</Link></li>
							<li><Link to={"/contact-us"}>Contact Us</Link></li>
							{/* <li><Link to={"#"}>Support</Link></li>
							<li><Link to={"#"}>How It Works</Link></li>
							<li><Link to={"#"}>For Employers</Link></li>
							<li><Link to={"#"}>Underwriting</Link></li>
							<li><Link to={"#"}>Contact Us</Link></li>
							<li><Link to={"#"}>Lending Licenses</Link></li>
							<li><Link to={"#"}>Support</Link></li> */}
						</ul>
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
					<div className="widget border-0">
						<h5 className="m-b30 text-white">Terms & Policies</h5>
						<ul className="list-2 w10 list-line">
							<li><Link to={"/privacy-policies"}>Privacy & Policies</Link></li>
							<li><Link to={"/term-and-condition"}>Terms And Condition</Link></li>
							<li><Link to={"/community-guidelines"}>Community And Guidelines </Link></li>
						</ul>
					</div>
				</div>
				<div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
					<div className="widget border-0">
						<h5 className="m-b30 text-white">Find Jobs</h5>
						<ul className="list-2 w10 list-line">

							{cityData.map((val,ind)=>{
								return(
									<li key={ind} onClick={()=>locationHandler(val.title)}><Link >{val.title} Jobs</Link></li>
							)})}
							
							
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="footer-bottom">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 text-center">
					<span> © Copyright  2023 by <i className="fa fa-heart m-lr5 text-red heart"></i>
					<Link to="https://yoosys.in" target="_blank">Yoosys </Link> All rights reserved.</span> 
				</div>
			</div>
		</div>
	</div>
</footer>
  );
}

export default Footer;
